import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import { POST_PATIENTLIST } from "../_main/actiontype";
import { RefreshToken } from "./RefreshSlice";

export const patientList = createAsyncThunk(
  POST_PATIENTLIST + "/postpatientlist",
  async (params, thunkAPI) => {
    const token = localStorage.getItem("AccessToken");

    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    };

    const url = `${API_URL + "Patient/list"}`;
    const response = await fetch(url, option).then((res) => {
      if (res.status == 401) {
        thunkAPI.dispatch(RefreshToken());
      }
      return res.json();
    });

    return response;
  }
);

const patientlistSlice = createSlice({
  name: POST_PATIENTLIST,
  initialState: {
    patient: [],
    nextPage: 1,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(patientList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patientList.fulfilled, (state, action) => {
      state.patient = action.payload;
      state.loading = false;
    });
    builder.addCase(patientList.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default patientlistSlice.reducer;
