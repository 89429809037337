import React, { useState } from "react";
import Footer from "../../Component/Footer";
import { Formik, Form, Field } from "formik";
import Pform from "../../Component/Pform";
import { NOT_EMPTY_REGEX } from "../../_main/constants";
import { InputMask } from "primereact/inputmask";
import {
  EMPTY_FIRSTNAME,
  EMPTY_LASTNAME,
  EMPTY_EMAIL,
  EMPTY_PHONE,
  EMPTY_DOB,
  EMPTY_MRN,
} from "../../_main/errorConstants";
import "./styles.css";
import { TextField } from "formik-material-ui";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import Picker from "../../Component/Date";
import { useNavigate } from "react-router-dom";
import { MEDICAL_ROUTE } from "../../_main/routeConstants";

function Patientform() {
  //navigation
  const navigate = useNavigate();

  //state
  const [checked, setChecked] = useState(false);
  const [selectName, setSelectName] = useState(null);
  const [selectName1, setSelectName1] = useState(null);
  const [selectName2, setSelectName2] = useState(null);
  const [hide, setHide] = React.useState("");
  const [values, setValues] = useState(null);
  const [show, toggleShow] = React.useState(false);
  const [list, setList] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const initials = {
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    dob: "",
    mrn: "",
  };
  const [initialVal, setInitialValues] = useState(initials);

  const navigateToEdit = (test) => {
    setHide(test);
    toggleShow(true);
  };

  const handleSubmit = (form) => {
    form.selectName = selectName.name;
    form.list = list;
  };

  const name = [
    { name: "- select one -" },
    { name: "Jr" },
    { name: "Sr" },
    { name: "II" },
    { name: "III" },
    { name: "IV" },
  ];

  const onSelectNameChange = (e) => {
    setSelectName(e.value);
  };

  const name9 = [
    { name: "A +ve" },
    { name: "A -ve" },
    { name: "B +ve" },
    { name: "B -ve" },
    { name: "AB +ve" },
    { name: "AB -ve" },
  ];

  const onSelectNameChange2 = (e) => {
    setSelectName(e.value);
  };

  const name11 = [{ name: "Male" }, { name: "Female" }];

  const onSelectNameChange1 = (e) => {
    setSelectName(e.value);
  };

  const onListChange = (e) => {
    let selectedList = [...list];
    if (e.checked) selectedList.push(e.value);
    else selectedList.splice(selectedList.indexOf(e.value), 1);
    setList(selectedList);
  };

  return (
    <div className="patient-container">
      <Card className="addpatient-card">
        <div
          style={{
            background: "#009688",
            borderRadius: "5px 6px 11px 10px",
          }}
        >
          <div className="ap-header">general information</div>
          <div
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "0px 0px 10px 10px",
            }}
          >
            <Formik
              initialValues={initialVal}
              onSubmit={handleSubmit}
              enableReinitialize
              validate={(values) => {
                const errors = {};
                for (const obj in values) {
                  switch (obj) {
                    case "firstname":
                      !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                        (errors.firstname = EMPTY_FIRSTNAME);
                      break;
                    case "lastname":
                      !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                        (errors.lastname = EMPTY_LASTNAME);
                      break;
                    case "email":
                      !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                        (errors.email = EMPTY_EMAIL);
                      break;
                    case "phone":
                      !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                        (errors.phone = EMPTY_PHONE);
                      break;
                 
                    case "mrn":
                      !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                        (errors.mrn = EMPTY_MRN);
                      break;
                  }
                }
                return errors;
              }}
            >
              {({}) => (
                <Form>
                  {!show ? (
                    <div>
                      <div className="grid">
                        <div className="col-3 md:col-6 lg:col-3">
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <div className="cfield-container">
                              <div>
                                <div>
                                  <label className="patient-label">
                                    First Name
                                  </label>
                                </div>
                                <div>
                                  <Field
                                    component={TextField}
                                    className="apinput-container"
                                    name="firstname"
                                    placeholder="Enter First Name"
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    disabled={false}
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="cfield-container">
                              <div>
                                <label className="patient-label">
                                  Last Name
                                </label>
                              </div>
                              <div>
                                <Field
                                  component={TextField}
                                  className="apinput-container"
                                  name="lastname"
                                  type="text"
                                  variant="outlined"
                                  placeholder="Enter Last Name"
                                  size="small"
                                  disabled={false}
                                />
                              </div>
                            </div>
                            <div className="cfield-container">
                              <div>
                                <label className="patient-label">
                                  Patient Name Suffix
                                </label>
                              </div>
                              <div>
                                <Dropdown
                                  className="pdropdown"
                                  value={selectName}
                                  options={name}
                                  onChange={onSelectNameChange}
                                  optionLabel="name"
                                  placeholder="- select one -"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="col-3 md:col-6 lg:col-3">
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div className="cfield-container">
                                <div style={{ marginBottom: "-0.5em" }}>
                                  <label className="patient-label">
                                    Date Of Birth
                                  </label>
                                </div>
                                <div className="apinput-container">
                                  <Picker />
                                </div>
                              </div>
                              <div className="cfield-container">
                                <div>
                                  <label className="patient-label">
                                    Email ID
                                  </label>
                                </div>
                                <div style={{ marginTop: "-0.5em" }}>
                                  <Field
                                    component={TextField}
                                    className="apinput-container"
                                    name="email"
                                    type="text"
                                    placeholder="Enter Email ID"
                                    variant="outlined"
                                    size="small"
                                    disabled={false}
                                  />
                                </div>
                              </div>
                              <div className="cfield-container">
                                <div>
                                  <label className="patient-label">
                                    Phone Number &nbsp;
                                    <span className="ap-format">
                                      format: (+444-555-6666)
                                    </span>
                                  </label>
                                </div>
                                <div style={{ marginTop: "-0.5em" }}>
                                  <Field
                                    component={TextField}
                                    className="apinput-container"
                                    name="phone"
                                    type="text"
                                    placeholder="Enter Phone Number"
                                    variant="outlined"
                                    size="small"
                                    disabled={false}
                                  />
                                 
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="col-3 md:col-6 lg:col-3">
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div className="cfield-container">
                                <div style={{ marginBottom: "-0.5em" }}>
                                  <label className="patient-label">
                                    Enrollment
                                  </label>
                                </div>
                                <div className="apinput-container">
                                  <Picker />
                                </div>
                              </div>
                              <div className="cfield-container">
                                <div>
                                  <label className="patient-label">
                                    MEDICAL RECORD NUMBER(MRN)
                                  </label>
                                </div>
                                <div style={{ marginTop: "-0.5em" }}>
                                  <Field
                                    component={TextField}
                                    className="apinput-container"
                                    name="mrn"
                                    placeholder="Enter MRN"
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    disabled={false}
                                  />
                                </div>
                              </div>
                              <div className="cfield-container">
                                <div>
                                  <label className="patient-label">
                                    Blood Group
                                  </label>
                                </div>
                                <div>
                                  <Dropdown
                                    className="pdropdown"
                                    value={selectName}
                                    options={name9}
                                    onChange={onSelectNameChange}
                                    optionLabel="name"
                                    placeholder="Select Blood Group"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div className="col-3 md:col-6 lg:col-3">
                            <div
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              <div className="cfield-container">
                                <div style={{ marginBottom: "-0.5em" }}>
                                  <label className="patient-label">
                                    Disease
                                  </label>
                                </div>
                                <div className="apinput-container">
                                  <Field
                                    component={TextField}
                                    className="apinput-container"
                                    name="disease"
                                    placeholder="Enter Disease"
                                    type="text"
                                    variant="outlined"
                                    size="small"
                                    disabled={false}
                                  />
                                </div>
                              </div>
                              <div className="cfield-container">
                                <div>
                                  <label className="patient-label">
                                    Gender
                                  </label>
                                </div>
                                <div style={{ marginTop: "-0.5em" }}>
                                  <Dropdown
                                    className="pdropdown"
                                    value={selectName}
                                    options={name11}
                                    onChange={onSelectNameChange}
                                    optionLabel="name"
                                    placeholder="Select Gender"
                                  />
                                </div>
                              </div>
                              <div className="cfield-container">
                                <div>
                                  <label className="patient-label">
                                    Select Patient Services
                                  </label>
                                </div>
                                <div className="pcheck">
                                  <Checkbox
                                    className="csize"
                                    inputId="list1"
                                    name="list"
                                    value="RPM"
                                    onChange={onListChange}
                                    checked={list.indexOf("RPM") !== -1}
                                  />
                                  <div className="rcheck">RPM</div>
                                  <Checkbox
                                    className="csize"
                                    inputId="list2"
                                    name="list"
                                    value="CCM"
                                    onChange={onListChange}
                                    checked={list.indexOf("CCM") !== -1}
                                  />
                                  <div className="rcheck">CCM</div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="commonclass">
                        <div className="field-container">
                          <div className="patient-btn">
                            <Button
                              className="pbtn-style"
                              variant="contained"
                              type="submit"
                              color="primary"
                              disabled={false}
                            >
                              SAVE
                            </Button>
                            <Button
                              className="pbtn1-style"
                              variant="contained"
                              type="submit"
                              color="primary"
                              disabled={false}
                              onClick={navigateToEdit}
                            >
                              NEXT
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div>123</div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
            {/* <Footer /> */}
          </div>
        </div>
      </Card>
    </div>
  );
}

export default Patientform;
