import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import callfetch from "../_main/fetch";
import { GET_METTING_LINK } from "../_main/actiontype";

export const videoUser = createAsyncThunk(
  GET_METTING_LINK + "/videoUser",
  async (param) => {
    const token = localStorage.getItem("AccessToken");
    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(param),
    };

    const url = `${API_URL + "Twilio/link?"}`;
    const response = await callfetch(url, option);
    localStorage.setItem("VideoToken", response.data.token);
    const value = await response;
    return value;
  }
);

const videoSlice = createSlice({
  name: GET_METTING_LINK,
  initialState: {
    video: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(videoUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(videoUser.fulfilled, (state, action) => {
      state.video = action.payload;
      state.loading = false;
    });
    builder.addCase(videoUser.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default videoSlice.reducer;
