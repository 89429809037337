import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, GET } from "../_main/constants";
import { GET_NOTIFICATIONREAD } from "../_main/actiontype";
import { RefreshToken } from "./RefreshSlice";

export const notificationRead = createAsyncThunk(
  GET_NOTIFICATIONREAD + "/getnotificationread",
  async (params, thunkAPI) => {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    const token = localStorage.getItem("AccessToken");

    const option = {
      method: GET,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const url = `${API_URL + "Notification/read?" + query}`;

    const response = await fetch(url, option).then((res) => {
      if (res.status == 401) {
        thunkAPI.dispatch(RefreshToken());
      }
      return res.json();
    });

    return response;
  }
);

const NotificationRead = createSlice({
  name: GET_NOTIFICATIONREAD,
  initialState: {
    NotificationList: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(notificationRead.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(notificationRead.fulfilled, (state, action) => {
      state.Register = action.payload;
      state.loading = false;
    });
    builder.addCase(notificationRead.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default NotificationRead.reducer;
