import React, { Profiler } from "react";
import "./styles.css";
import Avatar from "@mui/material/Avatar";
import Endcall from "../../asset/Image/Endcall.svg";
import Phonecall from "../../asset/Image/Phonecall.svg";
import profile from "../../asset/Image/profile.png";

  function Notification(props) {

    const acceptConnection = () => {
      props.connection.accept();
    };
    const rejectConnection = () => {
      props.connection.reject();
      props.callclick(true)
    };

  return (
    <div className="entirediv">
      <div className="notifycard">
        <div className="notifyflex">
          <div className="notifyavatardiv">
            <Avatar
              //  image="user.png" size="xlarge"
              shape="circle"
              style={{
                width: "80px",
                height: "80px",
                display: "flex",
                marginLeft: "auto",
                marginRight: "auto",
                marginTop: "2%",
              }}
            />
          </div>
          <div className="notifyphonediv">
            <div className="notifyphoneflex">
              <div className="notifyphonetext">Pradeep Kumar</div>
              <div className="notifyphonenumb">+91 9629976615</div>
            </div>
          </div>
          <div className="notifycalldiv">
            <img className="endcalldiv" src={Endcall}  onClick={rejectConnection}/>
            <img className="endcalldiv" src={Phonecall} onClick={acceptConnection}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Notification;
