//GET
export const GET_LOGDETAILS = "getlogdetails";
export const GET_STAFFDETAILS = "getstaffdetails";
export const GET_EVENTS = "getEvents";
export const GET_OTHERDETAIL = "getotherdetail";
export const GET_OTHERDETAILLIST = "getotherdetaillist";
export const GET_ADDPATIENT = "getaddpatient";
export const GET_DROPDOWNPATIENT = "getpatientdropdetails";
export const GET_STAFF = "getstaff";
export const GET_PATIENT = "getpatient";
export const GET_PATIENTID = "getpatientid";
export const GET_METTING_LINK = "videolink";
export const GET_MEETINGLOG = "getmeetinglog";
export const GET_MEETINGPARTICIPANTLOG = "getmeetingparticipantlog";
export const GET_DOCTORNOTES = "getdoctornotes";

export const GET_ORGANIZATION = "getorganization";
export const GET_EVENT = "getevent";
export const GET_PROFILEID = "getprofiledetail";
export const GET_EVENT_ID = "getEventId";
export const GET_NOTIFICATIONLIST = "getnotificationlist";
export const GET_LOCAL_STORAGE = "setLocalStorageDetails";
export const GET_NEWNOTIFICATIONLIST = "getnewnotificationlist";
export const GET_NOTIFICATIONREAD = "getnotificationread";
export const GET_NOTIFICATIONDELETE = "getnotificationdelete";
export const GET_COUNTS = "getCountsList";
export const GET_DROPDOWNMEMBERSHIPTYPE = "getmembershiptypedetails";
export const GET_TIMER = "gettimerdetail";
export const GET_CCM_TIMER = "getccmtimerdetail";
export const GET_FILTERED_DATA = "getFilteredData";
export const GET_NEW_READINGS = "getnewReadings";
export const VOICE_CALL = "getVoiceDeviceDetails";

//POST
export const POST_LOGOUT = "postlogout";
export const POST_LOGIN = "login";
export const POST_REGISTER = "Register";
export const POST_RESET = "reset";
export const POST_PROFILE = "profile";
export const POST_FORGOT = "change";
export const POST_ORGANIZATION = "postorganization";
export const POST_UPDATECHAT = "getlogdetails";
export const POST_UPDATEDETAILS = "postupdatedetails";
export const POST_PATIENT = "addpatient";
export const POST_CHATLIST = "Postgchatlist";
export const POST_CHATLATEST = "Postchatlatest";
export const POST_CHATTEXT = "Chattextmessage";
export const POST_DOCTORNOTE = "postdoctornote";
export const POST_MEDICATION = "postmedication";
export const POST_DIAGNOSIS = "postdiagnosis";

export const POST_DOCTORNOTES = "postdoctornotes";
export const POST_UPLOAD = "uploadimage";
export const POST_EVENTLIST = "posteventlist";
export const POST_SCHEDULE = "postschedule";
export const POST_PATIENTLIST = "postpatientlist";
// export const POST_NOTIFICATIONLIST ='postnotificationlist';
export const POST_PATIENTALLLIST = "patientalllist";
export const POST_BIONIME = "bionime";
export const POST_TIMER = "posttimer";
export const POST_IGLUCOSE = "postIglucose";
export const POST_CCM_TIMER = "updateccmtimer";
export const POST_PATIENTVIEWLIST = "postpatientVIEWlist";
export const POST_ACTIVE = "activepatient";
export const POST_VALIDATEUSERNAME = "validusername";

//DELETE
export const DELETE_CHATTEXT = "deletechattext";
export const DELETE_EVENT = "deleteevent";
export const DELETE_DOCTORNOTES = "deletedoctornotes";
export const DELETE_MEDICATION = "deletemedication";
export const DELETE_DIAGNOSIS = "deletediagnosis"
export const DELETE_PATIENT = "deletepatient";
export const DELETE_STAFF = "deletestaff";

//UPDATE
export const UPDATE_ORGANIZATION = "updateorganization";
export const CHANGE_PASSWORD = "changePassword";
