import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, GET } from "../_main/constants";
import callfetch from "../_main/fetch";
import { GET_STAFF } from "../_main/actiontype";

export const staffgetUsers = createAsyncThunk(
  GET_STAFF + "/staffgetUsers",
  async (params) => {
    const option = {
      method: GET,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };

    const url = `${API_URL + ""}`;
    const response = await callfetch(url, option);
    const value = await response;
    return value;
  }
);

const StaffGetSlice = createSlice({
  name: GET_STAFF,
  initialState: {
    getstaff: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(staffgetUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(staffgetUsers.fulfilled, (state, action) => {
      state.organization = action.payload;
      state.loading = false;
    });
    builder.addCase(staffgetUsers.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default StaffGetSlice.reducer;
