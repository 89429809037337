import React, { useState } from "react";
import { Button } from "primereact/button";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { Formik, Form, Field } from "formik";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css";
import { TextField } from "formik-material-ui";
import {
  NOT_EMPTY_REGEX,
  EMAIL_REGEX,
  PASSWORD_REGEX,
  VALIDEMAIL_REGEX,
  ALPHA_REGEX
} from "../../_main/constants";
import {
  EMPTY_USERNAME,
  EMPTY_PASSWORD,
  EMPTY_EMAIL,
  EMPTY_CONFIRMPASSWORD,
} from "../../_main/errorConstants";
import { useDispatch } from "react-redux";
import "./styles.css";
import { RegisterUsers } from "../../Api_Redux/RegisterSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { BASE_ROUTE } from "../../_main/routeConstants";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

function Signup() {
  //navigation
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //state
  const [active, setActive] = useState(false);
  const [updateduserType, setUpdatedUserType] = useState(0);
  const handleClick = () => {
    setActive(!active);
  };

  const initial = {
    id: 0,
    userName: "",
    email: "",
    password: "",
    confirmpassword: "",
    userType: updateduserType,
    phoneNumber: "",
    npiNumber: "",
    avatarUrl: "",
    firstName: "",
    lastName: "",
  };

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [confirmpasswordType, setConfirPassword] = useState("password");
  const [confirmPassword, setConfirmPassword] = useState("");

  const term = () => {
    window.open("https://www.rmthealth.com/privacy-policy")
  }


  const handlePasswordChange = (event) => {
    setPasswordInput(event.target.value);
  };
  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleConfirmPass = () => {
    if (confirmpasswordType === "password") {
      setConfirPassword("text");
      return;
    }
    setConfirPassword("password");
  };


  const handleSubmit = (form, { resetForm }) => {
    if (form.confirmpassword === form.password) {
      dispatch(RegisterUsers(form))
        .then(async (isSuccess) => {
          if (isSuccess.payload.status == 1) {
            resetForm();
            toast.success(isSuccess.payload.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setTimeout(() => {
              navigate(BASE_ROUTE);
            }, 1000);
          } else if (isSuccess.payload.status == 0) {
            resetForm();
            toast.error(isSuccess.payload.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          
          } else {
            resetForm();
            toast.error("Invalid", {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setTimeout(() => { }, 1000);
          }
        })
        .catch(({ error }) => {
          console.log(error);
        });
      setUpdatedUserType(0);
    } else {
      toast.error("Password & Confirm Password Mismatch", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
      setUpdatedUserType(0);
    }
    resetForm();
  };

  const handleButtonClick = () => {
    setUpdatedUserType(1);
  };

  return (
    <div>
      {/* <p className="select">Select Patient or Care Provider</p> */}
      <div className='main-Conatiner Register'>
        
          <div className='header-container'>
            <div className='header-setion'>
              <div className='header-content'>RPM</div>
              <div className='header-rpm'>DOCTOR</div>
            </div>
            <div className="header-field">
              <div className="regis-Container">
             
                <Button
                  className="rbtn2"
                  disabled={updateduserType == 1}
                  onClick={handleButtonClick}
                >
                  I am a Care Provider
                </Button>
              </div>
              <Formik
                initialValues={initial}
                onSubmit={handleSubmit}
                enableReinitialize
                validate={(values) => {
                  const errors = {};
                  for (const obj in values) {
                    switch (obj) {
                      case "userName":
                        !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                          (errors.userName = EMPTY_USERNAME);
                        break;
                      case "email":
                        !RegExp(EMAIL_REGEX, "g").test(values[obj]) &&
                          (errors.email = EMPTY_EMAIL);
                        break;
                      case "password":
                        !RegExp(PASSWORD_REGEX, "g").test(values[obj]) &&
                          (errors.password = EMPTY_PASSWORD);
                        break;
                      case "confirmpassword":
                        !RegExp(PASSWORD_REGEX, "g").test(values[obj]) &&
                          (errors.confirmpassword = EMPTY_CONFIRMPASSWORD);
                        break;
                    }
                  }
                  return errors;
                }}
              >
                {({ }) => (
                  <Form className=" product-section">
                    <div className="field-container">
                      <p className='mb-0'>
                        <label className="name-list">User Name</label>
                      </p>
                      <div className='reg-inputfield'>
                        <Field
                          component={TextField}
                          className="rinput-container"
                          name="userName"
                          type="text"
                          variant="outlined"
                          size="small"
                          disabled={false}
                        />
                      </div>
                      <p className='mb-0'>
                        <label className="name-list">Email</label>
                      </p>
                      <div className='reg-inputfield'>
                        <Field
                          component={TextField}
                          className="rinput-container"
                          name="email"
                          type="text"
                          variant="outlined"
                          size="small"
                          disabled={false}
                        />
                      </div>
                      <p className='mb-0'>
                        <label className="name-list">Password</label>
                      </p>
                      <div className='reg-inputfield'>
                        <Field
                          component={TextField}
                          className="rinput-container"
                          name="password"
                          variant="outlined"
                          size="small"
                          disabled={false}
                          type={passwordType}
                          onClick={handlePasswordChange}
                        />

                        <span onClick={togglePassword} style={{ marginRight: "-1.5rem" }}>
                          {passwordType === "password" ? (
                            <VisibilityOffIcon
                              style={{ position: "relative", right: "1.5em", top: "8px" }}
                            />
                          ) : (
                            <VisibilityIcon
                              style={{ position: "relative", right: "1.5em", top: "8px" }}
                            />
                          )}
                        </span>
                      </div>
                      <p className='mb-0'>
                        <label className="name-list">Confirm Password</label>
                      </p>
                      <div className='reg-inputfield'>
                        <Field
                          component={TextField}
                          className="rinput-container"
                          type={confirmpasswordType}
                          onClick={handleConfirmPassword}
                          name="confirmpassword"
                          variant="outlined"
                          size="small"
                          disabled={false}
                        />

                        <span
                          onClick={toggleConfirmPass}
                          style={{ marginRight: "-1.5rem" }}
                        >
                          {confirmpasswordType === "password" ? (
                            <VisibilityOffIcon
                              style={{ position: "relative", right: "1.5em", top: "8px" }}
                            />
                          ) : (
                            <VisibilityIcon
                              style={{ position: "relative", right: "1.5em", top: "8px" }}
                            />
                          )}
                        </span>
                      </div>
                    </div>
                    <div className="btn-container">
                      <Button
                        className="button-regis"
                        variant="contained"
                        color="primary"
                        type="submit"
                      >
                        Register
                      </Button>
                    </div>
                    <div className="text-Container">
                      <div className="signup-text">Have already an account?</div>
                      <a className="loginText" href="/">
                        &nbsp; Login Here
                      </a>
                    </div>
                  </Form>
                )}
              </Formik>
              <ToastContainer />
              <div className="ftext">
                <AiOutlineCopyrightCircle />&nbsp;
                <div>
                  2023&nbsp;-&nbsp;Rpm.Doctor&nbsp;-&nbsp;<a onClick={term}>Privacy</a>
                </div>
              </div>
            </div>

          </div>
        
      </div>

    </div>
  );
}

export default Signup;
