import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import ReactDOM from "react-dom";

import React, { useState } from "react";
import { SelectButton } from "primereact/selectbutton";


const SelectButtonRpmGraphDemo = (props) => {

  const [value1, setValue1] = useState("RPM");

  //   const options = ["RPM", "CCM"];

  //   const handleSelectbuttonchange = (e) =>{
  // props.onSelectchange(e.value)
  // setValue1(e.value)

  const options = props?.individualpatient?.rpm && props?.individualpatient?.ccm ? ["RPM", "CCM"] : props?.individualpatient?.rpm ? ["RPM"] : props?.individualpatient?.ccm ? ["CCM"] : [];
  const handleSelectbuttonchange = (e) => {
    if (e.value != null) {
      props.onSelectchange(e.value)
      setValue1(e.value)
    }
  }


  return (
    <div>
      <div>
        <SelectButton
          value={value1}
          options={options}
          // onChange={(e) => setValue1(e.value)}
          // onChange={handleSelectbuttonchange}
          onChange={handleSelectbuttonchange}

        />
      </div>
    </div>
  );
};

export default SelectButtonRpmGraphDemo;