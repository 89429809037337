import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { updateccmtimer } from "../../Api_Redux/TimerccmUpdateSlice";
import "./styles.css";

const Timer = (props) => {
  // const [timer, setTimer] = useState(props.timerData);
  const [timer, setTimer] = useState(props.data[0].totalSeconds);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const increment = useRef(null);
  const dispatch = useDispatch()
  const { state } = useLocation();

  useEffect(() => {
    handleStart()
  }, [])

  useEffect(() => {
    localStorage.setItem('ccm_timer', JSON.stringify(timer));
  }, [timer]);

  const handleStart = () => {
   
    setIsActive(true);
    setIsPaused(true);
    increment.current = setInterval(() => {
      setTimer((timer) => {
        // console.log("timer123",timer)
        // if (timer % 10 == 0) {
          if (timer % 10 == 0 && timer != props.data[0].totalSeconds) {
          const timerPayload = {
            userId:state?.userId,
            totalSeconds: timer,
            sessionId:1,
            id: props.data[0].id
          };
          dispatch(updateccmtimer(timerPayload))
          // console.log("timer123", timer);
        }
        return timer + 1;
      });
    }, 1000);
  };
  // useEffect(() => {
  //   handleStart();
  // }, []);

  const handlePause = () => {
    clearInterval(increment.current);
    setIsPaused(false);
  };

  const handleResume = () => {
    setIsPaused(true);
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handleReset = () => {
    clearInterval(increment.current);
    setIsActive(false);
    setIsPaused(false);
    setTimer(0);
  };

  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);

    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };

  return (
    <div className="video-overlay">
      <p>{formatTime()}</p>
      <div className="timer-button">
        {!isActive && !isPaused ? (
          <button onClick={handleStart}>Start</button>
        ) : isPaused ? (
          <button onClick={handlePause}>Pause</button>
        ) : (
          <button onClick={handleResume}>Resume</button>
        )}
        {/* <button onClick={handleReset} disabled={!isActive}>
          Reset
        </button> */}
      </div>
    </div>
  );
};

export default Timer;
