import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import smalltick from "../../asset/Image/smalltick.svg";
import { INDIVIDUALPATIENTDETAILS_ROUTE } from "../../_main/routeConstants";
import chat from "../../asset/Image/chat.svg";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";
import moment from "moment";
// import { getotherdetailslist } from "../../Api_Redux/PatientGetOtherDetailsListSlice";

const PatientUpdate = (props) => {
  const actionBodyTemplate1 = (rowData) => {
    return (
      <React.Fragment>
        <img src={smalltick} />
      </React.Fragment>
    );
  };
  // const dateTemplate = (rowData) => {
  //   return moment(rowData['dob']).format("DD-MM-YYYY");
  // }

  const individualpatient = useSelector(
    (state) => state.PatientidSlice.patientid.data
  );
  const dateTemplate = () => {
    return moment(individualpatient.dob).format("MM-DD-YYYY");
  };

  const dateTemplate1 = () => {
    return moment(individualpatient.enrollmentDate).format("MM-DD-YYYY");
  };

  return (
    <div>
      <div className="tabledata">
        <DataTable
          className="table-border"
          value={props.personalInformation}
          responsiveLayout="scroll"
        >
          <Column field="FullName" header="NAME"></Column>
          <Column field="Phone" header="PHONE NUMBER"></Column>
          <Column field="DOB" header="DOB" body={dateTemplate}></Column>
          <Column
            field="EnrollmentDate"
            header="DATE OF ENROLLMENT"
            body={dateTemplate1}
          ></Column>
          <Column body={actionBodyTemplate1} header="PHONE CALL"></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default PatientUpdate;
