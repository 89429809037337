import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, GET } from "../_main/constants";
import callfetch from "../_main/fetch";
import { GET_LOGDETAILS } from "../_main/actiontype";

export const getlogdetails = createAsyncThunk(
  GET_LOGDETAILS + "/getlogdetails",
  async (params) => {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    const token = localStorage.getItem("AccessToken");
    const option = {
      method: GET,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const url = `${API_URL + "Twilio/getparticipantlogdetail?" + query}`;
    const response = await callfetch(url, option);
    const value = await response;
    return value;
  }
);
const GetParticipantLogDetailsSlice = createSlice({
  name: GET_LOGDETAILS,
  initialState: {
    getlog: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getlogdetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getlogdetails.fulfilled, (state, action) => {
      state.getlog = action.payload;
      state.loading = false;
    });
    builder.addCase(getlogdetails.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default GetParticipantLogDetailsSlice.reducer;
