import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
// import Scatter from "primereact/Scatter";
// import "../../index.css";
import ReactDOM from "react-dom";

import React, { useState } from "react";
import { Chart } from "primereact/chart";
import moment from "moment";

const BarChartDemo = React.memo((props) => {
  const [createdDate, setCreatedDAte] = useState([]);
  const [Spo2, setSpo2] = useState([]);
  const [PulseBpm, setPulseBpm] = useState([]);

  if (props?.barChartBpGlucoseData?.length == 0) {
    createdDate.splice(0, createdDate.length);
  }

  if (props?.barChartBpGlucoseData?.length > 0) {
    createdDate.splice(0, createdDate.length);
    props?.barChartBpGlucoseData?.forEach((element, curr) => {

      createdDate.push(moment(element.createdOn).format("MM-DD-YYYY hh:mm A"));
      Spo2.push(JSON.parse(element.dataItemData).Spo2);
      PulseBpm.push(JSON.parse(element.dataItemData).PulseBpm);
    });
  }

  const [basicData] = useState({
    labels: createdDate,
    datasets: [
      {
        label: "Spo2",
        backgroundColor: "#009688",
        data: Spo2,
        borderWidth: 2,
        barThickness: 30,
        borderRadius: 20,
      },
      {
        label: "PulseBpm",
        backgroundColor: "#435D6A",
        data: PulseBpm,
        borderWidth: 2,
        barThickness: 30,
        borderRadius: 20,
      },
    ],
  });

  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: "grey",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "grey",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "grey",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };

    return {
      basicOptions,
    };
  };

  const { basicOptions } = getLightTheme();

  return (
    <div>
      <div className="card">
        <Chart type="bar" data={basicData} options={basicOptions} />
      </div>
    </div>
  );
});

export default BarChartDemo;
