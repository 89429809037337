import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, DELETE } from "../_main/constants";
import { DELETE_DIAGNOSIS } from "../_main/actiontype";
import { RefreshToken } from "./RefreshSlice";

export const deletediagnosis = createAsyncThunk(
  DELETE_DIAGNOSIS + "/delete-diagnosis",
  async (params, thunkAPI) => {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    const token = localStorage.getItem("AccessToken");
    const option = {
      method: DELETE,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const url = `${API_URL + "Patient/delete-diagnosis?" + query}`;
    const response = await fetch(url, option).then((res) => {
      if (res.status == 401) {
        thunkAPI.dispatch(RefreshToken());
      }
      return res.json();
    });

    return response;
  }
);

const DiagnosisDeleteSlice = createSlice({
  name: DELETE_DIAGNOSIS,
  initialState: {
    deletemed: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(deletediagnosis.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deletediagnosis.fulfilled, (state, action) => {
      state.deletemed = action.payload;
      state.loading = false;
    });
    builder.addCase(deletediagnosis.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default DiagnosisDeleteSlice.reducer;
