import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { POST, API_URL } from "../_main/constants";
import callfetch from "../_main/fetch";
import { POST_SCHEDULE } from "../_main/actiontype";
import { RefreshToken } from "./RefreshSlice";

export const scheduleUsers = createAsyncThunk(
  POST_SCHEDULE + "/scheduleUsers",
  async (params, thunkAPI) => {
    const token = localStorage.getItem("AccessToken");

    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    };

    const url = `${API_URL + "Event/update"}`;
    const response = await fetch(url, option).then((res) => {
      if (res.status == 401) {
        thunkAPI.dispatch(RefreshToken());
      }
      return res.json();
    });
    return response;
  }
);

export const ScheduleSlice2 = createAsyncThunk(
  "/Event/update",
  async (params) => {
    const option = {
      method: POST,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };

    const url = `${API_URL + "Auth/login"}`;
    const response = await callfetch(url, option);
    localStorage.setItem("AccessToken", response.data.accessToken);
    localStorage.setItem("refreshtoken", response.data.refreshToken);
    localStorage.setItem("careproviderId", response.data.id);
    localStorage.setItem("authorName", response.data.userName);
    const value = await response;
    return value;
  }
);

const ScheduleSlice = createSlice({
  name: POST_SCHEDULE,
  initialState: {
    schedule: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(scheduleUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(scheduleUsers.fulfilled, (state, action) => {
      state.schedule = action.payload;
      state.loading = false;
    });
    builder.addCase(scheduleUsers.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default ScheduleSlice.reducer;
