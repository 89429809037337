
//NEW CODE KAR

import React, { useState, useEffect } from "react";
import dashboard1 from "../../asset/Image/dashboard1.svg";
import calendar1 from "../../asset/Image/calendar1.svg";
import calendarcolor from "../../asset/Image/calendarcolor.svg";
import managepatientcolour from "../../asset/Image/managepatientcolour.svg";
import managestaffcolour from "../../asset/Image/managestaffcolour.svg";
import profilecolour from "../../asset/Image/profilecolour.svg";
import report from "../../asset/Image/report.svg";
import reportcolor from "../../asset/Image/reportcolor.svg";
import dmanagepatient from "../../asset/Image/dmanagepatient.svg";
import dprofile1 from "../../asset/Image/dprofile1.svg";
import dmanagestaff from "../../asset/Image/dmanagestaff.svg";
import { Navigate, NavLink, Outlet, useNavigate } from "react-router-dom";
// import Auth from "./Auth";
import sidebar_bg from "../../asset/Image/sidebar-bg.svg";
import logo from "../../asset/Image/logo.png";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { DASHBOARD_ROUTE } from "../../_main/routeConstants";
import { CHAT_ROUTE } from "../../_main/routeConstants";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import dashboardcolor from "../../asset/Image/dashboardcolor.svg";
import chatactive from "../../asset/Image/chatactive.svg";
import chat1 from "../../asset/Image/chat1.svg";
import healtheactive from "../../asset/Image/healtheactive.svg";
import healthinactive from "../../asset/Image/healthinactive.svg";
import { Sidebar, SidebarItem } from 'react-responsive-sidebar';
import appointment from "../../asset/Image/appointment.svg";
import appointmentcolor from "../../asset/Image/appointmentcolor.svg";

const careProviderMenu = [
  {
    name: "dashboard",
    image: dashboard1,
    image1: dashboardcolor,
    link: "/careprovider/dashboard",
    id: 1,
  },
  {
    name: "viewcalendar",
    image: calendar1,
    image1: calendarcolor,
    link: "/careprovider/viewcalendar",
    id: 2,
  },
  {
    name: "profile",
    image: dprofile1,
    image1: profilecolour,
    link: "/careprovider/profile",
    id: 3,
  },
  {
    name: "managepatient",
    // name1: "",
    image: dmanagepatient,
    image1: managepatientcolour,
    link: "/careprovider/managepatients",
    id: 4,
  },
  {
    name: "managestaff",
    // name1: "",
    image: dmanagestaff,
    image1: managestaffcolour,
    link: "/careprovider/managestaffs",
    id: 5,
  },

];



const patientMenu = [
  {
    name: "myhealth",
    image: healthinactive,
    image1: healtheactive,
    link: "/patient/myhealth",
  },
  {
    name: "profile",
    image: dprofile1,
    image1: profilecolour,
    link: "/patient/profile",
  },
  {
    name: "chat",
    image: chat1,
    image1: chatactive,
    link: "/patient/chat",
  },
  {
    name: "myappointment",
    image: appointment,
    image1: appointmentcolor,
    link: "/patient/myappointment",
  },
];

const doctorMenu = [
  {
    name: "dashboard",
    image: dashboard1,
    image1: dashboardcolor,
    link: "/staff/dashboard",
  },

  {
    name: "profile",
    image: dprofile1,
    image1: profilecolour,
    link: "/staff/profile",
  },

  {
    name: "managePatient",
    image: dmanagepatient,
    image1: managepatientcolour,
    link: "/Staff/managepatients",
  },
 
];

// const titlecase=(str)=>{
//   str = str.toUpperCase();
// }

function SideBars() {
  // const handleChange =(index) =>{
  //   if(careProviderMenu.id===1) return image1=dashboardcolor;
  // }

  const [state, setState] = useState(false);
  const [sidebar, setSidebar] = useState([]);

  const loginOrg = useSelector((state) => state.LoginSlice?.login?.data?.isOrg);
  const screenState = useSelector((state) => state.LoginSlice.login.data);
  const organizationData = useSelector((state) => { 
    // console.log('CCCCCCCCCC', state.OrganizationPostSlice?.organization);
     return state.OrganizationPostSlice?.organization?.data?.isOrg });

  // console.log('loginOrg', loginOrg)
  // console.log('organizationData', organizationData)

  const toggle = () => {
    setState(!state);
  };

  const navigate = useNavigate();
  const navigatelogo = () => {
    navigate(DASHBOARD_ROUTE);
  };
  // console.log("organizationData===>123", organizationData);

  useEffect(() => {
    setSidebar(screenState?.userType == 1
      ? careProviderMenu
      : screenState?.userType == 3
        ? patientMenu
        : screenState?.userType == 2
          ? doctorMenu
          : null);
  }, []);

  // const sidebarMenu = screenState.userType == 1 ? careProviderMenu : screenState.userType == 3 ? patientMenu : null

  const items2 = [
    <div className="sidebar-list"
      style={{
        backgroundImage: `url(${sidebar_bg})`,
        backgroundPosition: "center",
        backgroundRepeat: " no-repeat",
        backgroundSize: " cover",
      }}
    >
      <div className="side-bar sidemenu"

      >
        <div className="sidebar-image">
          <div className="side-logo">
            <img
              style={{ cursor: "pointer" }}
              src={logo}
              onClick={navigatelogo}
            />
          </div>

          {loginOrg == true && !organizationData && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {sidebar.map((item, index) => (
                  <div
                    className="sidebar-text"
                    key={index}
                    style={{
                      color: "#FFFFFF",
                      height: "120px",
                      textAlign: "center",
                      // textTransform: "uppercase",
                      fontSize: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      marginTop: "1em",
                      fontFamily: "Josefin Sans",
                    }}
                  >
                    <NavLink to={item.link}>
                      <div onClick={toggle}>
                        <div onClick={toggle}>
                          {window.location.href
                            .split("/")[5] == undefined ? window.location.href
                              .split("/")[4].includes(item.name) ? (
                            <img src={item.image1} className="imgcolor" />
                          ) : (
                            <img src={item.image} className="imgcolor" />
                          ) : window.location.href.split("/")[5].includes(item.name) ? (
                            <img src={item.image1} className="imgcolor" />
                          ) : (
                            <img src={item.image} className="imgcolor" />
                          )}
                        </div>
                        {/* <img onClick={handleChange}
                      src={item.image}
                      className='imgcolor'
                    /> */}
                      </div>

                      <div
                        className="catagories"
                        style={{ padding: "10px 0px", color: "#ffffff" }}
                      >
                        {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                      </div>
                      <div
                        className="catagories1"
                        style={{
                          padding: "0px 0px",
                          color: "#ffffff",
                          marginTop: "-13px",
                        }}
                      >
                        {item.name1}
                      </div>
                    </NavLink>
                    <div>
                      <hr style={{ width: "4em" }} />
                    </div>
                  </div>
              ))}
            </div>
          )}

          {organizationData == true && !loginOrg && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {sidebar.map((item, index) => (
               
                  <div
                    className="sidebar-text"
                    key={index}
                    style={{
                      color: "#FFFFFF",
                      height: "120px",
                      textAlign: "center",
                      // textTransform: "uppercase",
                      fontSize: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      marginTop: "1em",
                      fontFamily: "Josefin Sans",
                    }}
                  >
                    <NavLink to={item.link}>
                      <div onClick={toggle}>
                        <div onClick={toggle}>
                          {window.location.href
                            .split("/")[5] == undefined ? window.location.href
                              .split("/")[4].includes(item.name) ? (
                            <img src={item.image1} className="imgcolor" />
                          ) : (
                            <img src={item.image} className="imgcolor" />
                          ) : window.location.href.split("/")[5].includes(item.name) ? (
                            <img src={item.image1} className="imgcolor" />
                          ) : (
                            <img src={item.image} className="imgcolor" />
                          )}
                        </div>
                      </div>

                      <div
                        className="catagories"
                        style={{ padding: "10px 0px", color: "#ffffff" }}
                      >
                        {/* {item.name.toUpperCase()} */}

                        {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                      </div>
                      <div
                        className="catagories1"
                        style={{
                          padding: "0px 0px",
                          color: "#ffffff",
                          marginTop: "-13px",
                        }}
                      >
                        {/* {item.name1} */}
                      </div>
                    </NavLink>
                    <div>
                      <hr style={{ width: "4em" }} />
                    </div>
                  </div>
              
              ))}
            </div>
          )}

          {organizationData == true && loginOrg && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {sidebar.map((item, index) => (
               
                  <div
                    className="sidebar-text"
                    key={index}
                    style={{
                      color: "#FFFFFF",
                      height: "120px",
                      textAlign: "center",
                      // textTransform: "uppercase",
                      fontSize: "18px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      cursor: "pointer",
                      marginTop: "1em",
                      fontFamily: "Josefin Sans",
                    }}
                  >
                    {/* <img src={item.image1} className="imgcolor" />
                    <img src={item.image} className="imgcolor" /> */}
                    <NavLink to={item.link}>
                      <div onClick={toggle}>
                        {window.location.href
                          .split("/")[5] == undefined ? window.location.href
                            .split("/")[4].includes(item.name) ? (
                          <img src={item.image1} className="imgcolor" />
                        ) : (
                          <img src={item.image} className="imgcolor" />
                        ) : window.location.href.split("/")[5].includes(item.name) ? (
                          <img src={item.image1} className="imgcolor" />
                        ) : (
                          <img src={item.image} className="imgcolor" />
                        )}
                      </div>

                      <div
                        className="catagories"
                        style={{ padding: "10px 0px", color: "#ffffff" }}
                      >
                        {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
                      </div>
                      <div
                        className="catagories1"
                        style={{
                          padding: "0px 0px",
                          color: "#ffffff",
                          marginTop: "-13px",
                        }}
                      >
                        {/* {item.name1} */}
                      </div>
                    </NavLink>
                    <div>
                      <hr style={{ width: "4em" }} />
                    </div>
                  </div>
                
              ))}
            </div>
          )}
          {/* )} */}
        </div>
      </div>
    </div>
  ]

  return (
  
    <div className='mobile-menu'>
      <Sidebar content={items2} width={160}></Sidebar>
    </div>

  );
}

export default SideBars;
