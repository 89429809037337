import { GET_LOCAL_STORAGE } from "../_main/actiontype";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const setLocalStorageDetails = createAsyncThunk(
  GET_LOCAL_STORAGE + "/setLocalStorageDetails",
  async (params) => {
    // console.log("Login ======>>>>>>>>>>>>> 2", params)
    
    // const token = localStorage.getItem("AccessToken");
    // const option = {
    //   method: GET,
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //     "Content-Type": "application/json",
    //   },
    // };
    // const url = `${API_URL + "Notification/list"}`;
    // const response = await callfetch(url, option);
    // const value = await response;
    // return value;
  }
);

const LocalStorageSlice = createSlice({
  name: GET_LOCAL_STORAGE,
  initialState: {
    localStorageList: {
      notificationmsg: null,
      userId: null,
      chatrefresh: null,
      careproviderId: null,
      authorName: null,
      isLoggedIn: false,
      AccessToken: null,
      refreshtoken: null,
      UpdatedrefreshToken: null,
      email: null,
      userType: null,
      Notification: null,
      userID: null,
      roomSId: null,
      VideoToken: null,
      createddate: null,
      ccm_timer: null,
      timer: null,
      stateUserId: null,
      customRows1: null,
      current_page: null,
      incomingmessage: null,
    },
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setLocalStorageDetails.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setLocalStorageDetails.fulfilled, (state, action) => {
      state.Register = action.payload;
      state.loading = false;
    });
    builder.addCase(setLocalStorageDetails.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default LocalStorageSlice.reducer;
