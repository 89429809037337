// import {useEffect, useState } from 'react';
import Modal from "@mui/material/Modal";
import Avatar from "@mui/material/Avatar";
import MinimizeIcon from "@mui/icons-material/Minimize";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import CallEndIcon from "@mui/icons-material/CallEnd";
import FilterNoneIcon from "@mui/icons-material/FilterNone";
import IconButton from "@mui/material/IconButton";
import { useDispatch, useSelector } from "react-redux";
// import { popupMinimize, popupMaximize } from "../../Api_Redux/VoiceCallSDKSlice";
import { popupMinimize, popupMaximize } from "../../Api_Redux/VoiceCallSlice";
import { VoiceCallStatus } from "../../Component/States/states";
import Tooltip from "@mui/material/Tooltip";

function Ongoingcall(props) {
  const { handleHangup, fullName, phoneNumber, handleMute, mute, status } =
    props;
  const dispatch = useDispatch();
  // const getVoiceCallDetails = useSelector((state) => state.VoiceCallSDKSlice.voiceCallDetails);
  const getVoiceCallDetails = useSelector(
    (state) => state.VoiceCallSlice.voiceCallDetails
  );

  return (
    <div>
      <Modal
        className={`window-popup ${
          getVoiceCallDetails.popupMin ? "window-in" : ""
        }`}
        disableAutoFocus={true}
        disableEnforceFocus={true}
        open={true}
        onClose={handleHangup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="notifycard">
          <div className="header-modal">
            <div className="minimize-sec">
              <span>Outgoing Call</span>
              <Tooltip
                title={<p style={{ fontSize: "10px" }}>Minimize</p>}
                placement="right-start"
                arrow
              >
                <span
                  onClick={() => {
                    dispatch(popupMinimize());
                  }}
                >
                  <MinimizeIcon />
                </span>
              </Tooltip>
            </div>
            <div className="maximize-sec">
              <span>Outgoing Call</span>
              <Tooltip
                title={<p style={{ fontSize: "10px" }}>Minimize</p>}
                placement="right-start"
                arrow
              >
                <span
                  onClick={() => {
                    dispatch(popupMaximize());
                  }}
                >
                  <FilterNoneIcon />
                </span>
              </Tooltip>
            </div>
          </div>
          <div className="notifyflex">
            <div className="notifyavatardiv">
              <Avatar
                shape="circle"
                style={{
                  width: "80px",
                  height: "80px",
                  display: "flex",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "2%",
                }}
              />
            </div>
            <div className="notifyphonediv">
              <div className="notifyphoneflex">
                <div className="notifyphonetext">{fullName}</div>
                <div className="notifyphonenumb">{phoneNumber}</div>
                <div className="notifyphonenumb">{status}</div>
                {/* <div className="notifyphonenumb">{formatTime()}</div> */}
              </div>
            </div>
            <div className="notifycalldiv">
              <div className="mic-icon">
                {mute ? (
                  <Tooltip
                    title={<p style={{ fontSize: "10px" }}>Minimize</p>}
                    placement="right-start"
                    arrow
                  >
                    <IconButton onClick={handleMute}>
                      <MicOffIcon />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={<p style={{ fontSize: "10px" }}>Minimize</p>}
                    placement="right-start"
                    arrow
                  >
                    <IconButton
                      onClick={handleMute}
                      disabled={status === VoiceCallStatus.RINGING}
                    >
                      <MicIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </div>
              <div className="call-icon">
                <Tooltip
                  title={<p style={{ fontSize: "10px" }}>CallEnd</p>}
                  placement="right-start"
                  arrow
                >
                  <IconButton
                    onClick={handleHangup}
                    disabled={status === VoiceCallStatus.RINGING}
                  >
                    <CallEndIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Ongoingcall;
