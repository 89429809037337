import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_URL, POST } from '../_main/constants';
import {POST_CCM_TIMER} from '../_main/actiontype';
import { RefreshToken } from "./RefreshSlice";


export const updateccmtimer = createAsyncThunk(POST_CCM_TIMER+'/updateccmtimer', async (params,thunkAPI) => {

  const token = localStorage.getItem("AccessToken")

  const option = {
    method: POST,
    headers: {
      'Authorization':`Bearer ${token}`,
       'Content-Type': 'application/json',
       
  },
    body: JSON.stringify(params)
  };

  const url = `${API_URL + 'Timer/updateccmtimer'}`;    
  const response = await fetch(url, option)
  .then((res) => {
    if (res.status == 401) {
      thunkAPI.dispatch(RefreshToken())
    }
    return res.json();
  })

return response;


});


const updateccmtimerSlice = createSlice({
  name:POST_CCM_TIMER,
  initialState: {
    updateccmtimer: [],
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(updateccmtimer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateccmtimer.fulfilled, (state, action) => {
      state.Register = action.payload;
      state.loading = false;
    });
    builder.addCase(updateccmtimer.rejected, (state) => {
      state.loading = true;
    });
  }
});

export default updateccmtimerSlice.reducer;