import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import smalltick from "../../asset/Image/smalltick.svg";
import chat from "../../asset/Image/chat.svg";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";

const PatientMedications = () => {
  const createdDate = useSelector(
    (state) =>
      state.PatientGetOtherDetailsSlice?.getdetail?.data?.medicationList
  );

  const medication = [
    {
      rxnname: "Cymbalta",
      dose: "3 Dose",
      frequency: "2.5 Hz",
      description: "A dosage form that contains one or more active..",
    },
    {
      rxnname: "Cymbalta",
      dose: "3 Dose",
      frequency: "2.5 Hz",
      description: "A dosage form that contains one or more active..",
    },
  ];

  return (
    <div>
      <div className="tabledata">
        <DataTable
          className="table-border"
          value={createdDate}
          responsiveLayout="scroll"
        >
          <Column field="rxnName" header="RXN Name"></Column>
          <Column field="dose" header="DOSE"></Column>
          <Column field="frequency" header="FREQUENCY"></Column>
          <Column field="description" header="DESCRIPTION"></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default PatientMedications;
