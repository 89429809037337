import React, { useState, useRef, useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css"; //icons
import "primeflex/primeflex.css";


import Forgot from "../src/pages/Forgot";
import Reset from "../src/pages/Reset";
import Organization from "../src/pages/Organization";
import Dashboard from "../src/pages/Dashboard";
import Editprofile from "../src/pages/EditProfile";
import Profile from "../src/pages/Profile";
import Patientdashboard from "../src/pages/PatientDashboard";
import ManageStaff from "./pages/Manage Staff";
import Meetinglog from "./pages/Meetinglogos";
import Chat from "./pages/ChatDesign";
import Popup from "./pages/Popup";
import Report from "./pages/Report";
import Appointment from "./pages/Appointment";



import {
  BASE_ROUTE,
  FORGOT_ROUTE,
  RESET_ROUTE,
  DASHBOARD_ROUTE,
  STAFF_DASHBOARD_ROUTE,
  ORGANIZATION_ROUTE,
  EDITPROFILE_ROUTE,
  PROFILE_ROUTE,
  STAFF_PROFILE_ROUTE,
  PATIENT_PROFILE_ROUTE,
  INDIVIDUALPATIENTDETAILS_ROUTE,
  INDIVIDUALPATIENTDETAILSS_ROUTE,
  INDIVIDUALPATIENTDETAILSSS_ROUTE,
  MANAGEPATIENT_ROUTE,
  STAFF_MANAGEPATIENT_ROUTE,
  MANAGESTAFF_ROUTE,
  EDITSTAFF_ROUTE,
  ADDSTAFF_ROUTE,
  CALENDAREVE_ROUTE,
  NOTIFICATION_ROUTE,
  MEETINGLOGS_ROUTE,
  STAFF_MEETINGLOGS_ROUTE,
  MEETINGDETAILS_ROUTE,
  STAFF_MEETINGDETAILS_ROUTE,
  SCHEDULEMEETING_ROUTE,
  DMEETING_ROUTE,
  ONGOINGCALL_ROUTE,
  ADDPATIENT_ROUTE,
  CHAT_ROUTE,
  CARE_CHAT_ROUTE,
  STAFF_CHAT_ROUTE,
  PATIENTS_ROUTE,
  EDIT_PATIENTS_ROUTE,
  PEDIT_PATIENTS_ROUTE,
  SEDIT_PATIENTS_ROUTE,
  STAFF_PATIENTS_ROUTE,
  POPUP_ROUTE,
  CHANGEPASSWORD_ROUTE,
  REPORT_ROUTE,
  PATIENTHEALTH_ROUTE,
  NOTIFICATIONPAGE_ROUTE,
  NEWNOTIFICATIONS_ROUTE,
  REGISTER_ROUTE,
  PATIENTREQUEST_ROUTE,
  APPOINTMENT_ROUTE,
} from "./_main/routeConstants";


import PrivateRouter from "./routes/PrivateRouter";
import Meetingdetails from "./pages/Meetingdetails";
import ManagePatient from "./pages/ManagePatient";
import EditStaff from "./pages/Edit Staff";
import AddStaff from "./pages/Add Staff";
import CalendarEve from "./pages/Calendar/index";
import Notification from "./pages/Notification";
import Schedulemeeting from "./pages/ScheduleMeeting";
import Dmeeting from "./Component/Table/dmeeting";
import Ongoingcall from "./pages/Ongoingcall/index";
import Patientform from "./pages/PatientForm/index";
import Changepassword from "./pages/ChangePassword/index";
import Patienthealth from "./pages/PatientHealth";
import Patients from "./pages/NewPatient/LinearStepper2";


import "./App.css";




import { requestForToken } from "./_main/firebase";
import { onMessageListener } from "./_main/firebase";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NotificationPage from "./pages/NotificationPage";
import NewNotifications from "./pages/NewNotifications";
import { IdleTimer } from "./Component/IdleTimer";
import { Button } from "primereact/button";
import Modal from "react-bootstrap/Modal";
import { logoutUsers } from "./Api_Redux/LogoutSlice";
import { useDispatch, useSelector } from "react-redux";
import Signup from "./pages/Register";
import Login from "./pages/Login";
import Patientrequest from "./pages/Patient Request";
import {
  reset,
  deviceDisconnect,
  deviceMute,
  deviceStatus,
} from "./Api_Redux/VoiceCallSlice";
import { VoiceCallStatus } from "../src/Component/States/states";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ScatterController,
  LineController,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin,
  ScatterController,
  LineController
);

function App() {
  const idleTimerRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [modalOpen, SetModalOpen] = useState(false);
  const [deviceId, setDeviceId] = useState("");
  const [mute, SetMute] = useState(false);
  const [notification, setNotification] = useState({});
  localStorage.setItem("notificationmsg", notification);

  const userid = localStorage.getItem("userId");

  var msg = notification.body == undefined ? null : notification.body;
  localStorage.setItem("incomingmessage", msg);

  const navigateToChat = (rowData) => {
    navigate(CARE_CHAT_ROUTE, { state: notification });
  };

  localStorage.setItem("chatrefresh", true);

  onMessageListener()
    .then(async (payload) => {
      setNotification({
        title: payload.data.fullName,
        body: payload.data.body,
        avatarUrl: payload.data.imageUrl,
        userId: payload.data.userId,
        fullName: payload.data.fullName,
        channelSid: payload.data.cId,
      });
    })
    .catch((err) => console.log("failed", err));

  useEffect(() => {
    notification.title != undefined &&
      notification.userId != userid &&
      toast.info(<Display />);
  }, [notification]);

  const Display = () => {
    return (
      <div onClick={navigateToChat}>
        <h6 style={{ marginTop: "0.5em", color: "#009688" }}>
          {notification.title}
        </h6>
        <p className="ellipsis1">{notification.body}</p>
        {/* <button onClick={navigateToChat}></button> */}
      </div>
    );
  };

  const getVoiceCallDetails = useSelector(
    (state) => state.VoiceCallSlice.voiceCallDetails
  );

  const onIdle = () => {
    SetModalOpen(true);
  };

  useEffect(() => {
    dispatch(reset());
    requestForToken().then((res) => {
      setDeviceId(res);
    });
  }, []);

  useEffect(() => {
    if (getVoiceCallDetails.showPopup) {
      document.body.classList.add("voicecall-popup-open");
      dispatch(deviceStatus(VoiceCallStatus.RINGING));
      SetMute(false);
    } else {
      document.body.classList.remove("voicecall-popup-open");
    }
  }, [getVoiceCallDetails.showPopup]);

  const handleHangup = (e) => {
    e.preventDefault();
    dispatch(deviceDisconnect());
  };

  const handleMute = (e) => {
    e.preventDefault();
    dispatch(deviceMute(!mute));
    SetMute(!mute);
  };
  const param = {
    deviceId: deviceId,
    userId: localStorage.getItem("careproviderId"),
    
  };

  const logout = () => {
    dispatch(logoutUsers(param));
    SetModalOpen(false);
    navigate("/login");
    localStorage.removeItem("authorName");
    localStorage.removeItem("careproviderId");
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("AccessToken");
    localStorage.removeItem("refreshtoken");
    localStorage.removeItem("UpdatedrefreshToken");
    localStorage.removeItem("email");
    localStorage.removeItem("userType");
    localStorage.removeItem("Notification");
    localStorage.removeItem("userID");
    localStorage.removeItem("roomSId");
    localStorage.removeItem("VideoToken");
    localStorage.removeItem("createddate");
    localStorage.removeItem("ccm_timer");
    localStorage.removeItem("timer");
    localStorage.removeItem("stateUserId");
  };

  return (
    <>
      {getVoiceCallDetails?.showPopup && (
        <Ongoingcall
          handleHangup={handleHangup}
          handleMute={handleMute}
          mute={mute}
          fullName={getVoiceCallDetails.fullName}
          phoneNumber={getVoiceCallDetails.phoneNumber}
          status={getVoiceCallDetails.callStatus}
        />
      )}
      {localStorage.getItem("careproviderId") != null && (
        <div>
          <IdleTimer ref={idleTimerRef} onIdle={onIdle} timeout={1000 * 1800} />
          <Modal show={modalOpen}>
            <Modal.Body>
              <div className="Modal">
                <div className="idel-popup">
                  <h2>Confirmation</h2>
                  <p>
                    {" "}
                    You have been idle for 30 minutes, So your session has been
                    expired. Please login again.
                  </p>
                </div>
                <div style={{ textAlign: "center" }}>
                  <Button variant="contained" onClick={logout}>
                    OK
                  </Button>{" "}
                  &nbsp;
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      )}

      <ToastContainer />
      <Routes>
        <Route path={BASE_ROUTE} element={<Navigate replace to="/login" />} />
        <Route path="login" element={<Login />} />
        <Route path={REGISTER_ROUTE} element={<Signup />} />
        <Route path={FORGOT_ROUTE} element={<Forgot />} />
        <Route path={RESET_ROUTE} element={<Reset />} />
        <Route path={CHANGEPASSWORD_ROUTE} element={<Changepassword />} />
        <Route element={<PrivateRouter />}>
          <Route path={DASHBOARD_ROUTE} element={<Dashboard />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={STAFF_DASHBOARD_ROUTE} element={<Dashboard />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={ORGANIZATION_ROUTE} element={<Organization />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={EDITPROFILE_ROUTE} element={<Editprofile />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={PROFILE_ROUTE} element={<Profile />} />
          <Route path={STAFF_PROFILE_ROUTE} element={<Profile />} />
          <Route path={PATIENT_PROFILE_ROUTE} element={<Profile />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={PATIENTS_ROUTE} element={<Patients />} />
          <Route path={EDIT_PATIENTS_ROUTE} element={<Patients />} />
          <Route path={PEDIT_PATIENTS_ROUTE} element={<Patients />} />
          <Route path={SEDIT_PATIENTS_ROUTE} element={<Patients />} />
          <Route path={STAFF_PATIENTS_ROUTE} element={<Patients />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route
            path={INDIVIDUALPATIENTDETAILS_ROUTE}
            element={<Patientdashboard />}
          />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route
            path={INDIVIDUALPATIENTDETAILSS_ROUTE}
            element={<Patientdashboard />}
          />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route
            path={INDIVIDUALPATIENTDETAILSSS_ROUTE}
            element={<Patientdashboard />}
          />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={MANAGEPATIENT_ROUTE} element={<ManagePatient />} />
          <Route path={STAFF_MANAGEPATIENT_ROUTE} element={<ManagePatient />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={MANAGESTAFF_ROUTE} element={<ManageStaff />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={EDITSTAFF_ROUTE} element={<EditStaff />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={ADDSTAFF_ROUTE} element={<AddStaff />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={CALENDAREVE_ROUTE} element={<CalendarEve />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={NOTIFICATION_ROUTE} element={<Notification />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={MEETINGLOGS_ROUTE} element={<Meetinglog />} />
          <Route path={STAFF_MEETINGLOGS_ROUTE} element={<Meetinglog />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={DMEETING_ROUTE} element={<Dmeeting />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={ONGOINGCALL_ROUTE} element={<Ongoingcall />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={ADDPATIENT_ROUTE} element={<Patientform />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={CHAT_ROUTE} element={<Chat />} />
          <Route path={CARE_CHAT_ROUTE} element={<Chat />} />
          <Route path={STAFF_CHAT_ROUTE} element={<Chat />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={POPUP_ROUTE} element={<Popup />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={REPORT_ROUTE} element={<Report />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={PATIENTHEALTH_ROUTE} element={<Patienthealth />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={NOTIFICATIONPAGE_ROUTE} element={<NotificationPage />} />
        </Route>

        <Route element={<PrivateRouter />}>
          <Route path={NEWNOTIFICATIONS_ROUTE} element={<NewNotifications />} />
        </Route>

        <Route element={<PrivateRouter />}>
          <Route path={PATIENTREQUEST_ROUTE} element={<Patientrequest />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={APPOINTMENT_ROUTE} element={<Appointment />} />
        </Route>
        <Route element={<PrivateRouter />}>
          <Route path={MEETINGDETAILS_ROUTE} element={<Meetingdetails />} />
          <Route
            path={STAFF_MEETINGDETAILS_ROUTE}
            element={<Meetingdetails />}
          />
          <Route path={SCHEDULEMEETING_ROUTE} element={<Schedulemeeting />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
