/* eslint-disable no-lone-blocks */
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useNavigate } from "react-router-dom";
import {
  MEETINGDETAILS_ROUTE,
  STAFF_MEETINGDETAILS_ROUTE,
} from "../../_main/routeConstants";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { meetingList } from "../../Api_Redux/MeetingLogSlice";
import { meetingparticipantList } from "../../Api_Redux/MeetingParticipantSlice";
import { getlogdetails } from "../../Api_Redux/GetParticipantLogDetailsSlice";
import { Paginator } from "primereact/paginator";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import { DASHBOARD_ROUTE } from "../../_main/routeConstants";
import moment from "moment";
import { Dropdown } from "primereact/dropdown";

function Meetinglog() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [values, setValues] = useState("");
  const [totalCount, setTotalCount] = useState("");
  const [customFirst1, setCustomFirst1] = useState(0);
  const [customRows1, setCustomRows1] = useState(5);

  const screenState = useSelector((state) => state.LoginSlice.login.data);

  const meetingdata = useSelector(
    (state) => state.MeetingLogSlice?.meeting?.data?.meetingLogstList
  );

  const params = {
    CareProviderId: localStorage.getItem("careproviderId"),
    page: 1,
    pagesize: 5,
  };
  useEffect(() => {
    dispatch(meetingList(params))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          setTotalCount(isSuccess.payload.data.totalCount);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const goback = () => {
    navigate(DASHBOARD_ROUTE);
  };

  const pnavigateToEdit = (e) => {
    // console.log("data123456", e.data.roomSId)
    localStorage.setItem("roomSid", e.data.roomSId);
    setValues(e.data);
    const param = {
      roomSId: e.data.roomSId,
    };
    dispatch(getlogdetails(param));
    dispatch(meetingparticipantList(param));
    {
      screenState?.userType == 1
        ? navigate(MEETINGDETAILS_ROUTE)
        : navigate(STAFF_MEETINGDETAILS_ROUTE);
    }
  };

  const representativeBodyTemplate = (rowData) => {
    return (
      <>
        <span className="paname1">
          {moment(rowData.createdDate).format("MM-DD-YYYY HH:MM:SS a")}
        </span>
      </>
    );
  };

  const onCustomPageChange1 = (event) => {
    setCustomFirst1(event.first);
    setCustomRows1(event.rows);
    const Params = {
      pageNo: event.page + 1,
      pageSize: event.rows ? event.rows : 10,
      CareProviderId: localStorage.getItem("careproviderId"),
    };
    dispatch(meetingList(Params));
  };

  const template1 = {
    layout:
      "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-2">Pre</span>
        </button>
      );
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "100 and above", value: 5000 },
      ];

      return (
        <React.Fragment>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
            placeholder="Choose"
          />
        </React.Fragment>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-2">Next</span>
        </button>
      );
    },
  };

  return (
    <div>
      <div style={{ flexDirection: "row", display: "flex" }}>
        <Typography
          variant="h5"
          style={{ marginTop: "0.6em" }}
          onClick={goback}
        >
          <ArrowBackIcon
            style={{ cursor: "pointer" }}
            fontSize={"25"}
          ></ArrowBackIcon>
        </Typography>
        <div className="ml-title">COMPLETED MEETING LOGS</div>
      </div>

      <DataTable
        className="ml-text logs-section"
        onRowClick={(e) => pnavigateToEdit(e)}
        selectionMode="single"
        value={meetingdata}
        responsiveLayout="scroll"
        scrollable
        scrollHeight="485px"
      >
        <Column
          body={representativeBodyTemplate}
          field="createdDate"
          header="DATE"
          sortable
        ></Column>
        <Column field="roomId" header="MEETING ID" sortable></Column>
        <Column field="type" header="TYPE" sortable></Column>
        <Column field="duration" header="DURATION" sortable></Column>
        <Column field="roomStatus" header="STATUS" sortable></Column>
      </DataTable>

      <div className="paginator">
        <Paginator
          template={template1}
          first={customFirst1}
          rows={customRows1}
          totalRecords={totalCount}
          onPageChange={onCustomPageChange1}
        ></Paginator>
      </div>

      {/* <Footer /> */}
    </div>
  );
}

export default Meetinglog;
