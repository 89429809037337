
import React, { useEffect, useState } from "react";
import { IoMdAddCircle } from "react-icons/io";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import DatePicker from "react-datepicker";
import "./styles.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { Button } from "primereact/button";

import { useDispatch, useSelector } from "react-redux";
import { Divider } from "primereact/divider";
import { scheduleUsers } from "../../Api_Redux/ScheduleSlice";
import { Checkbox } from "primereact/checkbox";
import { geteventlist } from "../../Api_Redux/EventGetSlice";
import { addEventList } from "../../Api_Redux/EventListSlice"
import { getEvent } from "../../Api_Redux/GeteventSlices";
import Footer from '../../Component/Footer'
import PaginatorDemo from "../../Component/Paginator";
import { patientList } from "../../Api_Redux/PatientListSlice";
import { deleteEvent } from "../../Api_Redux/DeleteEventSlice";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { deleteeventlist } from "../../Api_Redux/EventDeleteSlice";
import { patientId } from "../../Api_Redux/PatientidSlice";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import "react-big-calendar/lib/css/react-big-calendar.css";
import "react-datepicker/dist/react-datepicker.css";

const locales = {
  "en-US": require("date-fns/locale/en-US"),
};
const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const events = [
  {
    title: "Big Meeting",
    allDay: true,
    start: "2022-12-09T13:44:00",
    end: "2022-12-09T13:44:00",
  },

];

export default function CalendarEve() {
  const [allEvents, setAllEvents] = React.useState([]);
  const [displayBasic, setDisplayBasic] = React.useState(false);
  const [checked, setChecked] = React.useState(false);
  const [isDisabled, setIsDisabled] = React.useState(true);
  const [isEditable, setIsEditable] = React.useState(false);
  const [updatedList, setUpdatedList] = React.useState(false);
  const [newEvent, setNewEvent] = React.useState({
    id: 0,
    eventName: "",
    description: "",
    patientId: "",
    // patientName: localStorage.getItem("fullName"),
    // patientEmailId: localStorage.getItem("emailId"),
    // careProviderName: "",
    startDate: new Date(),
    endDate: new Date(),
    isFullDay: false,
    meetingUrl: ''
    // createdBy: localStorage.getItem("careproviderId"),
    // start: "",
    // end: "",
    // selectedPatient: "",
  });
  // console.log("govindraj", localStorage.getItem("userId"));
  const [position, setPosition] = React.useState("center");
  const [selectedPatientRow, setSelectedPatientRow] = React.useState(null);
  const [eventId, setEventId] = useState('')

  const patientdata = useSelector(
    (state) => state.patientList?.patient?.data?.patientList
  );

  const total = useSelector(
    (state) => state.patientList?.patient?.data?.totalCount
  );
  const eventlistData = useSelector((state) => state.EventGetSlice?.getevent?.data?.eventList);
  const [eventlist, setEventList] = useState(eventlistData)


  const dispatch = useDispatch();

  const dialogFuncMap = {
    displayBasic: setDisplayBasic,
  };


  function convertUTCDateToLocalDate(date) {
    var dateLocal = new Date(date);
    var newDate = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset()*60*1000);
    return newDate;
  }

  const deletedEvent = () => {
    const param = {
      id: eventId
    }
    dispatch(deleteEvent(param))
      .then(async (isSuccess) => {
        if (isSuccess.payload.status == 1) {
          // console.log("isDeletedSuccess", isSuccess.payload.data)
          toast.success('Event Deleted Sucessfully', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setEventList(isSuccess.payload.data.eventList)
          setUpdatedList(!updatedList)
        } else {
          toast.error(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      }
      )
      .catch((err) => {
        console.log(err)
      })
    const fields = { ...newEvent };
    fields["eventName"] = "";
    fields["description"] = "";
    fields["startDate"] = "";
    fields["endDate"] = "";
    fields["selectedPatient"] = "";
    setNewEvent(fields);
    setDisplayBasic(false);
  };

  const addEvent = () => {
  const payload={
      eventName: newEvent.eventName,
      description: newEvent.description,
      patientId: newEvent.patientId,
      // startDate: moment.tz(newEvent.startDate,"America/Toronto").utc().format(),
      // endDate: moment.tz(newEvent.endDate,"America/Toronto").utc().format(),
      startDate: convertUTCDateToLocalDate(newEvent.startDate),
      endDate:convertUTCDateToLocalDate(newEvent.endDate),
      isFullDay: false,
      id: newEvent.id ? newEvent.id :0
    }
    dispatch(scheduleUsers(payload))
      .then(async (isSuccess) => {
        if (isSuccess.payload.status == 1) {
          toast.success(isSuccess.payload.message ? isSuccess.payload.message : 'Event updated Sucessfully', {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
          setEventList(isSuccess.payload.data.eventList)
          setUpdatedList(!updatedList)
        }
        else if (isSuccess.payload.status == 400) {
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
        else {
          toast.error(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });
        }
      }
      )
      .catch((err) => {
        console.log(err)
      })
    setDisplayBasic(false);
  };

  const handleClick = () => {
    setIsDisabled(!isDisabled);
  };

  const onHide = (name) => {
    dialogFuncMap[`${name}`](false);
    setNewEvent({
      eventName: "",
      startDate: "",
      endDate: "",
      description: "",
      isFullDay: false,
      meetingUrl: "",
      patientId: ""

    })
  };

  const onCalendarClick = (name, position) => {
    dialogFuncMap[`${name}`](true);

    if (position) {
      setPosition(position);
    }
  };

  const handleCheck = () => {
    setIsEditable(true);
  };



  useEffect(() => {
    const data = [];
    dispatch(geteventlist())
    .then((isSuccess) => {
      // console.log('isSuccess', isSuccess.payload.data.eventList)
   const event = isSuccess.payload.data.eventList
      setEventList(isSuccess.payload.data.eventList)
      // console.log('eventList', eventlist)
      if (event) {
        event.forEach((ele, index) => {
          data.push({
            title: ele.eventName,
            allDay: true,
            start: (ele.startDate),
            end: (ele.endDate),
            id: ele.id,
            meetingUrl: ele.meetigUrl,
            patientId: ele.patientId,
            patientName: ele.patientName
          });
        });
      }

    })
    .catch((err) => console.log(err))
    setAllEvents(data)
    //  console.log("eventconsole1234", dispatch( geteventlist(param))) ;
  }, [displayBasic, updatedList]);



  const handleSearch = (e) => {
    const patientParams = {
      pageNo: 1,
      pageSize: 5,
      search: e.target.value,
    };
    dispatch(patientList(patientParams));
  };


  // console.log('toggle', checked)


  const onSelectEvent = (event) => {
    // alert(`${event.start}`);
    // console.log('eventrrr', event)
    setEventId(event.id)
    dispatch(getEvent(event.id))
      .then(async (isSuccess) => {
        // console.log("isSuccess", isSuccess);
        if (isSuccess.payload !== undefined) {
          // console.log("isSuccess", isSuccess.payload.data)
          setDisplayBasic(true)
          setNewEvent({
            eventName: isSuccess.payload.data.eventName,
            startDate: new Date(isSuccess.payload.data.startDate),
            endDate: new Date(isSuccess.payload.data.endDate),
            description: isSuccess.payload.data.description,
            meetingUrl: isSuccess.payload.data.meetigUrl,
            isFullDay: isSuccess.payload.data.isFullDay,
            id: isSuccess.payload.data.id != null ? (isSuccess.payload.data.id) : 0,
            patientId: isSuccess.payload.data.patientId != null ? isSuccess.payload.data.patientId : "",
            patientName: isSuccess.payload.data.patientName
          })
        } else {
          console.log('errorr')
        }
      })
      .catch(({ error }) => {
        console.log(error);
      });
  };


  const handleRowClick = (e) => {
    // console.log('clicked row ', e.data)
    // console.log('clicked row id', e.data.id)
    setNewEvent({
      eventName: newEvent.eventName,
      description: newEvent.description,
      startDate: newEvent.startDate,
      endDate: newEvent.endDate,
      patientName: newEvent.patientName,
      patientId: JSON.stringify(e.data.userId),
      isFullDay: false,
      id: 0

    })
  }


  return (
    <div>

<Box>
      <Grid container spacing={2} className='event-tracker'>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12} className='calendar-viewpage'>
            <div className='calendar-text'>
            <h1>CALENDAR</h1>
            </div>
          </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <div className='text-center float-end'>
            <div>
              <span>
                <IoMdAddCircle
                  size={40}
                  className='dashbadd-patient'
                  onClick={setDisplayBasic}
                />
              </span>
            </div>
            <div className="add-patient">ADD EVENT</div>
          </div>
        </Grid>
      </Grid>
</Box>
      <Dialog
        className='add-event-popup'
        header="Add Event details"
        visible={displayBasic}
        style={{ width: "65vw" }}
        // footer={renderFooter("displayBasic")}
        onHide={() => onHide("displayBasic")}
      >
        <div> 
          <Grid container spacing={2} className="org-rowgap">
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div style={{ marginBottom: "5px" }}>Add Title</div>
              <InputText
                style={{ width: "100%", marginBottom: "5px" }}
                value={newEvent.eventName}
                onChange={(e) => {
                  const fields = { ...newEvent };
                  fields["eventName"] = e.target.value;
                  setNewEvent(fields);
                }}
              />
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div style={{ marginBottom: "5px" }}>Description</div>
              <InputText
                style={{ width: "100%", marginBottom: "5px" }}
                value={newEvent.description}
                onChange={(e) => {
                  const fields = { ...newEvent };
                  fields["description"] = e.target.value;
                  setNewEvent(fields);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2} className="org-rowgap">
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div style={{ marginBottom: "5px" }}>Start Date</div>
              <DatePicker
                className="datepicker"
                placeholderText="Start Date"
                // style={{ marginRight: "50px" }}
                selected={newEvent.startDate}
                onChange={(startDate) => setNewEvent({ ...newEvent, startDate })}
                showTimeSelect
                value={newEvent.startDate}
                timeFormat="HH:mm"
                dateFormat="MMMM d, yyyy h:mm aa"
              />            
            </Grid>
            <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
              <div style={{ marginBottom: "5px" }}>End Date</div>
                <DatePicker
                  className="datepicker"
                  placeholderText="End Date"
                  selected={newEvent.endDate}
                  onChange={(endDate) => setNewEvent({ ...newEvent, endDate })}
                  showTimeSelect
                  value={newEvent.endDate}
                  timeFormat="HH:mm"
                  dateFormat="MMMM d, yyyy h:mm aa"
                  disabled={isEditable}
                />              
            </Grid>
          </Grid>
          {newEvent.meetingUrl ?
            <div className="row" style={{ marginBottom: "5px" }}>
              <div className="col">
                Meeting Url
              </div>
              <div className="col">
                <a style={{marginLeft:"-10em"}} href={newEvent.meetingUrl}>{newEvent.meetingUrl}</a>
              </div>
              <div className="col">Participant</div>
              <div className="col"><span style={{marginLeft:"-10em"}}>{newEvent.patientName}</span></div>
            </div>
            : ''}
        </div>
        <div style={{ marginTop: "20px",padding: '10px'}}>
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              onChange={handleSearch}
              placeholder="Search"
              className="w-100"
            />
          </span>
        </div>
        <div  style={{ marginTop: "20px",padding: '10px'}}>
          <DataTable
            className="table-border"
            selectionMode="single"
            value={patientdata}
            // onSelectionChange={(e) => setSelectedPatient(e.value)}
            selection={selectedPatientRow}
            onRowClick={handleRowClick}
            onSelectionChange={(e) => {
              const field = { ...newEvent };
              setSelectedPatientRow(e.value);
              field["selectedPatient"] = e.value.id;
              // setNewEvent(field);
            }}
          // responsiveLayout="scroll"
          >
            <Column field="fullName" header="Patient Name"></Column>
            <Column field="emailId" header="Email ID"></Column>
          </DataTable>
        </div>
        <div className='pagenation-section'>
          <PaginatorDemo
            patientDataLength={total}
          />
        </div>
        {/* <Divider /> */}
        <div className='button-list'>
          {newEvent.eventName ?
            <Button
              label="Delete Event"
              className='delete-btn'
              icon="pi pi-times"
              onClick={deletedEvent}
              
            /> : ''}
          <Button
            label="Add Event"
            icon="pi pi-check"
            onClick={addEvent}
            // autoFocus
            className='event-btn'
          />
        </div>
        <div className="field-checkbox">
          <Checkbox
            inputId="binary"
            checked={checked}
            value={newEvent.isFullDay}
            onClick={handleCheck}
            id="chkPan"
            onChange={(e) => {
              setChecked(e.checked)

            }}
          />
          <label className="checklabel" htmlFor="binary">
            Is Full Day Event
          </label>
        </div>
        <ToastContainer />
      </Dialog>
      <Calendar
        className="cal-size"
        selectable
        localizer={localizer}
        events={allEvents}
        onSelectSlot={() => setDisplayBasic(true)}
        select={displayBasic}
        onSelectEvent={onSelectEvent}
      />
    </div>
  );
}
