import React, { useState, useRef, useEffect } from 'react'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "./styles.css"

const BionomineTable = (props) => {
  const prevProps = useRef(props);
  const [overallLineData, setOverallLineData] = useState([])

  useEffect(
    () => {
      if (prevProps.current.overallTableData !== props.overallTableData) {
        const reconstructedArray = props.overallTableData.reduce((result, item) => {
          result[item.header] = parseInt(item.body);
          return result;
        }, {});

        let linedata = []
        linedata.push(reconstructedArray)
        setOverallLineData(linedata)

      }
    }, [props])

  return (
    <div className="tabledata">
      <DataTable className='table-border' value={overallLineData} style={{ cursor: "pointer" }}  >
        <Column style={{ fontSize: "12px", fontWeight: "bold",textAlign:"center" }} field="Bedtime(10pm-12am)" header="Bedtime(10pm-12am)"></Column>
        <Column style={{ fontSize: "12px", fontWeight: "bold",textAlign:"center" }} field="After Dinner(7pm-10pm)" header="After Dinner(7pm-10pm)"></Column>
        <Column style={{ fontSize: "12px", fontWeight: "bold",textAlign:"center" }} field="Before Dinner(5pm-7pm)" header="Before Dinner(5pm-7pm)"></Column>
        <Column style={{ fontSize: "12px", fontWeight: "bold",textAlign:"center" }} field="After Lunch(2pm-5pm)" header="After Lunch(2pm-5pm)"></Column>
        <Column style={{ fontSize: "12px", fontWeight: "bold",textAlign:"center" }} field="Before Lunch(11am-2pm)" header="Before Lunch(11am-2pm)"></Column>
        <Column style={{ fontSize: "12px", fontWeight: "bold",textAlign:"center" }} field="After Breakfast(9am-11am)" header="After Breakfast(9am-11am)"></Column>
        <Column style={{ fontSize: "12px", fontWeight: "bold",textAlign:"center" }} field="Before Breakfast(6am-9am)" header="Before Breakfast(6am-9am)"></Column>
        <Column style={{ fontSize: "12px", fontWeight: "bold",textAlign:"center" }} field="Overnight(12am-6am)" header="Overnight(12am-6am)"></Column>
      </DataTable>
    </div>
  )
}

export default BionomineTable
