import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import callfetch from "../_main/fetch";
import { POST_REGISTER } from "../_main/actiontype";

export const RefreshToken = createAsyncThunk(
  POST_REGISTER + "/RefreshToken",
  async (params) => {
    const token = localStorage.getItem("AccessToken");

    const refreshToken = localStorage.getItem("refreshtoken");

    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ token, refreshToken }),
    };

    const url = `${API_URL + "Token/refresh"}`;
    const response = await callfetch(url, option);
    const value = await response;
    localStorage.removeItem("AccessToken");
    localStorage.removeItem("refreshtoken");
    localStorage.setItem("AccessToken", value.data.jwtToken);
    localStorage.setItem("refreshtoken", value.data.refreshToken);
    return value;
  }
);

const RefreshSlice = createSlice({
  name: POST_REGISTER,
  initialState: {
    refreshToken: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(RefreshToken.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(RefreshToken.fulfilled, (state, action) => {
      state.refreshToken = action.payload;
      state.loading = false;
    });
    builder.addCase(RefreshToken.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default RefreshSlice.reducer;
