import React from "react";
import "./styles.css";
import vedio1 from "../../asset/Image/vedio1.svg"
import 'primeicons/primeicons.css';
import mute1 from "../../asset/Image/mute1.svg"
import camera1 from "../../asset/Image/camera1.svg"
import screeshare1 from "../../asset/Image/screeshare1.svg"
import end1 from "../../asset/Image/end1.svg"
import addperson1 from "../../asset/Image/addperson1.svg"

function Popup(props) {
 
  return (
    <div>
      <div style={{ background: "black", height: "95vh" }}>
       
        <div style={{ display: "flex", justifyContent: "center", paddingTop: "10em" }}>
          <img alt="no_image" src={vedio1} />
        </div>
        <div className="addpersonvedio"></div>   
        <div className="othericon">
          <img style={{ marginLeft: "2em", cursor: "pointer", width: "3.5em" }} alt="no_image" src={mute1} />
          <img style={{ marginLeft: "2em", cursor: "pointer", width: "3.5em" }} alt="no_image" src={camera1} />
          <img style={{ marginLeft: "2em", cursor: "pointer", width: "3.5em" }} alt="no_image" src={screeshare1} />
          <img style={{ marginLeft: "2em", cursor: "pointer", width: "3.5em" }} alt="no_image" src={end1} />
        </div>
        <span className="addpar">
          <img style={{ marginTop: "-3.5em", width: "3.5em" }} alt="no_image" src={addperson1} />
        </span>
      </div>
    </div>
  );
}

export default Popup;
