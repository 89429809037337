import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, GET } from "../_main/constants";
import callfetch from "../_main/fetch";
import { GET_COUNTS } from "../_main/actiontype";
import { RefreshToken } from "./RefreshSlice";

export const getCounts = createAsyncThunk(
  GET_COUNTS + "/getCounts",
  async (params, thunkAPI) => {

    const token = localStorage.getItem("AccessToken");

    const option = {
      method: GET,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const url = `${API_URL + "Auth/getCountlist"}`;

    const response = await fetch(url, option).then((res) => {
      if (res.status == 401) {
        thunkAPI.dispatch(RefreshToken());
      }
      return res.json();
    });
    return response;
  }
);

const getCountsListSlice = createSlice({
  name: GET_COUNTS,
  initialState: {
    counts: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCounts.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCounts.fulfilled, (state, action) => {
      state.counts = action.payload;
      state.loading = false;
    });
    builder.addCase(getCounts.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default getCountsListSlice.reducer;
