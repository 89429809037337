import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import callfetch from "../_main/fetch";
import { POST_PATIENTLIST } from "../_main/actiontype";

export const postpatientlist = createAsyncThunk(
  POST_PATIENTLIST + "/postpatientlist",
  async (params) => {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    const token = localStorage.getItem("AccessToken");

    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const url = `${API_URL + "Event/listforpatient?" + query}`;
    const response = await callfetch(url, option);
    const value = await response;
    return value;
  }
);

const ListOfPatientSlice = createSlice({
  name: POST_PATIENTLIST,
  initialState: {
    postpatient: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postpatientlist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postpatientlist.fulfilled, (state, action) => {
      state.postpatient = action.payload;
      state.loading = false;
    });
    builder.addCase(postpatientlist.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default ListOfPatientSlice.reducer;
