
// /* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Formik, Form, Field, useFormik } from "formik";
import { Dropdown } from "primereact/dropdown";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import Footer from "../../Component/Footer";
import { TextField } from "formik-material-ui";
import { NOT_EMPTY_REGEX, PHONE_REGEX } from "../../_main/constants";
import {
  EMPTY_ORGANIZATIONNAME,
  EMPTY_ADDRESS1,
  EMPTY_ADDRESS2,
  EMPTY_CITY,
  EMPTY_STATE,
  EMPTY_ZIP,
  EMPTY_PHONE,
  EMPTY_FAX,
  EMPTY_MEMBERSHIP,
  EMPTY_CONTACTFNAME,
  EMPTY_CONTACTLNAME,
  EMPTY_EMAIL,
  EMPTY_OFFICEPHONE,
  EMPTY_CONTACTTITLE,
  PHONE_NUM_REGEX,
} from "../../_main/errorConstants";
import "./styles.css";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InputMask } from "primereact/inputmask";
import { Card } from "primereact/card";
import { EDITPROFILE_ROUTE, PROFILE_ROUTE } from "../../_main/routeConstants";
import { orgpostUsers } from "../../Api_Redux/OrganizationPostSlice";
import { useDispatch, useSelector } from "react-redux";
import validation from "../../_main/validation";
import Grid from "@mui/material/Grid";
import Box from '@mui/material/Box';
import { GetMembershipTypedropdown } from "../../Api_Redux/MembershipTypeDropdown";
import { orggetUsers } from "../../Api_Redux/OrganizationGetSlice";




function Organizaton() {
  const screenState = useSelector((state) => state.LoginSlice.login.data);


  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [values, setValues] = useState({
    number: "",
  });

  // const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };


  const [city, setSetCity] = useState("");

  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [membershipTypes, setMembershipTypes] = useState(null)
  const [orgId, setOrgId] = useState(0)

  const countries = [
    {
      name: "USA",
      country: [
        {
          cities: [
            "Avon",
            "Bedfordshire",
            "Berkshire",
            "Buckinghamshire",
            "Cambridgeshire",
            "Cheshire",
            "Cleveland",
            "Cornwall",
            "Cumbria",
            "Derbyshire",
            "Devon",
            "Dorset",
            "Durham",
            "East-Sussex",
            "Essex",
            "Gloucestershire",
            "Hampshire",
            "Herefordshire",
            "Hertfordshire",
            "Isle-of-Wight",
            "Kent",
            "Lancashire",
            "Leicestershire",
            "Lincolnshire",
            "London",
            "Merseyside",
            "Middlesex",
            "Norfolk",
            "Northamptonshire",
            "Northumberland",
            "North-Humberside",
            "North-Yorkshire",
            "Nottinghamshire",
            "Oxfordshire",
            "Rutland",
            "Shropshire",
            "Somerset",
            "South-Humberside",
            "South-Yorkshire",
            "Staffordshire",
            "Suffolk",
            "Surrey",
            "Tyne-and-Wear",
            "Warwickshire",
            "West-Midlands",
            "West-Sussex",
            "West-Yorkshire",
            "Wiltshire",
            "Worcestershire",
          ],
        },
      ],
    },
  ];

  const availableState = countries.find(
    (c) => c.name === selectedCountry?.name
  );
  const availableCities = availableState?.country?.find(
    (s) => s.name === selectedState
  );
  const finalArray = availableCities?.cities.map(function (obj) {
    return obj;
  });

  const [value, setValue] = useState({
    // organizationID: 0,
    organizationID: orgId,
    organizationName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    fax: "",
    // membershipType: "",
    membershipType: membershipTypes,
    officeContactFirstName: "",
    officeContactLastName: "",
    officeContactEmail: "",
    officeContactPhone: "",
    officeContactTitle: "",
  })

  // const [value, setValue] = useState(initial);


  useEffect(() => {
    dispatch(GetMembershipTypedropdown());
    dispatch(orggetUsers())
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {

          setValue(isSuccess.payload.data)
          setMembershipTypes(isSuccess.payload.data.membershipType)
          setOrgId(isSuccess.payload.data.id)

        }
        else {
          console.log(isSuccess.payload.message)
        }
      })
      .catch((err) => {
        console.log(err)
      })


  }, [])



  const Dropdowndata = useSelector(
    (state) => state?.MembershipTypeDropdown?.DropDownMembershipType?.data
  );
  const PatientNameSuffix = Dropdowndata?.membershipTypeList.map((obj) => ({
    value: obj.id,
    label: obj.name,
  }));

  const handleChange1 = (event) => {
    const result = event.target.value.replace(/[^a-z]/gi, "");

    // setMessage(result);
  };

  // const [initialVal, setInitialValues] = useState(initial);



  const handleSubmit = (form, { resetForm }, e) => {
    const payload = {}
    if (form.organizationName) {
      payload['organizationID'] = orgId ? orgId : 0;
      payload['organizationName'] = form.organizationName ? form.organizationName : "";
      payload['address1'] = form.address1 ? form.address1 : "";
      payload['address2'] = form.address2 ? form.address2 : "";
      payload['city'] = form.city ? form.city : "";
      payload['state'] = form.state ? form.state : "";
      payload['zip'] = form.zip ? form.zip : "";
      payload['phone'] = form.phone ? form.phone : "";
      payload['fax'] = form.fax ? form.fax : "";
      payload['membershipType'] = membershipTypes ? membershipTypes : 0;
      payload['officeContactFirstName'] = form.officeContactFirstName ? form.officeContactFirstName : "";
      payload['officeContactLastName'] = form.officeContactLastName ? form.officeContactLastName : "";
      payload['officeContactEmail'] = form.officeContactEmail ? form.officeContactEmail : "";
      payload['officeContactPhone'] = form.officeContactPhone ? form.officeContactPhone : "";
      payload['officeContactTitle'] = form.officeContactTitle ? form.officeContactTitle : "";
      dispatch(orgpostUsers(payload))
        .then(async (isSuccess) => {
          if (isSuccess.payload.status == 1) {
            resetForm();
            toast.success(isSuccess.payload.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setTimeout(() => {
              navigate(PROFILE_ROUTE);
            }, 1000)
          }
          else if (isSuccess.payload.status == 0) {
            toast.error(isSuccess.payload.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setTimeout(() => {
              // navigate(PROFILE_ROUTE);
            }, 1000)
            resetForm();
          }
          else {
            toast.error(isSuccess.payload.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
          }
          resetForm();
        })
        .catch((error) => {
          toast.error(error);
          resetForm();
        });
    } else {
      toast.error('Organization name required', {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }

  };

  // const navigateToEdit = () => {
  //     navigate(EDITPROFILE_ROUTE);
  //   };

  const onSelectMembershipTypes = (e) => {
    setMembershipTypes(e.value);
  };

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    let phoneNumber = e.target.value;
    if (keyCode < 48 || keyCode > 57) {
      e.preventDefault();
      return;
    }
    if (phoneNumber.length === 15) {
      e.preventDefault();
      return;
    }
    if (phoneNumber.length === 0) {
      phoneNumber = "+1 ";
    } else if (phoneNumber.length === 3) {
      phoneNumber = `+1 ${phoneNumber.slice(1)}`;
    } else if (phoneNumber.length === 7) {
      phoneNumber = `${phoneNumber.slice(0, 6)}-${phoneNumber.slice(6)}`;
    } else if (phoneNumber.length === 11) {
      phoneNumber = `${phoneNumber.slice(0, 10)}-${phoneNumber.slice(10)}`;
    }
    e.target.value = phoneNumber;
  }




  const handleKeyPressOff = (e) => {
    const keyCode = e.keyCode || e.which;
    let phoneNumber = e.target.value;
    if (keyCode < 48 || keyCode > 57) {
      e.preventDefault();
      return;
    }
    if (phoneNumber.length === 15) {
      e.preventDefault();
      return;
    }
    if (phoneNumber.length === 0) {
      phoneNumber = "+1 ";
    } else if (phoneNumber.length === 3) {
      phoneNumber = `+1 ${phoneNumber.slice(1)}`;
    } else if (phoneNumber.length === 7) {
      phoneNumber = `${phoneNumber.slice(0, 6)}-${phoneNumber.slice(6)}`;
    } else if (phoneNumber.length === 11) {
      phoneNumber = `${phoneNumber.slice(0, 10)}-${phoneNumber.slice(10)}`;
    }
    e.target.value = phoneNumber;
  }


  return (
    <div>
      {screenState.userType == 1 ? (
        <Card style={{ padding: "0px", margin: "0px" }}>
          <div>
            <div
              style={{
                background: "#009688",
                borderRadius: "10px 10px 11px 10px",
              }}
            >
              <div className="org-head">CREATE ORGANIZATION</div>
              <div className="overall-ocard">
                <Formik
                  initialValues={value}
                  onSubmit={handleSubmit}
                  enableReinitialize
                  validate={(values) => {
                    const errors = {};

                    // if (!values.organizationName) {
                    //   errors.organizationName = 'Organization Name Required';
                    // } else if (!/^[a-zA-Z_ ]+$/i.test(values.organizationName)) {
                    //   errors.organizationName = 'Invalid organizatioName';
                    // }
                    // if (!values.address1) {
                    //   errors.address1 = 'Address1 Required';
                    // // } else if (!/^[0-9a-zA-Z_ ]+$/i.test(values.address1)) {
                    // } else if (!/^[0-9a-zA-Z_,. ]+$/i.test(values.address1)) {
                    //   errors.address1 = 'Invalid address1';
                    // }
                    // if (!values.address2) {
                    //   errors.address2 = 'Address2 Required';
                    // // } else if (!/^[0-9a-zA-Z_ ]+$/i.test(values.address2)) {
                    // } else if (!/^[0-9a-zA-Z_,. ]+$/i.test(values.address2)) {
                    //   errors.address2 = 'Invalid address2';
                    // }
                    // if (!values.city) {
                    //   errors.city = 'City Required';
                    // } else if (!/^[0-9a-zA-Z_ ]+$/i.test(values.city)) {
                    //   errors.city = 'Invalid city';
                    // }
                    // if (!values.state) {
                    //   errors.state = 'State Required';
                    // } else if (!/^[0-9a-zA-Z_ ]+$/i.test(values.state)) {
                    //   errors.state = 'Invalid state';
                    // }
                    // if (!values.fax) {
                    //   errors.fax = 'Fax Required';
                    // } else if (!/^[0-9+/-]{13}$/i.test(values.fax)) {
                    //   errors.fax = 'Invalid fax';
                    // }
                    // if (!values.zip) {
                    //   errors.zip = 'Zip Required';
                    // } else if (!/^[0-9]{2,8}$/i.test(values.zip)) {
                    //   errors.zip = 'Invalid zip';
                    // }
                    // if (!values.phone) {
                    //   errors.phone = 'Phone Required';
                    // } 
                    // if (!values.officeContactPhone) {
                    //   errors.officeContactPhone = 'Office contact phone Required';
                    // } 

                    // if (!values.officeContactFirstName) {
                    //   errors.officeContactFirstName = 'OfficeContact FirstName Required';
                    // } else if (!/^[0-9a-zA-Z_ ]+$/i.test(values.officeContactFirstName)) {
                    //   errors.officeContactFirstName = 'Invalid officeContactFirstName';
                    // }
                    // if (!values.officeContactLastName) {
                    //   errors.officeContactLastName = 'OfficeContact LastName Required';
                    // } else if (!/^[0-9a-zA-Z_ ]+$/i.test(values.officeContactLastName)) {
                    //   errors.officeContactLastName = 'Invalid officeContactLastName';
                    // }
                    // if (!values.officeContactEmail) {
                    //   errors.officeContactEmail = 'OfficeContact Email Required';
                    // } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.officeContactEmail)) {
                    //   errors.officeContactEmail = 'Invalid officeContactEmail';
                    // }

                    // if (!values.officeContactTitle) {
                    //   errors.officeContactTitle = 'OfficeContact Title Required';
                    // } else if (!/^[0-9a-zA-Z_ ]+$/i.test(values.officeContactTitle)) {
                    //   errors.officeContactTitle = 'Invalid officeContactTitle';
                    // }
                    return errors;
                  }}
                >
                  {({ }) => (
                    <Form>
                      <div className="orgwrap">
                        <p className="hello">
                          Hello! Thank you for registering as a Care Provider
                          with RPM Doctor
                        </p>
                        <p className="hello1" style={{ marginTop: "-0.5%" }}>
                          We need to know a little about your organizaton before
                          we can continue. Please fill out the information below
                          before proceeding.
                        </p>
                        <div className="org-margin">
                          <Box>
                            <Grid container spacing={2} className="org-rowgap">
                              <Grid item xl={3} lg={3} md={6} sm={6} xs={12} >
                                <div>
                                  <div className="label-space">
                                    <label className="organization-label">
                                      Organization Name <sub>*</sub>
                                    </label>
                                  </div>

                                  <Field
                                    component={TextField}
                                    className="cinput-container"
                                    name="organizationName"
                                    id="organizationName"
                                    type="text"
                                    placeholder="Enter Organization Name"
                                    // onChange={handleChange1}
                                    variant="outlined"
                                    size="small"
                                    disabled={false}
                                    value={value.organizationName}
                                  />

                                </div>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} sm={6} xs={12} >
                                <div className="field-container">
                                  <div className="label-space">
                                    <label className="organization-label">Address 1</label>
                                  </div>
                                  <div>
                                    <Field
                                      component={TextField}
                                      className="cinput-container"
                                      type="text"
                                      name="address1"
                                      placeholder="Enter Address 1"
                                      variant="outlined"
                                      size="small"
                                      disabled={false}
                                      value={value.address1}
                                    />
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} sm={6} xs={12} >
                                <div className="field-container">
                                  <div className="label-space">
                                    <label className="organization-label">Address 2</label>
                                  </div>
                                  <div>
                                    <Field
                                      component={TextField}
                                      className="cinput-container"
                                      type="text"
                                      name="address2"
                                      variant="outlined"
                                      placeholder="Enter Address 2"
                                      size="small"
                                      disabled={false}
                                      value={value.address2}
                                    />
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} sm={6} xs={12} >
                                <div className="field-container">
                                  <div className="label-space">
                                    <label className="organization-label">City</label>
                                  </div>
                                  <div>
                                    <div>
                                      <Field
                                        component={TextField}
                                        className="cinput-container"
                                        type="text"
                                        name="city"
                                        variant="outlined"
                                        placeholder="City"
                                        size="small"
                                        disabled={false}
                                        value={value.city}
                                      />
                                    </div>

                                  </div>
                                </div>

                              </Grid>
                            </Grid>
                          </Box>
                          <Box>
                            <Grid container spacing={2} className="org-rowgap">
                              <Grid item xl={3} lg={3} md={6} sm={6} xs={12} >
                                <div >
                                  <div className="label-space">
                                    <label className="organization-label">State</label>
                                  </div>

                                  <div className="holder">

                                    <div>
                                      <Field
                                        component={TextField}
                                        className="cinput-container"
                                        type="text"
                                        name="state"
                                        variant="outlined"
                                        placeholder="State"
                                        size="small"
                                        disabled={false}
                                        value={value.state}
                                      />
                                    </div>


                                  </div>
                                </div>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} sm={6} xs={12} >
                                <div className="field-container">
                                  <div className="label-space">
                                    <label className="organization-label">Zip</label>
                                  </div>
                                  <div>
                                    <Field
                                      component={TextField}
                                      className="cinput-container"
                                      type="text"
                                      name="zip"
                                      variant="outlined"
                                      placeholder="Enter Zip"
                                      size="small"
                                      disabled={false}
                                      value={value.zip}
                                    />
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} sm={6} xs={12} >
                                <div className="field-container">
                                  <div className="label-space">
                                    <label className="organization-label">
                                      Phone &nbsp;
                                      <span className="phoneformat">
                                        format: (+444-555-6666)
                                      </span>
                                    </label>
                                  </div>
                                  <div>

                                    <Field
                                      component={TextField}
                                      className="cinput-container"
                                      type="text"
                                      name="phone"
                                      placeholder="Enter Phone Number"
                                      variant="outlined"
                                      size="small"
                                      disabled={false}
                                      maxLength={15}
                                      onKeyPress={handleKeyPress}
                                      value={value.phoneNumber}

                                    />
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} sm={6} xs={12} >
                                <div className="field-container">
                                  <div className="label-space">
                                    <label className="organization-label">
                                      Fax&nbsp;&nbsp;
                                      <span className="phoneformat">
                                        format: (+444-555-6666)
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    {/* <InputMask style={{ height: "2.5em" }} mask="999-999-9999" className="cinput-container" placeholder="Enter Phone Number" id="ssn" ></InputMask> */}
                                    <Field
                                      component={TextField}
                                      className="cinput-container"
                                      type="text"
                                      name="fax"
                                      placeholder="Enter Fax"
                                      variant="outlined"
                                      size="small"
                                      disabled={false}
                                      maxLength={13}
                                      onKeyPress={handleKeyPress}
                                      value={value.fax}
                                    />
                                  </div>
                                </div>
                              </Grid>
                            </Grid>

                          </Box>
                          <Box>
                            <Grid container spacing={2} className="org-rowgap">
                              <Grid item xl={3} lg={3} md={6} sm={6} xs={12} >
                                <div >
                                  <div className="label-space">
                                    <label className="organization-label">
                                      Membership Type
                                    </label>
                                  </div>
                                  <div>

                                    <Dropdown
                                      component={TextField}
                                      className="select-Membership"
                                      value={membershipTypes}
                                      options={PatientNameSuffix}
                                      onChange={(e) => {
                                        onSelectMembershipTypes(e)

                                      }}
                                      placeholder="Member Type"
                                      name="nameSuffixId"
                                    />
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} sm={6} xs={12} >
                                <div className="field-container">
                                  <div className="label-space">
                                    <label className="organization-label">
                                      Office Contact First Name
                                    </label>
                                  </div>
                                  <div>
                                    <Field
                                      component={TextField}
                                      className="cinput-container"
                                      type="text"
                                      placeholder="Enter Office Contact First Name"
                                      name="officeContactFirstName"
                                      variant="outlined"
                                      size="small"
                                      disabled={false}
                                      value={value.officeContactFirstName}
                                    />
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} sm={6} xs={12} >
                                <div className="field-container">
                                  <div className="label-space">
                                    <label className="organization-label">
                                      Office Contact Last Name
                                    </label>
                                  </div>
                                  <div>
                                    <Field
                                      component={TextField}
                                      className="cinput-container"
                                      type="text"
                                      placeholder="Enter Office Last Name"
                                      name="officeContactLastName"
                                      variant="outlined"
                                      size="small"
                                      disabled={false}
                                      value={value.officeContactLastName}
                                    />
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} sm={6} xs={12} >
                                <div className="field-container">
                                  <div className="label-space">
                                    <label className="organization-label">
                                      Office Contact Email
                                    </label>
                                  </div>
                                  <div>
                                    <Field
                                      component={TextField}
                                      className="cinput-container"
                                      type="text"
                                      name="officeContactEmail"
                                      placeholder="Enter Office Contact Email"
                                      variant="outlined"
                                      size="small"
                                      disabled={false}
                                      value={value.officeContactEmail}
                                    />
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box>
                            <Grid container spacing={2} className="org-rowgap">
                              <Grid item xl={3} lg={3} md={6} sm={6} xs={12} >

                                <div >
                                  <div className="label-space">
                                    <label className="organization-label">
                                      Office Contact Phone{" "}
                                      <span className="phoneformat">
                                        format: (+444-555-6666){" "}
                                      </span>
                                    </label>
                                  </div>
                                  <div>
                                    {/* <InputMask style={{ height: "2.5em" }} mask="999-999-9999" className="cinput-container" placeholder="Enter Phone Number" id="ssn" ></InputMask> */}
                                    <Field
                                      component={TextField}
                                      className="cinput-container"
                                      type="text"
                                      name="officeContactPhone"
                                      placeholder="Enter Office Contact Phone"
                                      variant="outlined"
                                      size="small"
                                      disabled={false}
                                      maxLength={15}
                                      onKeyPress={handleKeyPressOff}
                                      value={value.officeContactPhone}

                                    />
                                  </div>
                                </div>
                              </Grid>
                              <Grid item xl={3} lg={3} md={6} sm={6} xs={12} >
                                <div className="field-container">
                                  <div className="label-space">
                                    <label className="organization-label">
                                      Office Contact Title
                                    </label>
                                  </div>
                                  <div>
                                    <Field
                                      component={TextField}
                                      className="cinput-container"
                                      type="text"
                                      name="officeContactTitle"
                                      placeholder="Enter Office Contact Title"
                                      variant="outlined"
                                      size="small"
                                      disabled={false}
                                      value={value.officeContactTitle}
                                    />
                                  </div>
                                </div>
                              </Grid>
                            </Grid>
                          </Box>

                        </div>
                        <div className="bspace">
                          <Button
                            className="create-button"
                            variant="contained"
                            type="submit"
                            color="primary"
                            // onClick={handleFormSubmit}
                            disabled={false}
                          // onClick={navigateToEdit}
                          >
                            CREATE
                          </Button>
                        </div>
                      </div>


                    </Form>
                  )}
                </Formik>
              </div>
              <ToastContainer />
              {/* <Footer /> */}
            </div>
          </div>
        </Card>
      ) : null}
    </div>
  );
}

export default Organizaton;
