import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import "./styles.css";
import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";

const DataTableWeightDemo = (props) => {
  // console.log("testing", props);
  const [tableData, setTableData] = useState([]);

  if (props?.barChartBpGlucoseData?.length == 0) {
    tableData.splice(0, tableData.length);
  }

  if (props?.barChartBpGlucoseData?.length > 0) {
    tableData.splice(0, tableData.length);
    props?.barChartBpGlucoseData?.forEach((element, curr) => {
      tableData.push(element);
    });
  }

  const WeightTemplate = (rowData) => {
    if (rowData["dataItemData"]) {
      return (JSON.parse(rowData["dataItemData"]).weight / 453.6).toFixed(1);
    } else {
      return 0;
    }
  };

  const dateTemplate = (rowData) => {
    return moment(rowData["createdOn"]).format("DD-MM-YYYY hh:mm:ss A");
  };

  return (
    <div>
      <div className="tabledata">
        <DataTable
          value={tableData}
          responsiveLayout="scroll"
          scrollable
          scrollHeight="605px"
        >
          <Column field="createdOn" header="DATE" body={dateTemplate}></Column>
          <Column
            field="dataItemData"
            body={WeightTemplate}
            header="WEIGHT"
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default DataTableWeightDemo;
