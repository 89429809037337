import { createSlice, current } from "@reduxjs/toolkit";
import { states, VoiceCallStatus } from "../Component/States/states";

const initialState = {
  deviceDetails: {},
  voiceCallDetails: {
    fullName: "",
    phoneNumber: "",
    showPopup: false,
    callState: states.OFFLINE,
    popupMax: true,
    popupMin: false,
    callStatus: VoiceCallStatus.RINGING,
  },
};

const VoiceCallSlice = createSlice({
  name: "VoiceCallSlice",
  initialState,
  reducers: {
    handleVoiceCall: (state, action) => {
      state.voiceCallDetails = {
        ...state.voiceCallDetails,
        fullName: action.payload.fullName,
        phoneNumber: action.payload.phoneNumber,
        showPopup: true,
        callState: states.ON_CALL,
        popupMax: true,
        popupMin: false,
      };
    },
    handleDevice: (state, action) => {
      state.deviceDetails = { ...state.deviceDetails, ...action.payload };
    },
    deviceDisconnect: (state, action) => {
      const call = current(state.deviceDetails);
      call._activeCall.disconnect();
      state.voiceCallDetails = initialState.voiceCallDetails;
      state.deviceDetails = initialState.deviceDetails;
    },
    deviceMute: (state, action) => {
      const call = current(state.deviceDetails);
      call._activeCall.mute(action.payload);
    },
    popupMinimize: (state, action) => {
      // show popup at bottom
      state.voiceCallDetails = {
        ...state.voiceCallDetails,
        popupMin: true,
        popupMax: false,
      };
    },
    popupMaximize: (state, action) => {
      // show popup at center
      state.voiceCallDetails = {
        ...state.voiceCallDetails,
        popupMax: true,
        popupMin: false,
      };
    },
    deviceStatus: (state, action) => {
      state.voiceCallDetails = {
        ...state.voiceCallDetails,
        callStatus: action.payload,
      };
    },
    reset: (state, action) => {
      state.voiceCallDetails = initialState.voiceCallDetails;
      state.deviceDetails = initialState.deviceDetails;
    },
  },
});

export const {
  handleVoiceCall,
  handleDevice,
  deviceDisconnect,
  reset,
  deviceMute,
  deviceStatus,
  popupMinimize,
  popupMaximize,
} = VoiceCallSlice.actions;

export default VoiceCallSlice.reducer;
