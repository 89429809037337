import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
// import Scatter from "primereact/Scatter";
// import "../../index.css";
import ReactDOM from "react-dom";
// import React, { useState } from "react";
// import { Chart } from "primereact/chart";
import React, { useState, useEffect } from "react";
import { Chart } from "primereact/chart";
import moment from "moment";

const BarChartDemo = React.memo((props) => {
  // console.log("testing", props?.barChartBpGlucoseData);
  // console.log("props", props?.barChartBpGlucoseData.length);

  const [createdDate, setCreatedDAte] = useState([]);
  const [systolicData, setSystolicData] = useState([]);
  const [diastolicData, setDiastolicData] = useState([]);
  const [pulseData, setPulseData] = useState([]);

  if (props?.barChartBpGlucoseData?.length == 0) {
    createdDate.splice(0, createdDate.length);
  }
  
  if (props?.barChartBpGlucoseData?.length > 0) {
    createdDate.splice(0, createdDate.length);
    props?.barChartBpGlucoseData?.forEach((element, curr) => {
      createdDate.push(moment(element.createdOn).format("MM-DD-YYYY hh:mm A"));
      systolicData.push(JSON.parse(element.dataItemData).systolic);
      diastolicData.push(JSON.parse(element.dataItemData).diastolic);
      pulseData.push(JSON.parse(element.dataItemData).pulse);
    });
  }


  const [basicData] = useState({
    labels: createdDate,
    datasets: [
      {
        label: "Systolic (mmHg)",
        backgroundColor: "#009688",
        data: systolicData,
        borderWidth: 2,
        barThickness: 30,
        borderRadius: 20,
      },
      {
        label: "Diastolic (mmHg)",
        backgroundColor: "#435D6A",
        data: diastolicData,
        borderWidth: 2,
        barThickness: 30,
        borderRadius: 20,
      },
      {
        label: "Pulse ",
        backgroundColor: "#FFD700",
        data: pulseData,
        borderWidth: 2,
        barThickness: 30,
        borderRadius: 20,
      },
    ],
  });

  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.8,
      plugins: {
        legend: {
          labels: {
            color: "grey",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "grey",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "grey",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };
    return {
      basicOptions,
    };
  };

  const { basicOptions } = getLightTheme();

  return (
    <div>
      {(props?.barChartBpGlucoseData?.length >= 20)?
      <div className="card">
        <Chart type="bar" data={basicData} options={basicOptions} />
      </div>
      :
      <div className="cardtable">
        <Chart type="bar" data={basicData} options={basicOptions} />
      </div>}
    </div>
  );
});

export default BarChartDemo;
