  import React, { useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import "./styles.css";
import { Formik, Form, Field } from "formik";
import { NOT_EMPTY_REGEX } from "../../_main/constants";
import { EMPTY_EMAIL } from "../../_main/errorConstants";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { TextField } from "formik-material-ui";
import { RESET_ROUTE } from "../../_main/routeConstants";
import { changeUsers } from "../../Api_Redux/ForgotSlice";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

function Forget() {
  //navigation
  const navigate = useNavigate();
  const dispatch = useDispatch();      

  const initial = {
    email: "",
  };
//state
  const [state, setState] = useState({ message: "",      isMessage: false, autoSeek: "name", });
  const [initialVal, setInitialValues] = useState(initial);

  const navigateToLogin = () =>{
    navigate("/")
  }

  const handleSubmit = (form, { resetForm }) => {
    dispatch(changeUsers(form))
      .then((isSuccess) => {
        if (isSuccess.payload !== undefined) {
          resetForm();
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });       
        } else {
          resetForm();
          toast.error("Invalid Email", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      })
      .catch(({ error }) => {
        console.log(error);
      });
  };

  return (
    <div className="main-Conatiner">
        <div className="header-container">
          <div className='header-setion'>
            <div className="forgot-head">RPM</div>
            <div className="forgot-two">DOCTOR</div>
          </div>
          <div className="header-field">
            <Formik
              initialValues={initialVal}
              onSubmit={handleSubmit}
              enableReinitialize
              validate={(values) => {
                const errors = {};
                // eslint-disable-next-line no-restricted-syntax
                for (const obj in values) {
                  // eslint-disable-next-line default-case
                  switch (obj) {
                    case "email":
                      !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                        (errors.email = EMPTY_EMAIL);
                      break;
                  }
                }
                return errors;
              }}
            >
              {({}) => (
                <Form className=" product-section">
                  <div className="field-container">
                    <p className="mb-0">
                      <label className='name-list'>Email</label>
                    </p>
                    <div>
                      <Field
                        component={TextField}
                        className="input-container"
                        name="email"
                        type="text"
                        placeholder="Email"
                        variant="outlined"
                        size="small"
                        disabled={false}
                      />
                    </div>
                  </div>
                  <div>
                    <p className="forgotten">
                      If you have forgotten your password you can reset here
                    </p>
                  </div>
                  <div className="btn-container">
                    <Button
                      className="button-reset"
                      style={{ width: "14em" }}
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={false}
                      // onClick={navigateToReset}
                    >
                      Reset My Password
                    </Button>
                  </div>
                  <div className="btn-container">
                    <Button
                      style={{ width: "14em" }}
                      className="button-reset"
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={false}
                      onClick={navigateToLogin}
                    >
                      Cancel
                    </Button>
                    </div>
                </Form>
              )}
            </Formik>
            <ToastContainer />
            <div className="ftext">              
              <AiOutlineCopyrightCircle />&nbsp;
              <div>
                2023&nbsp;-&nbsp;Rpm.Doctor&nbsp;-&nbsp;<a>Privacy</a>
              </div>
            </div>
          </div>
        </div> 
      
    </div>
    // </div>
  );
}

export default Forget;
