import React, { useEffect, useState } from "react";
// import Table from "../../Component/Table";
import "./styles.css";
import { IoMdAddCircle } from "react-icons/io";
import { ADDSTAFF_ROUTE, EDITSTAFF_ROUTE } from "../../_main/routeConstants";
import { useNavigate } from "react-router-dom";
import PaginatorDemo from "../../Component/Paginator";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MdModeEdit } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import { GrPowerReset } from "react-icons/gr";
import { AiFillDelete } from "react-icons/ai";
import { BsGenderFemale } from "react-icons/bs";
import { BsGenderMale } from "react-icons/bs";
import image1 from "../../asset/Image/image1.png";
import Footer from "../../Component/Footer";
import { staffList } from "../../Api_Redux/StaffListSlice";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { InputText } from "primereact/inputtext";
import { Avatar } from "primereact/avatar";
import { deleteStaff } from "../../Api_Redux/StaffDeleteSlice";
import { changeUsers } from "../../Api_Redux/ForgotSlice";
import { Button } from "primereact/button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import IconButton from '@mui/material/IconButton';
import Tooltip from "@mui/material/Tooltip";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DASHBOARD_ROUTE } from "../../_main/routeConstants";
import edit_icon from "../../asset/Image/edit_icon.png";
import del_icon from "../../asset/Image/del_icon.png";
import ResetPassword from "../../asset/Image/ResetPassword.svg";

function ManageStaff() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navigate1 = useNavigate();
  //state
  const [value3, setValue3] = useState("");
  const [show, setShow] = useState(false);
  const [showreset, setresetShow] = useState(false);

  const [fullName, setFullName] = useState("");
  const [staffId, setStaffId] = useState(0);
  const [staffEmail, setStaffEmail] = useState("");


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleresetClose = () => setresetShow(false);
  const handleresetShow = () => setresetShow(true);

  const customRows1 = localStorage.getItem("customRows1");
  const current_page = localStorage.getItem("current_page");


  //useselector
  const staffdata = useSelector(
    (state) => state.StaffListSlice?.staff?.data?.staffList
  );
  const total = useSelector(
    (state) => state.StaffListSlice?.staff?.data?.totalCount
  );

  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseOut = () => {
    setIsHovering(false);
  };

  // const navigateToEdit = (rowData) => {
  //   navigate(EDITSTAFF_ROUTE, { state: rowData.id });
  // };

  const navigateToEdit = (rowData) => {
    navigate(EDITSTAFF_ROUTE, { state: rowData });
  };

  const navigateToEdit1 = () => {
    navigate1(ADDSTAFF_ROUTE);
  };

  const getPageDetail = (data) => {
    const params = {
      pageNo: data.page + 1,
      pageSize: data.pageCount,
      search: "",
    };
    dispatch(staffList(params));
  };

  const openDeleteModal = (rowData) => {
    // console.log("deletedstaff", rowData.id, rowData.email);
    setFullName(rowData.firstName);
    setStaffId(rowData.id);
    setShow(true);
  };

  const openResetModal = (rowData) => {
    // console.log("resetStaff", rowData.email);
    setFullName(rowData.firstName);
    setStaffEmail(rowData.email);
    setresetShow(true);
  };

  const handleDeleteStaff = () => {
    const params = {
      id: staffId,
    };
    dispatch(deleteStaff(params))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          const params = {
            pageNo: 1,
            pageSize: 5,
            search: "",
          };
          dispatch(staffList(params));
          setTimeout(() => { }, 1000);
          setShow(false);
        } else {
          toast.error(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => { }, 1000);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const handleResetStaff = () => {
    const params = {
      email: staffEmail,
    };
    dispatch(changeUsers(params))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => { }, 1000);
          // setShow(false);
          setresetShow(false);
        } else {
          toast.error(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => { }, 1000);
        }
      })
      .catch((error) => {
        alert(error);
      });
  };

  const actionBodyTemplate = (rowData) => {
    return (

      <React.Fragment>
        <Tooltip
          title={<p style={{ fontSize: "10px" }}>Edit Staff</p>}
          placement="right-start"
          arrow
        >
          {/* <MdModeEdit
            size={25}
            onClick={() => navigateToEdit(rowData)}
            className="ms-edit"
          /> */}

          <img
            className="ms-edit"
            src={edit_icon}
            onClick={() => navigateToEdit(rowData)}
          // onClick={(e) => { e.stopPropagation(); navigateToVideo(rowData); } }
          />

        </Tooltip>

        <Tooltip
          title={<p style={{ fontSize: "10px" }}>Delete Staff</p>}
          placement="bottom"
          arrow
        >
          <img
            className="ms-delete"
            src={del_icon}
            onClick={() => openDeleteModal(rowData)}
          // onClick={(e) => { e.stopPropagation(); navigateToVideo(rowData); } }
          />
        </Tooltip>


        {/* <GrPowerReset size={25}  onClick={() => openResetModal(rowData)} className="ms-reset" /> */}

        <Tooltip
          title={<p style={{ fontSize: "10px" }}>Reset Password</p>}
          placement="bottom"
          arrow
        >
          <img
            className="ms-reset"
            src={ResetPassword}
            onClick={() => openResetModal(rowData)}
          // onClick={(e) => { e.stopPropagation(); navigateToVideo(rowData); } }
          />
        </Tooltip>

        {/* {isHovering && <h2>Hello world</h2>} */}

      </React.Fragment>
    );
  };

  const goback = () => {
    navigate(DASHBOARD_ROUTE);
  };

  const handleSearch = (e) => {
    const staffParams = {
      pageNo: current_page,
      pageSize: customRows1,
      search: e.target.value,
    };
    dispatch(staffList(staffParams));
  };
  const params = {
    pageNo: current_page,
    pageSize: customRows1,
    search: "",
  };
  useEffect(() => {
    dispatch(staffList(params));
  }, []);

  const representativeBodyTemplate = (rowData) => {
    return (
      <>
        {/* <img src={image1} style={{ width: "2.5em", height: "2.5em" }} /> */}
        <Avatar
          image={rowData.avatarUrl}
          className="mr-2"
          size="large"
          shape="circle"
          style={{ backgroundColor: "#99D5CF", color: "#ffff" }}
          label={rowData.fullName.toUpperCase().slice(0, 1)}
        />
        <span className="staffname">{rowData.firstName}</span>
      </>
    );
  };

  return (
    <>
      <div>
        <Modal show={show} onHide={handleClose} animation={false}>
          <Modal.Header className='popupclose-btn' closeButton></Modal.Header>
          <Modal.Body>Do you want to Delete {fullName} ?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleDeleteStaff}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        {/* --------- */}
        <Modal show={showreset} onHide={handleresetClose} animation={false}>
          <Modal.Header className='popupclose-btn' closeButton></Modal.Header>
          <Modal.Body >Do you want to reset "{fullName}" password?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleresetClose}>
              No
            </Button>
            <Button variant="primary" onClick={handleResetStaff}>
              Yes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
      <Grid container spacing={2} className="section1">
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <div className="managestaff">MANAGE STAFF</div>
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
          <div className="dashbsearch-patient">
            <div className="p-input-icon-left seach-btn">
              <i className="pi pi-search" />
              <InputText onChange={handleSearch} placeholder="Search" />
            </div>
            <div className='text-center'>
              <div>
                <span>
                  <IoMdAddCircle
                    size={40}
                    className='dashbadd-patient'
                    onClick={navigateToEdit1}
                  />
                </span>
              </div>
              <div className="add-patient">ADD STAFF</div>
            </div>
          </div>
        </Grid>
      </Grid>

      <div>
        <ToastContainer />
        <div className="tabular">
          <DataTable
            // paginator
            responsiveLayout="scroll"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={7}
            rowsPerPageOptions={[10, 20, 50]}
            className='dashtable'
            value={staffdata}
            scrollable
            scrollHeight="1000px"
          >
            <Column
              body={representativeBodyTemplate}
              field="firstName"
              header="FIRST NAME"
              sortable
            ></Column>
            <Column field="lastName" header="LAST NAME" sortable></Column>
            <Column field="email" header="EMAIL" sortable></Column>
            <Column field="phoneNumber" header="PHONE NUMBER" sortable></Column>
            <Column field="userName" header="USER NAME" sortable></Column>
            <Column field="npiNumber" header="NPI NUMBER" sortable></Column>
            <Column body={actionBodyTemplate} header="ACTION"></Column>
          </DataTable>
        </div>
        <PaginatorDemo staffDataLength={total} />
      </div>
      {/* <Footer /> */}
    </>
  );
}

export default ManageStaff;
