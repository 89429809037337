import React, { useRef, useEffect, useState } from "react";
import { Navigate, NavLink, Outlet, useNavigate } from "react-router-dom";
import dashboard_menu from "../../asset/Image/dashboard-menu.svg";
import search from "../../asset/Image/search.png";
import message from "../../asset/Image/message.png";
import { Button } from "primereact/button";
import alaram from "../../asset/Image/alaram.png";
// import mask from "../../asset/Image/mask.svg";
import dummyavatar from "../../asset/Image/dummyavatar.svg";
import menu1 from "../../asset/Image/menu1.png";
// import sbarrow from "../../asset/Image/sbarrow.svg"
import { Menu } from "primereact/menu";
import { logoutUsers } from "../../Api_Redux/LogoutSlice.js";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
// import { CHANGEPASSWORD_ROUTE } from "../../_main/errorConstants";
import { signOut } from "../../Api_Redux/UserSlice";
import Polygon from "../../asset/Image/Polygon .svg";
import { InputText } from "primereact/inputtext";
import { CHANGEPASSWORD_ROUTE } from "../../_main/routeConstants";
import { BsKey } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { CiLogout } from "react-icons/ci";
// import { useNavigate } from "react-router-dom";
import { Alert } from "bootstrap";
import { VscOrganization } from "react-icons/vsc";
import { requestForToken } from "../../_main/firebase";
import { getprofiledetail } from "../../Api_Redux/GetProfileSlice";
import { Avatar } from "primereact/avatar";
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
// import addNotificationList from "../../Api_Redux/NotificationListSlice"
import Modal from "react-bootstrap/Modal";
import {
  NOTIFICATIONPAGE_ROUTE,
  NEWNOTIFICATIONS_ROUTE,
  INDIVIDUALPATIENTDETAILS_ROUTE,
} from "../../_main/routeConstants";
import Badge from "@mui/material/Badge";
import patientprofile from "../../../src/asset/Image/patientprofile.png";
import { getCounts } from "../../Api_Redux/GetCountsListSlice";
import DraftsIcon from "@mui/icons-material/Drafts";
import { getNewNotificationList } from "../../Api_Redux/NewNotificationsSlice";
import { patientId } from "../../Api_Redux/PatientidSlice";
import NewNotifications from "../../pages/NewNotifications";
import {
  CAREPROVIDER_PROFILE_URL,
  CAREPROVIDER_ORGANIZATION_URL,
  CHANGEPASSWORD_URL,
  PATIENT_PROFILE_URL,
  STAFF_PROFILE_URL,
} from "../../_main/constants";
// import Button from "@material-ui/core/Button";
// import Menu from "@material-ui/core/Menu";
import moment from "moment";

const items2 = [
  {
    items: [
      {
        label: "Notification",
      },
    ],
  },
];

const leftMenu = [
  {
    image: dashboard_menu,
    link: "",
  },
];

function Toolbar() {
  const dispatch = useDispatch();
  const navigates = useNavigate();
  const [deviceId, setDeviceId] = useState("");
  const [show, setShow] = useState(false);
  const [overallCount, setOverAllCount] = useState(0);
  const [newNotificationCount, setNewNotificationCount] = useState(0);
  const [notificationList, setNotificationData] = useState([]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const notificationdata = useSelector(
    (state) => state.NewNotificationsSlice?.newNotificationList?.data
    
  );
  
  const notify = () => {
    navigates(NOTIFICATIONPAGE_ROUTE);
  };

  const newNotify = () => {
    navigates(NEWNOTIFICATIONS_ROUTE);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose1 = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePatientDashboard = (user) => {
    const param = {
      id: user.userId,
    };
    dispatch(patientId(param));
    navigate(INDIVIDUALPATIENTDETAILS_ROUTE, { state: user });
    setNewNotificationCount(newNotificationCount-1)
  };



  useEffect(() => {
    // console.log("worksssss")
    requestForToken()
      //
      .then((res) => {
        setDeviceId(res);
      });
    dispatch(getprofiledetail());
    dispatch(getCounts())
      .then((isSucess) => {
        if (isSucess.payload.status == 0) {
          setOverAllCount(isSucess.payload.data.notificationCount);
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // dispatch(getNewNotificationList()).then((isSuccess) => {
    //   // console.log('isSuccessisSuccess', isSuccess)

    //   if (isSuccess.payload.status == 1) {
    //     setNewNotificationCount(isSuccess.payload.data.notificationList.length);
    //   }
    // });
  }, [overallCount]);

  useEffect(() => {

    dispatch(getNewNotificationList()).then((isSuccess) => {
      // console.log('isSuccessisSuccess', isSuccess)
      // debugger
      if (isSuccess.payload.status == 1) {
        setNewNotificationCount(isSuccess.payload.data.notificationList.length);
        setNotificationData(isSuccess.payload.data.notificationList);
      }
    });
  },[newNotificationCount])

  const notificationsLabel = (count) => {
    if (count === 0) {
      return "no notifications";
    }
    if (count > 99) {
      return "more than 99 notifications";
    }
    return `${count} notifications`;
  };

  // console.log(deviceId)

  const param = {
    deviceId: deviceId,
    userId: localStorage.getItem("careproviderId"),
    
  };

  // console.log(param,"/////")

  const items4 = [
    {
      items: [
        {
          label: "Edit Profile",
          icon: <CgProfile size={25} />,
          url: PATIENT_PROFILE_URL,
        },
        {
          label: "Change Password",
          icon: <BsKey size={25} />,
          url: CHANGEPASSWORD_URL,
          command: () => {
            navigate(CHANGEPASSWORD_ROUTE);
            // alert("123")
          },
        },
        {
          label: "Log Out",
          icon: <CiLogout size={25} />,
          // url: "http://localhost:3000",
          // url:'https://doctorfaraway20200801160839-rpmstaging.azurewebsites.net/',
          command: () => {
            setShow(true);
          },
        },
      ],
    },
  ];

  const items6 = [
    {
      items: [
        {
          label: "Edit Profile",
          icon: <CgProfile size={25} />,
          url: STAFF_PROFILE_URL,
        },
        {
          label: "Change Password",
          icon: <BsKey size={25} />,
          url: CHANGEPASSWORD_URL,
          command: () => {
            navigate(CHANGEPASSWORD_ROUTE);
            // alert("123")
          },
        },
        {
          label: "Log Out",
          icon: <CiLogout size={25} />,
          // url: "http://localhost:3000",
          // url:'https://doctorfaraway20200801160839-rpmstaging.azurewebsites.net/',
          command: () => {
            setShow(true);
          },
        },
      ],
    },
  ];

  const items5 = [
    {
      items: [
        {
          label: "Edit Profile",
          icon: <CgProfile size={25} />,
          url: CAREPROVIDER_PROFILE_URL,
        },
        {
          label: "My Organization",
          icon: <VscOrganization size={25} />,
          url: CAREPROVIDER_ORGANIZATION_URL,
        },
        {
          label: "Change Password",
          icon: <BsKey size={25} />,
          // url: "http://localhost:3000/#/changepassword",
          // url:"doctorfaraway20200801160839-rpmstaging.azurewebsites.net/#/changepassword"
          command: () => {
            navigate(CHANGEPASSWORD_ROUTE);
            // alert("123")
          },
        },
        {
          label: "Log Out",
          icon: <CiLogout size={25} />,
          // url: "http://localhost:3000",
          // url:'https://doctorfaraway20200801160839-rpmstaging.azurewebsites.net/',
          command: () => {
            setShow(true);
          },
        },
      ],
    },
  ];

  const handleLogout = () => {
  //  debugger;
    // console.log(param,"/[[[]][][[");
    navigate("/login");
    localStorage.removeItem("authorName");
          localStorage.removeItem("careproviderId");
          localStorage.removeItem("isLoggedIn");
          localStorage.removeItem("AccessToken");
          localStorage.removeItem("refreshtoken");
          localStorage.removeItem("UpdatedrefreshToken");
          localStorage.removeItem("email");
          localStorage.removeItem("userType");
          localStorage.removeItem("Notification");
          localStorage.removeItem("userID");
          localStorage.removeItem("roomSId");
          localStorage.removeItem("VideoToken");
          localStorage.removeItem("createddate");
          localStorage.removeItem("ccm_timer");
          localStorage.removeItem("timer");
          localStorage.removeItem("stateUserId");
    
    dispatch(logoutUsers(param))
    

      .then((isSuccess) => {
        // navigate("/login");
        // debugger;
        if (isSuccess.payload.status == 1) {
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          })
        
          // localStorage.removeItem("authorName");
          // localStorage.removeItem("careproviderId");
          // localStorage.removeItem("isLoggedIn");
          // localStorage.removeItem("AccessToken");
          // localStorage.removeItem("refreshtoken");
          // localStorage.removeItem("UpdatedrefreshToken");
          // localStorage.removeItem("email");
          // localStorage.removeItem("userType");
          // localStorage.removeItem("Notification");
          // localStorage.removeItem("userID");
          // localStorage.removeItem("roomSId");
          // localStorage.removeItem("VideoToken");
          // localStorage.removeItem("createddate");
          // localStorage.removeItem("ccm_timer");
          // localStorage.removeItem("timer");
          // localStorage.removeItem("stateUserId");
        } else {
          toast.error("Logout Failed", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => { }, 1000);
        }
      });
      // .catch((err) => console.log(err,"////"));
    setShow(false);
    // debugger;
  };

  const menu = useRef(null);
  const menu1 = useRef(null);
  const menu2 = useRef(null);
  const menu3 = useRef(null);
  const menu4 = useRef(null);
  const menu5 = useRef(null);
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();

  const screenState = useSelector((state) => state.LoginSlice?.login?.data);

  const updatedProf = useSelector(
    (state) => state.GetProfileSlice?.getpro?.data
  );

  const carproviderdetails = useSelector(
    (state) => state.PatientidSlice?.patientid?.data
  );

  // import DraftsIcon from "@mui/icons-material/Drafts";

  const rightMenu = [
    {
      image: message,
      link: "",
      id: 3,
    },
    {
      image: alaram,
      link: "",
      id: 4,
    },

    {
      // image: updatedProf?.avatarURL.length !=0 ? updatedProf?.avatarURL : mask,
      image:
        updatedProf?.avatarURL?.length != 0
          ? updatedProf?.avatarURL
          : dummyavatar,
      link: "",
      id: 5,
    },
  ];



  // useEffect(() => {
  //   dispatch(getNewNotificationList()).then((isSuccess) => {
  //     if (isSuccess.payload.status == 1) {
  //       setNotificationData(isSuccess.payload.data.notificationList);
  //     }
  //   });
  // }, []);


  const itemss = [
    // {label:'NOTIFICATION'},
    // {separator:true},
    {
      template: (item, options) => {
        return (
          <div>

            {notificationList?.length === 0 ? (
              <div >
                <div
                  style={{
                    display: "flex",
                    gap: "0.2rem",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  No result found
                </div>
              </div>
            ) : (
              ""
            )}
            {notificationList?.map((user, index) => {
              return (
                <>
                  <div class="container" style={{ width: "90%", marginLeft: "0.5em" }}>
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        gap: "0.2rem",
                        justifyContent: "space-between",
                        width: "100%",
                      }}
                    >
                      <div onClick={() => handlePatientDashboard(user)}>
                        <div className="d-flex align-items-center">
                          <img
                            className="diagonal1"
                            src={user.avatarUrl ? user.avatarUrl : dummyavatar}
                            alt="Avatar"
                          />
                          <span
                            style={{ marginLeft: "10px", whiteSpace: "nowrap" }}
                          >
                            {user.fullName}
                          </span>
                        </div>
                        {/* <div className="notificationDate">{moment(user.createdDate).format("DD-MM-YYYY HH:MM:SS a")}</div> */}
                        <div>
                          {user.bsStatusName ? (
                            <div
                              className={
                                user.bsStatusName == "Blood Sugar Low"
                                  ? "notificationContentLow"
                                  : "notificationContentHigh"
                              }
                            >
                              {user.bsStatusName}
                            </div>
                          ) : (
                            ""
                          )}
                          {user.bpStatusName ? (
                            <div
                              className={
                                user.bsStatusName == "Blood Pressure Low"
                                  ? "notificationContentLow"
                                  : "notificationContentHigh"
                              }
                            >
                              {user.bpStatusName}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <div>
        <div className="header-part">
          {leftMenu.map((item, index) => (
            <div className="header-part2" key={index}>
              <div className="leftside-logo">
                <img
                  src={dashboard_menu}
                  style={{
                    width: "40px",
                    margin: "6px 8px",
                    cursor: "pointer",
                    // borderRadius:"15px"
                  }}
                />

                {screenState.userType == 3 && (
                  <div className="care-provider">
                    {/* <img className="diagonal" src={carproviderdetails?.careProviderAvatarURL ? carproviderdetails?.careProviderAvatarURL : patientprofile}></img> */}
                    {/* <div className="drtextsize">DR</div> */}
                    <div className="carepro-text">
                      <div>Dr.{carproviderdetails?.careProviderName}</div>
                      <div className="carepro-text-doc">Care Provider</div>
                    </div>
                  </div>
                )}
              </div>

              <div className="smobile-view">
                {rightMenu.map((item, index) => (
                  <div key={index}>
                    {item.id === 3 && (
                      <div className="position-relative">
                        <img
                          className="diagonal card-hghlght1"
                          src={item.image}
                          onClick={(event) => {
                            if (item.id === 3) {
                              return notify();
                              menu2.current.toggle(event);
                            }
                          }}
                          aria-controls="popup_menu"
                          aria-haspopup
                        />
                        {overallCount > 0 ? (
                          <Badge badgeContent={overallCount} color="error">
                            {" "}
                          </Badge>
                        ) : (
                          ""
                        )}
                      </div>
                    )}
                    {screenState.userType !== 3 ? (
                      <>
                        {item.id === 4 && (
                          <div className="position-relative">
                            <img
                              className="diagonal card-hghlght1"
                              src={item.image}
                              onClick={(event) => {
                                if (item.id === 4) {
                                  return menu5.current.toggle(event);
                                }
                              }}
                              aria-controls="popup_menu"
                              aria-haspopup
                            />

                            <Menu
                              model={itemss}
                              popup
                              ref={menu5}
                              id="popup_menu"
                              className={(notificationList?.length === 0) ? "notification-popup1" : "notification-popup"}
                            // {...(notificationList?.length === 0) ? className="notification-popup1" : className='notification-popup' }
                            />
                            <Badge
                              badgeContent={newNotificationCount}
                              color="error"
                            ></Badge>
                          </div>
                        )}
                      </>
                    ) : (
                      ""
                    )}
                    {item.id === 5 && (
                      <img
                        className="diagonal card-hghlght1"
                        src={item.image}
                        onClick={(event) => {
                          if (item.id === 1) return menu.current.toggle(event);
                          if (item.id === 2) return menu1.current.toggle(event);
                          if (item.id === 4) return menu3.current.toggle(event);
                          if (item.id === 5) return menu4.current.toggle(event);
                        }}
                        aria-controls="popup_menu"
                        aria-haspopup
                      />
                    )}

                    <Menu model={items2} popup ref={menu2} id="popup_menu" />

                    {updatedProf?.roleId === 1 ? (
                      <Menu
                        model={items5}
                        popup
                        ref={menu4}
                        id="popup_menu"
                        className="profile-popup"
                        style={{ fontSize: "16px" }}
                      // onClick={() => handleSignOut()}
                      />
                    ) : updatedProf?.roleId === 3 ? (
                      <Menu
                        model={items4}
                        popup
                        ref={menu4}
                        id="popup_menu"
                        className="profile-popup"
                        style={{ fontSize: "16px" }}
                      // onClick={() => handleSignOut()}
                      />
                    ) : (
                      <Menu
                        model={items6}
                        popup
                        ref={menu4}
                        id="popup_menu"
                        className="profile-popup"
                        style={{ fontSize: "16px" }}
                      // onClick={() => handleSignOut()}
                      />
                    )}
                  </div>
                ))}
                <div>
                  <div
                    className="username"
                    style={{
                      color: "#009688",
                      padding: "2px 0px",
                      fontSize: "18px",
                    }}
                  >
                    {updatedProf?.firstName != ""
                      ? updatedProf?.firstName + " " + updatedProf?.lastName
                      : updatedProf?.userName}
                  </div>
                  <div
                    style={{
                      fontSize: "14px",
                      color: "#263238",
                      padding: "2px 0px",
                    }}
                  >
                    {screenState ? screenState?.userTypeName : ""}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* <div className='sidebar-section1 managepatient1'>
          <Outlet />
        </div> */}
      </div>

      <div>
        <Modal show={show} onHide={handleClose} animation={false}>
          {/* <Modal.Header closeButton><h3>Ready to Leave ?</h3></Modal.Header> */}
          <p
            style={{
              marginTop: "3px",
              marginBottom: "1rem",
              marginLeft: "14px",
              fontSize: "25px",
              fontFamily: "Josefin Sans', sans-serif !important",
            }}
          >
            {" "}
            Ready to Leave ?{" "}
          </p>
          <Modal.Body>
            Select 'Logout' below if you are ready to end your current session
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              CANCEL
            </Button>
            <Button variant="primary" onClick={handleLogout}>
              LOGOUT
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
}

export default Toolbar;














// import React, { useRef, useEffect, useState } from "react";
// import { Navigate, NavLink, Outlet, useNavigate } from "react-router-dom";
// import dashboard_menu from "../../asset/Image/dashboard-menu.svg";
// import search from "../../asset/Image/search.png";
// import message from "../../asset/Image/message.png";
// import { Button } from "primereact/button";
// import alaram from "../../asset/Image/alaram.png";
// // import mask from "../../asset/Image/mask.svg";
// import dummyavatar from "../../asset/Image/dummyavatar.svg";
// import menu1 from "../../asset/Image/menu1.png";
// // import sbarrow from "../../asset/Image/sbarrow.svg"
// import { Menu } from "primereact/menu";
// import { logoutUsers } from "../../Api_Redux/LogoutSlice";
// import "./styles.css";
// import { useDispatch, useSelector } from "react-redux";
// import { ToastContainer, toast } from "react-toastify";
// // import { CHANGEPASSWORD_ROUTE } from "../../_main/errorConstants";
// import { signOut } from "../../Api_Redux/UserSlice";
// import Polygon from "../../asset/Image/Polygon .svg";
// import { InputText } from "primereact/inputtext";
// import { CHANGEPASSWORD_ROUTE } from "../../_main/routeConstants";
// import { BsKey } from "react-icons/bs";
// import { CgProfile } from "react-icons/cg";
// import { CiLogout } from "react-icons/ci";
// // import { useNavigate } from "react-router-dom";
// import { Alert } from "bootstrap";
// import { VscOrganization } from "react-icons/vsc";
// import { requestForToken } from "../../_main/firebase";
// import { getprofiledetail } from "../../Api_Redux/GetProfileSlice";
// import { Avatar } from "primereact/avatar";
// import "primeicons/primeicons.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/primereact.css";
// import "primeflex/primeflex.css";
// // import addNotificationList from "../../Api_Redux/NotificationListSlice"
// import Modal from "react-bootstrap/Modal";
// import {
//   NOTIFICATIONPAGE_ROUTE,
//   NEWNOTIFICATIONS_ROUTE,
//   INDIVIDUALPATIENTDETAILS_ROUTE,
// } from "../../_main/routeConstants";
// import Badge from "@mui/material/Badge";
// import patientprofile from "../../../src/asset/Image/patientprofile.png";
// import { getCounts } from "../../Api_Redux/GetCountsListSlice";
// import DraftsIcon from "@mui/icons-material/Drafts";
// import { getNewNotificationList } from "../../Api_Redux/NewNotificationsSlice";
// import { patientId } from "../../Api_Redux/PatientidSlice";
// import NewNotifications from "../../pages/NewNotifications";
// import {
//   CAREPROVIDER_PROFILE_URL,
//   CAREPROVIDER_ORGANIZATION_URL,
//   CHANGEPASSWORD_URL,
//   PATIENT_PROFILE_URL,
//   STAFF_PROFILE_URL,
// } from "../../_main/constants";
// // import Button from "@material-ui/core/Button";
// // import Menu from "@material-ui/core/Menu";
// import moment from "moment";

// const items2 = [
//   {
//     items: [
//       {
//         label: "Notification",
//       },
//     ],
//   },
// ];

// const leftMenu = [
//   {
//     image: dashboard_menu,
//     link: "",
//   },
// ];

// function Toolbar() {
//   const dispatch = useDispatch();
//   const navigates = useNavigate();
//   const [deviceId, setDeviceId] = useState("");
//   const [show, setShow] = useState(false);
//   const [overallCount, setOverAllCount] = useState(0);
//   const [newNotificationCount, setNewNotificationCount] = useState(0);
//   const [notificationList, setNotificationData] = useState([]);
//   const handleClose = () => setShow(false);
//   const handleShow = () => setShow(true);

//   const notificationdata = useSelector(
//     (state) => state.NewNotificationsSlice?.newNotificationList?.data
    
//   );
  
//   const notify = () => {
//     navigates(NOTIFICATIONPAGE_ROUTE);
//   };

//   const newNotify = () => {
//     navigates(NEWNOTIFICATIONS_ROUTE);
//   };

//   const [anchorEl, setAnchorEl] = React.useState(null);

//   const handleClose1 = () => {
//     setAnchorEl(null);
//   };

//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };

//   const handlePatientDashboard = (user) => {
//     const param = {
//       id: user.userId,
//     };
//     dispatch(patientId(param));
//     navigate(INDIVIDUALPATIENTDETAILS_ROUTE, { state: user });
//     setNewNotificationCount(newNotificationCount-1)
//   };



//   useEffect(() => {
//     // console.log("worksssss")
//     requestForToken()
//       //
//       .then((res) => {
//         setDeviceId(res);
//       });
//     dispatch(getprofiledetail());
//     dispatch(getCounts())
//       .then((isSucess) => {
//         if (isSucess.payload.status == 0) {
//           setOverAllCount(isSucess.payload.data.notificationCount);
//         }
//       })
//       .catch((err) => {
//         console.log(err);
//       });

//     // dispatch(getNewNotificationList()).then((isSuccess) => {
//     //   // console.log('isSuccessisSuccess', isSuccess)

//     //   if (isSuccess.payload.status == 1) {
//     //     setNewNotificationCount(isSuccess.payload.data.notificationList.length);
//     //   }
//     // });
//   }, [overallCount]);

//   useEffect(() => {

//     dispatch(getNewNotificationList()).then((isSuccess) => {
//       // console.log('isSuccessisSuccess', isSuccess)
//       // debugger
//       if (isSuccess.payload.status == 1) {
//         setNewNotificationCount(isSuccess.payload.data.notificationList.length);
//         setNotificationData(isSuccess.payload.data.notificationList);
//       }
//     });
//   },[newNotificationCount])

//   const notificationsLabel = (count) => {
//     if (count === 0) {
//       return "no notifications";
//     }
//     if (count > 99) {
//       return "more than 99 notifications";
//     }
//     return `${count} notifications`;
//   };

//   // console.log(deviceId)

//   const param = {
//     deviceId: deviceId,
//     userId: localStorage.getItem("careproviderId"),
//   };

//   const items4 = [
//     {
//       items: [
//         {
//           label: "Edit Profile",
//           icon: <CgProfile size={25} />,
//           url: PATIENT_PROFILE_URL,
//         },
//         {
//           label: "Change Password",
//           icon: <BsKey size={25} />,
//           url: CHANGEPASSWORD_URL,
//           command: () => {
//             navigate(CHANGEPASSWORD_ROUTE);
//             // alert("123")
//           },
//         },
//         {
//           label: "Log Out",
//           icon: <CiLogout size={25} />,
//           // url: "http://localhost:3000",
//           // url:'https://doctorfaraway20200801160839-rpmstaging.azurewebsites.net/',
//           command: () => {
//             setShow(true);
//           },
//         },
//       ],
//     },
//   ];

//   const items6 = [
//     {
//       items: [
//         {
//           label: "Edit Profile",
//           icon: <CgProfile size={25} />,
//           url: STAFF_PROFILE_URL,
//         },
//         {
//           label: "Change Password",
//           icon: <BsKey size={25} />,
//           url: CHANGEPASSWORD_URL,
//           command: () => {
//             navigate(CHANGEPASSWORD_ROUTE);
//             // alert("123")
//           },
//         },
//         {
//           label: "Log Out",
//           icon: <CiLogout size={25} />,
//           // url: "http://localhost:3000",
//           // url:'https://doctorfaraway20200801160839-rpmstaging.azurewebsites.net/',
//           command: () => {
//             setShow(true);
//           },
//         },
//       ],
//     },
//   ];

//   const items5 = [
//     {
//       items: [
//         {
//           label: "Edit Profile",
//           icon: <CgProfile size={25} />,
//           url: CAREPROVIDER_PROFILE_URL,
//         },
//         {
//           label: "My Organization",
//           icon: <VscOrganization size={25} />,
//           url: CAREPROVIDER_ORGANIZATION_URL,
//         },
//         {
//           label: "Change Password",
//           icon: <BsKey size={25} />,
//           // url: "http://localhost:3000/#/changepassword",
//           // url:"doctorfaraway20200801160839-rpmstaging.azurewebsites.net/#/changepassword"
//           command: () => {
//             navigate(CHANGEPASSWORD_ROUTE);
//             // alert("123")
//           },
//         },
//         {
//           label: "Log Out",
//           icon: <CiLogout size={25} />,
//           // url: "http://localhost:3000",
//           // url:'https://doctorfaraway20200801160839-rpmstaging.azurewebsites.net/',
//           command: () => {
//             setShow(true);
//           },
//         },
//       ],
//     },
//   ];

//   const handleLogout = () => {
//     // console.log(param,"/[[[]][][[");
//     dispatch(logoutUsers(param))

//       .then((isSuccess) => {
//         // navigate("/login");
//         if (isSuccess.payload.status == 1) {
//           toast.success(isSuccess.payload.message, {
//             position: toast.POSITION.TOP_RIGHT,
//             autoClose: 2000,
//           });
//           navigate("/login");
//           localStorage.removeItem("authorName");
//           localStorage.removeItem("careproviderId");
//           localStorage.removeItem("isLoggedIn");
//           localStorage.removeItem("AccessToken");
//           localStorage.removeItem("refreshtoken");
//           localStorage.removeItem("UpdatedrefreshToken");
//           localStorage.removeItem("email");
//           localStorage.removeItem("userType");
//           localStorage.removeItem("Notification");
//           localStorage.removeItem("userID");
//           localStorage.removeItem("roomSId");
//           localStorage.removeItem("VideoToken");
//           localStorage.removeItem("createddate");
//           localStorage.removeItem("ccm_timer");
//           localStorage.removeItem("timer");
//           localStorage.removeItem("stateUserId");
//         } else {
//           toast.error("Logout Failed", {
//             position: toast.POSITION.TOP_RIGHT,
//             autoClose: 3000,
//           });
//           setTimeout(() => { }, 1000);
//         }
//       });
//       // .catch((err) => console.log(err,"////"));
//     setShow(false);
//   };

//   const menu = useRef(null);
//   const menu1 = useRef(null);
//   const menu2 = useRef(null);
//   const menu3 = useRef(null);
//   const menu4 = useRef(null);
//   const menu5 = useRef(null);
//   const user = useSelector((state) => state.user);

//   const navigate = useNavigate();

//   const screenState = useSelector((state) => state.LoginSlice?.login?.data);

//   const updatedProf = useSelector(
//     (state) => state.GetProfileSlice?.getpro?.data
//   );

//   const carproviderdetails = useSelector(
//     (state) => state.PatientidSlice?.patientid?.data
//   );

//   // import DraftsIcon from "@mui/icons-material/Drafts";

//   const rightMenu = [
//     {
//       image: message,
//       link: "",
//       id: 3,
//     },
//     {
//       image: alaram,
//       link: "",
//       id: 4,
//     },

//     {
//       // image: updatedProf?.avatarURL.length !=0 ? updatedProf?.avatarURL : mask,
//       image:
//         updatedProf?.avatarURL?.length != 0
//           ? updatedProf?.avatarURL
//           : dummyavatar,
//       link: "",
//       id: 5,
//     },
//   ];



//   // useEffect(() => {
//   //   dispatch(getNewNotificationList()).then((isSuccess) => {
//   //     if (isSuccess.payload.status == 1) {
//   //       setNotificationData(isSuccess.payload.data.notificationList);
//   //     }
//   //   });
//   // }, []);


//   const itemss = [
//     // {label:'NOTIFICATION'},
//     // {separator:true},
//     {
//       template: (item, options) => {
//         return (
//           <div>

//             {notificationList?.length === 0 ? (
//               <div >
//                 <div
//                   style={{
//                     display: "flex",
//                     gap: "0.2rem",
//                     justifyContent: "space-between",
//                     width: "100%",
//                   }}
//                 >
//                   No result found
//                 </div>
//               </div>
//             ) : (
//               ""
//             )}
//             {notificationList?.map((user, index) => {
//               return (
//                 <>
//                   <div class="container" style={{ width: "90%", marginLeft: "0.5em" }}>
//                     <div
//                       key={index}
//                       style={{
//                         display: "flex",
//                         gap: "0.2rem",
//                         justifyContent: "space-between",
//                         width: "100%",
//                       }}
//                     >
//                       <div onClick={() => handlePatientDashboard(user)}>
//                         <div className="d-flex align-items-center">
//                           <img
//                             className="diagonal1"
//                             src={user.avatarUrl ? user.avatarUrl : dummyavatar}
//                             alt="Avatar"
//                           />
//                           <span
//                             style={{ marginLeft: "10px", whiteSpace: "nowrap" }}
//                           >
//                             {user.fullName}
//                           </span>
//                         </div>
//                         {/* <div className="notificationDate">{moment(user.createdDate).format("DD-MM-YYYY HH:MM:SS a")}</div> */}
//                         <div>
//                           {user.bsStatusName ? (
//                             <div
//                               className={
//                                 user.bsStatusName == "Blood Sugar Low"
//                                   ? "notificationContentLow"
//                                   : "notificationContentHigh"
//                               }
//                             >
//                               {user.bsStatusName}
//                             </div>
//                           ) : (
//                             ""
//                           )}
//                           {user.bpStatusName ? (
//                             <div
//                               className={
//                                 user.bsStatusName == "Blood Pressure Low"
//                                   ? "notificationContentLow"
//                                   : "notificationContentHigh"
//                               }
//                             >
//                               {user.bpStatusName}
//                             </div>
//                           ) : (
//                             ""
//                           )}
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </>
//               );
//             })}
//           </div>
//         );
//       },
//     },
//   ];

//   return (
//     <div>
//       <div>
//         <div className="header-part">
//           {leftMenu.map((item, index) => (
//             <div className="header-part2" key={index}>
//               <div className="leftside-logo">
//                 <img
//                   src={dashboard_menu}
//                   style={{
//                     width: "40px",
//                     margin: "6px 8px",
//                     cursor: "pointer",
//                     // borderRadius:"15px"
//                   }}
//                 />

//                 {screenState.userType == 3 && (
//                   <div className="care-provider">
//                     {/* <img className="diagonal" src={carproviderdetails?.careProviderAvatarURL ? carproviderdetails?.careProviderAvatarURL : patientprofile}></img> */}
//                     {/* <div className="drtextsize">DR</div> */}
//                     <div className="carepro-text">
//                       <div>Dr.{carproviderdetails?.careProviderName}</div>
//                       <div className="carepro-text-doc">Care Provider</div>
//                     </div>
//                   </div>
//                 )}
//               </div>

//               <div className="smobile-view">
//                 {rightMenu.map((item, index) => (
//                   <div key={index}>
//                     {item.id === 3 && (
//                       <div className="position-relative">
//                         <img
//                           className="diagonal card-hghlght1"
//                           src={item.image}
//                           onClick={(event) => {
//                             if (item.id === 3) {
//                               return notify();
//                               menu2.current.toggle(event);
//                             }
//                           }}
//                           aria-controls="popup_menu"
//                           aria-haspopup
//                         />
//                         {overallCount > 0 ? (
//                           <Badge badgeContent={overallCount} color="error">
//                             {" "}
//                           </Badge>
//                         ) : (
//                           ""
//                         )}
//                       </div>
//                     )}
//                     {screenState.userType !== 3 ? (
//                       <>
//                         {item.id === 4 && (
//                           <div className="position-relative">
//                             <img
//                               className="diagonal card-hghlght1"
//                               src={item.image}
//                               onClick={(event) => {
//                                 if (item.id === 4) {
//                                   return menu5.current.toggle(event);
//                                 }
//                               }}
//                               aria-controls="popup_menu"
//                               aria-haspopup
//                             />

//                             <Menu
//                               model={itemss}
//                               popup
//                               ref={menu5}
//                               id="popup_menu"
//                               className={(notificationList?.length === 0) ? "notification-popup1" : "notification-popup"}
//                             // {...(notificationList?.length === 0) ? className="notification-popup1" : className='notification-popup' }
//                             />
//                             <Badge
//                               badgeContent={newNotificationCount}
//                               color="error"
//                             ></Badge>
//                           </div>
//                         )}
//                       </>
//                     ) : (
//                       ""
//                     )}
//                     {item.id === 5 && (
//                       <img
//                         className="diagonal card-hghlght1"
//                         src={item.image}
//                         onClick={(event) => {
//                           if (item.id === 1) return menu.current.toggle(event);
//                           if (item.id === 2) return menu1.current.toggle(event);
//                           if (item.id === 4) return menu3.current.toggle(event);
//                           if (item.id === 5) return menu4.current.toggle(event);
//                         }}
//                         aria-controls="popup_menu"
//                         aria-haspopup
//                       />
//                     )}

//                     <Menu model={items2} popup ref={menu2} id="popup_menu" />

//                     {updatedProf?.roleId === 1 ? (
//                       <Menu
//                         model={items5}
//                         popup
//                         ref={menu4}
//                         id="popup_menu"
//                         className="profile-popup"
//                         style={{ fontSize: "16px" }}
//                       // onClick={() => handleSignOut()}
//                       />
//                     ) : updatedProf?.roleId === 3 ? (
//                       <Menu
//                         model={items4}
//                         popup
//                         ref={menu4}
//                         id="popup_menu"
//                         className="profile-popup"
//                         style={{ fontSize: "16px" }}
//                       // onClick={() => handleSignOut()}
//                       />
//                     ) : (
//                       <Menu
//                         model={items6}
//                         popup
//                         ref={menu4}
//                         id="popup_menu"
//                         className="profile-popup"
//                         style={{ fontSize: "16px" }}
//                       // onClick={() => handleSignOut()}
//                       />
//                     )}
//                   </div>
//                 ))}
//                 <div>
//                   <div
//                     className="username"
//                     style={{
//                       color: "#009688",
//                       padding: "2px 0px",
//                       fontSize: "18px",
//                     }}
//                   >
//                     {updatedProf?.firstName != ""
//                       ? updatedProf?.firstName + " " + updatedProf?.lastName
//                       : updatedProf?.userName}
//                   </div>
//                   <div
//                     style={{
//                       fontSize: "14px",
//                       color: "#263238",
//                       padding: "2px 0px",
//                     }}
//                   >
//                     {screenState ? screenState?.userTypeName : ""}
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>

//         {/* <div className='sidebar-section1 managepatient1'>
//           <Outlet />
//         </div> */}
//       </div>

//       <div>
//         <Modal show={show} onHide={handleClose} animation={false}>
//           {/* <Modal.Header closeButton><h3>Ready to Leave ?</h3></Modal.Header> */}
//           <p
//             style={{
//               marginTop: "3px",
//               marginBottom: "1rem",
//               marginLeft: "14px",
//               fontSize: "25px",
//               fontFamily: "Josefin Sans', sans-serif !important",
//             }}
//           >
//             {" "}
//             Ready to Leave ?{" "}
//           </p>
//           <Modal.Body>
//             Select 'Logout' below if you are ready to end your current session
//           </Modal.Body>
//           <Modal.Footer>
//             <Button variant="secondary" onClick={handleClose}>
//               CANCEL
//             </Button>
//             <Button variant="primary" onClick={handleLogout}>
//               LOGOUT
//             </Button>
//           </Modal.Footer>
//         </Modal>
//       </div>
//     </div>
//   );
// }

// export default Toolbar;
