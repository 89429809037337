import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
// import Scatter from "primereact/Scatter";
// import "../../index.css";
import ReactDOM from "react-dom";

import React, { useState, useRef, useEffect } from "react";
import { Chart } from "primereact/chart";
import moment from 'moment';



const BarChartWeightDemo = React.memo((props) => {

    const cardRef = useRef(null);

    const [createdDate, setCreatedDAte] = useState([])

    const [weights, setWeight] = useState([])

    if (props?.barChartBpGlucoseData?.length == 0) {
        createdDate.splice(0, createdDate.length)

    }

    if (props?.barChartBpGlucoseData?.length > 0) {
        createdDate.splice(0, createdDate.length)
        props?.barChartBpGlucoseData?.forEach((element, curr) => {
            createdDate.push(moment(element.createdOn).format('MM/DD/YYYY hh:mm A'))
            weights.push(((JSON.parse(element.dataItemData).weight) / 453.6).toFixed(1))
        });
    }

    const [basicData] = useState({
        labels: createdDate,
        datasets: [
            {
                label: "Weight (kg)",
                backgroundColor: "#009688",
                data: weights,
                categoryPercentage: 0.8,
                borderWidth: 2,
                barThickness: 30,
                borderRadius: 20,
            },

        ]
    });


    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: 0.8,
            plugins: {
                legend: {
                    labels: {
                        color: "grey"
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: "grey"
                    },
                    grid: {
                        color: "#ebedef"
                    }
                },
                y: {
                    ticks: {
                        color: "grey"
                    },
                    grid: {
                        color: "#ebedef"
                    }
                }
            }
        };



        return {
            basicOptions

        };
    };

    const { basicOptions } = getLightTheme();

    useEffect(() => {
        if (cardRef.current !== null) {
            const cardWidth = cardRef.current.offsetWidth;
            if(cardRef.scrollX > cardWidth){
            cardRef.current.scrollBy(cardWidth, 0);
            }
        }
    });
   
    
    return (
        <div>
            <div ref={cardRef} id="chart" className="cardbody">
                <Chart type="bar" data={basicData} options={basicOptions} />
            </div>
        </div>
    );
});


export default BarChartWeightDemo;