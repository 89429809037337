/* eslint-disable default-case */
import React, { useState } from "react";
import { Button } from "primereact/button";
import "../Forgot/styles.css";
import { Formik, Form, Field } from "formik";
import { NOT_EMPTY_REGEX } from "../../_main/constants";
import {
  EMPTY_PASSWORD,
  EMPTY_CONFIRMPASSWORD,
} from "../../_main/errorConstants";
import { TextField } from "formik-material-ui";
import { useNavigate, useLocation } from "react-router-dom";
import { BASE_ROUTE } from "../../_main/routeConstants";
import "./styles.css";
import { resetUsers } from "../../Api_Redux/ResetSlice";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";

function Reset() {
  //navigation
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //queryparams
  const search = useLocation().search;
  const username = new URLSearchParams(search).get("username");
  const email = new URLSearchParams(search).get("email");
  const key = new URLSearchParams(search).get("key");

  const initial = {
    email: email,
    username: username,
    key: key,
    password: "",
    confirmpassword: "",
  };

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [confirmpasswordType, setConfirPassword] = useState("password");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [initialVal] = useState(initial);

  const handlePasswordChange = (event) => {
    setPasswordInput(event.target.value);
  };
  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const toggleConfirmPass = () => {
    if (confirmpasswordType === "password") {
      setConfirPassword("text");
      return;
    }
    setConfirPassword("password");
  };

  const handleSubmit = (form, { resetForm }) => {
    if (form.confirmpassword === form.password) {
      dispatch(resetUsers(form))
        .then((isSuccess) => {
          if (isSuccess.payload.status == 1) {
            resetForm();
            toast.success(isSuccess.payload.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setTimeout(() => {
              navigate(BASE_ROUTE);
            }, 1000);
          } else {
            resetForm();
            toast.error(isSuccess.payload.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setTimeout(() => {}, 1000);
          }
        })
        .catch((error) => {});
    } else {
      toast.error("Password & Confirm Password Mismatch", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  return (
    <div className="main-Conatiner">
      <div className="header-container">
        <div className="header-setion">
          <div className="reset-head">RPM</div>
          <div className="reset-two">DOCTOR</div>
        </div>
        <div className="header-field">
          <Formik
            initialValues={initialVal}
            onSubmit={handleSubmit}
            enableReinitialize
            validate={(values) => {
              const errors = {};
              for (const obj in values) {
                switch (obj) {
                  case "password":
                    !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                      (errors.password = EMPTY_PASSWORD);
                    break;
                  case "confirmpassword":
                    !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                      (errors.confirmpassword = EMPTY_CONFIRMPASSWORD);
                    break;
                }
              }
              return errors;
            }}
          >
            {({}) => (
              <Form className=" product-section">
                <div className="field-container">
                  <div className="reset-text">RESET YOUR PASSWORD</div>
                  <p className="mb-0">
                    <label className="name-list">Email</label>
                  </p>
                  <div className="reset-input">
                    <Field
                      component={TextField}
                      className="input-container"
                      name="email"
                      type="text"
                      value={email}
                      variant="outlined"
                      size="small"
                      disabled={true}
                    />
                  </div>
                  <p className="mb-0">
                    <label className="name-list">User Name</label>
                  </p>
                  <div className="reset-input">
                    <Field
                      component={TextField}
                      className="input-container"
                      type="text"
                      value={username}
                      name="username"
                      variant="outlined"
                      size="small"
                      disabled={true}
                    />
                  </div>
                  <p className="mb-0">
                    <label className="name-list">Password</label>
                  </p>
                  <div className="reset-input">
                    <Field
                      component={TextField}
                      className="input-container"
                      type={passwordType}
                      onClick={handlePasswordChange}
                      name="password"
                      variant="outlined"
                      size="small"
                      disabled={false}
                    />
                    <span
                      onClick={togglePassword}
                      style={{ marginRight: "-1.5rem" }}
                    >
                      {passwordType === "password" ? (
                        <VisibilityOffIcon
                          style={{
                            position: "relative",
                            right: "1.5em",
                            top: "8px",
                          }}
                        />
                      ) : (
                        <VisibilityIcon
                          style={{
                            position: "relative",
                            right: "1.5em",
                            top: "8px",
                          }}
                        />
                      )}
                    </span>
                  </div>
                  <p className="mb-0">
                    <label className="name-list">Confirm Password</label>
                  </p>
                  <div className="reset-input">
                    <Field
                      component={TextField}
                      className="input-container"
                      type={confirmpasswordType}
                      onClick={handleConfirmPassword}
                      name="confirmpassword"
                      variant="outlined"
                      size="small"
                      disabled={false}
                    />
                    <span
                      onClick={toggleConfirmPass}
                      style={{ marginRight: "-1.5rem" }}
                    >
                      {confirmpasswordType === "password" ? (
                        <VisibilityOffIcon
                          style={{
                            position: "relative",
                            right: "1.5em",
                            top: "8px",
                          }}
                        />
                      ) : (
                        <VisibilityIcon
                          style={{
                            position: "relative",
                            right: "1.5em",
                            top: "8px",
                          }}
                        />
                      )}
                    </span>
                  </div>
                </div>
                <div className="btn-container">
                  <Button
                    className="button-forgots"
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={false}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
}

export default Reset;
