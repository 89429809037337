/* eslint-disable default-case */
import React, { useState } from "react";
import { Button } from "primereact/button";
import Footer from "../../Component/Footer";
import { Formik, Form, Field } from "formik";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { Card } from "primereact/card";
import { TextField } from "formik-material-ui";
import { NOT_EMPTY_REGEX } from "../../_main/constants";
import { useDispatch ,useSelector } from "react-redux";
import {
  EMPTY_USERTYPE,
  EMPTY_USERNAME,
  EMPTY_FIRSTNAME,
  EMPTY_LASTNAME,
  EMPTY_EMAIL,
  EMPTY_PHONE,
  EMPTY_NPINUMBER
} from "../../_main/errorConstants";
import "./styles.css";
import {DASHBOARD_ROUTE } from "../../_main/routeConstants"
import { RegisterUsers } from "../../Api_Redux/RegisterSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";



function EditProfile() {

  // const [txt, setTxt] = useState('');

  const dispatch = useDispatch();

  //useselector
  const screenState = useSelector(state => state.LoginSlice.login.data);


  const initial = {
    id: "",
    usertype: "",
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    npiNumber: "",
    avatarUrl: ""
  };


  const handleSubmit = (form, { resetForm }) => {
    form.id = localStorage.getItem(("careproviderId"))
    if (form.confirmpassword === form.password) {
      dispatch(RegisterUsers(form))
        .then((isSuccess) => {
          if (isSuccess.payload.status == 1) {
            resetForm();
            toast.success(isSuccess.payload.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            resetForm();
            toast.error(isSuccess.payload.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setTimeout(() => {}, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      toast.error("Password & Confirm Password Mismatch", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 3000,
      });
    }
    
  };

  return (
    <div>
      <Card style={{ padding: "0px", margin: "0px" }}>
        <div
          style={{
            background: "#009688",
            borderRadius: "10px 10px 11px 10px"
          }}
        >
          <div
            style={{
              color: "white",
              textTransform: "uppercase",
              fontSize: "20px",
              paddingTop: "1%",
              paddingBottom: "1%",
              marginLeft: "1em"
            }}
          >
            edit your profile
          </div>
          <div
            style={{
              background: "#fff",
              padding: "20px",
              borderRadius: "0px 0px 10px 10px",
            }}
          >
            <div className="ecard">
            <Formik
              initialValues={initial}
              onSubmit={handleSubmit}
              enableReinitialize
              validate={(values) => {
                const errors = {};
                // eslint-disable-next-line no-restricted-syntax
                for (const obj in values) {
                  switch (obj) {
                    
                    case "firstname":
                      !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                        (errors.firstname = EMPTY_FIRSTNAME);
                      break;
                    case "lastname":
                      !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                        (errors.lastname = EMPTY_LASTNAME);
                      break;
                    
                    case "phone":
                      !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                        (errors.phone = EMPTY_PHONE);
                      break;
                    case "npinumber":
                      !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                        (errors.npinumber = EMPTY_NPINUMBER);
                      break;
                  }
                }
                return errors;
              }}
            >
              {({ }) => (
                <Form>
                  <p className="ephelloo">
                    Thank you for registering your organization
                  </p>
                  <p className="ephelloo">
                    You could take a moment to update your personal information
                    below, or you could just go straight to your&nbsp; {" "}
                    <a
                      href="https://doctorfaraway20200801160839-rpmstaging.azurewebsites.net/#/dashboard"
                      style={{ color: "#0E91FF", fontWeight: "bold" }}
                    >
                      Dashboard
                    </a>
                  </p>
                    <div className="grid">
                      <div className="col-12">
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          
                          <div className="epfield-container">
                            <label className="elabel">User Name</label>
                            <div className="einputfield">
                              <Field
                                component={TextField}
                                className="epinput-container"
                                type="text"
                                name="username"
                                variant="outlined"
                                value={screenState?screenState.userName:""}
                                size="small"
                                disabled={true}
                                placeholder="User Name"
                                // onChange={onInputChange}
                              />
                            </div>
                          </div>
                          <div className="epfield-container">
                            <label className="elabel">First Name</label>
                            <div className="einputfield">
                              <Field
                                component={TextField}
                                className="epinput-container"
                                type="text"
                                name="firstName"
                                variant="outlined"
                                size="small"
                                disabled={false}
                                placeholder="First Name"
                              />
                            </div>
                          </div>
                          <div className="epfield-container">
                            <label className="elabel">Last Name</label>
                            <div className="einputfield">
                              <Field
                                component={TextField}
                                className="epinput-container"
                                type="text"
                                name="lastName"
                                variant="outlined"
                                size="small"
                                disabled={false}
                                placeholder="Last Name"
                              />
                            </div>
                          </div>
                          <div className="epfield-container">
                            <label className="elabel">Email ID</label>
                            <div className="einputfield">
                              <Field
                                component={TextField}
                                className="epinput-container"
                                name="email"
                                type="text"
                                value={screenState?screenState.email:" "}
                                variant="outlined"
                                size="small"
                                disabled={true}
                                placeholder="Email ID"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid">
                      <div className="col-12">
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <div className="epfield-container">
                            <label className="elabel">
                              Phone Number
                              &nbsp;<span className="epspan">(format: 4445556666)</span>
                            </label>
                            <div className="einputfield">
                              <Field
                                component={TextField}
                                className="epinput-container"
                                type="text"
                                name="phone"
                                variant="outlined"
                                size="small"
                                disabled={false}
                                placeholder="Phone Number"
                              />
                            </div>
                          </div>
                          <div className="epfield-container">
                            <label className="elabel">NPI Number</label>
                            <div className="einputfield">
                              <Field
                                component={TextField}
                                className="epinput-container"
                                type="text"
                                name="npinumber"
                                variant="outlined"
                                size="small"
                                disabled={false}
                                placeholder="NPI Number"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="editp-btn">
                  <Button
                    className="editp-button"
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={false}
                  >
                    SAVE
                  </Button>
                </div>
                </Form>
              )}
            </Formik>
            </div>
          </div>
          {/* <Footer /> */}
        </div>
      </Card>
    </div>
  );
}

export default EditProfile;
