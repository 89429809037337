/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import "./styles.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { BsGenderFemale } from "react-icons/bs";
import { BsGenderMale } from "react-icons/bs";
import { Image } from "primereact/image";
import chat from "../../asset/Image/chat.svg";
import { IoMdAddCircle } from "react-icons/io";
import { useLocation, useNavigate } from "react-router-dom";
import doctorgroup from "../../asset/Image/doctorgroup.svg";
import { videoUser } from "../../Api_Redux/VideoSlice";
import { patientList } from "../../Api_Redux/PatientListSlice";
import { useDispatch, useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import {
  MEETINGLOGS_ROUTE,
  PATIENTHEALTH_ROUTE,
  MANAGESTAFF_ROUTE,
  INDIVIDUALPATIENTDETAILS_ROUTE,
  STAFF_MEETINGLOGS_ROUTE,
} from "../../_main/routeConstants";
import { CARE_CHAT_ROUTE, PATIENTS_ROUTE } from "../../_main/routeConstants";
import { InputText } from "primereact/inputtext";
import { states, VoiceCallStatus } from "../../Component/States/states";
import patientcount from "../../asset/Image/patientcount.svg";
import Grid from "@mui/material/Grid";
import { Avatar } from "primereact/avatar";
import Tooltip from "@mui/material/Tooltip";
import { patientId } from "../../Api_Redux/PatientidSlice";
import Box from "@mui/material/Box";
import moment from "moment";
import { getCounts } from "../../Api_Redux/GetCountsListSlice";
import { patientViewList } from "../../Api_Redux/MyPatientsViewSlice";
import {
  handleVoiceCall,
  handleDevice,
  deviceStatus,
} from "../../Api_Redux/VoiceCallSlice";
import { Paginator } from "primereact/paginator";
import WifiCalling3Icon from "@mui/icons-material/WifiCalling3";
import IconButton from "@mui/material/IconButton";
import { Device } from "@twilio/voice-sdk"; //twilio-client
import { CALLING_API_URL } from "../../_main/constants";
import { CHANGEPASSWORD_ROUTE } from "../../_main/routeConstants";
import { Dropdown } from "primereact/dropdown";
import videocall from "../../asset/Image/videocall.svg";
import Videofull from "../../asset/Image/Videofull.svg";

function Dashboard() {
  //navigation
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationState = useLocation();

  const isDisableCallIcon = useSelector(
    (state) => state.VoiceCallSlice.voiceCallDetails.showPopup
  );

  const [showStatusModal, setShowStatusModal] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [token, setToken] = useState("");
  const [state, setState] = useState(states.CONNECTING);
  const [rowdata, setRowdata] = useState("");
  const [device, setDevice] = useState(null);
  const [overallCount, setOverAllCount] = useState(0);
  const [customFirst1, setCustomFirst1] = useState(0);
  const [customRows1, setCustomRows1] = useState(5);

  const screenState = useSelector((state) => state.LoginSlice.login.data);

  const screenpatientlistState = useSelector(
    (state) => state.patientList?.patient?.data?.totalCount
  );

  const patientViewData = useSelector(
    (state) => state.MyPatientsViewSlice?.patients?.data?.patientList
  );

  const navigateToEdit = () => {
    navigate(PATIENTS_ROUTE);
  };

  const navigateToEdit4 = () => {
    navigate(PATIENTHEALTH_ROUTE);
  };

  const navigateToStaff = () => {
    navigate(MANAGESTAFF_ROUTE);
  };

  const navigateToEdit3 = () => {
    {
      screenState?.userType == 1
        ? navigate(MEETINGLOGS_ROUTE)
        : navigate(STAFF_MEETINGLOGS_ROUTE);
    }
    // navigate(MEETINGLOGS_ROUTE);
  };

  const template1 = {
    layout:
      "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
    PrevPageLink: (options) => {
      
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-2">Pre</span>
        </button>
      );
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: "100 and above", value: 5000 },
      ];

      return (
        <React.Fragment>
          <Dropdown
            value={options.value}
            options={dropdownOptions}
            onChange={options.onChange}
            placeholder="Choose"
          />
        </React.Fragment>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-2">Next</span>
        </button>
      );
    },
  };

  useEffect(() => {
    const patientParams = {
      pageNo: 1,
      pageSize: 5,
      search: "",
    };
    // dispatch(patientList(patientParams));
    dispatch(patientViewList(patientParams));
    dispatch(patientList(patientParams));
    dispatch(getCounts())
      .then((isSucess) => {
        if (isSucess.payload.status == 0) {
          setOverAllCount(isSucess.payload.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSearch = (e) => {
    const patientParams = {
      pageNo: 1,
      pageSize: customRows1,
      search: e.target.value,
    };
    dispatch(patientViewList(patientParams));
  };

  const patientParams = {
    pageNo: 1,
    pageSize: 5,
    search: "",
  };
  // useEffect(() => {
  //   dispatch(patientViewList(patientParams));
  // }, []);

  /*** START: Voice call New integration ***/
  useEffect(() => {
    const url = `${CALLING_API_URL + "voice/token"}`;
    fetch(url)
      .then((response) => response.json())
      .then(({ token }) => {
        setToken(token);
      });
    dispatch(handleDevice({}));
  }, []);

  const unloadCallback = (event) => {
    device._activeCall.disconnect();
  };

  useEffect(() => {
    if (device) {
      dispatch(handleDevice(device));
      dispatch(handleVoiceCall(rowdata));
      window.addEventListener("beforeunload", (event) => unloadCallback(event));
      return () => window.removeEventListener("beforeunload", unloadCallback);
    }
  }, [device]);

  //new one
  const handleCall = async (rowData) => {
    setRowdata(rowData);
    setState(states.ON_CALL);
    const device = new Device(token);
    device.register();
    await device.connect({
      params: {
        To: rowData.phoneNumber,
      },
    });
    setDevice(device);
    const call = device._activeCall;

    call.on("accept", (call) => {
      console.log("Twilio accepted", call.status());
      dispatch(deviceStatus(VoiceCallStatus.CONNECTED));
    });

    call.on("ignore", (call) => {
      console.log("Twilio ignore", call.status());
    });

    call.on("cancel", (call) => {
      console.log("Twilio canceled.", call.status());
    });

    call.on("reject", (call) => {
      console.log("Twilio rejected.", call.status());
    });

    call.on("reconnecting", (twilioError) => {
      console.log("Twilio Connectivity error: ", twilioError);
    });

    call.on("reconnected", (call) => {
      console.log("Twilio regained connectivity.", call.status());
    });

    call.on("disconnect", (call) => {
      console.log("Twilio disconnected.", call.status());
      setTimeout(() => {
        dispatch(deviceStatus(VoiceCallStatus.DISCONNECTED));
      }, 500);
    });
  };
  /*** END: Voice call New integration ***/

  const pnavigateToEdit = (e) => {
    // setValues(e.data)
    const param = {
      id: e.data.userId,
    };
    dispatch(patientId(param));
    navigate(INDIVIDUALPATIENTDETAILS_ROUTE, { state: e.data });
  };

  const onCustomPageChange1 = (event) => {
    setCustomFirst1(event.first);
    setCustomRows1(event.rows);
    const patientParams = {
      pageNo: event.page + 1,
      pageSize: event.rows ? event.rows : 5,
      search: "",
    };
    dispatch(patientViewList(patientParams));
    dispatch(patientList(patientParams));
  };

  const dateTemplate = (rowData) => {
    return moment(rowData["dob"]).format("MM-DD-YYYY");
  };
  const navigateToChat = (rowData) => {
    // console.log("rowdatarowdata", rowData);
    navigate(CARE_CHAT_ROUTE, { state: rowData });
  };

  const navigateToVideo = (rowData) => {
    const param = {
      userId: rowData.userId,
      meetingLink: "",
    };
    dispatch(videoUser(param)).then((isSucess) => {
      if (isSucess) {
        window.open(isSucess.payload.data.url, "_blank").focus();
      }
    });
  };

  const representativeBodyTemplate = (rowData) => {
    return (
      <>
        <Avatar
          image={rowData.avatarUrl}
          className="mr-2"
          size="large"
          shape="circle"
          style={{
            backgroundColor: "#99D5CF",
            color: "#ffff",
            border:
              rowData.bpStatus >= 10 || rowData.bsStatus >= 10
                ? "2px solid #FF0000"
                : rowData.bpStatus == 0 && rowData.bsStatus == 0
                ? "2px solid green"
                : "2px solid #FF8C00",
          }}
          label={rowData.fullName.toUpperCase().slice(0, 1)}
        />
        <span className="patname">{rowData.fullName}</span>
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Tooltip
          title={<p style={{ fontSize: "10px" }}>Make a Call</p>}
          placement="bottom"
          arrow
        >
          <IconButton
            style={{ marginLeft: "-0.5em" }}
            onClick={(e) => {
              e.stopPropagation();
              handleCall(rowData);
            }}
            disabled={isDisableCallIcon}
          >
            {isDisableCallIcon == true ? (
              <WifiCalling3Icon color="disabled" sx={{ fontSize: 27 }} />
            ) : (
              <WifiCalling3Icon color="success" sx={{ fontSize: 27 }} />
            )}
          </IconButton>
        </Tooltip>
        <Tooltip
          title={<p style={{ fontSize: "10px" }}>Chat</p>}
          placement="bottom"
          arrow
        >
          <img
            className="vedioicon"
            src={chat}
            onClick={(e) => {
              e.stopPropagation();
              navigateToChat(rowData);
            }}
            style={{ marginRight: "1em", marginLeft: "1em" }}
            alt="no_image"
          />
        </Tooltip>
        <Tooltip
          title={<p style={{ fontSize: "10px" }}>Make a Video Call</p>}
          placement="bottom"
          arrow
        >
          <img
            className="vedioicon"
            src={Videofull}
            alt="no_image"
            onClick={(e) => {
              e.stopPropagation();
              navigateToVideo(rowData);
            }}
          />
        </Tooltip>
      </React.Fragment>
    );
  };

  const actionBodyTemplate1 = (rowData) => {
    return (
      <>
        <div style={{ marginLeft: "1.5em" }}>
          {rowData?.gender === "Male" ? (
            <BsGenderMale size={25} />
          ) : rowData?.gender === "Female" ? (
            <BsGenderFemale size={25} />
          ) : (
            ""
          )}
        </div>
        {/* (area == 1) ? icon1 : (area == 2) ? icon2 : icon0; */}
      </>
    );
  };

  const callBackClick = (data) => {
    setClicked(false);
  };

  const handleClose2 = () => {
    // setSelectedData(null)
    setShowStatusModal(!showStatusModal);
  };

  const handleClick = () => {
    navigate(CHANGEPASSWORD_ROUTE);
  };

  return (
    <>
      {/* <ProgressSpinner/> */}
      {/* {clicked == false ? ( */}
      <div>
        {screenState.loginCount <= 1 &&
        locationState.state?.fromLogin &&
        screenState.userType !== 1 ? (
          <Modal show={showStatusModal} onHide={handleClose2} animation={false}>
            <Modal.Header className="popupclose-btn" closeButton></Modal.Header>
            <Modal.Body>
              It’s a perfect time to change your password to stronger ones.
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "center" }}>
              <button
                style={{ color: "white", background: "#009688" }}
                variant="primary"
                onClick={handleClick}
              >
                Click Here
              </button>
            </Modal.Footer>
          </Modal>
        ) : (
          ""
        )}
      </div>
      <div>
        <div className="Container1">
          <div className="section1">
            <Grid container spacing={2} className="main-Container1">
              <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                <div className="dcard card-hghlght" onClick={navigateToEdit3}>
                  <div className="cardsection">
                    <div className="dash-card-section">
                      <div className="dash-card-img">
                        <Image src={videocall} />
                      </div>
                      MEETING LOGS
                    </div>

                    <div className="number">
                      {overallCount ? overallCount.meetingLogsTotalCount : 0}
                    </div>
                  </div>
                  <div className="lineone">View All</div>
                </div>
              </Grid>
              {screenState?.userType == 1 ? (
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                  <div className="dcard card-hghlght" onClick={navigateToStaff}>
                    <div className="cardsection">
                      <div className="dash-card-section">
                        <div className="dash-card-img">
                          <Image src={doctorgroup} />
                        </div>
                        TOTAL NO OF STAFF
                      </div>
                      <div className="number">
                        {overallCount ? overallCount.staffsTotalCount : 0}
                      </div>
                    </div>
                    <div className="lineone">View All</div>
                  </div>
                </Grid>
              ) : null}
              {screenState?.userType == 1 ? (
                <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                  <div className="dcard card-hghlght" onClick={navigateToEdit4}>
                    <div className="cardsection">
                      <div className="dash-card-section">
                        <div className="dash-card-img">
                          <Image src={patientcount} />
                        </div>
                        TOTAL NO OF PATIENTS
                      </div>

                      <div className="number">
                        {overallCount ? overallCount.patientsTotalCount : 0}
                      </div>
                    </div>
                    <div className="lineone">View All</div>
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </div>
        </div>
        {screenState?.userType == 1 ? (
          <div>
            <Box>
              <Grid container spacing={2} className="mb-2">
                <Grid
                  item
                  xl={6}
                  lg={6}
                  md={6}
                  sm={12}
                  xs={12}
                  className="patient-text"
                >
                  <div className="patient">YOUR PATIENTS </div>
                </Grid>
                <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                  <div className="dashbsearch-patient">
                    <div className="p-input-icon-left seach-btn">
                      <i className="pi pi-search" />
                      <InputText onChange={handleSearch} placeholder="Search" />
                    </div>
                    <div className="text-center">
                      <div>
                        <span>
                          <IoMdAddCircle
                            size={40}
                            className="dashbadd-patient"
                            onClick={navigateToEdit}
                          />
                        </span>
                      </div>
                      <div className="add-patient">ADD PATIENT</div>
                    </div>
                  </div>
                </Grid>
              </Grid>
            </Box>
            <div className="dtable">
              <div className="tabular mobile-table dashbord-table">
                <DataTable
                  className="datatable-section"
                  value={patientViewData}
                  onRowClick={(e) => pnavigateToEdit(e)}
                  responsiveLayout="scroll"
                  scrollable
                  scrollHeight="480px"
                  selectionMode="single"
                >
                  <Column
                    body={representativeBodyTemplate}
                    field="fullName"
                    header="NAME"
                    sortable
                  ></Column>
                  <Column
                    header="GENDER"
                    body={actionBodyTemplate1}
                    sortable
                  ></Column>
                  <Column
                    field="dob"
                    header="DATE OF BIRTH"
                    body={dateTemplate}
                    sortable
                  ></Column>
                  <Column
                    className="overflow-ellipsis"
                    field="emailId"
                    header="EMAIL"
                    sortable
                  ></Column>
                  <Column field="phoneNumber" header="PHONE" sortable></Column>
                  <Column body={actionBodyTemplate} header="ACTION"></Column>
                </DataTable>
              </div>
            </div>
            <div>
              <Paginator
                template={template1}
                first={customFirst1}
                rows={customRows1}
                totalRecords={screenpatientlistState}
                onPageChange={onCustomPageChange1}
                // rowsPerPageOptions={[5, 10, 20, 50]}
              ></Paginator>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
}

export default Dashboard;
