export const API_URL =
  "https://doctorfaraway20200801160839-rpmstaging.azurewebsites.net/api/";
export const CALLING_API_URL =
  "https://doctorfaraway20200801160839-rpmstaging.azurewebsites.net/";
export const TWILIOVIDEO_URL = "https://rmttwilio.azurewebsites.net/";
export const TWILIOCHAT_URL = "https://rmttwilio.azurewebsites.net/#/chat";

export const CAREPROVIDER_PROFILE_URL =
  "https://webapp.rmthealth.com/#/careprovider/profile";
export const CAREPROVIDER_ORGANIZATION_URL =
  "https://webapp.rmthealth.com/#/careprovider/organization";
export const CHANGEPASSWORD_URL =
  "https://webapp.rmthealth.com/#/changepassword";
export const PATIENT_PROFILE_URL =
  "https://webapp.rmthealth.com/#/patient/profile";
export const STAFF_PROFILE_URL = "https://webapp.rmthealth.com/#/staff/profile";

export const EMAIL_REGEX =
  "^([a-zA-Z0-9_\\-\\.]+)@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.)|(([a-zA-Z0-9\\-]+\\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\\]?)$";
export const NOT_EMPTY_REGEX = "^(?!\\s*$).+";
export const ALPHA_REGEX = "^[0-9a-zA-Z]+$";
export const PASSWORD_REGEX =
  "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})";
export const PHONE_REGEX = "/^(?([0-9]{3}))?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/";

//Methods
export const POST = "POST";
export const GET = "GET";
export const UPDATE = "UPDATE";
export const DELETE = "DELETE";
