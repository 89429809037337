import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { RiDeleteBin6Line } from "react-icons/ri";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { addNotificationList } from "../../Api_Redux/NotificationListSlice";
import { notificationRead } from "../../Api_Redux/NotificationRead";
import { notificationDelete } from "../../Api_Redux/NotificationDelete";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useLocation } from "react-router-dom";
import { UpdateDisabled } from "@mui/icons-material";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import DraftsIcon from "@mui/icons-material/Drafts";
import del_icon from "../../asset/Image/del_icon.png";
import Tooltip from "@mui/material/Tooltip";
import moment from "moment";
import { getCounts } from "../../Api_Redux/GetCountsListSlice";
import { getNewNotificationList } from "../../Api_Redux/NewNotificationsSlice";
import dummyavatar from "../../asset/Image/dummyavatar.svg";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DASHBOARD_ROUTE } from "../../_main/routeConstants";
import { useNavigate } from "react-router-dom";
import { patientId } from "../../Api_Redux/PatientidSlice";
import { INDIVIDUALPATIENTDETAILS_ROUTE } from "../../_main/routeConstants";

function NewNotifications() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [notificationList, setNotificationData] = useState([]);

  useEffect(() => {
    dispatch(getNewNotificationList()).then((isSuccess) => {
      if (isSuccess.payload.status == 1) {
        setNotificationData(isSuccess.payload.data.notificationList);
      }
    });
  }, []);

  const actionBodyTemplate1 = (rowData) => {
    return (
      <React.Fragment>
        <RiDeleteBin6Line size={25} className="ms-delete" />
      </React.Fragment>
    );
  };

  const goback = () => {
    navigate(DASHBOARD_ROUTE);
  };

  const handlePatientDashboard = (user) => {
    const param = {
      id: user.userId,
    };
    dispatch(patientId(param));
    navigate(INDIVIDUALPATIENTDETAILS_ROUTE, { state: user });
  };

  return (
    <div>
      <ArrowBackIcon
        style={{ cursor: "pointer", fontSize: "25px" }}
        onClick={goback}
      ></ArrowBackIcon>
      <div className="container" style={{ fontSize: "24px" }}>
        Notification's
      </div>

      {notificationList?.length === 0 ? (
        <div class="container" style={{ opacity: 0.5 }}>
          <div
            style={{
              display: "flex",
              gap: "0.2rem",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            No result found
          </div>
        </div>
      ) : (
        ""
      )}
      <div>
        {notificationList?.map((user, index) => {
          return (
            <>
              <div class="container">
                <div
                  key={index}
                  style={{
                    display: "flex",
                    gap: "0.2rem",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div onClick={() => handlePatientDashboard(user)}>
                    <img
                      className="diagonal"
                      src={user.avatarUrl ? user.avatarUrl : dummyavatar}
                      alt="Avatar"
                    />
                    <div>{user.fullName}</div>
                    <div className="notificationDate">
                      {moment(user.createdDate).format("DD-MM-YYYY HH:MM:SS a")}
                    </div>
                    <div>
                      {user.bsStatusName ? (
                        <div
                          className={
                            user.bsStatusName == "Blood Sugar Low"
                              ? "notificationContentLow"
                              : "notificationContentHigh"
                          }
                        >
                          {user.bsStatusName}
                        </div>
                      ) : (
                        ""
                      )}
                      {user.bpStatusName ? (
                        <div
                          className={
                            user.bsStatusName == "Blood Pressure Low"
                              ? "notificationContentLow"
                              : "notificationContentHigh"
                          }
                        >
                          {user.bpStatusName}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          );
        })}
      </div>
    </div>
  );
}

export default NewNotifications;
