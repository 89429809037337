import React, { useState, useEffect } from "react";
import { BsClockFill } from "react-icons/bs";
import { Button } from 'primereact/button'
import { useDispatch, useSelector } from "react-redux";
import { postpatientlist } from '../../Api_Redux/ListOfPatientSlice';
import "./styles.css"
import moment from 'moment';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";

function Appointment() {

    const dispatch = useDispatch();

    const individualpatient = useSelector(
        (state) => state.PatientidSlice.patientid.data     
    );

    const eventpatient = useSelector(
        (state) => state.ListOfPatientSlice?.postpatient?.data?.eventList
    );

    const param = {
        patientId: individualpatient?.userId
    };

    useEffect(() => {
        dispatch(postpatientlist(param))
    }, []);

    

    return (
        <div>
            <div className="myappointment">Appointment</div>
            {eventpatient?.length === 0 ? (
                <div className="appointmentcontainer">
                    <div style={{ display: "flex", gap: "0.2rem", justifyContent: "space-between", width: "100%" }}>
                        No result found
                    </div>
                </div>
            ) : ""
            }
            <div>
                {eventpatient?.map((user, index) => {
                    return (
                        <Box>
                            <Grid container spacing={2} className='appoint-time'>
                                <Grid item xl={10} lg={10} md={12} sm={12} xs={12}>

                                    <Grid container spacing={2} key={index} className="appointmentcontainer">
                                        <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>

                                            <div className="appointmentblock1">{moment(user.startDate).format("dddd, MMMM Do YYYY")}</div>
                                        </Grid>
                                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                            <div className="appointmentcontent">
                                                <div className="appointmenttopic">{user.eventName}</div>
                                                <div className="appointmentcontent1">{user.description}</div>
                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                                    <div><span className="appointmenttime">{moment(user.startDate).format(" HH:MM a")}</span></div>
                                                    <div>  To  </div>
                                                    <div><span className="appointmenttime">{moment(user.endDate).format(" HH:MM a")}</span></div>
                                                </div>

                                            </div>
                                        </Grid>
                                        <Grid item xl={3} lg={3} md={3} sm={12} xs={12} className='join-btn'>
                                            <a  target="_blank" href={user.meetigUrl}><Button>Join</Button></a>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Box>

                    )
                })}
            </div>
        </div>
    )
}

export default Appointment