import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, GET } from "../_main/constants";
import callfetch from "../_main/fetch";
import { GET_NOTIFICATIONLIST } from "../_main/actiontype";

export const addNotificationList = createAsyncThunk(
  GET_NOTIFICATIONLIST + "/getnotificationlist",
  async (params) => {
    const token = localStorage.getItem("AccessToken");

    const option = {
      method: GET,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const url = `${API_URL + "Notification/list"}`;
    const response = await callfetch(url, option);
    const value = await response;
    return value;
  }
);

const NotificationListSlice = createSlice({
  name: GET_NOTIFICATIONLIST,
  initialState: {
    NotificationList: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addNotificationList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addNotificationList.fulfilled, (state, action) => {
      state.Register = action.payload;
      state.loading = false;
    });
    builder.addCase(addNotificationList.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default NotificationListSlice.reducer;
