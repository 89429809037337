import React from 'react'
import { Chart } from "react-chartjs-2";
import './style.css'
const ChartjsScatterLine = ({ chartData, listLabels, bloodSugarLevel }) => {
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: true,
        layout: {
            padding: 10
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                displayColors: false,
                cornerRadius: 4,
                caretSize: 4,
                xPadding: 16,
                yPadding: 10,
                backgroundColor: 'rgba(51, 150, 255, 0.96)',
                titleFontStyle: 'normal',
                titleMarginBottom: 15,
                // filter: function (tooltipItem, chartData) {
                //     return tooltipItem.label != 0;
                // },
                callbacks: { // For showing tooltip datas for scatter and line
                    title: function () { },
                    label: function (tooltipItem, chartData) {
                        let temp_text = ""
                        if (tooltipItem.datasetIndex === 1) {
                            if (Number(tooltipItem.label) === 6) {
                                temp_text = "Overnight Average: " + Math.floor(tooltipItem.formattedValue).toString();
                            } else if (Number(tooltipItem.label) === 9) {
                                temp_text = "Average Before Breakfast: " + Math.floor(tooltipItem.formattedValue).toString();
                            } else if (Number(tooltipItem.label) === 11) {
                                temp_text = "Average After Breakfast: " + Math.floor(tooltipItem.formattedValue).toString();
                            } else if (Number(tooltipItem.label) === 14) {
                                temp_text = "Average Before Lunch: " + Math.floor(tooltipItem.formattedValue).toString();
                            } else if (Number(tooltipItem.label) === 17) {
                                temp_text = "Average After Lunch: " + Math.floor(tooltipItem.formattedValue).toString();
                            } else if (Number(tooltipItem.label) === 19) {
                                temp_text = "Average Before Dinner: " + Math.floor(tooltipItem.formattedValue).toString();
                            } else if (Number(tooltipItem.label) === 22) {
                                temp_text = "Average After Dinner: " + Math.floor(tooltipItem.formattedValue).toString();
                            } else if (Number(tooltipItem.label) === 24) {
                                temp_text = "Bedtime Average: " + Math.floor(tooltipItem.formattedValue).toString();
                            }
                            return temp_text;
                        } else {
                            temp_text = listLabels[tooltipItem.dataIndex] + " Blood Sugar=" + Math.floor(tooltipItem.parsed.y).toString() + "mg/dl"
                            return temp_text;
                        }
                    }
                }
            },
            annotation: {
                annotations: {
                    box1: {
                        drawTime: "beforeDatasetsDraw",
                        type: 'box',
                        xMin: 0,
                        xMax: 24,
                        yMax: bloodSugarLevel.max,
                        yMin: bloodSugarLevel.min,
                        borderColor: 'green',
                        borderWidth: .5,
                        backgroundColor: 'rgba(0, 255, 0, 0.25)'
                    },
                    box2: {
                        drawTime: "beforeDatasetsDraw",
                        type: 'box',
                        xMin: 0,
                        xMax: 6,
                        yMax: 0,
                        yMin: 600,
                        borderColor: '',
                        borderWidth: .5,
                        backgroundColor: 'rgba(227, 227, 227, 0.28)'
                    },
                    box3: {
                        drawTime: "beforeDatasetsDraw",
                        type: 'box',
                        xMin: 6,
                        xMax: 9,
                        yMax: 0,
                        yMin: 600,
                        borderColor: '',
                        borderWidth: .5,
                        backgroundColor: 'rgba(184, 184, 184, 0.28)'
                    },
                    box4: {
                        drawTime: "beforeDatasetsDraw",
                        type: 'box',
                        xMin: 9,
                        xMax: 11,
                        yMax: 0,
                        yMin: 600,
                        borderColor: '',
                        borderWidth: .5,
                        backgroundColor: 'rgba(227, 227, 227, 0.28)'
                    },
                    box5: {
                        drawTime: "beforeDatasetsDraw",
                        type: 'box',
                        xMin: 11,
                        xMax: 14,
                        yMax: 0,
                        yMin: 600,
                        borderColor: '',
                        borderWidth: .5,
                        backgroundColor: 'rgba(184, 184, 184, 0.28)'
                    },
                    box6: {
                        drawTime: "beforeDatasetsDraw",
                        type: 'box',
                        xMin: 14,
                        xMax: 17,
                        yMax: 0,
                        yMin: 600,
                        borderColor: '',
                        borderWidth: .5,
                        backgroundColor: 'rgba(227, 227, 227, 0.28)'
                    },
                    box7: {
                        drawTime: "beforeDatasetsDraw",
                        type: 'box',
                        xMin: 17,
                        xMax: 19,
                        yMax: 0,
                        yMin: 600,
                        borderColor: '',
                        borderWidth: .5,
                        backgroundColor: 'rgba(184, 184, 184, 0.28)'
                    },
                    box8: {
                        drawTime: "beforeDatasetsDraw",
                        type: 'box',
                        xMin: 19,
                        xMax: 22,
                        yMax: 0,
                        yMin: 600,
                        borderColor: '',
                        borderWidth: .5,
                        backgroundColor: 'rgba(227, 227, 227, 0.28)'
                    },
                    box9: {
                        drawTime: "beforeDatasetsDraw",
                        type: 'box',
                        xMin: 22,
                        xMax: 24,
                        yMax: 0,
                        yMin: 600,
                        borderColor: '',
                        borderWidth: .5,
                        backgroundColor: 'rgba(184, 184, 184, 0.28)'
                    }
                }
            },
        },
        scales: {
            x: {
                offset: false,
                grid: {
                    offset: false
                },
                title: {
                    display: true,
                    text: 'Hours',
                    fontStyle: "bold",
                    font: {
                        size: 12,
                        weight: 'bold',
                    }
                },
                type: 'linear',
                ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                    max: 24,
                    min: 0,
                    callback: function (value, index, values) {
                        if (index === 0) {
                            return "12am";
                        } else if (index <= 11) {
                            return (index + "am");
                        } else if (index === 12) {
                            return "12pm"
                        } else if (index >= 13 && value !== 24) {
                            return (index - 12 + "pm");
                        } else if (value === 24) {
                            return ("12am");
                        }
                    }
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'Glucose mg/dl',
                    font: {
                        size: 12,
                        weight: 'bold',
                    }
                },
                type: 'linear',
                ticks: {
                    beginAtZero: true,
                    stepSize: 50,
                    max: 600,
                    min: 0,
                },
            },

        }
    }

    return (
        <Chart
            type='bar'
            data={chartData}
            options={chartOptions}
            width={100}
            height={30}
        />
    )
}

export default ChartjsScatterLine;
