import React, { useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import "../Forgot/styles.css";
import { Formik, Form, Field } from "formik";
import { NOT_EMPTY_REGEX } from "../../_main/constants";
import {
  EMPTY_PASSWORD,
  EMPTY_CONFIRMPASSWORD,
  EMPTY_OLD_PASSWORD,
  EMPTY_NEW_PASSWORD
} from "../../_main/errorConstants";
import { TextField } from "formik-material-ui";
import { useNavigate, useLocation } from "react-router-dom";
import { BASE_ROUTE } from "../../_main/routeConstants";
import "./styles.css";
import { resetUsers } from "../../Api_Redux/ResetSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setPassword } from "../../Api_Redux/ChangePasswordSlice";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { INDIVIDUALPATIENTDETAILS_ROUTE, DASHBOARD_ROUTE } from "../../_main/routeConstants";

function Changepassword() {

//navigation
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const search = useLocation().search;
  const userName = (localStorage.getItem('authorName'))
  const email = (localStorage.getItem('email'))

  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");

  const [newpasswordType, setNewPassword] = useState("password");
  const [confirmPassword, setNewPass] = useState("");

  const initial = {
    userName: userName,
    oldPassword: "",
    newPassword: "",
  };


  const handleSubmit = (form, { resetForm }) => {

    dispatch(setPassword(form))
      .then(async (isSuccess) => {
        if (isSuccess.payload.status == 1) {
          resetForm();
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 1000,
          });

          setTimeout(() => {
            if (localStorage.getItem('userType') == 3) {
              // navigate(INDIVIDUALPATIENTDETAILS_ROUTE)
              navigate("/")
            }
            else {
              // navigate(DASHBOARD_ROUTE)
              navigate("/")
            }

          }, 1000);

        } else {
          resetForm();
          toast.error("Invalid crdential ", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setTimeout(() => { }, 1000);
        }
      })
      .catch(({ error }) => {
        console.log(error);
      });

  };


 

  const handlePasswordChange = (event) => {
    setPasswordInput(event.target.value);
  }

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }
  const handleConfirmPassword = (event) => {
    setNewPass(event.target.value);
  };

  const toggleNewPass = () => {
    if (newpasswordType === "password") {
      setNewPassword("text");
      return;
    }
    setNewPassword("password");
  };

  return (
    <div className="main-Conatiner">
      
        <div className='header-container'>
          <div className='header-setion'>
            <div className="reset-head">RPM</div>
            <div className="reset-two">DOCTOR</div>
          </div>
          <div className='header-field'>
            <Formik
              initialValues={initial}
              onSubmit={handleSubmit}
              enableReinitialize
              validate={(values) => {
                const errors = {};
                for (const obj in values) {
                  switch (obj) {
                    case "oldPassword":
                      !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                        (errors.oldPassword = EMPTY_OLD_PASSWORD);
                      break;
                    case "newPassword":
                      !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                        (errors.newPassword = EMPTY_NEW_PASSWORD);
                      break;
                  }
                }
                return errors;
              }}
            >
              {({ }) => (
                <Form className=" product-section">
                  <div className="field-container">
                    <div className='reset-text'>
                      CHANGE YOUR PASSWORD
                    </div>
                    <p className='mb-0'>
                      <label
                        className="name-list" >
                        User Name
                      </label>
                    </p>
                    <div className='change-inputfield'>
                      <Field
                        component={TextField}
                        className="input-container"
                        type="text"
                        value={userName}
                        name="userName"
                        variant="outlined"
                        size="small"
                        disabled={true}
                      />
                    </div>
                    <p className='mb-0'>
                      <label
                        className="name-list"
                        
                      >
                        Old Password
                      </label>
                    </p>
                    <div className='change-inputfield'>
                      <Field
                        component={TextField}
                        className="input-container"
                        type={passwordType}
                        name="oldPassword"
                        variant="outlined"
                        size="small"
                        disabled={false}
                        onClick={handlePasswordChange}
                      />
                      <span onClick={togglePassword} style={{ marginRight: '-1.5rem' }}>
                          {passwordType === "password" ? <VisibilityOffIcon style={{ position: 'relative', right: '1.5em', top: '8px' }} /> : <VisibilityIcon style={{ position: 'relative', right: '1.5em', top: '8px' }} />}
                        </span>
                    </div>
                    <p className='mb-0'>
                      <label
                        className="name-list"
                        
                      >
                        New Password
                      </label>
                    </p>
                    <div className='change-inputfield'>
                      <Field
                        component={TextField}
                        className="input-container"
                        type={newpasswordType}
                        name="newPassword"
                        variant="outlined"
                        size="small"
                        disabled={false}
                      />
                      <span
                onClick={toggleNewPass}
                style={{ marginRight: "-1.5rem" }}
              >
                {newpasswordType === "password" ? (
                  <VisibilityOffIcon
                    style={{ position: "relative", right: "1.5em", top: "8px" }}
                  />
                ) : (
                  <VisibilityIcon
                    style={{ position: "relative", right: "1.5em", top: "8px" }}
                  />
                )}
              </span>
                    </div>
                  </div>
                  <div className="btn-container">
                    <Button
                      className="button-forgots"
                      variant="contained"
                      type="submit"
                      color="primary"
                      disabled={false}
                    >
                      Reset
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            <ToastContainer />
          </div>
        </div>
     
    </div>
  );
}

export default Changepassword;
