import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, DELETE } from "../_main/constants";
import callfetch from "../_main/fetch";
import { DELETE_CHATTEXT } from "../_main/actiontype";

export const Chatdeletetext = createAsyncThunk(
  DELETE_CHATTEXT + "/Chatdeletetext",
  async (params) => {
    const token = localStorage.getItem("AccessToken");
    const option = {
      method: DELETE,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    };

    const url = `${API_URL + "Conversation/deleteconversationmessage"}`;
    const response = await callfetch(url, option);
    const value = await response;
    return value;
  }
);

const ChatdeleteTextSlice = createSlice({
  name: DELETE_CHATTEXT,
  initialState: {
    Chatdeletetest: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Chatdeletetext.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Chatdeletetext.fulfilled, (state, action) => {
      state.Chatdeletetest = action.payload;
      state.loading = false;
    });
    builder.addCase(Chatdeletetext.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default ChatdeleteTextSlice.reducer;
