
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import Footer from "../../Component/Footer";
import { Formik, Form, Field } from "formik";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import { InputMask } from "primereact/inputmask";
import "primeflex/primeflex.css";
import { Card } from "primereact/card";
import { TextField } from "formik-material-ui";
import {
  NOT_EMPTY_REGEX,
  PHONE_REGEX,
  EMAIL_REGEX,
} from "../../_main/constants";
import {
  EMPTY_USERTYPE,
  EMPTY_USERNAME,
  EMPTY_FIRSTNAME,
  EMPTY_LASTNAME,
  EMPTY_EMAIL,
  EMPTY_PHONE,
  EMPTY_NPINUMBER,
} from "../../_main/errorConstants";
import "./styles.css";
import { Grid, Box } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getStaffdetails } from "../../Api_Redux/GetstaffDetailsSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { RegiStaff } from "../../Api_Redux/AddStaffSlice";
import { MANAGESTAFF_ROUTE } from "../../_main/routeConstants";

function EditStaff() {
  //navigation
  const location = useLocation();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const staffid = location.state;
  // console.log("staffid", staffid);

  //useselector
  const staffdetails = staffid;


  const initial = {
    usertype: staffdetails?.usertype,
    // userTypeName: staffdetails?.userTypeName,
    userName: staffdetails?.userName,
    id: staffdetails?.id,
    firstName: staffdetails?.firstName,
    lastName: staffdetails?.lastName,
    email: staffdetails?.email,
    phoneNumber: staffdetails?.phoneNumber,
    npiNumber: staffdetails?.npiNumber,
    createdBy: staffdetails?.createdBy,
    password: staffdetails?.password,
  };


  const [initialVal, setInitialValues] = useState(initial);
  // console.log("Add_Staff_inputField", initialVal);

  const params = {
    id: staffid.id,
  };
  useEffect(() => {
    dispatch(getStaffdetails(params));
  }, []);

  const handleFieldChange = (e) => {
    e.preventDefault();

    const form = { ...initialVal };
    // console.log("update_form", form);
    form[e.target.name] = e.checked
      ? e.checked
      : e.value
        ? e.value
        : e.target.value;
    setInitialValues(form);
  };

  const handleSubmit = (form, { resetForm }) => {
    dispatch(RegiStaff(form))
      .then((isSuccess) => {
        if (isSuccess.payload !== undefined) {
          resetForm();
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          navigate(MANAGESTAFF_ROUTE);
        } else {
          resetForm();
          toast.error(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      })
      .catch(({ error }) => {
        console.log(error);
      });
  };

  {
    /* <Box>
              <Grid container spacing={2}>
                <Grid item xl={6} lg={6}  md={6} sm={12} xs={12} ></Grid> */
  }

  return (
    <div>
      <div style={{ marginTop: "20px" }}>
        <Card style={{ padding: "0px", margin: "9px" }}>
          <div>
            <div className="staff-edit">Manage Staff / Edit Staff Member</div>
            <div className="estaff-ocard">
              <Formik
                initialValues={initialVal}
                onSubmit={handleSubmit}
                enableReinitialize
                validate={(values) => {
                  const errors = {};
                  // eslint-disable-next-line no-restricted-syntax

                  if (!values.firstName) {
                    errors.firstName = " FirstName Required";
                  } else if (!/^[0-9a-zA-Z_ ]+$/i.test(values.firstName)) {
                    errors.firstName = "Invalid FirstName";
                  }
                  if (!values.lastName) {
                    errors.lastName = " LastName Required";
                  } else if (!/^[0-9a-zA-Z_ ]+$/i.test(values.lastName)) {
                    errors.lastName = "Invalid LastName";
                  }

                  if (!values.phoneNumber) {
                    errors.phoneNumber = " Phone Number Required";
                  } else if (!/^[0-9+]{12}$/i.test(values.phoneNumber)) {
                    errors.phoneNumber = "Invalid Phone Number";
                  }
                  return errors;
                }}
              >
                {({ }) => (
                  <Form>
                    <Box>
                    <Grid container spacing={2} className='editbox'>
                      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>

                        <div>
                          <label className="eslabel">User Type</label>
                        </div>

                        <Field
                          component={TextField}
                          className="esinput-container"
                          type="text"
                          name="userTypeName"
                          placeholder="User Type"
                          variant="outlined"
                          size="small"
                          disabled={true}
                          value={"Staff"}
                        />

                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                        <div>
                          <label className="eslabel">User Name</label>
                        </div>

                        <Field
                          component={TextField}
                          className="esinput-container"
                          type="text"
                          name="userName"
                          variant="outlined"
                          placeholder="User Name"
                          size="small"
                          disabled={true}
                          value={staffdetails?.userName}
                          onChange={handleFieldChange}
                        />

                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                        <div>
                          <label className="eslabel">First Name</label>
                        </div>

                        <Field
                          component={TextField}
                          className="esinput-container"
                          type="text"
                          placeholder="First Name"
                          name="firstName"
                          variant="outlined"
                          size="small"
                          disabled={false}
                          value={initialVal?.firstName}
                          onChange={handleFieldChange}
                        />

                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>
                        <div>
                          <label className="eslabel">Last Name</label>
                        </div>

                        <Field
                          component={TextField}
                          className="esinput-container"
                          type="text"
                          name="lastName"
                          variant="outlined"
                          placeholder="Last Name"
                          size="small"
                          disabled={false}
                          value={initialVal?.lastName}
                          onChange={handleFieldChange}
                        />

                      </Grid>

</Grid>

                    </Box>
                    <Box>
                    <Grid container spacing={2}>
                      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>

                        <div>
                          <label className="eslabel">Email</label>
                        </div>
                        <div>
                          <Field
                            component={TextField}
                            className="esinput-container"
                            type="text"
                            placeholder="Email ID"
                            name="emailId"
                            variant="outlined"
                            size="small"
                            disabled={true}
                            value={staffdetails?.email}
                            onChange={handleFieldChange}
                          />
                        </div>
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>

                        <div>
                          <label className="eslabel">
                            Phone Number &nbsp;
                            <span className="es-format">
                              format: (+4445556666)
                            </span>
                          </label>
                        </div>
                     
                          <Field
                            component={TextField}
                            className="esinput-container"
                            type="text"
                            placeholder="Phone Number"
                            name="phoneNumber"
                            variant="outlined"
                            size="small"
                            disabled={false}
                            value={initialVal?.phoneNumber}
                            onChange={handleFieldChange}
                          />
                       
                      </Grid>
                      <Grid item xl={3} lg={3} md={3} sm={6} xs={12}>

                        <div>
                          <label className="eslabel">NPI Number</label>
                        </div>
                       
                          <Field
                            component={TextField}
                            className="esinput-container"
                            type="number"
                            placeholder="NPI Number"
                            name="npiNumber"
                            variant="outlined"
                            size="small"
                            disabled={false}
                            value={initialVal?.npiNumber}
                            onChange={handleFieldChange}
                            onKeyPress={(e) =>
                              !/[0-9]/.test(e.key) && e.preventDefault()
                            }
                          />
                       


                      </Grid>
</Grid>
                    </Box>

                    <div className="edits-btn">
                      <Button
                        className="edits-button"
                        variant="contained"
                        type="submit"
                        color="primary"
                        disabled={false}
                      >
                        SAVE
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
              {/* <Footer /> */}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default EditStaff;
