import { combineReducers } from "redux";
import AddStaffSlice from "./AddStaffSlice";
import ForgotSlice from "./ForgotSlice";
import LoginSlice from "./LoginSlice";
import OrganizationGetSlice from "./OrganizationGetSlice";
import OrganizationPostSlice from "./OrganizationPostSlice";
import OrganizationUpdateSlice from "./OrganizationUpdateSlice";
import RegisterSlice from "./RegisterSlice";
import ResetSlice from "./ResetSlice";
import StaffGetSlice from "./StaffGetSlice";
import EventGetSlice from "./EventGetSlice";
import PostEventListSlice from "./PostEventListSlice";
import GetDoctorNotesSlice from "./GetDoctorNotesSlice";
import PostDoctorNotesSlice from "./PostDoctorNotesSlice";
import ListOfPatientSlice from "./ListOfPatientSlice";
import UploadImageSlice from "./UploadImageSlice";
import EventDeleteSlice from "./EventDeleteSlice";
import DoctorNotesDeleteSlice from "./DoctorNotesDeleteSlice";
import patientList from "./PatientListSlice";
import Videolink from "./VideoSlice";
import ScheduleSlice from "./ScheduleSlice";
import StaffListSlice from "./StaffListSlice";
import MeetingLogSlice from "./MeetingLogSlice";
import MeetingPartcipantSlice from "./MeetingParticipantSlice";
import PatientGetOtherDetailsSlice from "./PatientGetOtherDetailsSlice";
import PatientGetOtherDetailsListSlice from "./PatientGetOtherDetailsListSlice";
import ProfileSlice from "./ProfileSlice";
import Chatlistallconversation from "./Chatlistallconversation";
import PatientDeleteSlice from "./PatientDeleteSlice";
import Chatlatestconversation from "./Chatlatestconversation";
import DoctorNotesSlice from "./DoctorNotesSlice";
import PatientidSlice from "./PatientidSlice";
import GetProfileSlice from "./GetProfileSlice";
import PatientUpdateOtherDetailsSlice from "./PatientUpdateOtherDetailsSlice";
import GetParticipantLogDetailsSlice from "./GetParticipantLogDetailsSlice";
import PatientDropdowndetails from "./PatientDropdowndetails";
import AddPatientSlice from "./AddPatientSlice";
import LogoutSlice from "./LogoutSlice";
import scheduleMeetSlice from "./ScheduleMeetSlice";
import GetstaffDetailsSlice from "./GetstaffDetailsSlice";
import NotificationListSlice from "./NotificationListSlice";
import ChangePasswordSlice from "./ChangePasswordSlice";
import RefreshSlice from "./RefreshSlice";
import PatientalllistSlice from "./PatientalldetailsSlice";
import BionimeSlice from "./BionimeSlice";
import StaffDeleteSlice from "./StaffDeleteSlice";
import storage from "redux-persist/lib/storage";
import GetCountsListSlice from "./GetCountsListSlice";
import MembershipTypeDropdown from "./MembershipTypeDropdown";
import TimerUpdateSlice from "./TimerUpdateSlice";
import TimerGetSlice from "./TimerGetSlice";
import IGlucoseSlice from "./IGlucoseSlice";
import GetFilteredDataSlice from "./GetFilteredDataSlice";
import TimerccmGetSlice from "./TimerccmGetSlice";
import TimerccmUpdateSlice from "./TimerccmUpdateSlice";
import PatientGetNewReadingsSlice from "./PatientGetNewReadingsSlice";
import MyPatientsViewSlice from "./MyPatientsViewSlice";
import ActiveSlice from "./ActiveSlice";
import ValideUsernameSlice from "./ValideUsernameSlice";
import VoiceCallSlice from "./VoiceCallSlice";
import LocalStorageSlice from "./localStorageSlice";
import { enableMapSet } from "immer";

enableMapSet();

const appReducer = combineReducers({
  LogoutSlice: LogoutSlice,
  LoginSlice: LoginSlice,
  ForgotSlice: ForgotSlice,
  ResetSlice: ResetSlice,
  RegisterSlice: RegisterSlice,
  OrganizationGetSlice: OrganizationGetSlice,
  OrganizationUpdateSlice: OrganizationUpdateSlice,
  OrganizationPostSlice: OrganizationPostSlice,
  AddStaffSlice: AddStaffSlice,
  StaffGetSlice: StaffGetSlice,
  EventGetSlice: EventGetSlice,
  PostEventListSlice: PostEventListSlice,
  ListOfPatientSlice: ListOfPatientSlice,
  UploadImageSlice: UploadImageSlice,
  GetDoctorNotesSlice: GetDoctorNotesSlice,
  PostDoctorNotesSlice: PostDoctorNotesSlice,
  EventDeleteSlice: EventDeleteSlice,
  DoctorNotesDeleteSlice: DoctorNotesDeleteSlice,
  PatientGetOtherDetailsSlice: PatientGetOtherDetailsSlice,
  PatientGetOtherDetailsListSlice: PatientGetOtherDetailsListSlice,
  PatientUpdateOtherDetailsSlice: PatientUpdateOtherDetailsSlice,
  GetParticipantLogDetailsSlice: GetParticipantLogDetailsSlice,
  patientList: patientList,
  PatientDeleteSlice: PatientDeleteSlice,
  Videolink: Videolink,
  ScheduleSlice: ScheduleSlice,
  StaffListSlice: StaffListSlice,
  MeetingLogSlice: MeetingLogSlice,
  MeetingPartcipantSlice: MeetingPartcipantSlice,
  ProfileSlice: ProfileSlice,
  Chatlistallconversation: Chatlistallconversation,
  Chatlatestconversation: Chatlatestconversation,
  DoctorNotesSlice: DoctorNotesSlice,
  PatientidSlice: PatientidSlice,
  GetProfileSlice: GetProfileSlice,
  PatientDropdowndetails: PatientDropdowndetails,
  AddPatientSlice: AddPatientSlice,
 
  scheduleMeetSlice: scheduleMeetSlice,
  GetstaffDetailsSlice: GetstaffDetailsSlice,
  NotificationListSlice: NotificationListSlice,
  ChangePasswordSlice: ChangePasswordSlice,
  RefreshSlice: RefreshSlice,
  PatientalllistSlice: PatientalllistSlice,
  BionimeSlice: BionimeSlice,
  StaffDeleteSlice: StaffDeleteSlice,
  GetCountsListSlice: GetCountsListSlice,
  MembershipTypeDropdown: MembershipTypeDropdown,
  TimerUpdateSlice: TimerUpdateSlice,
  TimerGetSlice: TimerGetSlice,
  IGlucoseSlice: IGlucoseSlice,
  GetFilteredDataSlice: GetFilteredDataSlice,
  TimerccmGetSlice: TimerccmGetSlice,
  TimerccmUpdateSlice: TimerccmUpdateSlice,
  PatientGetNewReadingsSlice: PatientGetNewReadingsSlice,
  MyPatientsViewSlice: MyPatientsViewSlice,
  // VoiceCallSDKSlice : VoiceCallSDKSlice,
  VoiceCallSlice: VoiceCallSlice,
  LocalStorageSlice: LocalStorageSlice,
  ActiveSlice: ActiveSlice,
  ValideUsernameSlice: ValideUsernameSlice,
});

const rootReducer = (state, action) => {

  if (action.type === "postlogout/logoutUsers/fulfilled" || action.type === "postlogout/logoutUsers/pending") {
    storage.removeItem("persist:root");
    window.location.reload();
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export default rootReducer;
