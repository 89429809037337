
//NEW CODE KAR

/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import { Formik, Form, Field } from "formik";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { TextField } from "formik-material-ui";
import { NOT_EMPTY_REGEX } from "../../_main/constants";
import { useNavigate } from "react-router-dom";
import { EMPTY_USERNAME, EMPTY_PASSWORD } from "../../_main/errorConstants";
import { loginUsers } from "../../Api_Redux/LoginSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  FORGOT_ROUTE,
  ORGANIZATION_ROUTE,
  INDIVIDUALPATIENTDETAILS_ROUTE,
  INDIVIDUALPATIENTDETAILSS_ROUTE,
  INDIVIDUALPATIENTDETAILSSS_ROUTE,
  DASHBOARD_ROUTE,
  STAFF_DASHBOARD_ROUTE,
  CHANGEPASSWORD_ROUTE
} from "../../_main/routeConstants";
import "./styles.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { signIn } from "../../Api_Redux/UserSlice";
import { Password } from "primereact/password";
import { patientId } from "../../Api_Redux/PatientidSlice";
import { requestForToken } from "../../_main/firebase";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';

function Login() {
  //navigate
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const navigateToContacts = () => {
    navigate(FORGOT_ROUTE);
  };

  //localstorage
  // localStorage.getItem("Notification");

  //useSelector
  const screenState = useSelector((state) => state.LoginSlice.login.data);

  //state
  const initials = {
    username: "",
    password: "",
    deviceId: localStorage.getItem('Notification'),
    // deviceId: "123",
    platform: 1,
  };
  const [initialVal, setInitialValues] = useState(initials);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [showStatusModal, setShowStatusModal] = useState(false);


  const term = () => {
    window.open("https://www.rmthealth.com/privacy-policy")
  }

  const handlePasswordChange = (event) => {
    setPasswordInput(event.target.value);
  }

  const [state, setState] = useState({
    message: "",
    isMessage: false,
    autoSeek: "name",
  });

  useEffect(() => {
    requestForToken().then((res) => {
      // console.log("res", res);
      setInitialValues({
        deviceId: res ? res : "",
        username: "",
        password: "",
        platform: 1,
      });
    });
  }, []);

  const handleSubmit = (form, { resetForm }) => {
    dispatch(loginUsers(form))
      .then(async (isSuccess) => {
        if (isSuccess.payload !== undefined) {
          resetForm();
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          const param = {
            id: isSuccess.payload.data.id,
          };
          localStorage.setItem("isLoggedIn", "true");
          dispatch(patientId(param));
          // console.log("isSuccess.payload ===---->>>", isSuccess.payload);
          if (
            isSuccess.payload.data.isOrg &&
            isSuccess.payload.data.userType === 1
          ) {
            navigate(DASHBOARD_ROUTE, {state: {fromLogin: true}});
          } else if (
            isSuccess.payload.data.isOrg &&
            isSuccess.payload.data.userType === 2
          ) {
            navigate(STAFF_DASHBOARD_ROUTE, {state: {fromLogin: true}});
          }
          else {
            setTimeout(() => {
              if (isSuccess.payload.data.userType === 1) {
                navigate(INDIVIDUALPATIENTDETAILS_ROUTE, {state: {fromLogin: true}});
              }
              if (isSuccess.payload.data.userType === 2) {
                navigate(INDIVIDUALPATIENTDETAILSS_ROUTE, {state: {fromLogin: true}});
              }
              if (isSuccess.payload.data.userType === 3) {
                navigate(INDIVIDUALPATIENTDETAILSSS_ROUTE, {state: {fromLogin: true}});
              }
              else {
                navigate(ORGANIZATION_ROUTE);
              }
            });
          }
        } else {
          resetForm();
          toast.error("Invalid UserName/Password ", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          setTimeout(() => { }, 1000);
        }
      })
      .catch(({ error }) => {
        console.log(error);
      });
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }


  return (

    <div className='main-Conatiner'>
      <div className='header-container'>
        <div className='header-setion'>
          <div className='header-content'>RPM</div>
          <div className='header-rpm'>DOCTOR</div>
        </div>
        <div className="header-field">
          <Formik
            initialValues={initialVal}
            onSubmit={handleSubmit}
            enableReinitialize
            validate={(values) => {
              const errors = {};
              // eslint-disable-next-line no-restricted-syntax
              for (const obj in values) {
                switch (obj) {
                  case "username":
                    !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                      (errors.username = EMPTY_USERNAME);
                    break;
                  case "password":
                    !RegExp(NOT_EMPTY_REGEX, "g").test(values[obj]) &&
                      (errors.password = EMPTY_PASSWORD);
                    break;
                }
              }
              return errors;
            }}
          >
            {({ }) => (
              <Form className=" product-section">
                <div className="field-container">
                  <p className='mb-0'>
                    <label className="label">User Name</label>
                  </p>
                  <div>
                    <Field
                      component={TextField}
                      className="input-container"
                      name="username"
                      type="text"
                      placeholder="Enter User Name"
                      variant="outlined"
                      size="small"
                      disabled={false}
                    />
                  </div>

                  <p className='password-field'>
                    <label className="label" style={{ paddingBottom: "5px" }}>
                      Password
                    </label>
                  </p>
                  <div>
                    {/* <Password */}
                    <Field
                      component={TextField}
                      className="input-container"
                      type={passwordType}
                      placeholder="Enter Password"
                      name="password"
                      variant="outlined"
                      size="small"
                      disabled={false}
                      onClick={handlePasswordChange}
                    // toggleMask
                    />
                    <span onClick={togglePassword} style={{ marginRight: '-1.5rem' }}>
                      {passwordType === "password" ? <VisibilityOffIcon style={{ position: 'relative', right: '1.5em', top: '8px' }} /> : <VisibilityIcon style={{ position: 'relative', right: '1.5em', top: '8px' }} />}
                    </span>
                  </div>
                </div>
                <div>
                  <div className="para" onClick={navigateToContacts}>
                    Forgot Password
                  </div>
                </div>
                <div className="btn-container">
                  <Button
                    className="lgin-to-rpm"
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={false}
                  >
                    LOGIN TO RPM
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
          <ToastContainer />
          <div className="ftext">
            <AiOutlineCopyrightCircle />&nbsp;
            <div>
              2023&nbsp;-&nbsp;Rpm.Doctor&nbsp;-&nbsp;<a onClick={term}>Privacy</a>
            </div>
          </div>
        </div>
      </div>
    </div>


  );
}

export default Login;
