import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import callfetch from "../_main/fetch";
import { POST_CHATLATEST } from "../_main/actiontype";

export const Chatlatestdata = createAsyncThunk(
  POST_CHATLATEST + "/ChatChannel",
  async (params) => {
    const token = localStorage.getItem("AccessToken");

    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    };

    const url = `${API_URL + "Conversation/listallconversation"}`;
    const response = await callfetch(url, option);
    const value = await response;
    return value;
  }
);

const ChatlatestSlice = createSlice({
  name: POST_CHATLATEST,
  initialState: {
    Chatlatest: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Chatlatestdata.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Chatlatestdata.fulfilled, (state, action) => {
      state.Chatlatest = action.payload;
      state.loading = false;
    });
    builder.addCase(Chatlatestdata.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default ChatlatestSlice.reducer;
