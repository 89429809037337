import React, { useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Footer from "../../Component/Footer";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { meetingparticipantList } from "../../Api_Redux/MeetingParticipantSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { MEETINGLOGS_ROUTE } from "../../_main/routeConstants";
import moment from "moment";

function Meetingdetails() {
  //navigation
  const dispatch = useDispatch();
  const navigate = useNavigate();

  //useselector
  const meetinglodata = useSelector(
    (state) => state.MeetingPartcipantSlice.meetinglog
  );

  const getLog = useSelector(
    (state) => state.GetParticipantLogDetailsSlice?.getlog?.data?.roomDetail
  );

  // console.log("getLoggetLog", getLog);

  const participantLog = useSelector(
    (state) =>
      state.GetParticipantLogDetailsSlice?.getlog?.data?.participantList
  );

  const params = {
    roomSId: localStorage.getItem("roomSId"),
  };

  const goback = () => {
    navigate(MEETINGLOGS_ROUTE);
  };


  useEffect(() => {
    dispatch(meetingparticipantList(params));
  }, []);

  const dateTemplate = (rowData) => {
    return moment(rowData['JoinAt']).format("MM-DD-YYYY hh:mm:ss A");
  }

  return (
    <div>
      <div style={{ flexDirection:"row", display:"flex"}}>
        <Typography variant="h5" style={{marginTop:"0.8em"}} onClick={goback}>
          <ArrowBackIcon
            style={{ marginBottom: "1em", cursor: "pointer" }}
            fontSize={"25"}
          ></ArrowBackIcon>
        </Typography>
        <div className="md-title">MEETING DETAILS</div>
      </div>
      <div className="md-dcard">
        <div className="flex" style={{ padding: "1%" }}>
          <div className="col-3 lg:col-3">
            <div className="col">
              <div className="md-textstyle">
                Unique Name:
                <span className="md-textstyles">{getLog?.[0]?.RoomId}</span>
              </div>
              <div className="md-textstyle">
                Total Duration:
                <span className="md-textstyles">{getLog?.[0]?.Duration}</span>
              </div>
            </div>
          </div>
          <div className="col-3 lg:col-3">
            <div className="col">
              <div className="md-textstyle">
                Status:
                <span className="md-textstyles">{getLog?.[0]?.RoomStatus}</span>
              </div>
              <div className="md-textstyle">
                Type:
                <span className="md-textstyles1">{getLog?.[0]?.Type}</span>
              </div>
            </div>
          </div>
          <div className="col-3 lg:col-3">
            <div className="col">
              <div className="md-textstyle">
                Date Created:
                <span className="md-textstyles">{moment(getLog?.[0]?.CreatedAt).format("MM-DD-YYYY HH:MM:SS a")}</span>
                {/* <span className="md-textstyles">{getLog?.[0]?.CreatedAt}</span> */}
              </div>
              <div className="md-textstyle">
                Media Region:
                <span className="md-textstyles">{getLog?.[0]?.Region}</span>
              </div>
            </div>
          </div>
          <div className="col-3 lg:col-3">
            <div className="col">
              <div className="md-textstyle1">
                Date Completed:
                <span className="md-textstyles">{moment(getLog?.[0]?.EndAt).format("MM-DD-YYYY HH:MM:SS a")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md-title1">PARTICIPANTS</div>
      <div style={{ marginTop: "0em" }}>
        <DataTable
          className="md-text"
          value={participantLog}
          responsiveLayout="scroll"
        >
          <Column field="JoinAt" header="DATE CREATE" body={dateTemplate} sortable></Column>
          <Column
            field="ParticipanName"
            header="PARTICIPANTS"
            sortable
          ></Column>
          <Column field="Duration" header="DURATION" sortable></Column>
        </DataTable>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Meetingdetails;
