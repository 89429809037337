import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, GET } from "../_main/constants";
import callfetch from "../_main/fetch";
import { GET_MEETINGPARTICIPANTLOG } from "../_main/actiontype";

export const meetingparticipantList = createAsyncThunk(
  GET_MEETINGPARTICIPANTLOG + "/meetingparticipantList",
  async (params) => {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");

    const token = localStorage.getItem("AccessToken");
    const option = {
      method: GET,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const url = `${API_URL + "Twilio/getparticipantlogdetail?" + query}`;
    const response = await callfetch(url, option);
    localStorage.setItem("roomSId", response.data.roomSId);
    const value = await response;
    return value;
  }
);

const meetingpartcipantlogSlice = createSlice({
  name: GET_MEETINGPARTICIPANTLOG,
  initialState: {
    meetinglog: [],
    nextPage: 1,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(meetingparticipantList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(meetingparticipantList.fulfilled, (state, action) => {
      state.nextPage = parseInt(action.payload.data.totalCount) + 1;
      state.meeting = action.payload;
      state.loading = false;
    });
    builder.addCase(meetingparticipantList.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default meetingpartcipantlogSlice.reducer;
