import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import callfetch from "../_main/fetch";
import { POST_EVENTLIST } from "../_main/actiontype";

export const posteventlist = createAsyncThunk(
  POST_EVENTLIST + "/posteventlist",
  async (params) => {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    const token = localStorage.getItem("AccessToken");

    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const url = `${API_URL + "Event/list?" + query}`;
    const response = await callfetch(url, option);
    const value = await response;
    return value;
  }
);

const PostEventListSlice = createSlice({
  name: POST_EVENTLIST,
  initialState: {
    postevent: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(posteventlist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(posteventlist.fulfilled, (state, action) => {
      state.postevent = action.payload;
      state.loading = false;
    });
    builder.addCase(posteventlist.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default PostEventListSlice.reducer;
