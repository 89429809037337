import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import callfetch from "../_main/fetch";
import { POST_BIONIME } from "../_main/actiontype";

export const bionime = createAsyncThunk(
  POST_BIONIME + "/bionime",
  async (params) => {
    const token = localStorage.getItem("AccessToken");
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const url = `${API_URL + "Patient/sendauthorization?" + query}`;
    const response = await callfetch(url, option);
    const value = await response;
    return value;
  }
);

const BionimeSlice = createSlice({
  name: POST_BIONIME,
  initialState: {
    bionime: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(bionime.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(bionime.fulfilled, (state, action) => {
      state.bionime = action.payload;
      state.loading = false;
    });
    builder.addCase(bionime.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default BionimeSlice.reducer;
