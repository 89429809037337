// /* eslint-disable react-hooks/exhaustive-deps */
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import { matchRoutes, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
// import { Chart } from "primereact/chart";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { patientId } from "../../Api_Redux/PatientidSlice";
import ReactEcharts from "echarts-for-react"
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import BionomineTable from "../Table/bgbionimie";
import ChartjsScatterLine from "./ChartjsScatterLine";

const chartState = {
  datasets: [
    {
      type: "scatter",
      label: 'scatter',
      pointBackgroundColor: "rgb(0,0,255)",
      data: [], // scatterValues
      showLine: false,
      pointStyle: 'triangle',
      pointRadius: 7,
      pointHitRadius: 7,
    },
    {

      label: 'Average',
      data: [], // lineValues
      type: 'line',
      fill: false,
      borderColor: "black",
      pointRadius: 1.5,
      borderWidth: 2,
      pointHitRadius: 7,
    }
  ]
};

const LineChartSugarDemo = (props) => {
  // console.log("testing", props);
  let Data = {}

  const [createdDate, setCreatedDate] = useState([]);
  const [bloodglucose, setbloodglucose] = useState([]);
  const [overallTableData, setoverallTableData] = useState([]);
  const [PulseBpm, setPulseBpm] = useState([]);
  const [individualpatient, setIndividualpatient] = useState("");
  // console.log("individualpatientindividualpatient", individualpatient)
  const { state } = useLocation();
  const screenState = useSelector((state) => state.LoginSlice.login.data);
  const dispatch = useDispatch();
  const [bloodSugarLevel, setBloodSugarLevel] = useState(props.sugarLevel)
  // console.log("bloodSugarMin=======>>>", bloodSugarMin)
  const [basicData, setBasicData] = useState({})
  const [chartProps, setChartProps] = useState(props?.barChartBpGlucoseData)
  const eChartsRef = React.useRef(null);

  const startTime = moment('2020-01-01 01:00:00').valueOf();
  const endTime = moment('2023-05-10 02:00:00').valueOf();

  const data1 = [
    [startTime, 0],
    [startTime + 5 * 60 * 1000, 10],
    [startTime + 15 * 60 * 1000, 20],
    [startTime + 30 * 60 * 1000, 30],
    [startTime + 45 * 60 * 1000, 40],
    [endTime, 50],
  ];

  // Chartjs states
  const [chartData, setChartData] = useState(chartState);
  const [listLabels, setListLabels] = useState([]);
  
  const [scatterChart, setScatterChart] = useState(
    {
      grid: { top: '10%', right: '95%', bottom: '10%', left: '95%' },
      dataset: {
        source: {
          // ["type", "0", "01AM", "02AM", "03AM", "04AM", "05AM", "06AM", "07AM", "08AM", "09AM", "10AM", "11AM", "12PM", "01PM", "02PM", "03PM", "04PM", "05PM", "06PM", "07PM", "08PM", "09PM", "10PM", "11PM", "12AM"],
          // timestamp: ['2018-04-10T20:40:33Z', '2018-04-10T20:40:53Z', '2018-04-10T20:41:03Z'],
          // ["Forest", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""]
        }
      },
      xAxis: {
        type: "category",
        // boundaryGap: false,
        data: ["0", "01AM", "02AM", "03AM", "04AM", "05AM", "06AM", "07AM", "08AM", "09AM", "10AM", "11AM", "12PM", "01PM", "02PM", "03PM", "04PM", "05PM", "06PM", "07PM", "08PM", "09PM", "10PM", "11PM", "12AM"],
        // display: false,
        axisTick: {
          show: false,
          // alignWithLabel: true,
        },
        axisLine: {
          show: true
        },
        boundaryGap: false,
        name: 'Hours',
        nameTextStyle: {
          fontWeight: 'bold', // set the font weight to bold
        },
        nameLocation: 'middle',
        nameGap: 20,
        splitLine: {
          show: true,
          lineStyle: {
            color: 'lightgrey', // set color here
          },
        },
      },
      yAxis: {
        splitLine: {
          show: true
        },
        axisLine: {
          show: true
        },
        min: 0,
        max: 600,
        interval: 50,
        name: 'Glucose mg/dl',
        nameTextStyle: {
          fontWeight: 'bold', // set the font weight to bold
        },
        nameLocation: 'middle',
        nameGap: 30,
      },
      tooltip: {
        // position: 'bottom',
        // padding: 5,
        // textStyle: {
        //   textAlign: 'left'
        // },
        borderWidth: 1,
        padding: [
          5,  // up
          10, // right
          5,  // down
          10, // left
        ],
        trigger: "item",
        formatter: function (params) {
          if (params.componentSubType === 'line') {
            if (params.value !== undefined && params.data.tooltipLabel !== '') {
              return `${params.data.tooltipLabel}: ${parseInt(params.value[1])}`;
            }
          } else {
            if (params.value !== undefined) {
              const date = moment(params.data.date).format("MM/DD/YYYY hh:mm:ssA");
              return `${date}<br/>Blood Sugar: ${params.value[1]}(mg/dl)`;
            }
          }
        },
      },
      series: [
        {
          data: [],
          markArea: {
            itemStyle: {
              color: '#E2E4E7',
              opacity: '0.5',
              borderColor: '#BEC0C6',
              // borderColor: '#999',
              borderWidth: 1,
            },
            data: [
              [
                {
                  xAxis: '06AM'
                },
                {
                  xAxis: '09AM'
                }
              ],
              [
                {
                  xAxis: '11AM'
                },
                {
                  xAxis: '02PM'
                }
              ],
              [
                {
                  xAxis: '05PM'
                },
                {
                  xAxis: '07PM'
                }
              ],
              [
                {
                  xAxis: '10PM'
                },
                {
                  xAxis: '12AM'
                }
              ]
            ]
          },
          type: "scatter",
          name: 'scatter',
          seriesLayoutBy: "row",
          smooth: true,
          symbol: 'triangle',
          // datasetIndex: 1,
          symbolSize: 14,
        },
        {
          data: data1,
          markArea: {
            itemStyle: {
              color: '#C3F2C6',
              opacity: '0.6',
              borderColor: '#7BB97D',
              borderWidth: 1,
            },
            data: [
              [
                {
                  yAxis: bloodSugarLevel.max
                },
                {
                  yAxis: bloodSugarLevel.min
                }
              ],
            ]
          },
          name: 'line',
          type: 'line',
          smooth: true,
          datasetIndex: 1,
          symbolSize: 5,
          itemStyle: {
            normal: {
              color: 'black'
            },

          }
        }
      ],
    }
  )


  useEffect(() => {

    const patId = {
      id: state?.userId
        ? state?.userId
        : localStorage.getItem("stateUserId")
          ? localStorage.getItem("stateUserId")
          : screenState.id,
    };


    dispatch(patientId(patId))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          setIndividualpatient(isSuccess.payload.data);
          // setBloodSugarMax(isSuccess.payload.data.bloodSugarMax);
        }

        setBasicData(Data)
      })
  }, [scatterChart]);

  useEffect(() => {
    let noonArray = []
    let earlyMorningArray = []
    let morning = []
    let afternoon = []
    let beforeevening = []
    let evening = []
    let afterevening = []
    let night = []
    Data = {
      labels: createdDate,
      datasets: [
        {
          label: "Bloodglucose",
          data: bloodglucose,
          borderColor: "#C8ECCC",
          // backgroundColor: "#F0FAF1",
          pointBorderColor: "#AAA",
          backgroundColor: function (context) {
            return bloodglucose[context.index] < individualpatient?.bloodSugarMax ? 'green' : 'red'

          },
        }
      ],
    }
    if (props?.barChartBpGlucoseData?.length == 0) {
      createdDate.splice(0, createdDate.length);
    }

    if (props?.barChartBpGlucoseData?.length > 0) {
      createdDate.splice(0, createdDate.length);
      props?.barChartBpGlucoseData?.forEach((element, curr) => {
        // createdDate.push(moment(element.createdOn).format("MM-DD-YYYY hh:mm A"));
        createdDate.push(moment(element.createdOn).format("hhA"));
        bloodglucose.push(JSON.parse(element.dataItemData).bloodglucose);
        setbloodglucose(bloodglucose)
        setCreatedDate(createdDate)
      });
    }

    if (props?.barChartBpGlucoseData.length > 0) {
      props.barChartBpGlucoseData.forEach((item) => {
        let date = new Date(item.createdOn).getHours()
        if (date < 6) {
          noonArray.push(item)
        }
        if (date >= 6 && date < 9) {
          earlyMorningArray.push(item)
        }
        if (date >= 9 && date < 11) {
          morning.push(item)
        }
        if (date >= 11 && date < 14) {
          afternoon.push(item)
        }
        if (date >= 14 && date < 17) {
          beforeevening.push(item)
        }
        if (date >= 17 && date < 19) {
          evening.push(item)
        }
        if (date >= 19 && date < 22) {
          afterevening.push(item)
        }
        if (date >= 22 && date <= 23) {
          night.push(item)
        }
      })

      let result0_6 = getSumOfGlucose(noonArray)
      let result6_9 = getSumOfGlucose(earlyMorningArray)
      let result9_11 = getSumOfGlucose(morning)
      let result11_14 = getSumOfGlucose(afternoon)
      let result14_17 = getSumOfGlucose(beforeevening)
      let result17_19 = getSumOfGlucose(evening)
      let result19_22 = getSumOfGlucose(afterevening)
      let result22_0 = getSumOfGlucose(night)


      let overallLineData = [
        // ["12am", 0],
        // ["6am", result0_6 == 0 ? 0 : result0_6 / noonArray.length],
        // ["9am", result6_9 == 0 ? 0 : result6_9 / earlyMorningArray.length],
        // ["11am", result9_11 == 0 ? 0 : result9_11 / morning.length],
        // ["2pm", result11_14 == 0 ? 0 : result11_14 / afternoon.length],
        // ["5pm", result14_17 == 0 ? 0 : result14_17 / beforeevening.length],
        // ["7pm", result17_19 == 0 ? 0 : result17_19 / evening.length],
        // ["10pm", result19_22 == 0 ? 0 : result19_22 / afterevening.length],
        // ["12pm", result22_0 == 0 ? 0 : result22_0 / night.length]

        // ["12am", 0],
        // ["6am", result0_6 == 0 ? 0 : result0_6 / noonArray.length],
        // ["9am", result6_9 == 0 ? 0 : result6_9 / earlyMorningArray.length],
        // ["11am", result9_11 == 0 ? 0 : result9_11 / morning.length],
        // ["2pm", result11_14 == 0 ? 0 : result11_14 / afternoon.length],
        // ["5pm", result14_17 == 0 ? 0 : result14_17 / beforeevening.length],
        // ["7pm", result17_19 == 0 ? 0 : result17_19 / evening.length],
        // ["10pm", result19_22 == 0 ? 0 : result19_22 / afterevening.length],
        // ["12pm", result22_0 == 0 ? 0 : result22_0 / night.length]

        // Old data
        // ["0", 0],
        // ["06AM", result0_6 == 0 ? 0 : result0_6 / noonArray.length],
        // ["09AM", result6_9 == 0 ? 0 : result6_9 / earlyMorningArray.length],
        // ["11AM", result9_11 == 0 ? 0 : result9_11 / morning.length],
        // ["02PM", result11_14 == 0 ? 0 : result11_14 / afternoon.length],
        // ["05PM", result14_17 == 0 ? 0 : result14_17 / beforeevening.length],
        // ["07PM", result17_19 == 0 ? 0 : result17_19 / evening.length],
        // ["10PM", result19_22 == 0 ? 0 : result19_22 / afterevening.length],
        // ["12AM", result22_0 == 0 ? 0 : result22_0 / night.length],

        // New data
        {
          tooltipLabel: '',
          value: ["0", 0],
        },
        {
          tooltipLabel: 'Overnight Average',
          value: ["06AM", result0_6 == 0 ? 0 : result0_6 / noonArray.length],
        },
        {
          tooltipLabel: 'Average Before Breakfast',
          value: ["09AM", result6_9 == 0 ? 0 : result6_9 / earlyMorningArray.length],
        },
        {
          tooltipLabel: 'Average After Breakfast',
          value: ["11AM", result9_11 == 0 ? 0 : result9_11 / morning.length],
        },
        {
          tooltipLabel: 'Average Before Lunch',
          value: ["02PM", result11_14 == 0 ? 0 : result11_14 / afternoon.length],
        },
        {
          tooltipLabel: 'Average After Lunch',
          value: ["05PM", result14_17 == 0 ? 0 : result14_17 / beforeevening.length],
        },
        {
          tooltipLabel: 'Average Before Dinner',
          value: ["07PM", result17_19 == 0 ? 0 : result17_19 / evening.length],
        },
        {
          tooltipLabel: 'Average After Dinner',
          value: ["10PM", result19_22 == 0 ? 0 : result19_22 / afterevening.length],
        },
        {
          tooltipLabel: 'Bedtime Average',
          value: ["12AM", result22_0 == 0 ? 0 : result22_0 / night.length],
        },
      ]

      let overallTableData = [
        { body: result0_6 == 0 ? 0 : result0_6 / noonArray.length, header: "Overnight(12am-6am)" },
        { body: result6_9 == 0 ? 0 : result6_9 / earlyMorningArray.length, header: "Before Breakfast(6am-9am)" },
        { body: result9_11 == 0 ? 0 : result9_11 / morning.length, header: "After Breakfast(9am-11am)" },
        { body: result11_14 == 0 ? 0 : result11_14 / afternoon.length, header: "Before Lunch(11am-2pm)" },
        { body: result14_17 == 0 ? 0 : result14_17 / beforeevening.length, header: "After Lunch(2pm-5pm)" },
        { body: result17_19 == 0 ? 0 : result17_19 / evening.length, header: "Before Dinner(5pm-7pm)" },
        { body: result19_22 == 0 ? 0 : result19_22 / afterevening.length, header: "After Dinner(7pm-10pm)" },
        { body: result22_0 == 0 ? 0 : result22_0 / night.length, header: "Bedtime(10pm-12am)" }
      ]

      // console.log("overallTableData====>", overallTableData)


      setoverallTableData(overallTableData)

      //Scatter chart
      // console.log('scatterChart.dataset', scatterChart.dataset)
      // console.log('scatterChart.dataset2 0', scatterChart['dataset']['source'][0])
      // console.log('scatterChart.dataset2 1', scatterChart['dataset']['source'][1])
      // console.log('createdDate loop', createdDate)
      // scatterChart['dataset']['source'][0] = [...scatterChart['dataset']['source'][0], ...createdDate];
      // scatterChart['dataset']['source'][1] = [...scatterChart['dataset']['source'][1], ...bloodglucose]

      // let res1 = result0_6 == 0 ? 0 : result0_6 / noonArray.length
      // let res2 = result6_9 == 0 ? 0 : result6_9 / earlyMorningArray.length
      // let res3 = result9_11 == 0 ? 0 : result9_11 / morning.length
      // let res4 = result11_14 == 0 ? 0 : result11_14 / afternoon.length
      // let res5 = result14_17 == 0 ? 0 : result14_17 / beforeevening.length
      // let res6 = result17_19 == 0 ? 0 : result17_19 / evening.length
      // let res7 = result19_22 == 0 ? 0 : result19_22 / afterevening.length
      // let res8 = result22_0 == 0 ? 0 : result22_0 / night.length
      // console.log('##@0', props?.barChartBpGlucoseData)

      /*** Start: Chartjs Line datas ***/
      let overnightAvg = result0_6 == 0 ? 0 : result0_6 / noonArray.length;
      let beforeBreakfastAvg = result6_9 == 0 ? 0 : result6_9 / earlyMorningArray.length;
      let afterBreakfastAvg = result9_11 == 0 ? 0 : result9_11 / morning.length;
      let beforeLunchAvg = result11_14 == 0 ? 0 : result11_14 / afternoon.length;
      let afterLunchAvg = result14_17 == 0 ? 0 : result14_17 / beforeevening.length;
      let beforeDinnerAvg = result17_19 == 0 ? 0 : result17_19 / evening.length;
      let afterDinnerAvg = result19_22 == 0 ? 0 : result19_22 / afterevening.length;
      let bedtimeAvg = result22_0 == 0 ? 0 : result22_0 / night.length;

      let avgMeasureValues = [
        { x: 0, y: 0 },
        { x: 6, y: overnightAvg },
        { x: 9, y: beforeBreakfastAvg },
        { x: 11, y: afterBreakfastAvg },
        { x: 14, y: beforeLunchAvg },
        { x: 17, y: afterLunchAvg },
        { x: 19, y: beforeDinnerAvg },
        { x: 22, y: afterDinnerAvg },
        { x: 24, y: bedtimeAvg }
      ];
      /*** End: Chartjs Line datas ***/

      let overallScatterData = noonArray.concat(earlyMorningArray).concat(morning).concat(afternoon).concat(beforeevening).concat(evening).concat(afterevening).concat(night)

      /*** Start: Chartjs Scatter datas ***/
      if (overallScatterData.length) {
        var storage = [];
        var labels = [];
        let pointColors = [];

        overallScatterData.forEach((ele, i) => {
          const parse = JSON.parse(ele.dataItemData)
          var d = new Date(ele.createdOn);
          var dateHour = d.getHours();
          var dateMinutes = d.getMinutes();
          var decimalMinutes = dateMinutes / 60;
          dateHour = dateHour + decimalMinutes;
          var x = dateHour;
          var y = parse.bloodglucose;

          if (parse.bloodglucose > individualpatient.bloodSugarMin && individualpatient.bloodSugarMax > parse.bloodglucose) {
            pointColors.push("rgba(19, 195, 31, 1)");
          }
          else if (individualpatient.bloodSugarMax < parse.bloodglucose) {
            pointColors.push("red");
          } else {
            pointColors.push("blue");
          }

          var json = { x: x, y: y };
          storage.push(json);
          let utcDate = ele.createdOn;
          let date = moment(utcDate);
          let newDate = date.format('MM/DD/YYYY');
          labels.push(newDate);
          // chartData['datasets'][0].data[i] = storage

          // scatterChart['series'][0].data[i] = {}
          // scatterChart['series'][0].data[i]["value"] = [moment(ele.createdOn).format("hhA"), parse.bloodglucose]
          // scatterChart['series'][0].data[i]["itemStyle"] = {}
          // scatterChart['series'][0].data[i]["itemStyle"]["color"] = getAnalytics(parse.bloodglucose);
          // scatterChart['series'][0].data[i]["date"] = ele.createdOn;
        })

        // chartData['datasets'][0].data = storage
        // chartData['datasets'][1].data = avgMeasureValues
        const chartStateUpdate = {
          datasets: [
            {
              type: "scatter",
              label: 'scatter',
              pointBackgroundColor: pointColors,
              data: storage, // scatterValues
              showLine: false,
              pointStyle: 'triangle',
              pointRadius: 7,
              pointHitRadius: 7,
            },
            {

              label: 'Average',
              data: avgMeasureValues, // lineValues
              type: 'line',
              fill: false,
              borderColor: "black",
              pointRadius: 1.5,
              borderWidth: 2,
              pointHitRadius: 7,
            }
          ]
        }
        setChartData(chartStateUpdate);
        setListLabels(labels);
      }
      /*** End: Chartjs Scatter datas ***/

    
      //Line chart
      overallLineData.forEach((ele, i) => {
        scatterChart['series'][1].data[i] = ele
      })

      if (eChartsRef && eChartsRef.current) {
        eChartsRef.current?.getEchartsInstance().setOption(scatterChart);
      }
      setScatterChart(scatterChart)
      // scatterChart['series'][0].data[1]["value"] = 69


      // console.log('RESULT0-611', result0_6 / noonArray.length);
      // console.log('RESULT6-9', result6_9 / earlyMorningArray.length);
      // console.log('RESULT9-11', result9_11 / morning.length);
      // console.log('RESULT11_14', result11_14 / afternoon.length);
      // console.log('RESULT14_17', result14_17 / beforeevening.length);
      // console.log('RESULT17_19', result17_19 / evening.length);
      // console.log('RESULT19_22', result19_22 / afterevening.length);
      // console.log('RESULT22_0', result22_0 / night.length);
    }


  }, [bloodglucose, individualpatient, props?.barChartBpGlucoseData])

  useEffect(() => {
    // console.log('PROPS UDPATED')
  }, [chartProps])


  if (props?.barChartBpGlucoseData?.length > 0) {
    createdDate.splice(0, createdDate.length);
    props?.barChartBpGlucoseData?.forEach((element, curr) => {
      // setCreatedDate(createdDate)
      // const dateTimeString = createdDate.push(moment(element.createdOn))
      // console.log("dateTimeString", dateTimeString)
      const dateTime = new Date(createdDate.push(moment(element.createdOn)));
      const hour = dateTime.getHours();
      const minute = dateTime.getMinutes();
      const second = dateTime.getSeconds();
    })
  }

  const getSumOfGlucose = (array) => {
    const initialValue = 0;
    if (array.length > 0) {

      const sumWithInitial = array.reduce(
        (accumulator, currentValue) => {
          const parser = JSON.parse(currentValue.dataItemData)
          return accumulator + parser.bloodglucose
        }, initialValue
      );
      return sumWithInitial
    } else {
      return initialValue
    }

  }

  const getAnalytics = (bloodLevel) => {
    if (bloodLevel > individualpatient.bloodSugarMin && individualpatient.bloodSugarMax > bloodLevel) {
      return 'green'
    }
    else if (individualpatient.bloodSugarMax < bloodLevel) {
      return 'red'
    } else {
      return 'blue'
    }
  }

  return (
    <div>
      <div className="cardtable">
        {JSON.stringify(scatterChart.series.data)}
        {/* <ReactEcharts
          option={scatterChart}
          style={{ width: "100%" }}
          ref={eChartsRef}
        ></ReactEcharts> */}

        {/*** Chartjs ***/}
        <ChartjsScatterLine
          chartData={chartData}
          listLabels={listLabels}
          bloodSugarLevel={bloodSugarLevel}
        />
      </div>
      <div className="averagetext" style={{justifyContent:"center"}}>
      AVERAGE BLOOD GLUCOSE(MG/DL) BY TIME OF DAY
      </div>
      <div style={{ marginTop: "1em" }}>
        <BionomineTable overallTableData={overallTableData} />
      </div>
    </div>
  );
};

export default React.memo(LineChartSugarDemo);
