import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';


import React, { useState } from 'react';
import { Chart } from 'primereact/chart';
import moment from 'moment';


const LineChartWeightDemo = React.memo((props) => {
    
    const [createdDate, setCreatedDAte] = useState([])
    const [weights, setWeight] = useState([])


    if(props?.barChartBpGlucoseData?.length == 0 ){
        createdDate.splice(0, createdDate.length)

    }

    if (props?.barChartBpGlucoseData?.length > 0) {
        createdDate.splice(0, createdDate.length)
        props?.barChartBpGlucoseData?.forEach((element, curr) => {

 
    
            createdDate.push(moment(element.createdOn).format("MM-DD-YYYY hh:mm A"))
            weights.push((JSON.parse(element.dataItemData).weight/453.6).toFixed(1))
        });
    }

    const [basicData] = useState({
        labels: createdDate,
        datasets: [
            {
                label: 'Weight',
                data: weights,
                fill: false,
                borderColor: '#FF0000',
                tension: .4
            },
        ]
    });
    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .6,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };


        return {
            basicOptions,
        }
    }

    const { basicOptions } = getLightTheme();

    return (
        <div>
            <div className="card">
                <Chart type="line" data={basicData} options={basicOptions} />
            </div>

        </div>
    )
})

export default LineChartWeightDemo;