export const states = {
  CONNECTING: "Connecting",
  READY: "Ready",
  INCOMING: "Incoming",
  ON_CALL: "On call",
  OFFLINE: "Offline",
  True : "True"
};

export const VoiceCallStatus = {
  CONNECTED: "Connected",
  RINGING: "Ringing",
  DISCONNECTED: "Disconnected",
};