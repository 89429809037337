import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import { POST_IGLUCOSE } from "../_main/actiontype";
import { RefreshToken } from "./RefreshSlice";

export const getIGlucose = createAsyncThunk(
  POST_IGLUCOSE + "/getIGlucose",
  async (params, thunkAPI) => {
    const token = localStorage.getItem("AccessToken");

    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    };
    const url = `${API_URL + "stats/customdata"}`;
    const response = await fetch(url, option).then((res) => {
      if (res.status == 401) {
        thunkAPI.dispatch(RefreshToken());
      }
      return res.json();
    });

    return response;
  }
);

const IGlucoseSlice = createSlice({
  name: POST_IGLUCOSE,
  initialState: {
    IGlucose: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIGlucose.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getIGlucose.fulfilled, (state, action) => {
      state.IGlucose = action.payload;
      state.loading = false;
    });
    builder.addCase(getIGlucose.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default IGlucoseSlice.reducer;
