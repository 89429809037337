import React from "react";
import "./styles.css";
import Schedule from "../../asset/Image/Schedule.svg";
import Sharescreen from "../../asset/Image/Sharescreen.svg";
import Join from "../../asset/Image/Join.svg";
import Polygon from "../../asset/Image/Polygon .svg";
import Bgschedule from "../../asset/Image/Bgschedule.svg";
import Footer from "../../Component/Footer";

function index() {
  return (
    <div>
      <div className="schedulecard">
        <div className="meetingcard">
          <div className="meetingicons">
            <div className="iconflex">
              <div className="schedule-topic">
                <div>
                  New Meeting{" "}
                  <span>
                    <img
                      src={Polygon}
                      style={{ cursor: "pointer" }}
                      alt="no_image"
                    />
                  </span>
                </div>
              </div>
              <div style={{ marginRight: "2em" }}>
                <img src={Join} style={{ cursor: "pointer" }} alt="no_image" />
                <div className="join-style">Join</div>
              </div>
              <div style={{ marginRight: "2em" }}>
                <img
                  src={Schedule}
                  style={{ cursor: "pointer" }}
                  alt="no_image"
                />
                <div className="sched-style">Schedule</div>
              </div>
              <div>
                <img
                  src={Sharescreen}
                  style={{ cursor: "pointer" }}
                  alt="no_image"
                />
                <div className="share-fsize">
                  Share Screen{" "}
                  <span>
                    <img
                      src={Polygon}
                      style={{ cursor: "pointer" }}
                      alt="no_image"
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="upcomingmeeting">
          <div>
            <img src={Bgschedule} style={{ width: "100%" }} alt="no_image" />
            <div className="imageovertext">03:10 PM</div>
            <div className="imageovertext1">WEDNESDAY, SEPTEMBER 30, 2022</div>
          </div>
          <div className="blanktext">No upcoming meeting today</div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default index;
