import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
// // import Scatter from "primereact/Scatter";
// // import "../../index.css";
// import ReactDOM from "react-dom";

import React, { useEffect, useState } from "react";
import { Chart } from "primereact/chart";
import moment from 'moment';


const BarChartGluc = React.memo((props) => {

    const [createdDate, setCreatedDAte] = useState([])
    const [bloodglucose, setbloodglucose] = useState([])


    if(props?.barChartBpGlucoseData?.length == 0 ){
        createdDate.splice(0, createdDate.length)

    }

    if (props?.barChartBpGlucoseData?.length > 0) {

        createdDate.splice(0, createdDate.length)
        props?.barChartBpGlucoseData?.forEach((element, curr) => {

            createdDate.push(moment(element.createdOn).format("MM-DD-YYYY hh:mm A"))
            bloodglucose.push(JSON.parse(element.dataItemData).bloodglucose)
        });
        
    }

    
useEffect(() =>{
    if (!bloodglucose){
        return <p>Sorry, it seems there is no data here for this patient</p>
    }
},[])
    


const [basicData] = useState({
    labels: createdDate,
    datasets: [
    {
        label: "Mean Blood Glucose (mmHg)",
        backgroundColor: "#00ACEE",
        data:bloodglucose,
        categoryPercentage: 0.8,
        barPercentage : 0.9,
        borderWidth: 1,
        barThickness: 20,
        borderRadius: 10,
    }
    ]
});


const getLightTheme = () => {
    let basicOptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.8,
    plugins: {
        legend: {
        labels: {
            color: "#00ACEE"
        }
        }
    },
    scales: {
        x: {
        ticks: {
            color: "grey"
        },
        grid: {
            color: "#ebedef"
        }
        },
        y: {
        ticks: {
            color: "grey"
        },
        grid: {
            color: "#ebedef"
        }
        }
    }
    };



    return {
    basicOptions
    };
};

const { basicOptions } = getLightTheme();

return (
    <div>
    {(props?.barChartBpGlucoseData?.length >= 35 )?<div className="cardbloodsugarbio">
        <Chart  type="bar" data={basicData} options={basicOptions} />
    </div>:<div className="cardtable">
        <Chart  type="bar" data={basicData} options={basicOptions} />
    </div>}
    </div>
);
});


export default BarChartGluc;