import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useDispatch, useSelector } from "react-redux";
import "./styles.css";

const IcdTable = () => {
  const diagonosis = useSelector(
    (state) => state.PatientGetOtherDetailsSlice?.getdetail?.data?.diagnosisList
  );

  return (
    <div>
      <div className="tabledata">
        <DataTable
          className="table-border"
          value={diagonosis}
          responsiveLayout="scroll"
        >
          <Column field="icdCode" header="ICD 10 CODE"></Column>
          <Column field="description" header="DESCRIPTION"></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default IcdTable;
