/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useRef, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import { TextField } from "formik-material-ui";
import "./styles.css";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import pcamera from "../../asset/Image/pcamera.svg";
import ImageUploading from "react-images-uploading";
import Footer from "../../Component/Footer";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import { profileUsers } from "../../Api_Redux/ProfileSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { uploadimage } from "../../Api_Redux/UploadImageSlice";
import { getprofiledetail } from "../../Api_Redux/GetProfileSlice";
import { Navigate, useNavigate } from "react-router-dom";
import { Grid } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DASHBOARD_ROUTE } from "../../_main/routeConstants";
import { INDIVIDUALPATIENTDETAILS_ROUTE } from "../../_main/routeConstants";
import dummyprofile from "../../asset/Image/dummyprofile.svg";
import Box from "@mui/material/Box";
import Modal from "react-bootstrap/Modal";
import { CHANGEPASSWORD_ROUTE } from "../../_main/routeConstants";
import { validateusername } from "../../Api_Redux/ValideUsernameSlice";
import { EMPTY_USERNAME } from "../../_main/errorConstants";
import { NOT_EMPTY_REGEX } from "../../_main/constants";

const maxNumber = 69;

const paramsdetail = { Id: localStorage.getItem("careproviderId") };

function Profile() {
  const screenState = useSelector((state) => state.LoginSlice.login.data);


  const [showStatusModal, setShowStatusModal] = useState(false);

  const [isEditable, setIsEditable] = useState(false);
  const [images, setImages] = React.useState([]);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNumber, setphoneNumber] = React.useState("");
  const [npiNumber, setnpiNumber] = React.useState("");
  const [profileImage, setProfileImage] = React.useState("");
  const [username, setUsername] = React.useState("");
  const [submitBtn, setSubmitBtn] = React.useState(false);
  const [isChange, setIsChange] = React.useState(true);

  const createdDate = useSelector(
    (state) => state.GetProfileSlice?.getpro?.data
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [inputField, setInputField] = useState({
    id: JSON.parse(localStorage.getItem("careproviderId")),
    userType: "",
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phoneNumber: "",
    npinumber: "",
    avatarUrl: "",
  });

  const [inputimageField, setInputimageField] = useState({
    avatarUrl: "",
  });

  useEffect(() => {
    dispatch(getprofiledetail());
    setFirstName(createdDate?.firstName);
    setLastName(createdDate?.lastName);
    setphoneNumber(createdDate?.phoneNumber);
    setnpiNumber(createdDate?.npiNumber);
    setProfileImage(createdDate?.avatarURL);
    setUsername(createdDate?.userName);
  }, [isEditable]);

  const onImageUpload = (imageList, addUpdateIndex) => {
    dispatch(uploadimage(imageList))
      .then(async (isSuccess) => {
        if (isSuccess.payload.status == 1) {
          setProfileImage(isSuccess.payload.data.FilePath);
        } else {
          toast.error("Invalid crdential ", {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 2000,
          });
          setTimeout(() => {}, 1000);
        }
      })
      .catch(({ error }) => {
        console.log(error);
      });
  };

  const handleSubmit = (form, { resetForm }) => {
    setIsEditable(!isEditable);
    if (isEditable) {
      const payload = {
        id: JSON.parse(localStorage.getItem("careproviderId")),
        userType: JSON.parse(localStorage.getItem("userType")),
        firstName: firstName,
        lastName: lastName,
        email: "",
        userName: username,
        password: "",
        phoneNumber: phoneNumber,
        npinumber: npiNumber,
        avatarUrl: profileImage,
      };
      dispatch(profileUsers(payload))
        .then(async (isSuccess) => {
          if (isSuccess.payload.status == 1) {
            dispatch(getprofiledetail());

            toast.success(isSuccess.payload.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 1000,
            });

            setTimeout(() => {
              if (localStorage.getItem("userType") == 3) {
                navigate(INDIVIDUALPATIENTDETAILS_ROUTE);
              } else {
                navigate(DASHBOARD_ROUTE);
              }
            }, 1000);
          } else {
            toast.error(isSuccess.payload.message, {
              position: toast.POSITION.TOP_RIGHT,
              autoClose: 3000,
            });
            setTimeout(() => {}, 1000);
          }
        })
        .catch(({ error }) => {
          console.log(error);
        });
    }
  };

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    let phoneNumber = e.target.value;
    if (keyCode < 48 || keyCode > 57) {
      e.preventDefault();
      return;
    }
    if (phoneNumber.length === 15) {
      e.preventDefault();
      return;
    }
    if (phoneNumber.length === 0) {
      phoneNumber = "+1 ";
    } else if (phoneNumber.length === 3) {
      phoneNumber = `+1 ${phoneNumber.slice(1)}`;
    } else if (phoneNumber.length === 7) {
      phoneNumber = `${phoneNumber.slice(0, 6)}-${phoneNumber.slice(6)}`;
    } else if (phoneNumber.length === 11) {
      phoneNumber = `${phoneNumber.slice(0, 10)}-${phoneNumber.slice(10)}`;
    }
    e.target.value = phoneNumber;
  };

  const handleClose2 = () => {
    setShowStatusModal(!showStatusModal);
  };

  const handleClick = () => {
    navigate(CHANGEPASSWORD_ROUTE);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const payload = {
      userName: username,
    };
    const response = dispatch(validateusername(payload));
    response.then((value) => {
      if (value.payload.status == 1) {
        setSubmitBtn(false);
        toast.success("Username available", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
        setIsChange(true);
      } else {
        setSubmitBtn(true);
        toast.error("Username unavailable", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 2000,
        });
      }
    });
    response.catch((err) => {
      setSubmitBtn(true);
      toast.error("Username unavailable", {
        position: toast.POSITION.TOP_RIGHT,
        autoClose: 2000,
      });
    });
   
  };

  return (
    <div>
      <div></div>
      <div className="profile-text">
        <div className="textprofile">PROFILE</div>
      </div>
      <div style={{ marginTop: "30px" }}>
        <div>
          {/* <div className="col-3 md:col-6 lg:col-3">
            <Card style={{ padding: "0px", margin: "0px" }}> */}
          <Grid container spacing={2} className="information-field">
            <Grid item xl={3} lg={3} md={3} sm={12} xs={12}>
              <div
                style={{ padding: "0px", margin: "0px" }}
                className="profile-pic"
              >
                <div
                  style={{
                    background: "#99D5CF",
                    padding: "15px",
                    borderRadius: "10px 10px 0px 0px",
                  }}
                >
                  <div className="ptopic">Profile Picture</div>
                </div>
                <div className="propic-card">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <div className="profile-section">
                      <ImageUploading
                        value={images}
                        onChange={onImageUpload}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                        acceptType={["jpg", "gif", "png", "jpeg", "heic"]}
                      >
                        {({
                          imageList,
                          onImageUpload,
                          onImageRemoveAll,
                          onImageUpdate,
                          onImageRemove,
                          isDragging,
                          dragProps,
                        }) => (
                          <div className="position-relative">
                            {/* <Avatar style={{ height: "150px", width: "150px" }}> */}
                            <Avatar
                              style={{ height: "150px", width: "150px" }}
                              className="avator-image"
                            >
                              {/* {imageList.map((image, index) => ( */}
                              <div className="image-item">
                                asd
                                <img
                                  src={
                                    profileImage
                                      ? profileImage
                                      : createdDate?.avatarURL
                                      ? createdDate?.avatarURL
                                      : dummyprofile
                                  }
                                  alt=""
                                  style={{ height: "190px", width: "190px" }}
                                />
                                <div className="image-item__btn-wrapper"></div>
                              </div>
                              {/* ))} */}
                            </Avatar>
                            {isEditable ? (
                              <img
                                src={pcamera}
                                className="iconcamera"
                                style={isDragging ? { color: "red" } : null}
                                onClick={onImageUpload}
                                {...dragProps}
                                size={"30px"}
                                showResults={false}
                              />
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </ImageUploading>
                    </div>
                    <div>
                      <div
                        style={{
                          fontWeight: 600,
                          padding: "5px ",
                        }}
                      >
                        <div className="username">
                          {createdDate ? createdDate.userName : " "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </Grid>
            <Grid item xl={9} lg={9} md={9} sm={12} xs={12}>
              <div
                style={{ padding: "0px", margin: "0px" }}
                className="profile-pic"
              >
                <div
                  style={{
                    background: "#99D5CF",
                    padding: "15px",
                    borderRadius: "10px 10px 0px 0px",
                  }}
                >
                  <div className="ptopic">Personal Information</div>
                </div>
                <div className="propic-card">
                  <Formik
                    initialValues={inputField}
                    onSubmit={handleSubmit}
                    enableReinitialize
                    validate={(values) => {
                      const errors = {};

                      for (const obj in values) {
                        switch (
                          obj
                       
                        ) {
                        }
                      }
                      return errors;
                    }}
                  >
                    {({}) => (
                      <Form>
                        {/* <div className="col-6"> */}

                        <Box>
                          <Grid container spacing={2}>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                              <p className="label-format">
                                <label className="textlable">FIRST NAME</label>
                              </p>

                              <Field
                                component={TextField}
                                className="input-container"
                                name="firstName"
                                value={
                                  !isEditable
                                    ? createdDate?.firstName
                                    : firstName
                                }
                                type="text"
                                placeholder="Enter First Name"
                                variant="outlined"
                                size="small"
                                disabled={!isEditable}
                                onChange={(e) => setFirstName(e.target.value)}
                              />
                            </Grid>
                            <Grid
                              Grid
                              item
                              xl={6}
                              lg={6}
                              md={6}
                              sm={12}
                              xs={12}
                            >
                              <p className="label-format">
                                <label className="textlable">LAST NAME</label>
                              </p>

                              <Field
                                component={TextField}
                                className="input-container"
                                name="lastName"
                                value={
                                  !isEditable ? createdDate?.lastName : lastName
                                }
                                type="text"
                                placeholder="Enter Last Name"
                                variant="outlined"
                                size="small"
                                disabled={!isEditable}
                                onChange={(e) => setLastName(e.target.value)}
                              />
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                              <p className="label-format">
                                <label className="textlable">USER NAME</label>
                              </p>

                              <Field
                                component={TextField}
                                className="input-container1"
                                name="userName"
                                type="text"
                                placeholder="Enter User Name"
                                variant="outlined"
                                size="small"
                                onChange={(e) => setUsername(e.target.value)}
                                value={username}
                                disabled={isChange}
                              />
                              {isChange == true ? (
                                <Button
                                  disabled={!isEditable}
                                  className="inputWithButton"
                                  onClick={(event) => {
                                    event.preventDefault();
                                    setIsChange(!isChange);
                                  }}
                                >
                                  Change
                                </Button>
                              ) : (
                                <Button
                                  className="inputWithButton"
                                  onClick={(event) => handleSearch(event)}
                                >
                                  Check
                                </Button>
                              )}
                              {/* {userNameState.status && userNameState.status == 1 ? <span style={{color: 'green'}}>User name available</span> : <span style={{color: 'red'}}>User name already exist</span>} */}
                            </Grid>
                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                              <p className="label-format">
                                <label className="textlable">
                                  PHONE NUMBER &nbsp;
                                  <span className="phoneformat1">
                                    format: (+444-555-6666)
                                  </span>
                                </label>
                              </p>

                              <Field
                                component={TextField}
                                className="input-container"
                                value={
                                  !isEditable
                                    ? createdDate?.phoneNumber
                                    : phoneNumber
                                }
                                name="phoneNumber"
                                type="text"
                                placeholder="Enter Phone Number"
                                variant="outlined"
                                size="small"
                                disabled={!isEditable}
                                onChange={(e) => setphoneNumber(e.target.value)}
                                onKeyPress={handleKeyPress}
                              />
                            </Grid>
                            {/* </div> */}
                            {/* <div className="col-6"> */}

                            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                              <p className="label-format">
                                <label className="textlable">EMAIL ID</label>
                              </p>

                              <Field
                                component={TextField}
                                className="input-container"
                                name="emailId"
                                type="text"
                                value={createdDate?.emailId}
                                placeholder="Enter Email ID"
                                variant="outlined"
                                size="small"
                                disabled={true}
                              />
                            </Grid>

                            {screenState.userType == 1 ||
                            screenState.userType == 2 ? (
                              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="commonclass">
                                  <p className="label-format">
                                    <label className="textlable">
                                      NPI NUMBER
                                    </label>
                                  </p>

                                  <Field
                                    component={TextField}
                                    className="input-container"
                                    value={
                                      !isEditable
                                        ? createdDate?.npiNumber
                                        : npiNumber
                                    }
                                    name="npinumber"
                                    type="text"
                                    placeholder="Enter NPI Number"
                                    variant="outlined"
                                    size="small"
                                    disabled={!isEditable}
                                    onChange={(e) =>
                                      setnpiNumber(e.target.value)
                                    }
                                  />
                                </div>
                              </Grid>
                            ) : null}
                          </Grid>
                        </Box>

                        <div className="commonclass profile-save">
                          <div className="field-container">
                            <Button
                              className="pro-btn"
                              variant="contained"
                              type="submit"
                              color="primary"
                              disabled={submitBtn}
                            >
                              {isEditable ? "SAVE" : "EDIT"}
                            </Button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                  <ToastContainer />
                </div>
              </div>
              {/* </div> */}
            </Grid>
          </Grid>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Profile;
