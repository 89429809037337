import 'primeicons/primeicons.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.css';
import 'primeflex/primeflex.css';


import React, { useState } from 'react';
import { Chart } from 'primereact/chart';
import moment from 'moment';


const LineChartPulseDemo = React.memo((props) => {
    
    // console.log('testing', props)

    const [createdDate, setCreatedDAte] = useState([])
    const [Spo2, setSpo2] = useState([])
    const [PulseBpm, setPulseBpm] = useState([])

    if(props?.barChartBpGlucoseData?.length == 0 ){
        createdDate.splice(0, createdDate.length)

    }


    if (props?.barChartBpGlucoseData?.length > 0) {
        createdDate.splice(0, createdDate.length)
        props?.barChartBpGlucoseData?.forEach((element, curr) => {

            createdDate.push(moment(element.createdOn).format("MM-DD-YYYY hh:mm A"))
            Spo2.push(JSON.parse(element.dataItemData).Spo2)
            PulseBpm.push(JSON.parse(element.dataItemData).PulseBpm)
        });
    }






    const [basicData] = useState({
        labels: createdDate,
        datasets: [
            {
                label: 'Spo2',
                data: Spo2,
                fill: false,
                borderColor: '#FF0000',
                tension: .4
            },
            {
                label: 'PulseBpm',
                data: PulseBpm,
                fill: false,
                borderColor: '#42A5F5',
                tension: .4
            }
        ]
    });



    const getLightTheme = () => {
        let basicOptions = {
            maintainAspectRatio: false,
            aspectRatio: .6,
            plugins: {
                legend: {
                    labels: {
                        color: '#495057'
                    }
                }
            },
            scales: {
                x: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                },
                y: {
                    ticks: {
                        color: '#495057'
                    },
                    grid: {
                        color: '#ebedef'
                    }
                }
            }
        };


        return {
            basicOptions,
        }
    }

    const { basicOptions } = getLightTheme();

    return (
        <div>
            <div className="cardtable">
                <Chart type="line" data={basicData} options={basicOptions} />
            </div>

        </div>
    )
})

export default LineChartPulseDemo;