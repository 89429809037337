import React from "react";
import { AiOutlineCopyrightCircle } from "react-icons/ai";
import "./styles.css";

function Footer() {

  const term = () =>{
    window.open("https://www.rmthealth.com/privacy-policy")
  }
  

  return (
    <div className="footer">
      <div className="ftext">
        <AiOutlineCopyrightCircle />&nbsp;
        <div>
          2023&nbsp;-&nbsp;Rpm.Doctor&nbsp;-&nbsp;<a onClick={term}>Privacy&nbsp;-&nbsp;v:1.0.5</a>
        </div>
      </div>
    </div>
  );
}

export default Footer;
