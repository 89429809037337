import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import callfetch from "../_main/fetch";
import { POST_DOCTORNOTES } from "../_main/actiontype";

export const postdoctornote = createAsyncThunk(
  POST_DOCTORNOTES + "/postdoctornote",
  async (params) => {
    const token = localStorage.getItem("AccessToken");
    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    };

    const url = `${API_URL + "Patient/doctornotes"}`;
    const response = await callfetch(url, option);
    const value = await response;
    return value;
  }
);

const PostDoctorNotesSlice = createSlice({
  name: POST_DOCTORNOTES,
  initialState: {
    doctornote: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(postdoctornote.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postdoctornote.fulfilled, (state, action) => {
      state.doctornote = action.payload;
      state.loading = false;
    });
    builder.addCase(postdoctornote.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default PostDoctorNotesSlice.reducer;
