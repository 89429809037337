import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import { POST_PATIENTVIEWLIST } from "../_main/actiontype";
import { RefreshToken } from "./RefreshSlice";

export const patientViewList = createAsyncThunk(
  POST_PATIENTVIEWLIST + "/patientViewList",
  async (params, thunkAPI) => {
    const token = localStorage.getItem("AccessToken");

    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    };

    const url = `${API_URL + "Patient/mypatientsview"}`;
    const response = await fetch(url, option).then((res) => {
      if (res.status == 401) {
        console.log("401401");
        thunkAPI.dispatch(RefreshToken());
      }


      return res.json();
    });

    return response;
  }
);

const patientlistViewSlice = createSlice({
  name: POST_PATIENTVIEWLIST,
  initialState: {
    patients: [],
    nextPage: 1,
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(patientViewList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(patientViewList.fulfilled, (state, action) => {
      state.patients = action.payload;
      state.loading = false;
    });
    builder.addCase(patientViewList.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default patientlistViewSlice.reducer;
