// LOGIN
export const EMPTY_BRANCH = "Please select your branch";
export const EMPTY_USERNAME = "Please enter the User Name";
export const EMPTY_USERTYPE = "Please enter the User Type";
export const EMPTY_PASSWORD = "Please enter the password";
export const EMPTY_EMAIL = "Please enter the vaild email";
export const EMPTY_CONFIRMPASSWORD = "Please enter the confirm password";
export const EMPTY_ORGANIZATIONNAME = "Please enter the Organization Name";
export const EMPTY_ADDRESS1 = "Please enter the Address1";
export const EMPTY_ADDRESS2 = "Please enter the Address2";
export const EMPTY_CITY = "Please enter the City";
export const EMPTY_STATE = "Please enter the State";
export const EMPTY_ZIP = "Please enter the zip";
export const EMPTY_PHONE = "Please enter the Phone Number";
export const EMPTY_FAX = "Please enter the Fax";
export const EMPTY_MEMBERSHIP = "Please enter the Membership Type";
export const EMPTY_FIRSTNAME = "Please enter the First Name";
export const EMPTY_LASTNAME = "Please enter the Last Name";
export const EMPTY_OFFICEPHONE = "Please enter the Office Contact Number";
export const EMPTY_CONTACTTITLE = "Please enter the Office Contact Title";
export const EMPTY_CONTACTFNAME = "Please enter the Office Contact First Name";
export const EMPTY_CONTACTLNAME = "Please enter the Office Contact Last Name";
export const EMPTY_NPINUMBER = "Please enter the NPI Number";
export const EMPTY_USERID = "Please enter the User ID";
export const EMPTY_DOB = "Please enter the Date of birth";
export const EMPTY_MRN = "Please enter the Medical Record Number";
export const EMPTY_MEDICAREID = "Please enter the Medicare ID";
export const EMPTY_OLD_PASSWORD = "Please enter the old Password";
export const EMPTY_NEW_PASSWORD = "Please enter the new Password";
export const VALIDEMAIL_REGEX = "Please enter valid email";
