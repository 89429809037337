import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import ReactDOM from "react-dom";

import React, { useState } from "react";
import { SelectButton } from "primereact/selectbutton";

const SelectWeightButtonGraphDemo = (props) => {

  const [value1, setValue1] = useState("Scatter");

    // React.useEffect(() => {
    //   props.onGlucoseChartFilterChange(value1)
    // }, [value1])


  const options = ["Bar", "Scatter", "Table"];

  


  const handleSelectbuttongraphchange = (e) =>{
    if(e.value != null){
    props.onWeightChartFilterChange(e.value)
    setValue1(e.value)
    }
  }

  return (
    <div>
      <div>
        <SelectButton
          value={value1}
          options={options}
          // onChange={(e) => setValue1(e.value)}
          onChange={handleSelectbuttongraphchange}
        />
      </div>
    </div>
  );
};

export default SelectWeightButtonGraphDemo;