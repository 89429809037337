import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { updatetimer } from "../../Api_Redux/TimerUpdateSlice";
import "./styles.css";
import Modal from "react-bootstrap/Modal";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { ToastContainer, toast } from "react-toastify";
import timericon from "../../asset/Image/timericon.png";
import Tooltip from "@mui/material/Tooltip";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const Timer = (props) => {
  // const [timer, setTimer] = useState(props.timerData);
  const [timer, setTimer] = useState(props.data[0].totalSeconds);
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const increment = useRef(null);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const [shows, setShow] = useState(false);
  const getpatientTimerData = useSelector(
    (state) => state.TimerGetSlice?.getdetail?.data
  );

  const handleClose = () => {
    setShow(false);
    handleStart();
  };
  const [timerValue, setTimerValue] = useState("");
 

  // useEffect(() => {
  //   handleStart();
  // }, []);

  

  useEffect(() => {
    setTimer(getpatientTimerData?.patientTimerDetail[0].totalSeconds)
    handleStart();
  }, [])

  // useEffect(() => {
  //   localStorage.setItem("timer", JSON.stringify(timer));
  // }, [timer]);

  const handleStart = () => {
    setIsActive(true);
    setIsPaused(true);
    increment.current = setInterval(() => {
      setTimer((timer) => {
        if (timer % 10 == 0 && timer != props.data[0].totalSeconds) {
          const timerPayload = {
            userId: state?.userId,
            totalSeconds: timer,
            sessionId: 1,
            id: props.data[0].id,
          };
          dispatch(updatetimer(timerPayload));
          // console.log("timer123", timer);
        }
        if (timer >= 5400) {
          clearInterval(increment.current);
        }
        return timer >= 5400 ? timer : timer + 1;
      });
    }, 1000);
  };

  const handlePause = () => {
    clearInterval(increment.current);
    setIsPaused(false);
  };

  const handleResume = () => {
    setIsPaused(true);
    increment.current = setInterval(() => {
      setTimer((timer) => timer + 1);
    }, 1000);
  };

  const handleReset = () => {
    clearInterval(increment.current);
    setIsActive(false);
    setIsPaused(false);
    setTimer(0);
  };

  const formatTime = () => {
    const getSeconds = `0${timer % 60}`.slice(-2);
    const minutes = `${Math.floor(timer / 60)}`;
    const getMinutes = `0${minutes % 60}`.slice(-2);
    const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
    // console.log('getMinutes ====>>> ', getMinutes)
    props.returnTime(getMinutes,getHours,getSeconds)
    return `${getHours} : ${getMinutes} : ${getSeconds}`;
  };


  const addTimer = () => {
    setShow(true);
    clearInterval(increment.current);
    setIsActive(false);
    setIsPaused(false);
  };

  const handleInputChange = (e) => {
    setTimerValue(e.target.value);
  };

  const addSubmit = (e) => {
    e.preventDefault();
    const converter = timerValue * 60;
    const totalTimer = Number(timer) + Number(converter);
    const timerPayload = {
      userId: state?.userId,
      totalSeconds: totalTimer,
      sessionId: 1,
      id: props.data[0].id,
    };
    dispatch(updatetimer(timerPayload))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
          });
          setTimer(totalTimer);
        }
        setShow(false);
        setTimerValue("");
      })
      .catch((err) => {
        console.log("err", err);
      });
    handleStart();
  };
  const subtractSubmit = (e) => {
    e.preventDefault();

    const converter = timerValue * 60;
    const totalTimer = Number(timer) - Number(converter);
    const timerPayload = {
      userId: state?.userId,
      totalSeconds: totalTimer,
      sessionId: 1,
      id: props.data[0].id,
    };
    dispatch(updatetimer(timerPayload))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 4000,
          });
          setTimer(totalTimer);
        }
        setShow(false);
        setTimerValue("");
      })
      .catch((err) => {
        console.log("err", err);
      });
    handleStart();
  };

  return (
    <>
      <div>
        <Modal show={shows} onHide={handleClose} animation={false}>
          <Modal.Header
            className="maintext"
            style={{ color: "#009688" }}
            closeButton
          >
            ADD TIMER
          </Modal.Header>
          <Grid
            container
            alignItems="center"
            justify="center"
            className="center-form"
          >
            <div className="center-form">
              <Grid
                container
                spacing={2}
                className=" card-container indigo-container"
              >
                <Grid>
                  <div className="flex-1">
                    <div
                      className="py-3"
                      style={{ color: "#009688", display: "flex" }}
                    >
                      <InputText
                        type="number"
                        maxLength={3}
                        placeholder="Minutes"
                        value={timerValue}
                        onChange={(e) => setTimerValue(e.target.value)}
                        style={{
                          width: "25rem",
                          marginLeft: "5.5rem",
                          width: "20rem",
                          marginTop: "1rem",
                        }}
                      />
                      &nbsp; &nbsp;
                      {timerValue === "" ? (
                        <>
                          <Button
                            disabled
                            style={{
                              position: "relative",
                              right: "7.5em",
                              top: "1.8rem",
                            }}
                            type="button"
                            className="pause-timer addtimer"
                            onClick={subtractSubmit}
                          >
                            <div className="timer-logo">
                              <RemoveIcon />
                            </div>
                          </Button>
                          <Button
                            disabled
                            style={{
                              position: "relative",
                              right: "7.5em",
                              top: "1.8rem",
                            }}
                            type="button"
                            className="pause-timer addtimer"
                            onClick={addSubmit}
                          >
                            <div className="timer-logo">
                              <AddIcon />
                            </div>
                          </Button>
                        </>
                      ) : (
                        <>
                          <Button
                            style={{
                              position: "relative",
                              right: "7.5em",
                              top: "1.8rem",
                            }}
                            type="button"
                            className="pause-timer addtimer"
                            onClick={subtractSubmit}
                          >
                            <div className="timer-logo">
                              <RemoveIcon />
                            </div>
                          </Button>
                          <Button
                            style={{
                              position: "relative",
                              right: "7.5em",
                              top: "1.8rem",
                            }}
                            type="button"
                            className="pause-timer addtimer"
                            onClick={addSubmit}
                          >
                            <div className="timer-logo">
                              <AddIcon />
                            </div>
                          </Button>
                        </>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Modal>
      </div>
      <div className="video-overlay">
        <p>{formatTime()}</p>
        <div className="timer-button timer-logos">
          {!isActive && !isPaused ? (
            <button
              style={{
                color: "white",
                borderRadius: "10px",
                backgroundColor: "#009688",
              }}
              onClick={handleStart}
            >
              Start
            </button>
          ) : isPaused ? (
            <button
              style={{
                color: "white",
                borderRadius: "10px",
                backgroundColor: "#009688",
              }}
              onClick={handlePause}
            >
              Pause
            </button>
          ) : (
            <button
              style={{
                color: "white",
                borderRadius: "10px",
                backgroundColor: "#009688",
              }}
              onClick={handleResume}
            >
              Resume
            </button>
          )}

          <button
            style={{
              color: "white",
              borderRadius: "10px",
              backgroundColor: "#009688",
            }}
            onClick={addTimer}
            disabled={!isActive}
          >
            {" "}
            Add time{" "}
          </button>
        </div>
      </div>
    </>
  );
};

export default Timer;
