import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import callfetch from "../_main/fetch";
import { POST_RESET } from "../_main/actiontype";

export const resetUsers = createAsyncThunk(
  POST_RESET + "/resetUsers",
  async (params) => {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    const option = {
      method: POST,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };

    const url = `${API_URL + "Auth/resetpassword"}`;
    const response = await callfetch(url, option);
    const value = await response;
    return value;
  }
);

const resetSlice = createSlice({
  name: POST_RESET,
  initialState: {
    reset: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(resetUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resetUsers.fulfilled, (state, action) => {
      state.reset = action.payload;
      state.loading = false;
    });
    builder.addCase(resetUsers.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default resetSlice.reducer;
