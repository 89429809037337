import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import callfetch from "../_main/fetch";
import { CHANGE_PASSWORD } from "../_main/actiontype";

export const setPassword = createAsyncThunk(
  CHANGE_PASSWORD + "/password",
  async (params) => {
    const token = localStorage.getItem("AccessToken");

    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    };

    const url = `${API_URL + "Auth/changepassword"}`;
    const response = await callfetch(url, option);
    if (response.status == 401) {
      localStorage.setItem("AccessToken", value.data.jwtToken);
      const response1 = await callfetch(url, {
        method: POST,
        headers: {
          Authorization: `Bearer ${value.data.jwtToken}`,
          "Content-Type": "application/json",
        },
      });

      const value1 = await response1;
      return value1;
    }
    const value = await response;
    return value;
  }
);

const changePasswordSlice = createSlice({
  name: CHANGE_PASSWORD,
  initialState: {
    password: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setPassword.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(setPassword.fulfilled, (state, action) => {
      state.password = action.payload;
      state.loading = false;
    });
    builder.addCase(setPassword.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default changePasswordSlice.reducer;
