import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Auth from "./Auth";
import SideBar from "../Component/Sidebar";
import Toolbar from "../Component/Tool";
import Footer from "../Component/Footer";

const PrivateRouter = () => {
  const isAllowed = Auth.isAuthenticated();
  return !isAllowed ? (
    <Navigate to="/" />
  ) : (
    <>
      <div>
        <div className="sidemenu-listed">
          <SideBar />
        </div>

        <div id="sticky-layout">
          <Toolbar />
          <div className="sidebar-section1 managepatient1">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
};

export default PrivateRouter;
