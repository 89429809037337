import React, { useEffect, useState } from "react";
import { Steps } from "primereact/steps";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";
import { Button } from "primereact/button";
import { Getpatientdropdown } from "../../Api_Redux/PatientDropdowndetails";
import { useDispatch, useSelector } from "react-redux";
import { Addpatient } from "../../Api_Redux/AddPatientSlice";
import moment from "moment";
import Footer from "../../Component/Footer";
import { InputMask } from "primereact/inputmask";
import { ToastContainer, toast } from "react-toastify";
import "./styles.css";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  MANAGEPATIENT_ROUTE,
  INDIVIDUALPATIENTDETAILS_ROUTE,
} from "../../_main/routeConstants";
import { bionime } from "../../Api_Redux/BionimeSlice";
import Alert from "react-bootstrap/Alert";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Spinner from "react-bootstrap/Spinner";

const items = [
  {
    label: "General Information",
  },
  {
    label: "Medicare Information",
  },
  {
    label: "Device Information",
  },
];

function Patients() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { state } = useLocation();

  const [value, setValue] = useState();

  const [activeIndex, setActiveIndex] = useState(0);
  const [error, setError] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [alertValue, setAlertValue] = useState("");
  const [statusCode, setStatusCode] = useState();
  const [isClicked, setIsClicked] = useState(false);
  const [emailDisabled, setEmailDisabled] = useState(true);

  useEffect(() => {
    setEmailDisabled(emailDisabled);
  }, [emailDisabled]);

  const [text, setText] = useState();

  const [inputField, setInputField] = useState({
    id: 0,
    careProviderId: JSON.parse(localStorage.getItem("careproviderId")),
    userId: 0,
    firstName: "",
    lastName: "",
    nameSuffixId: 0,
    dob: "",
    genderId: 0,
    bloodGroupId: "",
    emailId: "",
    address: "",
    diseases: "",
    phoneNumber: "",
    enrollmentDate:  "",
    // enrollmentDate: "2023-3-12",
    mrn: "",
    rpm: false,
    ccm: false,
    medicareId: "",
    advantagePlaneId: "",
    policyNumber: "",
    groupNumber: "",
    iGlucoseIMEI: "",
    bionimeIMEI: "",
    bloodSugarMin: "",
    bloodSugarMax: "",
    bodyTraceIMEI: "",
    iGlucoseBloodPressureIMEI: "",
    continuaBloodPressureIMEI:"",
    continuaBloodSugarIMEI:"",
    // bloodPressureMin: "",
    // bloodPressureMax: "",
    bodyTraceWeightScaleIMEI: "",
    iGlucosePulseOxIMEI: "",
    bloodSugarId: "",
    bloodPressureId: "",
    weightScaleId: "",
    pulseOXId: "",
    bloodPressureSystolicMin:"",
    bloodPressureSystolicMax:"",
    bloodPressureDiastolicMin:"",
    bloodPressureDiastolicMax:"",
    isOmronPatient: false,
  });

  useEffect(() => {
    setInputField({
      id: state ? state.id : 0,
      careProviderId: JSON.parse(localStorage.getItem("careproviderId")),
      userId: state ? state.userId : 0,
      firstName: state ? state.firstName : "",
      lastName: state ? state.lastName : "",
      nameSuffixId: state ? state.nameSuffixId : 0,
      dob: state ? new Date(state.dob) : "",
      genderId: state ? state.genderId : 0,
      bloodGroupId: state ? state.bloodGroupId : 1,
      emailId: state ? state.emailId : "",
      address: state ? state.address : "",
      diseases: state ? state.diseases : "",
      phoneNumber: state ? state.phoneNumber : "",
      enrollmentDate: state ? new Date(state.enrollmentDate) : "",
      mrn: state ? state.mrn : "",
      rpm: state ? state.rpm : false,
      ccm: state ? state.ccm : false,
      medicareId: state ? state.medicareId : "",
      advantagePlaneId: state ? state.advantagePlaneId : 1,
      policyNumber: state ? state.policyNumber : "",
      groupNumber: state ? state.groupNumber : "",
      iGlucoseIMEI: state ? state.iGlucoseIMEI : "",
      bionimeIMEI: state ? state.bionimeIMEI : "",
      bloodSugarMin: state ? state.bloodSugarMin : "",
      bloodSugarMax: state ? state.bloodSugarMax : "",
      bodyTraceIMEI: state ? state.bodyTraceIMEI : "",
      iGlucoseBloodPressureIMEI: state ? state.iGlucoseBloodPressureIMEI : "",
      continuaBloodPressureIMEI: state ? state.continuaBloodPressureIMEI: "",
      continuaBloodSugarIMEI: state ? state.continuaBloodSugarIMEI: "",
      // bloodPressureMin: state ? state.bloodPressureMin : "",
      // bloodPressureMax: state ? state.bloodPressureMax : "",
      bodyTraceWeightScaleIMEI: state ? state.bodyTraceWeightScaleIMEI : "",
      iGlucosePulseOxIMEI: state ? state.iGlucosePulseOxIMEI : "",
      bloodSugarId: state ? state.bloodSugarId : 1,
      bloodPressureId: state ? state.bloodPressureId : 1,
      weightScaleId: state ? state.weightScaleId : 1,
      pulseOXId: state ? state.pulseOXId : 1,
      isOmronPatient: state ? state.pulseOXId : 1,
      bloodPressureSystolicMin :state ? state.bloodPressureSystolicMin : "",
      bloodPressureSystolicMax: state ? state.bloodPressureSystolicMax : "",
      bloodPressureDiastolicMin: state ? state.bloodPressureDiastolicMin : "",
      bloodPressureDiastolicMax : state ? state.bloodPressureDiastolicMin : ""
    });
  }, [state]);

  const Dropdowndata = useSelector(
    (state) => state?.PatientDropdowndetails?.DropDownpatient?.data
  );
  const PatientNameSuffix = Dropdowndata?.nameSuffixList.map((obj) => ({
    value: obj.id,
    label: obj.name,
  }));
  const Gender = Dropdowndata?.genderList.map((obj) => ({
    value: obj.id,
    label: obj.name,
  }));
  const bloodGroup = Dropdowndata?.bloodGroupList.map((obj) => ({
    value: obj.id,
    label: obj.name,
  }));
  const PlanList = Dropdowndata?.advantagePlanList.map((obj) => ({
    value: obj.id,
    label: obj.name,
  }));
  const bloodSugar = Dropdowndata?.bloodSugarList.map((obj) => ({
    value: obj.id,
    label: obj.name,
  }));
  // console.log("@@@@@@", bloodSugar);
  const bloodPressure = Dropdowndata?.bloodPressureList.map((obj) => ({
    value: obj.id,
    label: obj.name,
  }));
  const weightScale = Dropdowndata?.weightScaleList.map((obj) => ({
    value: obj.id,
    label: obj.name,
  }));
  const pulse = Dropdowndata?.pulseoxList.map((obj) => ({
    value: obj.id,
    label: obj.name,
  }));

  useEffect(() => {
    dispatch(Getpatientdropdown());
  }, []);

  const handleFieldChange = (e) => {
    e.preventDefault();
    const form = { ...inputField };

    if (
      form.firstName !== undefined &&
      form.lastName !== undefined &&
      form.phoneNumber !== undefined &&
      form.dob !== undefined &&
      form.enrollmentDate !== undefined
    ) {
      setDisabled(false);
    } else if (form?.firstName?.length === 0) {
      setDisabled(true);
    } else {
      setDisabled(true);
    }
    if (e.target.name == "rpm" || e.target.name == "ccm") {
      form[e.target.name] = e.checked;
    } else {
      form[e.target.name] = e.checked
        ? e.checked
        : e.value
          ? e.value
          : e.target.value;
    }

    // : e.target.value? e.target.value:false;
    setInputField(form);
  };

  const handleDateChange = (e) => {
    e.preventDefault();
    const form = { ...inputField };
    form[e.target.name] = e.target.value;
    setInputField(form);
  };

  const handleStepperChange = (e) => {
    setActiveIndex(e.index);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    inputField["dob"] = moment(inputField.dob).format("YYYY-MM-DD");
    inputField["enrollmentDate"] = moment(inputField.enrollmentDate).format(
      "YYYY-MM-DD"
    );

    dispatch(Addpatient(inputField)).then(async (isSuccess) => {
      if (isSuccess.payload !== undefined && isSuccess.payload.status == 1) {
        //console.log("Response from server:", isSuccess.payload); // Check if continuaIMEI is properly sent and received
        // navigate(MANAGEPATIENT_ROUTE);


        toast.success(isSuccess.payload.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        // navigate(MANAGEPATIENT_ROUTE);
        setTimeout(() => {
          if (localStorage.getItem("userType") == 3) {
            navigate(INDIVIDUALPATIENTDETAILS_ROUTE);
          } else {
            navigate(MANAGEPATIENT_ROUTE);
          }
        }, 1000);
      } else {
        // resetForm();
        toast.error(isSuccess.payload.message, {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 3000,
        });
        setTimeout(() => { }, 1000);
      }
    });
  };

  const handlePrev = (e) => {
    e.preventDefault();
    setActiveIndex(activeIndex - 1);
  };
  const [hover, setHover] = useState(false);
  const onHover = () => {
    setHover(true);
  };
  const onLeave = () => {
    setHover(false);
  };

  const handleNext = (e) => {
    e.preventDefault();
    setActiveIndex(activeIndex + 1);
  };

  const handleSendAuthorization = (e) => {
    e.preventDefault();
    setIsClicked(true);
    const params = {
      PhoneNumber: inputField.bionimeIMEI,
      CheckDB: inputField.id == 0 ? true : false,
    };
    dispatch(bionime(params))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          setAlertValue(isSuccess.payload.message);
          setStatusCode(isSuccess.payload.status);
        } else {
          setAlertValue(isSuccess.payload.message);
          setStatusCode(isSuccess.payload.status);
        }
        setIsClicked(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    let phoneNumber = e.target.value;
    if (keyCode < 48 || keyCode > 57) {
      e.preventDefault();
      return;
    }
    if (phoneNumber.length === 0) {
      phoneNumber = "+1 ";
    } else if (phoneNumber.length === 3) {
      phoneNumber = `+1 ${phoneNumber.slice(1)}`;
    } else if (phoneNumber.length === 7) {
      phoneNumber = `${phoneNumber.slice(0, 6)}-${phoneNumber.slice(6)}`;
    } else if (phoneNumber.length === 11) {
      phoneNumber = `${phoneNumber.slice(0, 10)}-${phoneNumber.slice(10)}`;
    }
    e.target.value = phoneNumber;
  };

  return (
    <div>
      <div className="patient-container">
        <div className="informationsec">
          <div className="information-text">PATIENT INFORMATION</div>
          <div className="tab-select">
            <Steps
              model={items}
              activeIndex={activeIndex}
            //onSelect={handleStepperChange}
            //readOnly={false}
            />
          </div>
          <div className="information-tab">
            <form>
              {activeIndex == 0 ? (
                <>
                  <Box>
                    <Grid
                      container
                      spacing={2}
                      className=" card-container indigo-container"
                    >
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div className="flex-1">
                          <div className="py-3" style={{ color: "#009688" }}>
                            First Name
                            {inputField?.firstName?.length === 0 ||
                              inputField?.firstName == undefined ? (
                              <span style={{ color: "red" }}>&#x2A;</span>
                            ) : (
                              <span style={{ color: "#009688" }}>&#x2A;</span>
                            )}
                          </div>
                          <InputText
                            required
                            style={{ width: "100%" }}
                            name="firstName"
                            placeholder="Enter First Name"
                            type="text"
                            value={inputField.firstName}
                            onChange={handleFieldChange}
                          />
                        </div>
                      </Grid>
                      {/* <div className="flex-1 mx-4"> */}
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div className="flex-1 ">
                          <div className="py-3" style={{ color: "#009688" }}>
                            Last Name
                            {inputField?.lastName?.length === 0 ||
                              inputField?.lastName == undefined ? (
                              <span style={{ color: "red" }}>&#x2A;</span>
                            ) : (
                              <span style={{ color: "#009688" }}>&#x2A;</span>
                            )}
                          </div>
                          <InputText
                            style={{ width: "100%" }}
                            name="lastName"
                            placeholder="Enter Last Name"
                            value={inputField.lastName}
                            onChange={handleFieldChange}
                          />
                        </div>
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div className="flex-1">
                          <div className="py-3" style={{ color: "#009688" }}>
                            Patient Name Suffix
                          </div>

                          <Dropdown
                            style={{ width: "100%" }}
                            value={inputField.nameSuffixId}
                            options={PatientNameSuffix}
                            onChange={handleFieldChange}
                            placeholder="Select a Suffix Name"
                            name="nameSuffixId"
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid
                      container
                      spacing={2}
                      className=" card-container indigo-container"
                    >
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div className="flex-1">
                          <div className="py-3" style={{ color: "#009688" }}>
                            Date of Birth
                            {inputField?.dob?.length === 0 ||
                              inputField?.dob == undefined ? (
                              <span style={{ color: "red" }}>&#x2A;</span>
                            ) : (
                              <span style={{ color: "#009688" }}>&#x2A;</span>
                            )}
                          </div>
                          <Calendar
                            value={inputField.dob}
                            onChange={handleDateChange}
                            placeholder="Enter DOB"
                            style={{ width: "100%" }}
                            readOnlyInput={true}
                            name="dob"
                            maxDate={new Date()}
                          ></Calendar>
                        </div>
                      </Grid>
                      {/* <div className="flex-1 mx-4"> */}

                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div className="flex-1 ">
                          <div className="py-3" style={{ color: "#009688" }}>
                            Email &nbsp;
                            <Tooltip
                              title={
                                <p style={{ fontSize: "12px" }}>
                                  Email is optional
                                </p>
                              }
                              placement="right-start"
                              arrow
                            >
                              <input
                                type="checkbox"
                                onChange={(e) =>
                                  setEmailDisabled(!e.target.checked)
                                }
                              />
                            </Tooltip>
                          </div>
                          <InputText
                            style={{ width: "100%" }}
                            placeholder="Enter Email"
                            name="emailId"
                            value={inputField.emailId}
                            onChange={handleFieldChange}
                            disabled={emailDisabled}
                          />
                        </div>
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div className="flex-1">
                          <div className="py-3" style={{ color: "#009688" }}>
                            Phone Number
                            {inputField?.phoneNumber?.length === 0 ||
                              inputField?.phoneNumber == undefined ? (
                              <span style={{ color: "red" }}>&#x2A;</span>
                            ) : (
                              <span style={{ color: "#009688" }}>&#x2A;</span>
                            )}
                          </div>{" "}
                          <InputText
                            style={{ width: "100%" }}
                            name="phoneNumber"
                            placeholder="Enter Phone Number"
                            value={inputField.phoneNumber}
                            onChange={handleFieldChange}
                            // mask="(999) 999-9999"
                            maxLength={15}
                            onKeyPress={handleKeyPress}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Grid
                      container
                      spacing={2}
                      className="card-container indigo-container"
                    >
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <div className="flex-1">
                          <div className="py-3" style={{ color: "#009688" }}>
                            Address
                          </div>

                          <InputText
                            style={{ width: "100%" }}
                            name="address"
                            placeholder="Enter Address"
                            value={inputField.address}
                            onChange={handleFieldChange}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Box>

                  <Box>
                    <Grid
                      container
                      spacing={2}
                      className="card-container indigo-container">
                    
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div className="flex-1">
                          <div className="py-3" style={{ color: "#009688" }}>
                            Enrollment
                            {inputField?.enrollmentDate?.length === 0 ||
                              inputField?.enrollmentDate == undefined ? (
                              <span style={{ color: "red" }}>&#x2A;</span>
                            ) : (
                              <span style={{ color: "#009688" }}>&#x2A;</span>
                            )}
                          </div>
                          <Calendar
                            value={inputField.enrollmentDate}
                            onChange={handleDateChange}
                            placeholder="Enter Enrollment"
                            style={{ width: "100%" }}
                            readOnlyInput={true}
                            name="enrollmentDate"
                            maxDate={new Date()}
                          ></Calendar>
                        </div>
                      </Grid>
                      {/* <div className="flex-1 mx-4"> */}
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div className="flex-1 ">
                          <div className="py-3" style={{ color: "#009688" }}>
                            Medical Record Number
                          </div>

                          <InputText
                            style={{ width: "100%" }}
                            name="mrn"
                            placeholder="Enter MRN"
                            value={inputField.mrn}
                            onChange={handleFieldChange}
                          />
                        </div>
                      </Grid>
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div className="flex-1">
                        

                          <div className="flex-1">
                            <div className="py-3" style={{ color: "#009688" }}>
                              Diagonsis
                            </div>
                            <InputText
                              style={{ width: "100%" }}
                              name="diseases"
                              placeholder="Enter Diagonsis"
                              type="text"
                              value={inputField.diseases}
                              onChange={handleFieldChange}
                            />
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box>
                    <Grid
                      container
                      spacing={2}
                      className=" card-container indigo-container"
                    >
                      {/* <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div className="flex-1">
                          <div className="py-3" style={{ color: "#009688" }}>
                            Disease
                          </div>
                          <InputText
                            style={{ width: "100%" }}
                            name="diseases"
                            placeholder="Enter Disease"
                            type="text"
                            value={inputField.diseases}
                            onChange={handleFieldChange}
                          />
                        </div>
                      </Grid> */}
                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div className="flex-1 ">
                          {/* <div className="flex-1 mx-4"> */}
                          <div className="py-3" style={{ color: "#009688" }}>
                            Gender
                          </div>
                          <Dropdown
                            style={{ width: "100%" }}
                            value={inputField.genderId}
                            options={Gender}
                            onChange={handleFieldChange}
                            placeholder="Select a Gender"
                            name="genderId"
                          />
                        </div>
                      </Grid>

                      <Grid item xl={4} lg={4} md={4} sm={12} xs={12}>
                        <div className="flex-1">
                          <div className="py-3" style={{ color: "#009688" }}>
                            Select Patient Service
                          </div>

                          <div className="field-checkbox enable-field">
                            <div className="field-checkbox ccm-text">
                              <Checkbox
                                inputId="rpm"
                                name="rpm"
                                onChange={handleFieldChange}
                                checked={inputField.rpm}
                              />
                              <label
                                style={{
                                  margin: "0em",
                                }}
                                htmlFor="rpm"
                              >
                                RPM
                              </label>
                            </div>
                            <div className="field-checkbox ccm-text">
                              <Checkbox
                                inputId="ccm"
                                name="ccm"
                                onChange={handleFieldChange}
                                checked={inputField.ccm}
                              />
                              <label htmlFor="ccm">CCM</label>
                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                  <div>
                    <div className="flex-1" />
                    <div className="flex-1" />
                    <div className="flex-1">
                      {/* <Button disabled={disabled} label="Next" onClick={handleNext} /> */}
                      {inputField?.firstName?.length === 0 ||
                        inputField?.firstName == undefined ||
                        inputField?.lastName?.length === 0 ||
                        inputField?.lastName == undefined ||
                        inputField?.dob?.length === 0 ||
                        inputField?.dob == undefined ||
                        inputField?.enrollmentDate?.length === 0 ||
                        inputField?.enrollmentDate == undefined ||
                        // inputField?.emailId?.length === 0 ||
                        // inputField?.emailId == undefined ||
                        inputField?.phoneNumber?.length === 0 ||
                        inputField?.phoneNumber == undefined ? (
                        // <Button disabled label="Next" onClick={handleNext} />
                        <Tooltip
                          title={
                            <p style={{ fontSize: "12px" }}>
                              Please fill required fields *
                            </p>
                          }
                          placement="right-start"
                          arrow
                        >
                          <IconButton>
                            <Button
                              disabled
                              label="Next"
                              onClick={handleNext}
                            />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <Button
                          disabled={
                            state?.firstName?.length > 0 ? "" : disabled
                          }
                          label="Next"
                          onClick={handleNext}
                        />
                      )}
                      {/* {state?.firstName.length >0 ? (<Button label="state"  />):(<Button label=" no state"  />)}                */}
                    </div>
                  </div>
                </>
              ) : activeIndex == 1 ? (
                <div>
                  <div style={{ marginTop: "10px" }}>
                    <Box>
                      <Grid
                        container
                        spacing={2}
                        className="card-container indigo-container"
                      >
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <div className="flex-1">
                            <div className="py-3" style={{ color: "#009688" }}>
                              Medicare ID
                            </div>
                            <InputText
                              style={{ width: "100%" }}
                              name="medicareId"
                              placeholder="Enter Medicare ID"
                              value={inputField.medicareId}
                              onChange={handleFieldChange}
                            />
                          </div>
                        </Grid>
                        {/* <div class="flex-1 mx-4"> */}
                        <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                          <div class="flex-1 ">
                            <div className="py-3" style={{ color: "#009688" }}>
                              Medicare Advantage Plan
                            </div>
                            <Dropdown
                              style={{ width: "100%" }}
                              value={inputField.advantagePlaneId}
                              options={PlanList}
                              onChange={handleFieldChange}
                              placeholder="Select a Medicare Plan"
                              name="advantagePlaneId"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Box>
                    <div style={{ marginTop: "20px" }}>
                      {inputField.advantagePlaneId === 2 && (
                        <div style={{ marginTop: "20px" }}>
                          <Box>
                            <Grid
                              container
                              spacing={2}
                              className="card-container indigo-container"
                            >
                              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="flex-1">
                                  <div
                                    className="py-3"
                                    style={{ color: "#009688" }}
                                  >
                                    Medicare Advantage Policy Number
                                  </div>
                                  <InputText
                                    style={{ width: "100%" }}
                                    id="numbers"
                                    keyfilter="num"
                                    name="policyNumber"
                                    value={inputField.policyNumber}
                                    onChange={handleFieldChange}
                                  />
                                </div>
                              </Grid>
                              {/* <div className="flex-1 mx-4"> */}
                              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="flex-1 ">
                                  <div
                                    className="py-3"
                                    style={{ color: "#009688" }}
                                  >
                                    Medicare Advantage Group Number
                                  </div>
                                  <InputText
                                    style={{ width: "100%" }}
                                    id="numbers"
                                    keyfilter="num"
                                    name="groupNumber"
                                    value={inputField.groupNumber}
                                    onChange={handleFieldChange}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        </div>
                      )}
                      {inputField.advantagePlaneId === 3 && (
                        <div style={{ marginTop: "20px" }}>
                          <Box>
                            <Grid
                              container
                              spacing={2}
                              className="card-container indigo-container"
                            >
                              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="flex-1">
                                  <div
                                    className="py-3"
                                    style={{ color: "#009688" }}
                                  >
                                    Medicare Advantage Policy Number
                                  </div>
                                  <InputText
                                    style={{ width: "100%" }}
                                    name="policyNumber"
                                    value={inputField.policyNumber}
                                    onChange={handleFieldChange}
                                  />
                                </div>
                              </Grid>

                              {/* <div className="flex-1 mx-4"> */}
                              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="flex-1 ">
                                  <div
                                    className="py-3"
                                    style={{ color: "#009688" }}
                                  >
                                    Medicare Advantage Group Number
                                  </div>
                                  <InputText
                                    style={{ width: "100%" }}
                                    name="groupNumber"
                                    value={inputField.groupNumber}
                                    onChange={handleFieldChange}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        </div>
                      )}
                      {inputField.advantagePlaneId === 4 && (
                        <div style={{ marginTop: "20px" }}>
                          <Box>
                            <Grid
                              container
                              spacing={2}
                              className="card-container indigo-container"
                            >
                              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="flex-1">
                                  <div
                                    className="py-3"
                                    style={{ color: "#009688" }}
                                  >
                                    Medicare Advantage Policy Number
                                  </div>
                                  <InputText
                                    style={{ width: "100%" }}
                                    name="policyNumber"
                                    value={inputField.policyNumber}
                                    onChange={handleFieldChange}
                                  />
                                </div>
                              </Grid>
                              {/* <div className="flex-1 mx-4"> */}
                              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="flex-1 ">
                                  <div
                                    className="py-3"
                                    style={{ color: "#009688" }}
                                  >
                                    Medicare Advantage Group Number
                                  </div>
                                  <InputText
                                    style={{ width: "100%" }}
                                    name="groupNumber"
                                    value={inputField.groupNumber}
                                    onChange={handleFieldChange}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        </div>
                      )}
                      {inputField.advantagePlaneId === 5 && (
                        <div style={{ marginTop: "20px" }}>
                          <Box>
                            <Grid
                              container
                              spacing={2}
                              className="card-container indigo-container"
                            >
                              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="flex-1">
                                  <div
                                    className="py-3"
                                    style={{ color: "#009688" }}
                                  >
                                    Medicare Advantage Policy Number
                                  </div>
                                  <InputText
                                    style={{ width: "100%" }}
                                    name="policyNumber"
                                    value={inputField.policyNumber}
                                    onChange={handleFieldChange}
                                  />
                                </div>
                              </Grid>
                              {/* <div className="flex-1 mx-4"> */}
                              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="flex-1 ">
                                  <div
                                    className="py-3"
                                    style={{ color: "#009688" }}
                                  >
                                    Medicare Advantage Group Number
                                  </div>
                                  <InputText
                                    style={{ width: "100%" }}
                                    name="groupNumber"
                                    value={inputField.groupNumber}
                                    onChange={handleFieldChange}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        </div>
                      )}
                      {inputField.advantagePlaneId === 6 && (
                        <div style={{ marginTop: "20px" }}>
                          <Box>
                            <Grid
                              container
                              spacing={2}
                              className="card-container indigo-container"
                            >
                              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="flex-1">
                                  <div
                                    className="py-3"
                                    style={{ color: "#009688" }}
                                  >
                                    Medicare Advantage Policy Number
                                  </div>
                                  <InputText
                                    style={{ width: "100%" }}
                                    name="policyNumber"
                                    value={inputField.policyNumber}
                                    onChange={handleFieldChange}
                                  />
                                </div>
                              </Grid>
                              {/* <div className="flex-1 mx-4"> */}
                              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="flex-1 ">
                                  <div
                                    className="py-3"
                                    style={{ color: "#009688" }}
                                  >
                                    Medicare Advantage Group Number
                                  </div>
                                  <InputText
                                    style={{ width: "100%" }}
                                    name="groupNumber"
                                    value={inputField.groupNumber}
                                    onChange={handleFieldChange}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        </div>
                      )}
                      {inputField.advantagePlaneId === 7 && (
                        <div style={{ marginTop: "20px" }}>
                          <Box>
                            <Grid
                              container
                              spacing={2}
                              className="card-container indigo-container"
                            >
                              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="flex-1">
                                  <div
                                    className="py-3"
                                    style={{ color: "#009688" }}
                                  >
                                    Medicare Advantage Policy Number
                                  </div>
                                  <InputText
                                    style={{ width: "100%" }}
                                    name="policyNumber"
                                    value={inputField.policyNumber}
                                    onChange={handleFieldChange}
                                  />
                                </div>
                              </Grid>
                              {/* <div className="flex-1 mx-4"> */}
                              <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
                                <div className="flex-1 ">
                                  <div
                                    className="py-3"
                                    style={{ color: "#009688" }}
                                  >
                                    Medicare Advantage Group Number
                                  </div>
                                  <InputText
                                    style={{ width: "100%" }}
                                    name="groupNumber"
                                    value={inputField.groupNumber}
                                    onChange={handleFieldChange}
                                  />
                                </div>
                              </Grid>
                            </Grid>
                          </Box>
                        </div>
                      )}
                      {inputField.advantagePlaneId === 8 && (
                        <div style={{ marginTop: "20px" }}>
                          <div className="flex card-container indigo-container">
                            <div className="flex-1">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Policy Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="policyNumber"
                                value={inputField.policyNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                            {/* <div className="flex-1 mx-4"> */}
                            <div className="flex-1 ">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Group Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="groupNumber"
                                value={inputField.groupNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {inputField.advantagePlaneId === 9 && (
                        <div style={{ marginTop: "20px" }}>
                          <div className="flex card-container indigo-container">
                            <div className="flex-1">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Policy Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="policyNumber"
                                value={inputField.policyNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                            {/* <div className="flex-1 mx-4"> */}
                            <div className="flex-1 ">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Group Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="groupNumber"
                                value={inputField.groupNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {inputField.advantagePlaneId === 10 && (
                        <div style={{ marginTop: "20px" }}>
                          <div className="flex card-container indigo-container">
                            <div className="flex-1">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Policy Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="policyNumber"
                                value={inputField.policyNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                            {/* <div className="flex-1 mx-4"> */}
                            <div className="flex-1 ">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Group Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="groupNumber"
                                value={inputField.groupNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {inputField.advantagePlaneId === 11 && (
                        <div style={{ marginTop: "20px" }}>
                          <div className="flex card-container indigo-container">
                            <div className="flex-1">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Policy Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="policyNumber"
                                value={inputField.policyNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                            {/* <div className="flex-1 mx-4"> */}
                            <div className="flex-1 ">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Group Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="groupNumber"
                                value={inputField.groupNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {inputField.advantagePlaneId === 11 && (
                        <div style={{ marginTop: "20px" }}>
                          <div className="flex card-container indigo-container">
                            <div className="flex-1">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Policy Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="policyNumber"
                                value={inputField.policyNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                            {/* <div className="flex-1 mx-4"> */}
                            <div className="flex-1 ">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Group Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="groupNumber"
                                value={inputField.groupNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {inputField.advantagePlaneId === 12 && (
                        <div style={{ marginTop: "20px" }}>
                          <div className="flex card-container indigo-container">
                            <div className="flex-1">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Policy Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="policyNumber"
                                value={inputField.policyNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                            {/* <div className="flex-1 mx-4"> */}
                            <div className="flex-1 ">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Group Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="groupNumber"
                                value={inputField.groupNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {inputField.advantagePlaneId === 13 && (
                        <div style={{ marginTop: "20px" }}>
                          <div className="flex card-container indigo-container">
                            <div className="flex-1">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Policy Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="policyNumber"
                                value={inputField.policyNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                            {/* <div className="flex-1 mx-4"> */}
                            <div className="flex-1 ">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Group Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="groupNumber"
                                value={inputField.groupNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {inputField.advantagePlaneId === 14 && (
                        <div style={{ marginTop: "20px" }}>
                          <div className="flex card-container indigo-container">
                            <div className="flex-1">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Policy Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="policyNumber"
                                value={inputField.policyNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                            {/* <div className="flex-1 mx-4"> */}
                            <div className="flex-1 ">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Group Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="groupNumber"
                                value={inputField.groupNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {inputField.advantagePlaneId === 15 && (
                        <div style={{ marginTop: "20px" }}>
                          <div className="flex card-container indigo-container">
                            <div className="flex-1">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Policy Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="policyNumber"
                                value={inputField.policyNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                            {/* <div className="flex-1 mx-4"> */}
                            <div className="flex-1 ">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Group Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="groupNumber"
                                value={inputField.groupNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {inputField.advantagePlaneId === 16 && (
                        <div style={{ marginTop: "20px" }}>
                          <div className="flex card-container indigo-container">
                            <div className="flex-1">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Policy Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="policyNumber"
                                value={inputField.policyNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                            {/* <div className="flex-1 mx-4"> */}
                            <div className="flex-1 ">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Group Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="groupNumber"
                                value={inputField.groupNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {inputField.advantagePlaneId === 17 && (
                        <div style={{ marginTop: "20px" }}>
                          <div className="flex card-container indigo-container">
                            <div className="flex-1">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Policy Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="policyNumber"
                                value={inputField.policyNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                            {/* <div className="flex-1 mx-4"> */}
                            <div className="flex-1 ">
                              <div
                                className="py-3"
                                style={{ color: "#009688" }}
                              >
                                Medicare Advantage Group Number
                              </div>
                              <InputText
                                style={{ width: "100%" }}
                                name="groupNumber"
                                value={inputField.groupNumber}
                                onChange={handleFieldChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="flex-1">
                      <div className="flex-1" style={{ marginTop: "1em" }}>
                        <Button label="Previous" onClick={handlePrev} />
                        <span>
                          <Button
                            style={{ marginLeft: "1em" }}
                            label="Next"
                            onClick={handleNext}
                          />
                        </span>
                      </div>
                      {/* <div className="flex-1">
                      <Button label="Next" onClick={handleNext} />
                    </div> */}
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {/* <div>3</div> */}
                  {/* <div style={{ marginTop: "10px" }}>
                    <div className="flex card-container indigo-container"> */}
                  <div>
                    <Box>
                      <Grid
                        container
                        spacing={2}
                        className="flex card-container indigo-container"
                      >
                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                          <div className="flex-1">
                            <div className="py-3" style={{ color: "#009688" }}>
                              Blood Sugar
                            </div>
                            <Dropdown
                              style={{ width: "100%" }}
                              value={inputField.bloodSugarId}
                              options={bloodSugar}
                              // onChange={(e)=>setInputField({
                              //   ...inputField,
                              //   bloodSugarId: bloodSugar[e.target.value]
                              // })}
                              onChange={handleFieldChange}
                              placeholder="Select any one"
                              name="bloodSugarId"
                            />
                          </div>
                          {/* <div className="flex-1 mx-4"> */}
                        </Grid>
                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                          <div className="flex-1 ">
                            <div className="py-3 " style={{ color: "#009688" }}>
                              Blood Pressure
                            </div>
                            <Dropdown
                              style={{ width: "100%" }}
                              value={inputField.bloodPressureId}
                              options={bloodPressure}
                              onChange={handleFieldChange}
                              placeholder="Select any one"
                              name="bloodPressureId"
                            />
                          </div>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                          <div className="flex-1 ">
                            <div className="py-3" style={{ color: "#009688" }}>
                              Weight Scale
                            </div>
                            <Dropdown
                              style={{ width: "100%" }}
                              value={inputField.weightScaleId}
                              options={weightScale}
                              onChange={handleFieldChange}
                              placeholder="Select any one"
                              name="weightScaleId"
                            />
                          </div>
                          {/* </div> */}
                        </Grid>
                      </Grid>
                    </Box>

                    {/* <div class="flex-1" /> */}
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                          <div class="flex-1">
                            <div className="py-3" style={{ color: "#009688" }}>
                              Pulse OX
                            </div>
                            <Dropdown
                              // style={{ width: "32%" }}
                              style={{ width: "100%" }}
                              value={inputField.pulseOXId}
                              options={pulse}
                              onChange={handleFieldChange}
                              placeholder="Select any one"
                              name="pulseOXId"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </Box>


                    {inputField.bloodSugarId === 2 && (
                      <div style={{ marginTop: "20px" }}>
                        <Box>
                          <Grid
                            container
                            spacing={2}
                            className=" card-container indigo-container"
                          >
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  iGlucose IMEI
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="iGlucoseIMEI"
                                  value={inputField.iGlucoseIMEI}
                                  onChange={handleFieldChange}
                                />
                              </div>
                            </Grid>
                            {/* <div className="flex-1 mx-4"> */}
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1   inputfieldaside">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Blood Sugar Min
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodSugarMin"
                                  value={inputField.bloodSugarMin}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Blood Sugar Max
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodSugarMax"
                                  value={inputField.bloodSugarMax}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                    )}

                    {inputField.bloodSugarId === 3 && (
                      <div style={{ marginTop: "20px" }}>
                        <Box>
                          <Grid
                            container
                            spacing={2}
                            className=" card-container indigo-container"
                          >
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Bionime IMEI (Phone Number Connected With
                                  Device)
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bionimeIMEI"
                                  value={inputField.bionimeIMEI}
                                  onChange={handleFieldChange}
                                  maxLength={20}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </Grid>
                            {/* <div className="flex-1 mx-4"> */}
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1   inputfieldaside">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Blood Sugar Min
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodSugarMin"
                                  value={inputField.bloodSugarMin}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Blood Sugar Max
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodSugarMax"
                                  value={inputField.bloodSugarMax}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                            <div className="flex-1" style={{ display: "flex" }}>
                              <Button
                                disabled={inputField.bionimeIMEI.length <= 0}
                                style={{ marginTop: "1em" }}
                                label="SEND AUTHORIZATION REQUEST"
                                type="submit"
                                onClick={handleSendAuthorization}
                              />
                              {statusCode == 0 || statusCode == 1 ? (
                                <div>
                                  <Alert
                                    variant={
                                      statusCode == 1 ? "success" : "danger"
                                    }
                                    style={{
                                      marginTop: "1em",
                                      marginLeft: "100px",
                                    }}
                                  >
                                    {alertValue}
                                  </Alert>
                                </div>
                              ) : (
                                <Spinner
                                  animation="border"
                                  style={{
                                    marginTop: "1em",
                                    marginLeft: "100px",
                                  }}
                                  variant={isClicked ? "success" : "light"}
                                />
                              )}
                            </div>
                          </Grid>
                        </Box>
                      </div>
                    )}

                    {inputField.bloodSugarId === 4 && (
                      <div style={{ marginTop: "20px" }}>
                        <Box>
                          <Grid
                            container
                            spacing={2}
                            className=" card-container indigo-container"
                          >
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Continua Blood Sugar IMEI
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="continuaBloodSugarIMEI"
                                  value={inputField.continuaBloodSugarIMEI}
                                  onChange={handleFieldChange}
                                  maxLength={20}
                                  onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                      event.preventDefault();
                                    }
                                  }}
                                />
                              </div>
                            </Grid>
                            {/* <div className="flex-1 mx-4"> */}
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1   inputfieldaside">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Blood Sugar Min
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodSugarMin"
                                  value={inputField.bloodSugarMin}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Blood Sugar Max
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodSugarMax"
                                  value={inputField.bloodSugarMax}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                      </div>
                    )}


                    {inputField.bloodPressureId === 2 && (
                      <div style={{ marginTop: "20px" }}>
                        <Box>
                          <Grid
                            container
                            spacing={2}
                            className=" card-container indigo-container"
                          >
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Body Trace IMEI
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bodyTraceIMEI"
                                  value={inputField.bodyTraceIMEI}
                                  onChange={handleFieldChange}
                                />
                              </div>
                            </Grid>
                            {/* <div className="flex-1 mx-4"> */}
                            {/* <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1   inputfieldaside">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Blood Pressure Min
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureMin"
                                  value={inputField.bloodPressureMin}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid> */}
                            {/* <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Blood Pressure Max
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureMax"
                                  value={inputField.bloodPressureMax}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid> */}
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Systolic Min
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureSystolicMin"
                                  value={inputField.bloodPressureSystolicMin}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1   inputfieldaside">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Systolic Max
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureSystolicMax"
                                  value={inputField.bloodPressureSystolicMax}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                        <Box>
                          <Grid
                            container
                            spacing={2}
                            className=" card-container indigo-container"
                          >
                            {/* <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Systolic Min
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="systolicMin"
                                  value={inputField.systolicMin}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1   inputfieldaside">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Systolic Max
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="systolicMax"
                                  value={inputField.systolicMax}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid> */}
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Diastolic Min
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureDiastolicMin"
                                  value={inputField.bloodPressureDiastolicMin}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                   Diastolic Max
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureDiastolicMax"
                                  value={inputField.bloodPressureDiastolicMax}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                          </Grid>
                        </Box>
                        {/* <Box>
                        <Grid container
                        spacing={2}
                        className=" card-container indigo-container">
                           <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                   Diastolic Max
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="diastolicMax"
                                  value={inputField.diastolicMax}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                        </Grid>
                        </Box> */}
                      </div>
                    )}

                    {inputField.bloodPressureId === 3 && (
                      <div style={{ marginTop: "20px" }}>
                        <div className="omron-para">
                          Please verify all patient information before
                          proceeding with Omron Authorization
                        </div>
                        <div style={{ marginTop: "10px" }}>
                          You will be able to edit patient information in Manage
                          Patient afterwards
                        </div>
                        <Box>
                          <Grid
                            container
                            spacing={2}
                            className=" card-container indigo-container"
                          >
                             {/* <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                             <div className="flex card-container indigo-container">
                          <div className="flex-1">
                            <div className="py-3" style={{ color: "#009688" }}>
                              Blood Pressure Min
                            </div>
                            <InputText
                              style={{ width: "100%" }}
                              name="bloodPressureMin"
                              value={inputField.bloodPressureMin}
                              onChange={handleFieldChange}
                              onKeyPress={(e) =>
                                !/[0-9]/.test(e.key) && e.preventDefault()
                              }
                              maxLength={3}
                            />
                          </div>
                          </div>
                             </Grid> */}
                             {/* <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                             <div className="flex-1   inputfieldaside">
                            <div className="py-3" style={{ color: "#009688" }}>
                              Blood Pressure Max
                            </div>
                            <InputText
                              style={{ width: "100%" }}
                              name="bloodPressureMax"
                              value={inputField.bloodPressureMax}
                              onChange={handleFieldChange}
                              onKeyPress={(e) =>
                                !/[0-9]/.test(e.key) && e.preventDefault()
                              }
                              maxLength={3}
                            />
                          </div>
                      
                             </Grid> */}
                             <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                             <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Systolic Min
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureSystolicMin"
                                  value={inputField.bloodPressureSystolicMin}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                             </Grid>
                             <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                             <div className="flex-1   inputfieldaside">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Systolic Max
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureSystolicMax"
                                  value={inputField.bloodPressureSystolicMax}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                             </Grid>
                             <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                             <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Diastolic Min
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureDiastolicMin"
                                  value={inputField.bloodPressureDiastolicMin}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                              </Grid>
                          </Grid>
                          </Box>
                          <Box>
                          <Grid
                            container
                            spacing={2}
                            className=" card-container indigo-container"
                          >
                            
                              <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                              <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                   Diastolic Max
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureDiastolicMax"
                                  value={inputField.bloodPressureDiastolicMax}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                              </Grid>
                             </Grid>
                             
                             </Box>
                        
                          {/* <div className="flex-1 mx-4"> */}
                         
                          </div>
                    )}
                    {inputField.bloodPressureId === 4 && (
                      <div style={{ marginTop: "20px" }}>
                        {/* <div className="flex card-container indigo-container"> */}
                        <Box>
                          <Grid
                            container
                            spacing={2}
                            className=" card-container indigo-container"
                          >
                             <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                          <div className="flex-1">
                            <div className="py-3" style={{ color: "#009688" }}>
                              iGlucose Blood Pressure IMEI
                            </div>
                            <InputText
                              style={{ width: "100%" }}
                              name="iGlucoseBloodPressureIMEI"
                              value={inputField.iGlucoseBloodPressureIMEI}
                              onChange={handleFieldChange}
                            />
                          </div>
                          </Grid>
                          {/* <div className="flex-1 mx-4"> */}
                          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                          {/* <div className="flex-1   inputfieldaside">
                            <div className="py-3" style={{ color: "#009688" }}>
                              Blood Pressure Min
                            </div>
                            <InputText
                              style={{ width: "100%" }}
                              name="bloodPressureMin"
                              value={inputField.bloodPressureMin}
                              onChange={handleFieldChange}
                              onKeyPress={(e) =>
                                !/[0-9]/.test(e.key) && e.preventDefault()
                              }
                              maxLength={3}
                            />
                          </div> */}
                           <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Systolic Min
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureSystolicMin"
                                  value={inputField.bloodPressureSystolicMin}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                          </Grid>
                          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                          {/* <div className="flex-1">
                            <div className="py-3" style={{ color: "#009688" }}>
                              Blood Pressure Max
                            </div>
                            <InputText
                              style={{ width: "100%" }}
                              name="bloodPressureMax"
                              value={inputField.bloodPressureMax}
                              onChange={handleFieldChange}
                              onKeyPress={(e) =>
                                !/[0-9]/.test(e.key) && e.preventDefault()
                              }
                              maxLength={3}
                            />
                            </div> */}
                            <div className="flex-1   inputfieldaside">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Systolic Max
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureSystolicMax"
                                  value={inputField.bloodPressureSystolicMax}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                            </Grid>
                            </Box>
                            <Box>
                          <Grid
                            container
                            spacing={2}
                            className=" card-container indigo-container"
                          >
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                            <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Diastolic Min
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureDiastolicMin"
                                  value={inputField.bloodPressureDiastolicMin}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                            <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                   Diastolic Max
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureDiastolicMax"
                                  value={inputField.bloodPressureDiastolicMax}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                            </Grid>
                            </Box>

                        </div>
                      // </div>
                    )}

                    {inputField.bloodPressureId === 5 && (
                      <div style={{ marginTop: "20px" }}>
                        {/* <div className="flex card-container indigo-container"> */}
                        <Box>
                          <Grid
                            container
                            spacing={2}
                            className=" card-container indigo-container"
                          >
                             <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                          <div className="flex-1">
                            <div className="py-3" style={{ color: "#009688" }}>
                              Continua Blood Pressure IMEI
                            </div>
                            <InputText
                              style={{ width: "100%" }}
                              name="continuaBloodPressureIMEI"
                              value={inputField.continuaBloodPressureIMEI}
                              onChange={handleFieldChange}
                            />
                          </div>
                          </Grid>
                          {/* <div className="flex-1 mx-4"> */}
                          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                          {/* <div className="flex-1   inputfieldaside">
                            <div className="py-3" style={{ color: "#009688" }}>
                              Blood Pressure Min
                            </div>
                            <InputText
                              style={{ width: "100%" }}
                              name="bloodPressureMin"
                              value={inputField.bloodPressureMin}
                              onChange={handleFieldChange}
                              onKeyPress={(e) =>
                                !/[0-9]/.test(e.key) && e.preventDefault()
                              }
                              maxLength={3}
                            />
                          </div> */}
                           <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Systolic Min
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureSystolicMin"
                                  value={inputField.bloodPressureSystolicMin}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                          </Grid>
                          <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                          {/* <div className="flex-1">
                            <div className="py-3" style={{ color: "#009688" }}>
                              Blood Pressure Max
                            </div>
                            <InputText
                              style={{ width: "100%" }}
                              name="bloodPressureMax"
                              value={inputField.bloodPressureMax}
                              onChange={handleFieldChange}
                              onKeyPress={(e) =>
                                !/[0-9]/.test(e.key) && e.preventDefault()
                              }
                              maxLength={3}
                            />
                            </div> */}
                            <div className="flex-1   inputfieldaside">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Systolic Max
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureSystolicMax"
                                  value={inputField.bloodPressureSystolicMax}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                            </Grid>
                            </Box>
                            <Box>
                          <Grid
                            container
                            spacing={2}
                            className=" card-container indigo-container"
                          >
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                            <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                  Diastolic Min
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureDiastolicMin"
                                  value={inputField.bloodPressureDiastolicMin}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                            <Grid item xl={4} lg={4} md={6} sm={12} xs={12}>
                            <div className="flex-1">
                                <div
                                  className="py-3"
                                  style={{ color: "#009688" }}
                                >
                                   Diastolic Max
                                </div>
                                <InputText
                                  style={{ width: "100%" }}
                                  name="bloodPressureDiastolicMax"
                                  value={inputField.bloodPressureDiastolicMax}
                                  onChange={handleFieldChange}
                                  onKeyPress={(e) =>
                                    !/[0-9]/.test(e.key) && e.preventDefault()
                                  }
                                  maxLength={3}
                                />
                              </div>
                            </Grid>
                            </Grid>
                            </Box>

                        </div>
                      // </div>
                    )}

                    {inputField.weightScaleId === 2 && (
                      <div style={{ marginTop: "20px" }}>
                        <div class="flex card-container indigo-container">
                          <div class="flex-1">
                            <div className="py-3" style={{ color: "#009688" }}>
                              BodyTrace Weight Scale IMEI
                            </div>
                            <InputText
                              style={{ width: "100%" }}
                              name="bodyTraceWeightScaleIMEI"
                              value={inputField.bodyTraceWeightScaleIMEI}
                              onChange={handleFieldChange}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {inputField.pulseOXId === 2 && (
                      <div style={{ marginTop: "20px" }}>
                        <div class="flex card-container indigo-container">
                          <div class="flex-1">
                            <div className="py-3" style={{ color: "#009688" }}>
                              Iglucose Pulse OX IMEI
                            </div>
                            <InputText
                              style={{ width: "100%" }}
                              name="iGlucosePulseOxIMEI"
                              value={inputField.iGlucosePulseOxIMEI}
                              onChange={handleFieldChange}
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    <div className="flex-1" style={{ marginTop: "1em" }}>
                      <Button label="Previous" onClick={handlePrev} />
                      <span>
                        <Button
                          style={{ marginLeft: "1em" }}
                          label="Submit"
                          type="submit"
                          onClick={handleFormSubmit}
                        />
                      </span>
                    </div>
                    {/* <div className="flex-1">
                    <Button label="Submit" type="submit" />
                  </div> */}
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Patients;
