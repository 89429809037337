

import React, { useState, useEffect, useRef, useCallback } from "react";
import SendIcon from '@mui/icons-material/Send';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDispatch, useSelector } from "react-redux";
import { ChatChannellist } from "../../Api_Redux/Chatlistallconversation";
import { Chatlatestdata } from "../../Api_Redux/Chatlatestconversation";
import { ChatChanneltext } from "../../Api_Redux/ChatTextmessage";
import { useLocation, useNavigate } from "react-router-dom";
import { DASHBOARD_ROUTE, INDIVIDUALPATIENTDETAILS_ROUTE } from "../../_main/routeConstants";
import { Chatdeletetext } from "../../Api_Redux/ChatTextDelete";
import InputEmoji from "react-input-emoji";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Popup from "../Popup";
import { patientId } from "../../Api_Redux/PatientidSlice";
import Box from '@mui/material/Box';
import { onMessageListener } from "../../_main/firebase";
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import "./styles.css";
import { Grid, Paper, Divider, Typography, List, ListItem, ListItemText, Button, Avatar } from "@mui/material";

// const useStyles = createTheme({
//   table: {
//     minWidth: "500px",
//   },

//   headBG: {
//     backgroundColor: "#e0e0e0",
//   },

//   messageArea: {
//     height: "55vh",
//     overflowY: "auto",
//   },
//   inputbox: {
//     width: "100%",
//     padding: "1em",
//   },
//   btn: {
//     margin: "0.5em",
//     background: "rgb(0, 150, 136)"
//   },

//   // bordercenter300: {
//   //   marginTop: "3%",
//   //   width: "100%",
//   //   textAlign: "center",
//   //   color: "black",
//   //   fontSize: "18px",
//   // },
//   chat_Content: {
//     width: "100%",
//   },

//   doctorChats: {
//     float: "left",
//     padding: "3px 17px",
//     backgroundColor: "#6c8ea496",
//     borderRadius: "20px 20px 20px 0",
//     border: "1px solid #6c8ea496",
//     maxWidth: "25rem",
//   },
//   patientChat: {
//     maxWidth: "25rem",
//     backgroundColor: "#e1ffc7",
//     float: "right",
//     padding: "3px 17px",
//     borderRadius: "16px 16px 0 16px",
//     border: "1px solid #e1ffc7",
//   },


// });

const Chat = () => {
  //navigation
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const paitient = location.state;
  // const classes = useStyles();

  const livemsg = localStorage.getItem("notificationmsg")
  //state
  const [text, setText] = useState("");
  const [show, setShow] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  // const listener = localStorage.getItem("authorName");
  const listener = localStorage.getItem("userID");
  const payload = localStorage.getItem("incomingmessage")
  const [showIcon, setShowIcon] = useState(false);
  const [incomingmessage, setIncomingmessage] = useState(localStorage.getItem("incomingmessage"))

 

  const [message, setMessage] = useState("")




  const refresh = localStorage.getItem("chatrefresh")
  const [chat, setChat] = useState(refresh)

  //useselector
  const chatlistdata = useSelector((state) => state.Chatlatestconversation?.Chatlatest?.messages);
  const carproviderdetails = useSelector((state) => state.PatientidSlice?.patientid?.data);

  const userType = localStorage.getItem("userType");
  const data = {
    id: localStorage.getItem("careproviderId")
  }
  localStorage.setItem("userId", userType == 1 ? paitient.userId : carproviderdetails.careProviderId)

  useEffect(() => {
    return () => {
      const params = {
        conversationSid: paitient.channelSid,
        limit: 10000,
      };
      localStorage.setItem("userId", null)
      setIncomingmessage(incomingmessage)
      dispatch(ChatChannellist(params))
      dispatch(Chatlatestdata(params))
    };
  }, []);

  useEffect(() =>{
    const params = {
      conversationSid: paitient.channelSid,
      limit: 10000,
    };
    dispatch(Chatlatestdata(params))
  },[])


  const listRef = useRef();
  const scrollableRef = useRef();

  const setRefs = useCallback(
    (node) => {
      listRef.current = node;
      scrollableRef.current = node;
    },
    [listRef, scrollableRef]
  );

  useEffect(() => {
    dispatch(patientId(data))
  }, [])

  useEffect(() => {

    if (userType == 1) {
      const params = {
        conversationSid: paitient.channelSid,
        limit: 10000,
      };
      if (payload != "null") {
        dispatch(Chatlatestdata(params))
        dispatch(ChatChannellist(params));
        setIncomingmessage()
      }
    } else {
      const params = {
        conversationSid: carproviderdetails.channelSid,
        limit: 10000,
      };
      if (payload != "null") {
        dispatch(Chatlatestdata(params))
        dispatch(ChatChannellist(params));
        setIncomingmessage()
      }
    }

  }, [])

  useEffect(() => {
    const scrollable = scrollableRef.current;
    if (scrollable) {
      const handleScroll = () => {
        const { scrollTop, scrollHeight, clientHeight } = scrollable;
        const isScrolledToBottom = scrollHeight - scrollTop === clientHeight;
        setShowIcon(!isScrolledToBottom);
      };
      scrollable.addEventListener('scroll', handleScroll);
      return () => scrollable.removeEventListener('scroll', handleScroll);
    }
  }, []);

  useEffect(() => {
    const scrollable = scrollableRef.current;
    if (scrollable) {
      const lastChatMessage = listRef.current.lastChild;
      lastChatMessage?.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    if (userType == 1) {
      // console.log("careproviderId");
      const params = {
        conversationSid: paitient.channelSid,
        limit: 10000,
      };

      dispatch(ChatChannellist(params));
      dispatch(Chatlatestdata(params));
    } else {
      const params = {
        conversationSid: carproviderdetails.channelSid,
        limit: 10000,
      };
      dispatch(ChatChannellist(params));
      dispatch(Chatlatestdata(params));
    }

  }, []);

  useEffect(() => {
    listRef.current?.lastChild?.scrollIntoView({ behavior: "smooth" });
  }, [chatlistdata]);

  const handleSendMessage = (emojiObject) => {
    handleClick()
  }


  const handleClickRecent = () => {
    listRef.current?.lastChild?.scrollIntoView({ behavior: "smooth" });
  }

  const handleClick = (emojiObject) => {
    setText(emojiObject);
    if (userType == 1) {
      const params = {
        conversationSid: paitient.channelSid,
        //  author: localStorage.getItem("authorName"), 
        author: localStorage.getItem("userID"),
        body: text,
        toUserId: userType == 1 ? paitient.userId : carproviderdetails.careProviderId,
        limit: 10000,
      };
      dispatch(ChatChanneltext(params));
      setTimeout(() => {
        dispatch(Chatlatestdata(params));
        dispatch(ChatChannellist(params));
      }, 1000)
      setText("");
    }
    else {
      const params = {
        conversationSid: carproviderdetails.channelSid,
        //  author: localStorage.getItem("authorName"),
        author: localStorage.getItem("userID"),
        body: text,
        toUserId: userType == 1 ? paitient.userId : carproviderdetails.careProviderId,
        limit: 10000,
      };
      dispatch(ChatChanneltext(params));
      setTimeout(() => {
        dispatch(Chatlatestdata(params));
        dispatch(ChatChannellist(params));
      }, 1000)

      setText("");
    }

    setTimeout(() => {
      listRef.current?.lastChild?.scrollIntoView({ behavior: "smooth" });
    }, 100);

  };

  const handledelete = (index) => {
    setShow(!show)
    setSelectedIndex(index)
  };
  const handleHangup = () => {
    setShow(false);
    const param = {
      conversationSid: chatlistdata[selectedIndex].conversationSid,
      pathSid: chatlistdata[selectedIndex].sid,
    };
    dispatch(Chatdeletetext(param));
    if (userType == 1) {
      const params = {
        conversationSid: paitient.channelSid,
        // conversationSid: carproviderdetails.channelSid,
        //  author: localStorage.getItem("authorName"),
        author: localStorage.getItem("userID"),
        body: text,
        limit: +20,
      };
      dispatch(ChatChannellist(params));
      dispatch(Chatlatestdata(params));
    } else {
      const params = {
        conversationSid: carproviderdetails.channelSid,
        //  author: localStorage.getItem("authorName"),
        author: localStorage.getItem("userID"),
        body: text,
        limit: +20,
      };
      dispatch(ChatChannellist(params));
      dispatch(Chatlatestdata(params));
    }
  };

  const callBackClick = (data) => {
    setShow(false);
    if (userType == 1) {
      const params = {
        conversationSid: paitient.channelSid,
        // conversationSid: carproviderdetails.channelSid,
        //  author: localStorage.getItem("authorName"),
        author: localStorage.getItem("userID"),
        body: text,
        limit: 10000,
      };
      dispatch(ChatChannellist(params));
      dispatch(Chatlatestdata(params));
    } else {
      const params = {
        conversationSid: carproviderdetails.channelSid,
        //  author: localStorage.getItem("authorName"),
        author: localStorage.getItem("userID"),
        body: text,
        limit: 10000,
      };
      dispatch(ChatChannellist(params));
      dispatch(Chatlatestdata(params));
    }
  };
  const goback = (chat) => {
    // navigate(DASHBOARD_ROUTE);
    if (userType == 1) {
      navigate(DASHBOARD_ROUTE);
    }
    else {
      navigate(INDIVIDUALPATIENTDETAILS_ROUTE);
    }
  };

  const addExtraTime = (date) => {
    let newDate = new Date(date);
    newDate.setHours(newDate.getHours() + 12);
    newDate.setMinutes(newDate.getMinutes() + 0);
    if (newDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).slice(-2) === 'AM') {
      newDate.setHours(newDate.getHours() + 12);
    }

    return newDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
  }

  return (
    <>
      {/* {show == true && <Popup handleHangup={handleHangup} callclick={callBackClick} />} */}


      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
        <Typography variant="h5" className="header-message" >
          <ArrowBackIcon onClick={goback}
            style={{ marginBottom: "1.5em", marginTop: "1em", cursor: "pointer" }}
            fontSize={"25"}
          ></ArrowBackIcon>
        </Typography>
      </Grid>
      <Box className='chat-overlay'>
        <Grid container spacing={2} component={Paper} className='chat-section'>
          <Grid item xl={5} lg={5} md={5} sm={12} xs={12} className='borderRight500'>
            {/* Chat Profile */}
            <div className='rightside-profile'>

              <Avatar
                className='bordercenter500'
                alt="BigCo Inc. logo"
                src={(userType == 1) ? paitient.avatarUrl : carproviderdetails.careProviderAvatarURL}
              />

              <div className='bordercenter400'>{(userType == 1) ? paitient.fullName : carproviderdetails.careProviderName}</div>
              <div className='profile-below'>{(userType == 1) ? paitient.emailId : ""}</div>
            </div>
          </Grid>

          <Grid item xl={7} lg={7} md={7} sm={12} xs={12} className='chating-field'>
            <>
              <ListItem>
                <ListItemText
                  primary={(userType == 1) ? paitient.phoneNumber : carproviderdetails.careProviderName}
                  align="left"
                ></ListItemText>
              </ListItem>
              <Divider />
              <div>
              {/* Chat Me */}
                <List ref={setRefs} className="messageArea">
                  {chatlistdata?.length > 0 &&
                    chatlistdata.map((chat, index) => (
                      <ListItem key={index}>
                        <Grid container key={index}>
                          <Grid item xs={12}>
                            {/* <ListItemText className=`${classes.listChat}`, */}
                            <ListItemText className={(userType === 1) ?
                              (chat.author === listener ? payload || "patientChat" : "doctorChats")
                              :
                              (chat.author === listener ? payload || "patientChat" : "doctorChats")

                            } align={(userType == 1) ? chat.author == listener ? "right" : "left" : chat.author == listener ? "right" : "left"}
                              primary={chat.body}
                              //secondary={chat.dateCreated.slice(11, 16)}
                              secondary={addExtraTime(chat.dateCreated)}
                              onClick={() => handledelete(index)}
                            ></ListItemText>

                            {/* {console.log("test===>", chat)} */}

                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                </List>
              </div>
              <Divider />
              <div className="chat_Content">
                <Grid container className='texttyping'>
                  <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
                    <div>
                      <InputEmoji
                        value={text}
                        onChange={setText}
                        cleanOnEnter
                        // onEnter={onEmojiClick} 
                        onEnter={handleSendMessage}
                        placeholder="Type a message"
                      /></div>

                  </Grid>
                  <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className='smssend-icon'>
                    <Button

                      variant="contained"
                      color="primary"
                      className="btn"
                      onClick={handleClick}
                    >
                      <SendIcon ></SendIcon>
                    </Button>
                    <Button
                      style={{
                        position: 'fixed',
                        marginTop: '-13rem',
                        background: 'rgb(0 150 136 / 30%)',
                        opacity: '0.5',
                        height: '3.5rem',
                        borderRadius: '100%',
                        width: '1rem',
                        display: showIcon ? 'block' : 'none',
                      }}
                      variant="contained"
                      color="primary"
                      className="btn"
                      onClick={handleClickRecent}
                    >
                      <KeyboardArrowDownIcon />
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Chat;

































// import React, { useState, useEffect, useRef, useCallback } from "react";
// import SendIcon from '@mui/icons-material/Send';
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { useDispatch, useSelector } from "react-redux";
// import { ChatChannellist } from "../../Api_Redux/Chatlistallconversation";
// import { Chatlatestdata } from "../../Api_Redux/Chatlatestconversation";
// import { ChatChanneltext } from "../../Api_Redux/ChatTextmessage";
// import { useLocation, useNavigate } from "react-router-dom";
// import { DASHBOARD_ROUTE, INDIVIDUALPATIENTDETAILS_ROUTE } from "../../_main/routeConstants";
// import { Chatdeletetext } from "../../Api_Redux/ChatTextDelete";
// import InputEmoji from "react-input-emoji";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import Popup from "../Popup";
// import { patientId } from "../../Api_Redux/PatientidSlice";
// import Box from '@mui/material/Box';
// import { onMessageListener } from "../../_main/firebase";
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// import "./styles.css";
// import { Grid, Paper, Divider, Typography, List, ListItem, ListItemText, Button, Avatar } from "@mui/material";
// // import { makeStyles } from '@mui/styles';



// // const useStyles = makeStyles({
// //   table: {
// //     minWidth: "500px",
// //   },

// //   headBG: {
// //     backgroundColor: "#e0e0e0",
// //   },

// //   messageArea: {
// //     height: "55vh",
// //     overflowY: "auto",
// //   },
// //   inputbox: {
// //     width: "100%",
// //     padding: "1em",
// //   },
// //   btn: {
// //     margin: "0.5em",
// //     background: "rgb(0, 150, 136)"
// //   },

// //   // bordercenter300: {
// //   //   marginTop: "3%",
// //   //   width: "100%",
// //   //   textAlign: "center",
// //   //   color: "black",
// //   //   fontSize: "18px",
// //   // },
// //   chat_Content: {
// //     width: "100%",
// //   },

// //   doctorChats: {
// //     float: "left",
// //     padding: "3px 17px",
// //     backgroundColor: "#6c8ea496",
// //     borderRadius: "20px 20px 20px 0",
// //     border: "1px solid #6c8ea496",
// //     maxWidth: "25rem",
// //   },
// //   patientChat: {
// //     maxWidth: "25rem",
// //     backgroundColor: "#e1ffc7",
// //     float: "right",
// //     padding: "3px 17px",
// //     borderRadius: "16px 16px 0 16px",
// //     border: "1px solid #e1ffc7",
// //   },


// // });

// const Chat = () => {
//   //navigation
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const navigate = useNavigate();
//   const paitient = location.state;
//   // const classes = useStyles();

//   const livemsg = localStorage.getItem("notificationmsg")
//   //state
//   const [text, setText] = useState("");
//   const [show, setShow] = useState(false);
//   const [selectedIndex, setSelectedIndex] = useState(null);
//   // const listener = localStorage.getItem("authorName");
//   const listener = localStorage.getItem("userID");
//   const payload = localStorage.getItem("incomingmessage")
//   const [showIcon, setShowIcon] = useState(false);
//   const [incomingmessage, setIncomingmessage] = useState(localStorage.getItem("incomingmessage"))

 

//   const [message, setMessage] = useState("")




//   const refresh = localStorage.getItem("chatrefresh")
//   const [chat, setChat] = useState(refresh)

//   //useselector
//   const chatlistdata = useSelector((state) => state.Chatlatestconversation?.Chatlatest?.messages);
//   const carproviderdetails = useSelector((state) => state.PatientidSlice?.patientid?.data);

//   const userType = localStorage.getItem("userType");
//   const data = {
//     id: localStorage.getItem("careproviderId")
//   }
//   localStorage.setItem("userId", userType == 1 ? paitient.userId : carproviderdetails.careProviderId)

//   useEffect(() => {
//     return () => {
//       const params = {
//         conversationSid: paitient.channelSid,
//         limit: 10000,
//       };
//       localStorage.setItem("userId", null)
//       setIncomingmessage(incomingmessage)
//       dispatch(ChatChannellist(params))
//       dispatch(Chatlatestdata(params))
//     };
//   }, []);

//   useEffect(() =>{
//     const params = {
//       conversationSid: paitient.channelSid,
//       limit: 10000,
//     };
//     dispatch(Chatlatestdata(params))
//   },[])


//   const listRef = useRef();
//   const scrollableRef = useRef();

//   const setRefs = useCallback(
//     (node) => {
//       listRef.current = node;
//       scrollableRef.current = node;
//     },
//     [listRef, scrollableRef]
//   );

//   useEffect(() => {
//     dispatch(patientId(data))
//   }, [])

//   useEffect(() => {

//     if (userType == 1) {
//       const params = {
//         conversationSid: paitient.channelSid,
//         limit: 10000,
//       };
//       if (payload != "null") {
//         dispatch(Chatlatestdata(params))
//         dispatch(ChatChannellist(params));
//         setIncomingmessage()
//       }
//     } else {
//       const params = {
//         conversationSid: carproviderdetails.channelSid,
//         limit: 10000,
//       };
//       if (payload != "null") {
//         dispatch(Chatlatestdata(params))
//         dispatch(ChatChannellist(params));
//         setIncomingmessage()
//       }
//     }

//   }, [])

//   useEffect(() => {
//     const scrollable = scrollableRef.current;
//     if (scrollable) {
//       const handleScroll = () => {
//         const { scrollTop, scrollHeight, clientHeight } = scrollable;
//         const isScrolledToBottom = scrollHeight - scrollTop === clientHeight;
//         setShowIcon(!isScrolledToBottom);
//       };
//       scrollable.addEventListener('scroll', handleScroll);
//       return () => scrollable.removeEventListener('scroll', handleScroll);
//     }
//   }, []);

//   useEffect(() => {
//     const scrollable = scrollableRef.current;
//     if (scrollable) {
//       const lastChatMessage = listRef.current.lastChild;
//       lastChatMessage?.scrollIntoView({ behavior: 'smooth' });
//     }
//   }, []);

//   useEffect(() => {
//     if (userType == 1) {
//       // console.log("careproviderId");
//       const params = {
//         conversationSid: paitient.channelSid,
//         limit: 10000,
//       };

//       dispatch(ChatChannellist(params));
//       dispatch(Chatlatestdata(params));
//     } else {
//       const params = {
//         conversationSid: carproviderdetails.channelSid,
//         limit: 10000,
//       };
//       dispatch(ChatChannellist(params));
//       dispatch(Chatlatestdata(params));
//     }

//   }, []);

//   useEffect(() => {
//     listRef.current?.lastChild?.scrollIntoView({ behavior: "smooth" });
//   }, [chatlistdata]);

//   const handleSendMessage = (emojiObject) => {
//     handleClick()
//   }


//   const handleClickRecent = () => {
//     listRef.current?.lastChild?.scrollIntoView({ behavior: "smooth" });
//   }

//   const handleClick = (emojiObject) => {
//     setText(emojiObject);
//     if (userType == 1) {
//       const params = {
//         conversationSid: paitient.channelSid,
//         //  author: localStorage.getItem("authorName"), 
//         author: localStorage.getItem("userID"),
//         body: text,
//         toUserId: userType == 1 ? paitient.userId : carproviderdetails.careProviderId,
//         limit: 10000,
//       };
//       dispatch(ChatChanneltext(params));
//       setTimeout(() => {
//         dispatch(Chatlatestdata(params));
//         dispatch(ChatChannellist(params));
//       }, 1000)
//       setText("");
//     }
//     else {
//       const params = {
//         conversationSid: carproviderdetails.channelSid,
//         //  author: localStorage.getItem("authorName"),
//         author: localStorage.getItem("userID"),
//         body: text,
//         toUserId: userType == 1 ? paitient.userId : carproviderdetails.careProviderId,
//         limit: 10000,
//       };
//       dispatch(ChatChanneltext(params));
//       setTimeout(() => {
//         dispatch(Chatlatestdata(params));
//         dispatch(ChatChannellist(params));
//       }, 1000)

//       setText("");
//     }

//     setTimeout(() => {
//       listRef.current?.lastChild?.scrollIntoView({ behavior: "smooth" });
//     }, 100);

//   };

//   const handledelete = (index) => {
//     setShow(!show)
//     setSelectedIndex(index)
//   };
//   const handleHangup = () => {
//     setShow(false);
//     const param = {
//       conversationSid: chatlistdata[selectedIndex].conversationSid,
//       pathSid: chatlistdata[selectedIndex].sid,
//     };
//     dispatch(Chatdeletetext(param));
//     if (userType == 1) {
//       const params = {
//         conversationSid: paitient.channelSid,
//         // conversationSid: carproviderdetails.channelSid,
//         //  author: localStorage.getItem("authorName"),
//         author: localStorage.getItem("userID"),
//         body: text,
//         limit: +20,
//       };
//       dispatch(ChatChannellist(params));
//       dispatch(Chatlatestdata(params));
//     } else {
//       const params = {
//         conversationSid: carproviderdetails.channelSid,
//         //  author: localStorage.getItem("authorName"),
//         author: localStorage.getItem("userID"),
//         body: text,
//         limit: +20,
//       };
//       dispatch(ChatChannellist(params));
//       dispatch(Chatlatestdata(params));
//     }
//   };

//   const callBackClick = (data) => {
//     setShow(false);
//     if (userType == 1) {
//       const params = {
//         conversationSid: paitient.channelSid,
//         // conversationSid: carproviderdetails.channelSid,
//         //  author: localStorage.getItem("authorName"),
//         author: localStorage.getItem("userID"),
//         body: text,
//         limit: 10000,
//       };
//       dispatch(ChatChannellist(params));
//       dispatch(Chatlatestdata(params));
//     } else {
//       const params = {
//         conversationSid: carproviderdetails.channelSid,
//         //  author: localStorage.getItem("authorName"),
//         author: localStorage.getItem("userID"),
//         body: text,
//         limit: 10000,
//       };
//       dispatch(ChatChannellist(params));
//       dispatch(Chatlatestdata(params));
//     }
//   };
//   const goback = (chat) => {
//     // navigate(DASHBOARD_ROUTE);
//     if (userType == 1) {
//       navigate(DASHBOARD_ROUTE);
//     }
//     else {
//       navigate(INDIVIDUALPATIENTDETAILS_ROUTE);
//     }
//   };

//   const addExtraTime = (date) => {
//     let newDate = new Date(date);
//     newDate.setHours(newDate.getHours() + 12);
//     newDate.setMinutes(newDate.getMinutes() + 0);
//     if (newDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).slice(-2) === 'AM') {
//       newDate.setHours(newDate.getHours() + 12);
//     }

//     return newDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
//   }

//   return (
//     <>
//       {/* {show == true && <Popup handleHangup={handleHangup} callclick={callBackClick} />} */}


//       <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
//         <Typography variant="h5" className="header-message" >
//           <ArrowBackIcon onClick={goback}
//             style={{ marginBottom: "1.5em", marginTop: "1em", cursor: "pointer" }}
//             fontSize={"25"}
//           ></ArrowBackIcon>
//         </Typography>
//       </Grid>
//       <Box className='chat-overlay'>
//         <Grid container spacing={2} component={Paper} className='chat-section'>
//           <Grid item xl={5} lg={5} md={5} sm={12} xs={12} className='borderRight500'>
//             {/* Chat Profile */}
//             <div className='rightside-profile'>

//               <Avatar
//                 className='bordercenter500'
//                 alt="BigCo Inc. logo"
//                 src={(userType == 1) ? paitient.avatarUrl : carproviderdetails.careProviderAvatarURL}
//               />

//               <div className='bordercenter400'>{(userType == 1) ? paitient.fullName : carproviderdetails.careProviderName}</div>
//               <div className='profile-below'>{(userType == 1) ? paitient.emailId : ""}</div>
//             </div>
//           </Grid>

//           <Grid item xl={7} lg={7} md={7} sm={12} xs={12} className='chating-field'>
//             <>
//               <ListItem>
//                 <ListItemText
//                   primary={(userType == 1) ? paitient.phoneNumber : carproviderdetails.careProviderName}
//                   align="left"
//                 ></ListItemText>
//               </ListItem>
//               <Divider />
//               <div>
//               {/* Chat Me */}
//                 {/* <List ref={setRefs} className={classes.messageArea}> */}
//                 <List ref={setRefs}>
//                   {chatlistdata?.length > 0 &&
//                     chatlistdata.map((chat, index) => (
//                       <ListItem key={index}>
//                         <Grid container key={index}>
//                           <Grid item xs={12}>
//                             {/* <ListItemText className=`${classes.listChat}`, */}
//                             {/* <ListItemText className={(userType === 1) ?
//                               (chat.author === listener ? payload || classes.patientChat : classes.doctorChats)
//                               :
//                               (chat.author === listener ? payload || classes.patientChat : classes.doctorChats)

//                             } align={(userType == 1) ? chat.author == listener ? "right" : "left" : chat.author == listener ? "right" : "left"}
//                               primary={chat.body}
//                               //secondary={chat.dateCreated.slice(11, 16)}
//                               secondary={addExtraTime(chat.dateCreated)}
//                               onClick={() => handledelete(index)}
//                             ></ListItemText> */}
//                             <ListItemText
//                               primary={chat.body}
//                               //secondary={chat.dateCreated.slice(11, 16)}
//                               secondary={addExtraTime(chat.dateCreated)}
//                               onClick={() => handledelete(index)}
//                             ></ListItemText>

//                             {/* {console.log("test===>", chat)} */}

//                           </Grid>
//                         </Grid>
//                       </ListItem>
//                     ))}
//                 </List>
//               </div>
//               <Divider />
//               <div className="chat_Content">
//                 <Grid container className='texttyping'>
//                   <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
//                     <div>
//                       <InputEmoji
//                         value={text}
//                         onChange={setText}
//                         cleanOnEnter
//                         // onEnter={onEmojiClick} 
//                         onEnter={handleSendMessage}
//                         placeholder="Type a message"
//                       /></div>

//                   </Grid>
//                   <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className='smssend-icon'>
//                     <Button

//                       variant="contained"
//                       color="primary"
//                       // className={classes.btn}
//                       onClick={handleClick}
//                     >
//                       <SendIcon ></SendIcon>
//                     </Button>
//                     <Button
//                       style={{
//                         position: 'fixed',
//                         marginTop: '-13rem',
//                         background: 'rgb(0 150 136 / 30%)',
//                         opacity: '0.5',
//                         height: '3.5rem',
//                         borderRadius: '100%',
//                         width: '1rem',
//                         display: showIcon ? 'block' : 'none',
//                       }}
//                       variant="contained"
//                       color="primary"
//                       // className={classes.btn}
//                       onClick={handleClickRecent}
//                     >
//                       <KeyboardArrowDownIcon />
//                     </Button>
//                   </Grid>
//                 </Grid>
//               </div>
//             </>
//           </Grid>
//         </Grid>
//       </Box>
//     </>
//   );
// };

// export default Chat;

































// import React, { useState, useEffect, useRef, useCallback } from "react";
// import SendIcon from '@mui/icons-material/Send';
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// import { useDispatch, useSelector } from "react-redux";
// import { ChatChannellist } from "../../Api_Redux/Chatlistallconversation";
// import { Chatlatestdata } from "../../Api_Redux/Chatlatestconversation";
// import { ChatChanneltext } from "../../Api_Redux/ChatTextmessage";
// import { useLocation, useNavigate } from "react-router-dom";
// import { DASHBOARD_ROUTE, INDIVIDUALPATIENTDETAILS_ROUTE } from "../../_main/routeConstants";
// import { Chatdeletetext } from "../../Api_Redux/ChatTextDelete";
// import InputEmoji from "react-input-emoji";
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
// import Popup from "../Popup";
// import { patientId } from "../../Api_Redux/PatientidSlice";
// import Box from '@mui/material/Box';
// import { onMessageListener } from "../../_main/firebase";
// import { getMessaging, getToken, onMessage } from 'firebase/messaging';
// import "./styles.css";
// import { Grid, Paper, createTheme, Divider, Typography, List, ListItem, ListItemText, Button, Avatar } from "@mui/material";

// const useStyles = createTheme({
//   table: {
//     minWidth: "500px",
//   },

//   headBG: {
//     backgroundColor: "#e0e0e0",
//   },

//   messageArea: {
//     height: "55vh",
//     overflowY: "auto",
//   },
//   inputbox: {
//     width: "100%",
//     padding: "1em",
//   },
//   btn: {
//     margin: "0.5em",
//     background: "rgb(0, 150, 136)"
//   },

//   // bordercenter300: {
//   //   marginTop: "3%",
//   //   width: "100%",
//   //   textAlign: "center",
//   //   color: "black",
//   //   fontSize: "18px",
//   // },
//   chat_Content: {
//     width: "100%",
//   },

//   doctorChats: {
//     float: "left",
//     padding: "3px 17px",
//     backgroundColor: "#6c8ea496",
//     borderRadius: "20px 20px 20px 0",
//     border: "1px solid #6c8ea496",
//     maxWidth: "25rem",
//   },
//   patientChat: {
//     maxWidth: "25rem",
//     backgroundColor: "#e1ffc7",
//     float: "right",
//     padding: "3px 17px",
//     borderRadius: "16px 16px 0 16px",
//     border: "1px solid #e1ffc7",
//   },


// });

// const Chat = () => {
//   //navigation
//   const dispatch = useDispatch();
//   const location = useLocation();
//   const navigate = useNavigate();
//   const paitient = location.state;
//   const classes = useStyles();

//   const livemsg = localStorage.getItem("notificationmsg")
//   //state
//   const [text, setText] = useState("");
//   const [show, setShow] = useState(false);
//   const [selectedIndex, setSelectedIndex] = useState(null);
//   // const listener = localStorage.getItem("authorName");
//   const listener = localStorage.getItem("userID");
//   const payload = localStorage.getItem("incomingmessage")
//   const [showIcon, setShowIcon] = useState(false);
//   const [incomingmessage, setIncomingmessage] = useState(localStorage.getItem("incomingmessage"))

 

//   const [message, setMessage] = useState("")




//   const refresh = localStorage.getItem("chatrefresh")
//   const [chat, setChat] = useState(refresh)

//   //useselector
//   const chatlistdata = useSelector((state) => state.Chatlatestconversation?.Chatlatest?.messages);
//   const carproviderdetails = useSelector((state) => state.PatientidSlice?.patientid?.data);

//   const userType = localStorage.getItem("userType");
//   const data = {
//     id: localStorage.getItem("careproviderId")
//   }
//   localStorage.setItem("userId", userType == 1 ? paitient.userId : carproviderdetails.careProviderId)

//   useEffect(() => {
//     return () => {
//       const params = {
//         conversationSid: paitient.channelSid,
//         limit: 10000,
//       };
//       localStorage.setItem("userId", null)
//       setIncomingmessage(incomingmessage)
//       dispatch(ChatChannellist(params))
//       dispatch(Chatlatestdata(params))
//     };
//   }, []);

//   useEffect(() =>{
//     const params = {
//       conversationSid: paitient.channelSid,
//       limit: 10000,
//     };
//     dispatch(Chatlatestdata(params))
//   },[])


//   const listRef = useRef();
//   const scrollableRef = useRef();

//   const setRefs = useCallback(
//     (node) => {
//       listRef.current = node;
//       scrollableRef.current = node;
//     },
//     [listRef, scrollableRef]
//   );

//   useEffect(() => {
//     dispatch(patientId(data))
//   }, [])

//   useEffect(() => {

//     if (userType == 1) {
//       const params = {
//         conversationSid: paitient.channelSid,
//         limit: 10000,
//       };
//       if (payload != "null") {
//         dispatch(Chatlatestdata(params))
//         dispatch(ChatChannellist(params));
//         setIncomingmessage()
//       }
//     } else {
//       const params = {
//         conversationSid: carproviderdetails.channelSid,
//         limit: 10000,
//       };
//       if (payload != "null") {
//         dispatch(Chatlatestdata(params))
//         dispatch(ChatChannellist(params));
//         setIncomingmessage()
//       }
//     }

//   }, [])

//   useEffect(() => {
//     const scrollable = scrollableRef.current;
//     if (scrollable) {
//       const handleScroll = () => {
//         const { scrollTop, scrollHeight, clientHeight } = scrollable;
//         const isScrolledToBottom = scrollHeight - scrollTop === clientHeight;
//         setShowIcon(!isScrolledToBottom);
//       };
//       scrollable.addEventListener('scroll', handleScroll);
//       return () => scrollable.removeEventListener('scroll', handleScroll);
//     }
//   }, []);

//   useEffect(() => {
//     const scrollable = scrollableRef.current;
//     if (scrollable) {
//       const lastChatMessage = listRef.current.lastChild;
//       lastChatMessage?.scrollIntoView({ behavior: 'smooth' });
//     }
//   }, []);

//   useEffect(() => {
//     if (userType == 1) {
//       // console.log("careproviderId");
//       const params = {
//         conversationSid: paitient.channelSid,
//         limit: 10000,
//       };

//       dispatch(ChatChannellist(params));
//       dispatch(Chatlatestdata(params));
//     } else {
//       const params = {
//         conversationSid: carproviderdetails.channelSid,
//         limit: 10000,
//       };
//       dispatch(ChatChannellist(params));
//       dispatch(Chatlatestdata(params));
//     }

//   }, []);

//   useEffect(() => {
//     listRef.current?.lastChild?.scrollIntoView({ behavior: "smooth" });
//   }, [chatlistdata]);

//   const handleSendMessage = (emojiObject) => {
//     handleClick()
//   }


//   const handleClickRecent = () => {
//     listRef.current?.lastChild?.scrollIntoView({ behavior: "smooth" });
//   }

//   const handleClick = (emojiObject) => {
//     setText(emojiObject);
//     if (userType == 1) {
//       const params = {
//         conversationSid: paitient.channelSid,
//         //  author: localStorage.getItem("authorName"), 
//         author: localStorage.getItem("userID"),
//         body: text,
//         toUserId: userType == 1 ? paitient.userId : carproviderdetails.careProviderId,
//         limit: 10000,
//       };
//       dispatch(ChatChanneltext(params));
//       setTimeout(() => {
//         dispatch(Chatlatestdata(params));
//         dispatch(ChatChannellist(params));
//       }, 1000)
//       setText("");
//     }
//     else {
//       const params = {
//         conversationSid: carproviderdetails.channelSid,
//         //  author: localStorage.getItem("authorName"),
//         author: localStorage.getItem("userID"),
//         body: text,
//         toUserId: userType == 1 ? paitient.userId : carproviderdetails.careProviderId,
//         limit: 10000,
//       };
//       dispatch(ChatChanneltext(params));
//       setTimeout(() => {
//         dispatch(Chatlatestdata(params));
//         dispatch(ChatChannellist(params));
//       }, 1000)

//       setText("");
//     }

//     setTimeout(() => {
//       listRef.current?.lastChild?.scrollIntoView({ behavior: "smooth" });
//     }, 100);

//   };

//   const handledelete = (index) => {
//     setShow(!show)
//     setSelectedIndex(index)
//   };
//   const handleHangup = () => {
//     setShow(false);
//     const param = {
//       conversationSid: chatlistdata[selectedIndex].conversationSid,
//       pathSid: chatlistdata[selectedIndex].sid,
//     };
//     dispatch(Chatdeletetext(param));
//     if (userType == 1) {
//       const params = {
//         conversationSid: paitient.channelSid,
//         // conversationSid: carproviderdetails.channelSid,
//         //  author: localStorage.getItem("authorName"),
//         author: localStorage.getItem("userID"),
//         body: text,
//         limit: +20,
//       };
//       dispatch(ChatChannellist(params));
//       dispatch(Chatlatestdata(params));
//     } else {
//       const params = {
//         conversationSid: carproviderdetails.channelSid,
//         //  author: localStorage.getItem("authorName"),
//         author: localStorage.getItem("userID"),
//         body: text,
//         limit: +20,
//       };
//       dispatch(ChatChannellist(params));
//       dispatch(Chatlatestdata(params));
//     }
//   };

//   const callBackClick = (data) => {
//     setShow(false);
//     if (userType == 1) {
//       const params = {
//         conversationSid: paitient.channelSid,
//         // conversationSid: carproviderdetails.channelSid,
//         //  author: localStorage.getItem("authorName"),
//         author: localStorage.getItem("userID"),
//         body: text,
//         limit: 10000,
//       };
//       dispatch(ChatChannellist(params));
//       dispatch(Chatlatestdata(params));
//     } else {
//       const params = {
//         conversationSid: carproviderdetails.channelSid,
//         //  author: localStorage.getItem("authorName"),
//         author: localStorage.getItem("userID"),
//         body: text,
//         limit: 10000,
//       };
//       dispatch(ChatChannellist(params));
//       dispatch(Chatlatestdata(params));
//     }
//   };
//   const goback = (chat) => {
//     // navigate(DASHBOARD_ROUTE);
//     if (userType == 1) {
//       navigate(DASHBOARD_ROUTE);
//     }
//     else {
//       navigate(INDIVIDUALPATIENTDETAILS_ROUTE);
//     }
//   };

//   const addExtraTime = (date) => {
//     let newDate = new Date(date);
//     newDate.setHours(newDate.getHours() + 12);
//     newDate.setMinutes(newDate.getMinutes() + 0);
//     if (newDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).slice(-2) === 'AM') {
//       newDate.setHours(newDate.getHours() + 12);
//     }

//     return newDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
//   }

//   return (
//     <>
//       {/* {show == true && <Popup handleHangup={handleHangup} callclick={callBackClick} />} */}


//       <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
//         <Typography variant="h5" className="header-message" >
//           <ArrowBackIcon onClick={goback}
//             style={{ marginBottom: "1.5em", marginTop: "1em", cursor: "pointer" }}
//             fontSize={"25"}
//           ></ArrowBackIcon>
//         </Typography>
//       </Grid>
//       <Box className='chat-overlay'>
//         <Grid container spacing={2} component={Paper} className='chat-section'>
//           <Grid item xl={5} lg={5} md={5} sm={12} xs={12} className='borderRight500'>
//             {/* Chat Profile */}
//             <div className='rightside-profile'>

//               <Avatar
//                 className='bordercenter500'
//                 alt="BigCo Inc. logo"
//                 src={(userType == 1) ? paitient.avatarUrl : carproviderdetails.careProviderAvatarURL}
//               />

//               <div className='bordercenter400'>{(userType == 1) ? paitient.fullName : carproviderdetails.careProviderName}</div>
//               <div className='profile-below'>{(userType == 1) ? paitient.emailId : ""}</div>
//             </div>
//           </Grid>

//           <Grid item xl={7} lg={7} md={7} sm={12} xs={12} className='chating-field'>
//             <>
//               <ListItem>
//                 <ListItemText
//                   primary={(userType == 1) ? paitient.phoneNumber : carproviderdetails.careProviderName}
//                   align="left"
//                 ></ListItemText>
//               </ListItem>
//               <Divider />
//               <div>
//               {/* Chat Me */}
//                 <List ref={setRefs} className={classes.messageArea}>
//                   {chatlistdata?.length > 0 &&
//                     chatlistdata.map((chat, index) => (
//                       <ListItem key={index}>
//                         <Grid container key={index}>
//                           <Grid item xs={12}>
//                             {/* <ListItemText className=`${classes.listChat}`, */}
//                             <ListItemText className={(userType === 1) ?
//                               (chat.author === listener ? payload || classes.patientChat : classes.doctorChats)
//                               :
//                               (chat.author === listener ? payload || classes.patientChat : classes.doctorChats)

//                             } align={(userType == 1) ? chat.author == listener ? "right" : "left" : chat.author == listener ? "right" : "left"}
//                               primary={chat.body}
//                               //secondary={chat.dateCreated.slice(11, 16)}
//                               secondary={addExtraTime(chat.dateCreated)}
//                               onClick={() => handledelete(index)}
//                             ></ListItemText>

//                             {/* {console.log("test===>", chat)} */}

//                           </Grid>
//                         </Grid>
//                       </ListItem>
//                     ))}
//                 </List>
//               </div>
//               <Divider />
//               <div className="chat_Content">
//                 <Grid container className='texttyping'>
//                   <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
//                     <div>
//                       <InputEmoji
//                         value={text}
//                         onChange={setText}
//                         cleanOnEnter
//                         // onEnter={onEmojiClick} 
//                         onEnter={handleSendMessage}
//                         placeholder="Type a message"
//                       /></div>

//                   </Grid>
//                   <Grid item xs={2} sm={2} md={2} lg={2} xl={2} className='smssend-icon'>
//                     <Button

//                       variant="contained"
//                       color="primary"
//                       className={classes.btn}
//                       onClick={handleClick}
//                     >
//                       <SendIcon ></SendIcon>
//                     </Button>
//                     <Button
//                       style={{
//                         position: 'fixed',
//                         marginTop: '-13rem',
//                         background: 'rgb(0 150 136 / 30%)',
//                         opacity: '0.5',
//                         height: '3.5rem',
//                         borderRadius: '100%',
//                         width: '1rem',
//                         display: showIcon ? 'block' : 'none',
//                       }}
//                       variant="contained"
//                       color="primary"
//                       className={classes.btn}
//                       onClick={handleClickRecent}
//                     >
//                       <KeyboardArrowDownIcon />
//                     </Button>
//                   </Grid>
//                 </Grid>
//               </div>
//             </>
//           </Grid>
//         </Grid>
//       </Box>
//     </>
//   );
// };

// export default Chat;
