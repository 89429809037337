import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import { GET_FILTERED_DATA } from "../_main/actiontype";
import { RefreshToken } from "./RefreshSlice";

export const getFilteredData = createAsyncThunk(
  GET_FILTERED_DATA + "/getFilteredData",
  async (params, thunkAPI) => {
    const token = localStorage.getItem("AccessToken");
    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    };

    const url = `${API_URL + "stats/filtereddata"}`;
    const response = await fetch(url, option).then((res) => {
      if (res.status == 401) {
        thunkAPI.dispatch(RefreshToken());
      }
      return res.json();
    });
    //console.log("response", response);
    return response;
  }
);

const GetFilterdDataSlice = createSlice({
  name: GET_FILTERED_DATA,
  initialState: {
    getFilterData: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFilteredData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getFilteredData.fulfilled, (state, action) => {
      state.getFilterData = action.payload;
      state.loading = false;
    });
    builder.addCase(getFilteredData.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default GetFilterdDataSlice.reducer;
