import React, { useState } from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import "./styles.css";
import { Dropdown } from "primereact/dropdown";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import { Button } from "primereact/button";
import { Tooltip } from "primereact/tooltip";
import jsPDF from "jspdf";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import DataTableBasicDemo from "../../Component/Table";
import DataTableSugarDemo from "../../Component/Table/index_sugar";
import { toast } from "react-toastify";
import { getIGlucose } from "../../Api_Redux/IGlucoseSlice";
import { Calendar } from "primereact/calendar";
import DataTablePulseDemo from "../../Component/Table/index_pulse";
import { getFilteredData } from "../../Api_Redux/GetFilteredDataSlice";

function Report() {
  const [selectName, setSelectName] = useState(null);
  const [barChartBpGlucoseData, setBarChartBpGlucoseData] = useState([]);
  const [barChartSugarGlucoseData, setBarChartSugarGlucoseData] = useState([]);
  const [pulseData, setPulseData] = useState([]);

  const [dataItemName, setDataItemName] = useState("");
  const [IGlucoseStartDate, setIGlucoseStartDate] = useState(null);
  const [IGlucoseEndDate, setIGlucoseEndDate] = useState(null);
  const [latestGlucoseValue, setLatestGlucoseValue] = useState(0);
  const [latestSystolicValue, setLatestSystolicValue] = useState(0);
  const [latestDiastolicValue, setLatestDiastolicValue] = useState(0);
  const [latestPulseOxValue, setLatestPulseOxValue] = useState(0);

  const [bpChecked, setbpChecked] = useState(false);
  const [sugarChecked, setSugarChecked] = useState(false);
  const [pulseChecked, setPulseChecked] = useState(false);

  const dispatch = useDispatch();

  const individualpatient = useSelector(
    (state) => state.PatientidSlice.patientid.data
  );

  const exportPdf = () => {
    var pdf = new jsPDF({
      orientation: "landscape",
      unit: "px",
      format: [800, 1500],
    });
    pdf.setFont("Inter-Regular", "normal");
    pdf.setFontSize(8);
    pdf.addFont("helvetica", "normal");

    pdf.html(document.getElementById("content"), {
      margin: [50, 60, 40, 20],
      callback: function () {
        const currentDate = moment(new Date()).format("MM-DD-YYYY");

        pdf.save(individualpatient?.fullName + " " + currentDate);
        window.open(pdf.output("bloburl")); // to debug
      },
    });
  };

  function convertUTCDateToLocalDate(date) {
    var dateLocal = new Date(date);
    var newDate = new Date(
      dateLocal.getTime() - dateLocal.getTimezoneOffset() * 60 * 1000
    );
    return newDate;
  }

  const handleBloodPressure = () => {
    setbpChecked(!bpChecked);
    if (individualpatient?.bloodPressure === "OmronBloodPressure") {
      setDataItemName(individualpatient?.bloodPressure);
    } else {
      setDataItemName(individualpatient?.bloodPressure);
    }
  };

  const handleBloodSugar = () => {
    setSugarChecked(!sugarChecked);
    if (individualpatient?.bloodSugar === "BionimeBloodSugar") {
      setDataItemName(individualpatient?.bloodSugar);
    } else {
      setDataItemName(individualpatient?.bloodSugar);
    }
  };

  const handlePulse = () => {
    setPulseChecked(!pulseChecked);
    if (individualpatient?.pulseOX === "iGlucosePulseOX") {
      setDataItemName(individualpatient?.pulseOX);
    }
  };

  const handleFormSubmit = () => {
    if (selectName?.name === "Custom") {
      if (
        IGlucoseStartDate != "" &&
        IGlucoseEndDate != "" &&
        IGlucoseEndDate > IGlucoseStartDate
      ) {
        const bpParams = {
          dataItemName: dataItemName,
          dataItemVersion: "1.0",
          patientId: individualpatient?.userId,
          startDate: convertUTCDateToLocalDate(IGlucoseStartDate),
          endDate: convertUTCDateToLocalDate(IGlucoseEndDate),
        };

        dispatch(getIGlucose(bpParams))
          .then((isSuccess) => {
            if (isSuccess.payload.status == 1) {
              if (
                dataItemName === "OmronBloodPressure" ||
                dataItemName === "iGlucoseBloodPressure" ||
                dataItemName === "BodyTraceBloodPressure"
              ) {
                setBarChartBpGlucoseData(
                  isSuccess.payload.data.dataItemsSavedOutputModelList
                );
              } else if (
                dataItemName === "BionimeBloodSugar" ||
                dataItemName === "iGlucoseBloodSugar"
              ) {
                setBarChartSugarGlucoseData(
                  isSuccess.payload.data.dataItemsSavedOutputModelList
                );
              } else if (dataItemName === "iGlucosePulseOX") {
                setPulseData(
                  isSuccess.payload.data.dataItemsSavedOutputModelList
                );
              }

              if (
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).bloodglucose > 0
              ) {
                setLatestGlucoseValue(
                  JSON.parse(
                    isSuccess.payload.data.dataItemsSavedOutputModelList[
                      isSuccess.payload.data.dataItemsSavedOutputModelList
                        .length - 1
                    ].dataItemData
                  ).bloodglucose
                );
              }
              if (
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).systolic > 0
              ) {
                setLatestSystolicValue(
                  JSON.parse(
                    isSuccess.payload.data.dataItemsSavedOutputModelList[
                      isSuccess.payload.data.dataItemsSavedOutputModelList
                        .length - 1
                    ].dataItemData
                  ).systolic
                );
              }
              if (
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).diastolic > 0
              ) {
                setLatestDiastolicValue(
                  JSON.parse(
                    isSuccess.payload.data.dataItemsSavedOutputModelList[
                      isSuccess.payload.data.dataItemsSavedOutputModelList
                        .length - 1
                    ].dataItemData
                  ).diastolic
                );
              }
              if (
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).Spo2 > 0
              ) {
                setLatestPulseOxValue(
                  JSON.parse(
                    isSuccess.payload.data.dataItemsSavedOutputModelList[
                      isSuccess.payload.data.dataItemsSavedOutputModelList
                        .length - 1
                    ].dataItemData
                  ).Spo2
                );
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        toast.error("Please Check startDate and EndDate", {
          position: toast.POSITION.TOP_RIGHT,
          autoClose: 1000,
        });
      }
    } else {
      const params = {
        dataItemName: dataItemName,
        dataItemFrom: "Monthly",
        dataItemVersion: "1.0",
        patientId: individualpatient?.userId,
      };
      dispatch(getFilteredData(params))
        .then((isSuccess) => {
          if (isSuccess.payload.status == 1) {
            if (
              dataItemName === "OmronBloodPressure" ||
              dataItemName === "iGlucoseBloodPressure" ||
              dataItemName === "BodyTraceBloodPressure"
            ) {
              setBarChartBpGlucoseData(
                isSuccess.payload.data.dataItemsSavedOutputModelList
              );
            } else if (
              dataItemName === "BionimeBloodSugar" ||
              dataItemName === "iGlucoseBloodSugar"
            ) {
              setBarChartSugarGlucoseData(
                isSuccess.payload.data.dataItemsSavedOutputModelList
              );
            } else if (dataItemName === "iGlucosePulseOX") {
              setPulseData(
                isSuccess.payload.data.dataItemsSavedOutputModelList
              );
            }

            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                    1
                ].dataItemData
              ).bloodglucose > 0
            ) {
              setLatestGlucoseValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).bloodglucose
              );
            }
            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                    1
                ].dataItemData
              ).systolic > 0
            ) {
              setLatestSystolicValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).systolic
              );
            }
            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                    1
                ].dataItemData
              ).diastolic > 0
            ) {
              setLatestDiastolicValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).diastolic
              );
            }
            if (
              JSON.parse(
                isSuccess.payload.data.dataItemsSavedOutputModelList[
                  isSuccess.payload.data.dataItemsSavedOutputModelList.length -
                    1
                ].dataItemData
              ).Spo2 > 0
            ) {
              setLatestPulseOxValue(
                JSON.parse(
                  isSuccess.payload.data.dataItemsSavedOutputModelList[
                    isSuccess.payload.data.dataItemsSavedOutputModelList
                      .length - 1
                  ].dataItemData
                ).Spo2
              );
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const name = [{ name: "Last 30 days" }, { name: "Custom" }];

  const onSelectNameChange = (e) => {
    setSelectName(e.value);
  };

  const date = new Date();

  date.setDate(convertUTCDateToLocalDate(date).getDate() - 30);

  return (
    <div>
      <Box>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
            <div className="report-generate">
              <h3>Patients Report </h3>
            </div>
          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={6} xs={6} className="pdfdownload">
            <Tooltip target=".export-buttons>button" position="bottom" />
            <Button
              type="button"
              icon="pi pi-file-pdf"
              onClick={exportPdf}
              className="p-button-warning"
              data-pr-tooltip="PDF"
            />
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Grid container spacing={2}>
          <Grid
            item
            xl={2}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className="report-leftside"
          >
            <div className="report-leftside-inner">
              <p>Date Range</p>
              <div>
                <Dropdown
                  className="report-select"
                  placeholder="Select Date"
                  value={selectName}
                  options={name}
                  onChange={onSelectNameChange}
                  optionLabel="name"
                />
              </div>

              {selectName?.name === "Custom" && (
                <div className="report-input">
                  <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <div className="flex-1 date-overlay">
                        <div
                          style={{ color: "#009688" }}
                          className="eventstart-date"
                        >
                          Start Date
                        </div>
                        <Calendar
                          value={IGlucoseStartDate}
                          onChange={(e) => {
                            setIGlucoseStartDate(e.value);
                          }}
                          placeholder="MM/DD/YYYY"
                          style={{ width: "100%" }}
                          maxDate={new Date()}
                          name="dob"
                        ></Calendar>
                      </div>
                    </Grid>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <div className="flex-1 date-overlay">
                        <div
                          style={{ color: "#009688" }}
                          className="eventstart-date"
                        >
                          End Date
                        </div>
                        <Calendar
                          value={IGlucoseEndDate}
                          onChange={(e) => setIGlucoseEndDate(e.value)}
                          placeholder="MM/DD/YYYY"
                          style={{ width: "100%" }}
                          name="dob"
                          maxDate={new Date()}
                        ></Calendar>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
              <div>
                <p>Vitals</p>
              </div>

              <FormGroup className="enable-section">
                {individualpatient.bloodPressure === "OmronBloodPressure" ||
                individualpatient.bloodPressure === "iGlucoseBloodPressure" ||
                individualpatient?.bloodPressure ===
                  "BodyTraceBloodPressure" ? (
                  <FormControlLabel
                    control={<Checkbox onClick={handleBloodPressure} />}
                    label="Blood Pressure"
                  />
                ) : (
                  ""
                )}
                {individualpatient.bloodSugar === "BionimeBloodSugar" ||
                individualpatient.bloodSugar === "iGlucoseBloodSugar" ? (
                  <FormControlLabel
                    control={<Checkbox onClick={handleBloodSugar} />}
                    style={{ marginTop: "0em", marginBottom: "0em" }}
                    label="Blood Sugar"
                  />
                ) : (
                  ""
                )}
                {individualpatient.pulseOX === "iGlucosePulseOX" ? (
                  <FormControlLabel
                    control={<Checkbox onClick={handlePulse} />}
                    label="Pulse OX"
                  />
                ) : (
                  ""
                )}
              </FormGroup>

              <Button onClick={handleFormSubmit}>Submit</Button>
            </div>
          </Grid>
          <Grid
            item
            xl={10}
            lg={9}
            md={9}
            sm={12}
            xs={12}
            className="reports-rightside"
          >
            <div id="content">
              <Grid container spacing={2} className="reports-inner">
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className="userdetails p-0"
                >
                  <Grid container spacing={2} className="evenspace">
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className="leftside-text">Name :</div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className="textstylecolor1">
                        {individualpatient?.fullName
                          ? individualpatient?.fullName
                          : ""}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} className="evenspace">
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className="leftside-text">Date of birth :</div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className="date-field">
                        {individualpatient?.dob
                          ? moment(individualpatient?.dob).format("MM-DD-YYYY")
                          : ""}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className="userdetails p-0"
                >
                  <Grid container spacing={2} className="middle-section">
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className="leftside-text">Gender :</div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className="date-field">
                        {individualpatient ? individualpatient.gender : " "}
                      </div>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className="leftside-text">PhoneNumber :</div>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                      <div className="date-field">
                        {individualpatient?.phoneNumber
                          ? individualpatient?.phoneNumber
                          : ""}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xl={4}
                  lg={4}
                  md={12}
                  sm={12}
                  xs={12}
                  className="p-0"
                >
                  <div className="monitoring-part">
                    {/* <div className='time-measure'></div> */}
                    <div className="measurment">
                      {/* {IGlucoseStartDate != "" && IGlucoseEndDate != "" ? ( */}
                      <Grid container spacing={2} className="evenspace">
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <div className="leftside-text">
                            Report Date Range :
                          </div>
                        </Grid>
                        <Grid item xl={6} lg={6} md={6} sm={6} xs={12}>
                          <div className="date-field">
                            {selectName?.name === "Last 30 days"
                              ? moment()
                                  .subtract(30, "days")
                                  .format("MM-DD-YYYY") +
                                " - " +
                                moment().format("MM-DD-YYYY")
                              : selectName?.name === "Custom"
                              ? moment(IGlucoseStartDate).format("MM-DD-YYYY") +
                                " - " +
                                moment(IGlucoseEndDate).format("MM-DD-YYYY")
                              : ""}
                          </div>
                        </Grid>
                      </Grid>
                      {/* ) : 
                            ""
                      } */}
                    </div>
                    <div className="measurment">
                      <Grid container spacing={2}></Grid>
                    </div>
                  </div>
                </Grid>
                {(sugarChecked &&
                  individualpatient?.bloodSugar === "BionimeBloodSugar") ||
                (sugarChecked &&
                  individualpatient?.bloodSugar === "iGlucoseBloodSugar") ? (
                  <>
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="bloodpressure-text p-0"
                    >
                      <div>Blood Sugar</div>
                    </Grid>

                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="generalpart p-0"
                    >
                      <div className="bp-graph">
                        <DataTableSugarDemo
                          barChartBpGlucoseData={barChartSugarGlucoseData}
                          responsiveLayout="scroll"
                        />
                      </div>
                    </Grid>
                  </>
                ) : (
                  ""
                )}

                {(bpChecked &&
                  individualpatient?.bloodPressure === "OmronBloodPressure") ||
                (bpChecked &&
                  individualpatient?.bloodPressure ==
                    "iGlucoseBloodPressure") ||
                (bpChecked &&
                  individualpatient?.bloodPressure ==
                    "BodyTraceBloodPressure") ? (
                  <>
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="bloodpressure-text p-0"
                    >
                      <div>Blood Pressure</div>
                    </Grid>

                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="generalpart p-0"
                    >
                      <div className="bp-graph">
                        <DataTableBasicDemo
                          barChartBpGlucoseData={barChartBpGlucoseData}
                        />
                      </div>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
                {pulseChecked &&
                individualpatient?.pulseOX === "iGlucosePulseOX" ? (
                  <>
                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="bloodpressure-text p-0"
                    >
                      <div>Pulse OX</div>
                    </Grid>

                    <Grid
                      item
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className="generalpart p-0"
                    >
                      <div className="bp-graph">
                        <DataTablePulseDemo barChartBpGlucoseData={pulseData} />
                      </div>
                    </Grid>
                  </>
                ) : (
                  ""
                )}
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default Report;
