import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { POST, API_URL } from "../_main/constants";
import { POST_ORGANIZATION } from "../_main/actiontype";
import { RefreshToken } from "./RefreshSlice";

export const orgpostUsers = createAsyncThunk(
  POST_ORGANIZATION + "/orgpostUsers",
  async (params, thunkAPI) => {
    const token = localStorage.getItem("AccessToken");
    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    };

    const url = `${API_URL + "Organization/create"}`;
    //Need to pass the URL
    const response = await fetch(url, option).then((res) => {
      if (res.status == 401) {
        thunkAPI.dispatch(RefreshToken());
      }
      return res.json();
    });
    return response;
  }
);

const OrganizationPostSlice = createSlice({
  name: POST_ORGANIZATION,
  initialState: {
    organization: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(orgpostUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(orgpostUsers.fulfilled, (state, action) => {
      state.organization = action.payload;
      state.loading = false;
    });
    builder.addCase(orgpostUsers.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default OrganizationPostSlice.reducer;
