import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import callfetch from "../_main/fetch";
import { POST_CHATTEXT } from "../_main/actiontype";

export const ChatChanneltext = createAsyncThunk(
  POST_CHATTEXT + "/ChatChanneltext",
  async (params) => {
    const token = localStorage.getItem("AccessToken");

    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(params),
    };

    const url = `${API_URL + "Conversation/conversationmessage"}`;
    const response = await callfetch(url, option);
    const value = await response;
    return value;
  }
);

const ChatchannelTextSlice = createSlice({
  name: POST_CHATTEXT,
  initialState: {
    Chattext: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(ChatChanneltext.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(ChatChanneltext.fulfilled, (state, action) => {
      state.Chattext = action.payload;
      state.loading = false;
    });
    builder.addCase(ChatChanneltext.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default ChatchannelTextSlice.reducer;
