import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import { POST_EVENTLIST } from "../_main/actiontype";
import { RefreshToken } from "./RefreshSlice";

export const geteventlist = createAsyncThunk(
  POST_EVENTLIST + "/geteventlist",
  async (thunkAPI) => {
    const token = localStorage.getItem("AccessToken");
    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const url = `${API_URL + "Event/list"}`;
    const response = await fetch(url, option).then((res) => {
      if (res.status == 401) {
        thunkAPI.dispatch(RefreshToken());
      }
      return res.json();
    });

    return response;
  }
);

const EventGetSlice = createSlice({
  name: POST_EVENTLIST,
  initialState: {
    getevent: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(geteventlist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(geteventlist.fulfilled, (state, action) => {
      state.getevent = action.payload;
      state.loading = false;
    });
    builder.addCase(geteventlist.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default EventGetSlice.reducer;
