import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, UPDATE } from "../_main/constants";
import callfetch from "../_main/fetch";
import { UPDATE_ORGANIZATION } from "../_main/actiontype";

export const orgupdateUsers = createAsyncThunk(
  UPDATE_ORGANIZATION + "/orgupdateUsers",
  async (params) => {
    const option = {
      method: UPDATE,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };

    const url = `${API_URL + ""}`;
    const response = await callfetch(url, option);
    const value = await response;
    return value;
  }
);

const OrganizationUpdateSlice = createSlice({
  name: UPDATE_ORGANIZATION,
  initialState: {
    organization: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(orgupdateUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(orgupdateUsers.fulfilled, (state, action) => {
      state.organization = action.payload;
      state.loading = false;
    });
    builder.addCase(orgupdateUsers.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default OrganizationUpdateSlice.reducer;
