import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import callfetch from "../_main/fetch";
import { POST_FORGOT } from "../_main/actiontype";

export const changeUsers = createAsyncThunk(
  POST_FORGOT + "/changeUsers",
  async (params) => {
    let query = Object.keys(params)
      .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(params[k]))
      .join("&");
    const option = {
      method: POST,
      headers: { "Content-Type": "application/json" },
    };

    const url = `${API_URL + "Auth/forgotpassword?" + query}`;
    const response = await callfetch(url, option);
    const value = await response;
    return value;
  }
);

const loginSlice = createSlice({
  name: POST_FORGOT,
  initialState: {
    change: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changeUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(changeUsers.fulfilled, (state, action) => {
      state.change = action.payload;
      state.loading = false;
    });
    builder.addCase(changeUsers.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default loginSlice.reducer;
