import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import callfetch from "../_main/fetch";
import { POST_LOGIN } from "../_main/actiontype";


export const loginUsers = createAsyncThunk(
  POST_LOGIN + "/loginUsers",
  async (params) => {
    const option = {
      method: POST,
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
    };

    const url = `${API_URL + "Auth/login"}`;
    const response = await callfetch(url, option);
    //accesstoken
    localStorage.setItem("AccessToken", response.data.accessToken);

    //refreshtoken
    localStorage.setItem("refreshtoken", response.data.refreshToken);
    //userID
    localStorage.setItem("careproviderId", response.data.id);
    //authorName
    localStorage.setItem("authorName", response.data.userName);
    //
    localStorage.setItem("userType", response.data.userType);

    localStorage.setItem("userID", response.data.id);

    const value = await response;
    return value;
  }
);

const loginSlice = createSlice({
  name: POST_LOGIN,
  initialState: {
    login: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    
    builder.addCase(loginUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(loginUsers.fulfilled, (state, action) => {
      state.login = action.payload;
      state.loading = false;
    });
    builder.addCase(loginUsers.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default loginSlice.reducer;








// import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { API_URL, POST } from "../_main/constants";
// import callfetch from "../_main/fetch";
// import { POST_LOGIN } from "../_main/actiontype";

// export const loginUsers = createAsyncThunk(
//   POST_LOGIN + "/loginUsers",
//   async (params) => {
//     const option = {
//       method: POST,
//       headers: { "Content-Type": "application/json" },
//       body: JSON.stringify(params),
//     };

//     const url = `${API_URL + "Auth/login"}`;
//     const response = await callfetch(url, option);
//     //accesstoken
//     localStorage.setItem("AccessToken", response.data.accessToken);

//     //refreshtoken
//     localStorage.setItem("refreshtoken", response.data.refreshToken);
//     //userID
//     localStorage.setItem("careproviderId", response.data.id);
//     //authorName
//     localStorage.setItem("authorName", response.data.userName);
//     //
//     localStorage.setItem("userType", response.data.userType);

//     localStorage.setItem("userID", response.data.id);

//     const value = await response;
//     return value;
//   }
// );

// const loginSlice = createSlice({
//   name: POST_LOGIN,
//   initialState: {
//     login: [],
//     loading: false,
//   },
//   reducers: {},
//   extraReducers: (builder) => {
//     builder.addCase(loginUsers.pending, (state) => {
//       state.loading = true;
//     });
//     builder.addCase(loginUsers.fulfilled, (state, action) => {
//       state.login = action.payload;
//       state.loading = false;
//     });
//     builder.addCase(loginUsers.rejected, (state) => {
//       state.loading = true;
//     });
//   },
// });

// export default loginSlice.reducer;
