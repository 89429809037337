
import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { RiDeleteBin6Line } from "react-icons/ri";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { addNotificationList } from "../../Api_Redux/NotificationListSlice";
import { notificationRead } from "../../Api_Redux/NotificationRead";
import { notificationDelete } from "../../Api_Redux/NotificationDelete";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { useLocation } from "react-router-dom";
import { UpdateDisabled } from "@mui/icons-material";
import MarkunreadIcon from "@mui/icons-material/Markunread";
import DraftsIcon from "@mui/icons-material/Drafts";
import del_icon from "../../asset/Image/del_icon.png";
import Tooltip from '@mui/material/Tooltip';
import moment from 'moment';
import { getCounts } from "../../Api_Redux/GetCountsListSlice";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DASHBOARD_ROUTE,STAFF_DASHBOARD_ROUTE,INDIVIDUALPATIENTDETAILSSS_ROUTE } from "../../_main/routeConstants";
import { useNavigate } from "react-router-dom";


function NotificationPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const screenState = useSelector((state) => state.LoginSlice?.login?.data);


  const { state } = useLocation();

  const notificationdata = useSelector(
    (state) => state.NotificationListSlice?.Register?.data?.notificationList
  );

  const [notificationList, setNotificationData] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [markasReadnotify, setMarkasreadnotify] = useState({});
  const [overallCount, setOverAllCount] = useState(0)

  useEffect(() => {
    dispatch(getCounts())
    dispatch(addNotificationList()).then((isSuccess) => {
      if (isSuccess.payload.status == 1) {
        setNotificationData(isSuccess.payload.data.notificationList);
      }
    });
  }, []);

  const getPageDetail = (data) => {
    dispatch(addNotificationList());
    setNotificationData(notificationList);
  };


  const goback = () => {
    {(screenState?.userType  == 1) ? navigate(DASHBOARD_ROUTE) : (screenState?.userType  == 2) ? navigate(STAFF_DASHBOARD_ROUTE) : navigate(INDIVIDUALPATIENTDETAILSSS_ROUTE)};
  };

  const actionBodyTemplate1 = (rowData) => {
    return (
      <React.Fragment>
        <RiDeleteBin6Line
          size={25}
          // onClick={() => openDeleteModal(rowData)}
          className="ms-delete"
        />
      </React.Fragment>
    );
  };

  const markasRead = (user) => {
    const params = {
      id: user,
    };
    dispatch(notificationRead(params))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          setNotificationData(isSuccess.payload.data.notificationList);
          setUpdated(!updated);
        } else {
        }
      })
      .catch((error) => {
        alert(error);
      })
    dispatch(getCounts())
    .then((isSucess) => {
      if (isSucess.payload.status == 0) {
        setOverAllCount(isSucess.payload.data.notificationCount)
      }
    })
    .catch((err) => {
      console.log(err)
    })


  };

  const notifyDelete = (user) => {
    const params = {
      id: user,
    };
    dispatch(notificationDelete(params))
      .then((isSuccess) => {
        if (isSuccess.payload.status == 1) {
          setNotificationData(isSuccess.payload.data.notificationList);
          setUpdated(!updated);
        } else {
        }
      })
      .catch((error) => {
        alert(error);
      });
    dispatch(getCounts())

  };

  return (
    <div>
      <ArrowBackIcon
        style={{ cursor: "pointer", fontSize: "25px" }}
        onClick={goback}
      ></ArrowBackIcon>
      <div className="container" style={{ fontSize: "24px" }}>
        Messages
      </div>
      {notificationList?.length === 0 ? (
        <div class="container" style={{ opacity: 0.5 }}>
          <div style={{ display: "flex", gap: "0.2rem", justifyContent: "space-between", width: "100%" }}>
            No result found
          </div>
        </div>
      ) : ""
      }
      <div>
        {notificationList?.map((user, index) => {

          return (
            <>
              {user.isRead === true ? (
                <div class="container" style={{ opacity: 0.5 }}>
                  <div key={index} className='notification-popup msg-notification'>
                    <div class="inactiveIcon">
                      <FiberManualRecordIcon />
                    </div>
                    <div className='notification-images'>
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/d/df/Antu_preferences-desktop-notification-bell.svg"
                        alt="Avatar"
                        className='notify-section'
                      />
                    </div>
                    <div className='mobileview-notify'>
                      <div className="notificationHeader">{user.content}</div>
                      <div className="notificationDate">{moment(user.createdDate).format("DD-MM-YYYY HH:MM:SS a")}</div>
                      <div className="notificationContent"> {user.otherContent} </div>
                    </div>

                    <div className="notificationView">
                      <DraftsIcon
                        style={{ color: "#009688" }}
                        onClick={(e) => markasRead(user.id)}
                      />
                    </div>
                    <div className="notificationDelete">
                      <Tooltip title={<p style={{ fontSize: "12px" }}>Click to delete the message</p>} placement="top-start" arrow>

                        <img
                          style={{ cursor: "pointer" }}
                          src={del_icon}
                          onClick={() => notifyDelete(user.id)}
                          alt="delete"
                        />
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ) : (
                <div class="container">
                  <div key={index} className='notification-popup msg-notification'>
                    <div class="activeIcon">
                      <FiberManualRecordIcon />
                    </div>
                    <div className='notification-images'>
                      <img
                        src="https://upload.wikimedia.org/wikipedia/commons/d/df/Antu_preferences-desktop-notification-bell.svg"
                        alt="Avatar"
                        className='notify-section'
                      />
                    </div>
                    <div className='mobileview-notify'>
                      <div className="notificationHeader">{user.content}</div>
                      <div className="notificationDate">{moment(user.createdDate).format("DD-MM-YYYY HH:MM:SS a")}</div>
                      <div className="notificationContent">{user.otherContent}</div>
                    </div>
                    <div className="notificationView">
                      <Tooltip title={<p style={{ fontSize: "12px" }}>Click to read</p>} placement="top-start" arrow>
                        <MarkunreadIcon
                          style={{ color: "#009688" }}
                          onClick={(e) => markasRead(user.id)}
                        />
                      </Tooltip>


                    </div>
                    <div className="notificationDelete">
                      <Tooltip title={<p style={{ fontSize: "12px" }}>Click to delete the message</p>} placement="top-start" arrow>

                        <img
                          style={{ cursor: "pointer" }}
                          src={del_icon}
                          onClick={() => notifyDelete(user.id)}
                          alt="delete"
                        />
                      </Tooltip>

                    </div>
                  </div>
                </div>
              )}
            </>
          );
        })}
      </div>
    </div>
  );
}

export default NotificationPage;
