import React, { useEffect, useRef, useState } from "react";
import { Button } from "primereact/button";
import Footer from "../../Component/Footer";
import { Formik, Form, Field } from "formik";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import { Card } from "primereact/card";
import { InputMask } from "primereact/inputmask";
import { TextField } from "formik-material-ui";
import { NOT_EMPTY_REGEX, PHONE_REGEX } from "../../_main/constants";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  EMPTY_USERTYPE,
  EMPTY_USERNAME,
  EMPTY_PASSWORD,
  EMPTY_FIRSTNAME,
  EMPTY_LASTNAME,
  EMPTY_EMAIL,
  EMPTY_PHONE,
  EMPTY_NPINUMBER,
} from "../../_main/errorConstants";
import "./styles.css";
import { RegiStaff } from "../../Api_Redux/AddStaffSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { MANAGESTAFF_ROUTE } from "../../_main/routeConstants";
import { useNavigate } from "react-router-dom";
import ImageUploading from "react-images-uploading";
import pcamera from "../../asset/Image/pcamera.svg";

function AddStaff() {
  //navigation
  const ref = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initial = {
    // usertype: "",
    username: "",
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    phoneNumber: "",
    npinumber: "",
    createdBy: localStorage.getItem("careproviderId"),
  };
  //state
  const [initialVal, setInitialValues] = useState(initial);
  const [password, setPassword] = useState();
  const [images, setImages] = React.useState([]);
  const [val4, setVal4] = useState();
  const [usernames, setUsernames] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const uniqueId = Math.random().toString(36).substr(2, 5);

  const uniqueId1 = Math.floor(Math.random() * 999);

  let userName = "";

  const generateuserpassword = (data) => {
    let pwd = "#Pass" + data + uniqueId;
    pwd = pwd.replaceAll(" ", "");
    setPassword(pwd);
  };

  const maxNumber = 69;
  const onChange = (imageList, addUpdateIndex) => {
    setImages(imageList);
  };

  const generateusername = (field, data) => {
    if (field == "firstname") {
      setFirstName(data);
      userName = data + "_" + uniqueId1;
      setUsernames(userName);
    }
    // if (field == "lastname") {
    //   setLastName(data);
    //   userName = firstName + "_" + uniqueId1;
    //   setUsernames(userName);
    // }
    userName = userName.replaceAll(" ", "");
  };

  const handleSubmit = (form, { resetForm }) => {
    form.username = usernames;
    form.password = password;
    // console.log("name",name)
    dispatch(RegiStaff(form))
      .then((isSuccess) => {
        if (isSuccess.payload !== undefined) {
          resetForm();
          toast.success(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
          navigate(MANAGESTAFF_ROUTE);
        } else {
          resetForm();
          toast.error(isSuccess.payload.message, {
            position: toast.POSITION.TOP_RIGHT,
            autoClose: 3000,
          });
        }
      })
      .catch(({ error }) => {
        console.log(error);
      });
  };

  const handleKeyPress = (e) => {
    const keyCode = e.keyCode || e.which;
    let phoneNumber = e.target.value;
    if (keyCode < 48 || keyCode > 57) {
      e.preventDefault();
      return;
    }
    if (phoneNumber.length === 15) {
      e.preventDefault();
      return;
    }
    if (phoneNumber.length === 0) {
      phoneNumber = "+1 ";
    } else if (phoneNumber.length === 3) {
      phoneNumber = `+1 ${phoneNumber.slice(1)}`;
    } else if (phoneNumber.length === 7) {
      phoneNumber = `${phoneNumber.slice(0, 6)}-${phoneNumber.slice(6)}`;
    } else if (phoneNumber.length === 11) {
      phoneNumber = `${phoneNumber.slice(0, 10)}-${phoneNumber.slice(10)}`;
    }
    e.target.value = phoneNumber;
  };

  return (
    <div>
      <div className="manage-staff">Manage Staff / Create New Staff Member</div>

      <div className="inner-form">
        <Formik
          initialValues={initialVal}
          onSubmit={handleSubmit}
          enableReinitialize
          validate={(values) => {
            const errors = {};
            if (!values.firstname) {
              errors.firstname = " FirstName Required";
            } else if (!/^[0-9a-zA-Z_ ]+$/i.test(values.firstname)) {
              errors.firstname = "Invalid FirstName";
            }
            if (!values.lastname) {
              errors.lastname = " LastName Required";
            } else if (!/^[0-9a-zA-Z_ ]+$/i.test(values.lastname)) {
              errors.lastname = "Invalid LastName";
            }
            if (!values.email) {
              errors.email = " Email Required";
            } else if (
              !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
            ) {
              errors.email = "Invalid Email";
            }
            if (!values.phoneNumber) {
              errors.phoneNumber = " Phone Number Required";
            }
            //  else if (!/^[0-9+]{13}$/i.test(values.phoneNumber)) {
            //   errors.phoneNumber = 'Invalid Phone Number';
            // }
            return errors;
          }}
        >
          {({ props }) => (
            <Form
              onChange={(e) => {
                if (e.target.name == "firstname") {
                  generateuserpassword(e.target.value);
                  generateusername(e.target.name, e.target.value);
                }
                if (e.target.name == "lastname") {
                  generateusername(e.target.name, e.target.value);
                }
              }}
            >
              <div>
                {/* <div className="grid"> */}
                <Box className="addstafe-section">
                  <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                      {/* <div className="cfield-container"> */}
                      <div>
                        <label className="aslabel">User Type</label>
                      </div>

                      <Field
                        component={TextField}
                        className="asinput-container"
                        name="usertype"
                        type="text"
                        placeholder="Enter User Type"
                        variant="outlined"
                        size="small"
                        disabled={true}
                        value={"Staff"}
                      />

                      {/* </div> */}
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                      {/* <div className="cfield-container"> */}
                      <div>
                        <label className="aslabel">First Name</label>
                      </div>

                      <Field
                        component={TextField}
                        className="asinput-container"
                        type="text"
                        name="firstname"
                        variant="outlined"
                        placeholder="Enter First Name"
                        size="small"
                        disabled={false}

                        // onChange={handleKeyPress(firstname)}
                      />
                      {/* <TextField /> */}

                      {/* </div> */}
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                      {/* <div className="cfield-container"> */}
                      <div>
                        <label
                          className="aslabel"
                          style={{ marginbottom: "1em" }}
                        >
                          Last Name
                        </label>
                      </div>

                      <Field
                        component={TextField}
                        className="asinput-container"
                        type="text"
                        name="lastname"
                        variant="outlined"
                        size="small"
                        placeholder="Enter Last Name"
                        disabled={false}
                      />

                      {/* </div> */}
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                      {/* <div className="cfield-container"> */}
                      <div>
                        <label className="aslabel">Email ID</label>
                      </div>

                      <Field
                        component={TextField}
                        className="asinput-container"
                        type="text"
                        name="email"
                        variant="outlined"
                        size="small"
                        placeholder="Enter Email ID"
                        disabled={false}
                      />

                      {/* </div> */}
                    </Grid>
                  </Grid>
                </Box>
                {/* </div> */}
                {/* <div className="grid"> */}
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                      {/* <div className="cfield-container"> */}
                      <div>
                        <label className="aslabel">User Name</label>
                      </div>

                      <Field
                        component={TextField}
                        className="asinput-container"
                        type="text"
                        name="username"
                        placeholder="Enter User Name"
                        variant="outlined"
                        size="small"
                        disabled={true}
                        value={usernames}
                      />

                      {/* </div> */}
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                      {/* <div className="cfield-container"> */}
                      <div>
                        <label className="aslabel">Password</label>
                      </div>

                      <Field
                        component={TextField}
                        className="asinput-container"
                        name="password"
                        variant="outlined"
                        placeholder="Enter Password"
                        size="small"
                        disabled={true}
                        value={password}
                      />

                      {/* </div> */}
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                      {/* <div className="cfield-container"> */}
                      <div>
                        <label className="aslabel">
                          Phone Number &nbsp;
                          {/* <span className="as-pformat">
                                  format: (+4445556666)
                                </span> */}
                        </label>
                      </div>

                      <Field
                        component={TextField}
                        className="asinput-container"
                        // type="number"
                        name="phoneNumber"
                        variant="outlined"
                        size="small"
                        placeholder="Enter Phone Number"
                        disabled={false}
                        type="text"
                        onKeyPress={(e) => {
                          if (!/[0-9+]/.test(e.key)) {
                            e.preventDefault();
                          }
                          handleKeyPress(e);
                        }}
                        maxLength={15}
                      />

                      {/* </div> */}
                    </Grid>
                    <Grid item xl={3} lg={3} md={6} sm={12} xs={12}>
                      {/* <div className="cfield-container"> */}
                      <div>
                        <label className="aslabel">NPI Number</label>
                      </div>

                      <Field
                        component={TextField}
                        className="asinput-container"
                        // type="number"
                        name="npinumber"
                        variant="outlined"
                        size="small"
                        placeholder="Enter NPI Number"
                        disabled={false}
                        type="text"
                        onKeyPress={(e) =>
                          !/[0-9]/.test(e.key) && e.preventDefault()
                        }
                      />

                      {/* </div> */}
                    </Grid>
                  </Grid>
                </Box>
                {/* </div> */}
                <div className="as-button">
                  <Button
                    className="ebutton"
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={false}
                  >
                    SAVE
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
        <ToastContainer />
      </div>
    </div>
  );
}

export default AddStaff;
