import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import React, { useState } from "react";
import { Chart } from "primereact/chart";
import moment from "moment";

const LineChartDemo = React.memo((props) => {
  // console.log("testing", props);
  const [createdDate, setCreatedDAte] = useState([]);
  const [systolicData, setSystolicData] = useState([]);
  const [diastolicData, setDiastolicData] = useState([]);
  const [pulseData, setPulseData] = useState([]);

  
  if (props?.barChartBpGlucoseData?.length == 0) {
    createdDate.splice(0, createdDate.length);
  }

  if (props?.barChartBpGlucoseData?.length > 0) {
    createdDate.splice(0, createdDate.length);
    props?.barChartBpGlucoseData?.forEach((element, curr) => {
      createdDate.push(moment(element.createdOn).format("MM-DD-YYYY hh:mm A"));
      systolicData.push(JSON.parse(element.dataItemData).systolic);
      diastolicData.push(JSON.parse(element.dataItemData).diastolic);
      pulseData.push(JSON.parse(element.dataItemData).pulse);
    });
  }

  const [basicData] = useState({
    labels: createdDate,
    datasets: [
      {
        label: "SystolicData",
        data: systolicData,
        fill: false,
        borderColor: "#FF0000",
        tension: 0.4,
      },
      {
        label: "DiastolicData",
        data: diastolicData,
        fill: false,
        borderColor: "#42A5F5",
        tension: 0.4,
      },
      {
        label: "Pulse",
        data: pulseData,
        fill: false,
        borderColor: "#FFD700",
        tension: 0.4,
      },
    ],
  });

  const getLightTheme = () => {
    let basicOptions = {
      maintainAspectRatio: false,
      aspectRatio: 0.6,
      plugins: {
        legend: {
          labels: {
            color: "#495057",
          },
        },
      },
      scales: {
        x: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
        y: {
          ticks: {
            color: "#495057",
          },
          grid: {
            color: "#ebedef",
          },
        },
      },
    };

    return {
      basicOptions,
    };
  };

  const { basicOptions } = getLightTheme();

  return (
    <div>
      <div className="cardtable">
        <Chart type="line" data={basicData} options={basicOptions} />
      </div>
    </div>
  );
});

export default LineChartDemo;
