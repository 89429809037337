import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import React, { useState, useEffect } from "react";
import { Paginator } from "primereact/paginator";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { patientList } from "../../Api_Redux/PatientListSlice";
import { staffList } from "../../Api_Redux/StaffListSlice";
import { Dropdown } from "primereact/dropdown";

function PaginatorDemo(props) {
  const dispatch = useDispatch();

  const [customFirst1, setCustomFirst1] = useState(0);
  const [customRows1, setCustomRows1] = useState(() => {
    const storedValue = localStorage.getItem("customRows1");
    return storedValue ? parseInt(storedValue) : 7;
  });
  const [current_page, setCurrentPage] = useState(() => {
    const storedValue = localStorage.getItem("current_page");
    return storedValue ? parseInt(storedValue) : 0;
  });

  
  useEffect(() => {
    localStorage.setItem("customRows1", customRows1.toString());
  }, [customRows1]);

  useEffect(() => {
    localStorage.setItem("current_page", current_page.toString());
  }, [current_page]);

  const staffTotalCount = props.staffDataLength;
  const patientTotalCount = props.patientDataLength;

  const onCustomPageChange1 = (event) => {
    setCustomFirst1(event.first);
    setCustomRows1(event.rows);
    setCurrentPage(event.page+1)

    const patientParams = {
      pageNo: event.page + 1,
      pageSize: event.rows ? event.rows : 5,
      search: "",
    };
    if (patientTotalCount !== undefined) {
      dispatch(patientList(patientParams));
    } else {
      dispatch(staffList(patientParams));
    }
  };

  const template1 = {
    layout:
      "PrevPageLink PageLinks NextPageLink RowsPerPageDropdown CurrentPageReport",
    PrevPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-2">Pre</span>
        </button>
      );
    },
    RowsPerPageDropdown: (options) => {
      const dropdownOptions = [
       
   
        { label: 5, value: 5 },
        { label: 10, value: 10 },
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: "100 and above", value: 5000 }
      ];

      return (
        <React.Fragment>
       
          <Dropdown
            
            value={options.value}
         
            options={dropdownOptions}
            onChange={options.onChange}
            placeholder="Choose"
          />
        </React.Fragment>
      );
    },
    NextPageLink: (options) => {
      return (
        <button
          type="button"
          className={options.className}
          onClick={options.onClick}
          disabled={options.disabled}
        >
          <span className="p-2">Next</span>
        </button>
      );
    },
  };
  return (
    <div className="paginator">
      <Paginator
        template={template1}
        first={customFirst1}
        rows={customRows1}
        totalRecords={
          patientTotalCount !== undefined ? patientTotalCount : staffTotalCount
        }
        onPageChange={onCustomPageChange1}
        // rowsPerPageOptions={[5, 10, 20, 50]}
      ></Paginator>
    </div>
  );
}
export default PaginatorDemo;
