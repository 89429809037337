

import React from 'react';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import patientlist from '../../asset/Image/patientlist.png';
import "./styles.css";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';

function Patientrequest() {
  return (
    <div>
      <div className='videochat-text'>
        <p>select one or more Care  providers that you would like to video chat with.</p>
      </div>

      
          
            <div className='careprovider'>
              
                <div className='careprovider-sec'>
                  <p>Any Availabe Care Provider Any Staff</p>
                </div>
                <div  className=' selectbox'>
                <Checkbox disabled />
                </div>
              
            </div>
            
  
      <div className='videochat-text'>
        <p>Select a Time that is convenient for you</p>
      </div>
      <div className='Appointment-section'>
        <p>By clicking the Next Available Appointment” button, you with to Video Chat with you Care Provider as soon as possible, at their next available appointment.
          Generally, the Next Available Appointment is within business hours, but it could be any time of day.</p>
        <p>You will be notified of this appointment, and will have the opportunity to Accept or Decline.
        </p>
        <button className='next-appoint'>NEXT AVAILABLE  APPOINTMENT</button>
      </div>

      <div className='condition'>
        <p>-OR-</p>
      </div>
      <Box>
        <Grid container spacing={2}>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className='selectday'>
              <p>SELECT DAY AND TIMES THAT ARE CONVENIENT FOR YOU</p>
            </div>

          </Grid>
          <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
            <div className='convenient'>
              <button >MY CONVENIENT TIMES</button>
            </div>

          </Grid>
        </Grid>
      </Box>
      <TableContainer className='status-table'>
        <Table className='table-head'>
          <TableHead className='status-header'>
            <TableRow>
              <TableCell>DAYS</TableCell>
              <TableCell>MORNING</TableCell>
              <TableCell>AFTERNOON</TableCell>
              <TableCell>EVENING</TableCell>
            </TableRow>
          </TableHead>
          <TableBody className='table-datas'>
            <TableCell>Sunday</TableCell>
            <TableCell>  <Checkbox disabled /></TableCell>
            <TableCell> <Checkbox disabled /></TableCell>
            <TableCell> <Checkbox disabled /></TableCell>
            
          </TableBody>
          <TableBody className='table-datas'>
            <TableCell>Monday</TableCell>
            <TableCell> <Checkbox defaultChecked  /></TableCell>
            <TableCell> <Checkbox defaultChecked  /></TableCell>
            <TableCell> <Checkbox defaultChecked  /></TableCell>
           
          </TableBody>
          <TableBody className='table-datas'>
            <TableCell>Tuesday</TableCell>
            <TableCell> <Checkbox disabled /></TableCell>
            <TableCell> <Checkbox disabled /></TableCell>
            <TableCell> <Checkbox disabled /></TableCell>
          
          </TableBody>
          <TableBody className='table-datas'>
            <TableCell>Wednesday</TableCell>
            <TableCell> <Checkbox disabled /></TableCell>
            <TableCell> <Checkbox disabled /></TableCell>
            <TableCell> <Checkbox disabled /></TableCell>
          
          </TableBody>
          <TableBody className='table-datas'>
            <TableCell>thursday</TableCell>
            <TableCell> <Checkbox disabled /></TableCell>
            <TableCell> <Checkbox disabled /></TableCell>
            <TableCell> <Checkbox disabled /></TableCell>
          
          </TableBody>
          <TableBody className='table-datas'>
            <TableCell>Friday</TableCell>
            <TableCell> <Checkbox defaultChecked  /></TableCell>
            <TableCell> <Checkbox defaultChecked  /></TableCell>
            <TableCell> <Checkbox disabled /></TableCell>
           
          </TableBody>
          <TableBody className='table-datas'>
            <TableCell>Saturday</TableCell>
            <TableCell> <Checkbox disabled /></TableCell>
            <TableCell> <Checkbox disabled /></TableCell>
            <TableCell> <Checkbox disabled /></TableCell>
           
          </TableBody>
        </Table>
      </TableContainer>
    </div>


  )
}

export default Patientrequest