import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { API_URL, GET } from '../_main/constants';
import {GET_DROPDOWNMEMBERSHIPTYPE} from '../_main/actiontype';
import { RefreshToken } from './RefreshSlice';

export const GetMembershipTypedropdown = createAsyncThunk(GET_DROPDOWNMEMBERSHIPTYPE+'/GetMembershipTypedropdown', async (params,thunkAPI) => {

  const token = localStorage.getItem("AccessToken")

  const option = {
    method: GET,
    headers: {
      'Authorization':`Bearer ${token}`,
       'Content-Type': 'application/json',  
  },
  };

  const url = `${API_URL+'Organization/getdropdownitems'}`;    
  const response = await fetch(url, option)
  .then((res) => {
    if (res.status == 401) {
      thunkAPI.dispatch(RefreshToken())
    }
    return res.json();
  })
return response;
});


const MembershipTypeDropdownSlice = createSlice({
  name:GET_DROPDOWNMEMBERSHIPTYPE,
  initialState: {
    DropDownMembershipType: [],
    loading: false
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(GetMembershipTypedropdown.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(GetMembershipTypedropdown.fulfilled, (state, action) => {
      state.DropDownMembershipType = action.payload;
      state.loading = false;
    });
    builder.addCase(GetMembershipTypedropdown.rejected, (state) => {
      state.loading = true;
    });
  }
});

export default MembershipTypeDropdownSlice.reducer;