
import "primeicons/primeicons.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.css";
import "primeflex/primeflex.css";
import ReactDOM from "react-dom";

import React, { useState } from "react";
import { SelectButton } from "primereact/selectbutton";

const SelectButtonDemo = ({ handleFilterChange }) => {
  // const [value1, setValue1] = useState("Weekly");
 
  const [value1, setValue1] = useState("Weekly");
  const options = ["Today", "Weekly", "Last 30 days"];


  // console.log("clicked ======", value1)

  return (
    <div>
      <div>
        <SelectButton
          value={value1}
          options={options}
          onChange={(e) => {
            if(e.value != null){
            handleFilterChange(e.value)
            setValue1(e.value)
            }
          }}
        />
      </div>
    </div>
  );
};

export default SelectButtonDemo;