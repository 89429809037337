import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, GET } from "../_main/constants";
import callfetch from "../_main/fetch";
import { GET_DROPDOWNPATIENT } from "../_main/actiontype";

export const Getpatientdropdown = createAsyncThunk(
  GET_DROPDOWNPATIENT + "/Getpatientdropdown",
  async (params) => {
    const token = localStorage.getItem("AccessToken");

    const option = {
      method: GET,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const url = `${API_URL + "Patient/getdropdownitems"}`;
    const response = await callfetch(url, option);
    const value = await response;
    // print the value
    
    return value;
  }
);

const PatientDropdownSlice = createSlice({
  name: GET_DROPDOWNPATIENT,
  initialState: {
    DropDownpatient: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(Getpatientdropdown.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(Getpatientdropdown.fulfilled, (state, action) => {
      state.DropDownpatient = action.payload;
      state.loading = false;
    });
    builder.addCase(Getpatientdropdown.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default PatientDropdownSlice.reducer;
