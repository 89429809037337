export const BASE_ROUTE = "/";
export const REGISTER_ROUTE = "/signup";
export const FORGOT_ROUTE = "/forgot";
export const RESET_ROUTE = "/reset";
export const PROFILE_ROUTE = "/careprovider/profile";
export const STAFF_PROFILE_ROUTE = "/staff/profile";
export const PATIENT_PROFILE_ROUTE = "/patient/profile";
export const DASHBOARD_ROUTE = "/careprovider/dashboard";
export const STAFF_DASHBOARD_ROUTE = "/staff/dashboard";
export const ORGANIZATION_ROUTE = "/careprovider/organization";
export const TWILIO_ROUTE = "/twilio";
export const EDITPROFILE_ROUTE = "/editprofile";
export const MEDICAL_ROUTE = "/medical";
export const PATIENTDASHBOARD_ROUTE = "/patientdashboard";
export const INDIVIDUALPATIENTDETAILS_ROUTE = "/careprovider/myhealth";
export const INDIVIDUALPATIENTDETAILSS_ROUTE = "/staff/myhealth";
export const INDIVIDUALPATIENTDETAILSSS_ROUTE = "/patient/myhealth";
export const PATIENTDETAILS_ROUTE = "/patientdetails";
export const MANAGEPATIENT_ROUTE = "/careprovider/managepatients";
export const STAFF_MANAGEPATIENT_ROUTE = "/staff/managepatients";
export const MANAGESTAFF_ROUTE = "/careprovider/managestaffs";
export const EDITSTAFF_ROUTE = "/careprovider/edit-staff";
export const ADDSTAFF_ROUTE = "/careprovider/add-staff";
export const CALENDAREVE_ROUTE = "/careprovider/viewcalendar";
export const NOTIFICATION_ROUTE = "/notification";
export const MEETINGLOGS_ROUTE = "/careprovider/meetinglogs";
export const STAFF_MEETINGLOGS_ROUTE = "/staff/meetinglogs";
export const MEETINGDETAILS_ROUTE = "/careprovider/meetingdetails";
export const STAFF_MEETINGDETAILS_ROUTE = "/staff/meetingdetails";
export const SCHEDULEMEETING_ROUTE = "/schedulemeeting";
export const DMEETING_ROUTE = "/dmeeting";
export const ONGOINGCALL_ROUTE = "/ongoingcall";
export const ADDPATIENT_ROUTE = "/patientform";
export const CHAT_ROUTE = "/patient/chat";
export const CARE_CHAT_ROUTE = "/careprovider/chat";
export const STAFF_CHAT_ROUTE = "/staff/chat";
export const CHANGEPASSWORD_ROUTE = "/changepassword";
export const PATIENTS_ROUTE = "/careprovider/add-patient";
export const EDIT_PATIENTS_ROUTE = "/careprovider/edit-patient";
export const PEDIT_PATIENTS_ROUTE = "/patient/edit-patient";
export const SEDIT_PATIENTS_ROUTE = "/staff/edit-patient";
export const STAFF_PATIENTS_ROUTE = "/staff/add-patient";
export const POPUP_ROUTE = "/popup";
export const REPORT_ROUTE = "/reports";
export const PATIENTHEALTH_ROUTE = "/careprovider/patienthealth";
export const NOTIFICATIONPAGE_ROUTE = "/notificationpage";
export const NEWNOTIFICATIONS_ROUTE = "/newnotifications";
export const PATIENTREQUEST_ROUTE = "/patientrequest";
export const APPOINTMENT_ROUTE = "/patient/myappointment";
