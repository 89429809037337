import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API_URL, POST } from "../_main/constants";
import callfetch from "../_main/fetch";
import { POST_EVENTLIST } from "../_main/actiontype";

export const addEventList = createAsyncThunk(
  POST_EVENTLIST + "/addEventList",
  async (params) => {
    const token = localStorage.getItem("AccessToken");

    const option = {
      method: POST,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };

    const url = `${API_URL + "Event/list"}`;
    const response = await callfetch(url, option);
    const value = await response;
    return value;
  }
);

const EventListSlice = createSlice({
  name: POST_EVENTLIST,
  initialState: {
    EventList: [],
    loading: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addEventList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addEventList.fulfilled, (state, action) => {
      state.Register = action.payload;
      state.loading = false;
    });
    builder.addCase(addEventList.rejected, (state) => {
      state.loading = true;
    });
  },
});

export default EventListSlice.reducer;
