import React from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useNavigate } from "react-router-dom";
import Footer from '../../Component/Footer'   
import { MEETINGDETAILS_ROUTE } from "../../_main/routeConstants";
import './styles.css'

function Meetinglog() {

    const navigate = useNavigate();

    const navigateToEdit = () => {
        navigate(MEETINGDETAILS_ROUTE);
      };

    // const [selectedProduct1, setSelectedProduct1] = useState(null);

    const data1 = [
        { date: "11/7/2020 | 10:05:03 PM", id: "2d965eb486", type: "Group", time: "30 Minutes", status: "Completed" },
        { date: "11/7/2020 | 10:05:03 PM", id: "2d965eb486", type: "Group", time: "30 Minutes", status: "Completed" },
        { date: "11/7/2020 | 10:05:03 PM", id: "2d965eb486", type: "Group", time: "30 Minutes", status: "Completed" },
        { date: "11/7/2020 | 10:05:03 PM", id: "2d965eb486", type: "Group", time: "30 Minutes", status: "Completed" },
        { date: "11/7/2020 | 10:05:03 PM", id: "2d965eb486", type: "Group", time: "30 Minutes", status: "Completed" },
        { date: "11/7/2020 | 10:05:03 PM", id: "2d965eb486", type: "Group", time: "30 Minutes", status: "Completed" }
    ]
    return (
        <div>
            <div className="ml-title1">Meeting Logs</div>
            <div className="card9">
                <DataTable className='ml-text1' selectionMode="single" onClick={navigateToEdit} value={data1} >
                    <Column field="date" header="DATE"></Column>
                    <Column field="id" header="MEETING ID"></Column>
                    <Column field="type" header="TYPE"></Column>
                    <Column field="time" header="DURATION"></Column>
                    <Column field="status" header="STATUS"></Column>
                </DataTable>
            </div>
            <Footer />
        </div>
    )
}

export default Meetinglog