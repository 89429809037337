import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const Config = {
  apiKey: "AIzaSyARlT7MAaIeMPFFkvcwimgtchrJf2lqx_U",
  authDomain: "rmt-health.firebaseapp.com",
  projectId: "rmt-health",
  storageBucket: "rmt-health.appspot.com",
  messagingSenderId: "770980863738",
  appId: "1:770980863738:web:55bd0f3fc9e11359887343",
  measurementId: "G-P9P0NYC9X6",
};
initializeApp(Config);
const messaging = getMessaging();

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey:
      "BOupTnMjOrgUhomlvLJI_Syg7wU_u3I9bJ3u0ZZ6HX4F-6zPWblIrh4jR_fBKIdc0AI5jY5A-WkDswUu2Eyu3Ps",
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    // debugger
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
